import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  lastValueFrom,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  switchMap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user.interface';
import { Response } from '../types/interfaces/response.interface';
import { UpdateDisclaimerAgreementDto } from '../dto/update-disclaimer-agreement.dto';
import { AuthService } from './auth.service';
import { UserProfile } from 'src/app/user-profile/interfaces/user-profile.interface';
import { UserCreate } from '../interfaces/user-create';
import { PagedResponse } from '../interfaces/paged-response';
import { GridParams } from '../interfaces/grid-params.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl = environment.apiUrl;

  http = inject(HttpClient);

  private authService = inject(AuthService);

  private currentUserId$ = new ReplaySubject<number>(1);

  userSubject = this.currentUserId$.pipe(
    distinctUntilChanged(),
    switchMap((currentUserId) => this.getById(currentUserId)),
  );

  constructor() {
    this.updateCurrentUserId();
  }

  public selectUser() {
    this.updateCurrentUserId();

    return this.userSubject;
  }

  public async GetAllUsers(internalUser: boolean) {
    return await lastValueFrom(this.selectAllUsers(internalUser));
  }

  public async GetAllUsersWithPaging(params: GridParams, isInternal: boolean) {
    return await lastValueFrom(
      this.http.post<PagedResponse>(
        `${this.apiUrl}/User/GetAllUsersPaginationRequest/${isInternal}`,
        params,
      ),
    );
  }

  public selectAllUsers(internalUser: boolean) {
    return this.http
      .get<Response<User[]>>(`${this.apiUrl}/User/GetAllUsers/${internalUser}`)
      .pipe(map((response) => response.data));
  }

  public setSwitchingPlatform(allowSwitchingPlatform: boolean) {
    console.log(allowSwitchingPlatform);
    this.updateCurrentUserId();
  }

  async getActiveUsers() {
    return await lastValueFrom(this.selectActiveUsers());
  }

  selectActiveUsers() {
    return this.http
      .get<Response<User[]>>(`${this.apiUrl}/User/GetByCondition`)
      .pipe(map((response) => response.data));
  }

  async getActiveInternalUsers() {
    return await lastValueFrom(this.selectActiveInternalUsers());
  }

  selectActiveInternalUsers() {
    return this.http
      .get<Response<User[]>>(`${this.apiUrl}/User/GetByConditionExpression`)
      .pipe(
        map((response) => response.data),
        shareReplay(1),
      );
  }

  UpdateDisclaimerAgreement() {
    const url = `${this.apiUrl}/UserPreference/UpdateDisclaimerAgreement`;

    return this.http.put<Response<UpdateDisclaimerAgreementDto>>(url, {}).pipe(
      map((response) => response.data),
      map(
        (updateDisclaimerAgreementDto) =>
          updateDisclaimerAgreementDto.value === 'True',
      ),
    );
  }

  getById(id: number): Observable<User> {
    return this.http
      .get<Response<User>>(`${this.apiUrl}/User/GetById/${id}`)
      .pipe(map((response) => response.data));
  }

  updateCurrentUserId() {
    this.currentUserId$.next(this.authService.getUserId());
  }

  async updateUserAdministratorStatus(user: User) {
    const url = `${this.apiUrl}/User/UpdateUser`;

    return await lastValueFrom(
      this.http
        .put<Response<User>>(url, user)
        .pipe(map((response) => response.succeeded)),
    );
  }

  async updateUserProfile(user: UserProfile) {
    const url = `${this.apiUrl}/User/UpdateUserProfile`;

    return await lastValueFrom(
      this.http
        .put<Response<User>>(url, user)
        .pipe(map((response) => response.succeeded)),
    );
  }

  public createUser(user: UserCreate) {
    return this.http
      .post<Response<User>>(
        `${this.apiUrl}/User/CreateUserByBusinessEntity`,
        user,
      )
      .pipe(map((response) => response.data));
  }

  public selectUserByUserId(userId: number) {
    return this.http
      .get<Response<User>>(`${this.apiUrl}/User/GetById/${userId}`)
      .pipe(map((response) => response.data));
  }

  public updateUser(user: UserCreate, userId: number) {
    return this.http
      .put<Response<User>>(`${this.apiUrl}/User/UpdateUserByBusinessEntity`, {
        ...user,
        id: userId,
      })
      .pipe(map((response) => response.data));
  }

  public deleteUser(userId: number) {
    const options = {
      params: { userId },
    };
    return this.http
      .delete<Response<boolean>>(`${this.apiUrl}/User`, options)
      .pipe(map((response) => response.data));
  }
}
