import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CreateFolder } from '../../interfaces/create-folder';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { DIALOG_DATA } from '../../../dialog/tokens/DIALOG_DATA';
import { FileNameValidator } from '../../validators/filename.validator';
import { FormUrl } from 'src/app/shared/constants/form-url';
import { TranslateNewPipe } from 'src/app/translation/pipes/translate-new.pipe';

@Component({
  selector: 'app-data-rooms-folder-form',
  standalone: true,
  templateUrl: './folder-form.component.html',
  styleUrls: ['./folder-form.component.scss'],
  imports: [
    NgIf,
    AsyncPipe,
    TranslateNewPipe,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
})
export class FolderFormComponent {
  formUrl = FormUrl.DataRoomsFolderForm;

  @Input()
  set folder(folder: CreateFolder) {
    this.form.patchValue(folder);
  }

  @Output()
  public save = new EventEmitter<CreateFolder>();

  private dialogRef = inject(DIALOG_DATA).dialogRef;

  protected form = new FormGroup({
    nameEn: new FormControl('', {
      validators: [Validators.required, FileNameValidator()],
      nonNullable: true,
    }),
    nameDe: new FormControl('', {
      validators: [Validators.required, FileNameValidator()],
      nonNullable: true,
    }),
    isPrivate: new FormControl(false, { nonNullable: true }),
    hasApproval: new FormControl(false, { nonNullable: true }),
    allowComment: new FormControl(false, { nonNullable: true }),
    orderNo: new FormControl(0, {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  protected onFormSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.save.emit(this.form.getRawValue());
  }

  protected closeDialog() {
    this.dialogRef.close(false);
  }
}
