import { Component, inject } from '@angular/core';
import { AsyncPipe, NgIf, NgFor } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/shared/services/users.service';
import { DIALOG_DATA } from 'src/app/dialog/tokens/DIALOG_DATA';
import { ListItem } from '../../interfaces/list-item';
import { MatFormFieldModule } from '@angular/material/form-field';
import { User } from 'src/app/shared/interfaces/user.interface';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { DataRoomApproval } from '../../interfaces/data-room-approval.interface';
import { DataRoomService } from '../../services/data-room.service';
import {
  BehaviorSubject,
  lastValueFrom,
  map,
  ReplaySubject,
  switchMap,
  take,
} from 'rxjs';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { AutocompleteMultipleComponent } from '../../../shared/components/autocomplete-multiple/autocomplete-multiple.component';
import { AutocompleteOption } from '../../../shared/interfaces/autocomplete-option';
import { SpinnerDirective } from 'src/app/shared/directives/loading-spinner.directive';
import { FormUrl } from 'src/app/shared/constants/form-url';
import { TranslateNewPipe } from 'src/app/translation/pipes/translate-new.pipe';

@Component({
  selector: 'app-approval',
  standalone: true,
  imports: [
    AsyncPipe,
    NgFor,
    NgIf,
    TranslateNewPipe,
    SpinnerDirective,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    AutocompleteMultipleComponent,
    FormFieldComponent,
  ],
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss'],
  providers: [DataRoomService],
})
export class ApprovalComponent {
  private userService = inject(UserService);
  private dataRoomService = inject(DataRoomService);
  private dialogData = inject(DIALOG_DATA);

  formUrl = FormUrl.DataRoomsApproval;

  protected itemInformation = this.dialogData.data as ListItem;

  private allUsers$ = new ReplaySubject<User[]>(1);

  isSubmitting$ = new BehaviorSubject(true);

  protected users = new FormControl<number[]>([], { nonNullable: true });

  protected userOptions$ = this.selectUserOptions();

  protected possibleMainUsersList$ = this.selectPossibleMainUserList();

  protected form = new FormGroup({
    mainUser: new FormControl<number | null>(null, Validators.required),
  });

  constructor() {
    this.loadAllUsers();
  }

  protected loadApprovals() {
    this.dataRoomService
      .loadApprovals(this.itemInformation.id, this.itemInformation.dataRoomId)
      .pipe(take(1))
      .subscribe((approvals) => {
        const selectedUserIds = approvals
          .map((approval) => approval.approvedById)
          .filter((userId) => userId !== null) as number[];

        this.users.setValue(selectedUserIds);

        const mainUser = approvals.find((approval) => approval.isMain);
        if (mainUser) {
          this.form.get('mainUser')?.setValue(mainUser.approvedById);
        }
      });
  }

  private selectUserOptions() {
    return this.allUsers$.pipe(
      map((users) =>
        users.map(
          (user): AutocompleteOption => ({
            key: user.id,
            text: `${user.name} ${user.surname}`,
          }),
        ),
      ),
    );
  }

  private selectPossibleMainUserList() {
    return this.users.valueChanges.pipe(
      switchMap((selectedUsersList) =>
        this.allUsers$.pipe(
          map((users) =>
            users.filter((user) => selectedUsersList.includes(user.id)),
          ),
        ),
      ),
    );
  }

  private async loadAllUsers() {
    const allUsers = await lastValueFrom(this.userService.selectAllUsers(true));
    this.loadApprovals();

    this.allUsers$.next(allUsers);
    this.isSubmitting$.next(false);
  }

  protected async approval() {
    this.form.markAllAsTouched();

    const { dataRoomId, isDirectory, id, parentId } = this.itemInformation;

    const approvalsList: DataRoomApproval[] = this.users.value.map(
      (userId) => ({
        dataRoomId: dataRoomId ? dataRoomId : 0,
        approvedById: userId,
        isMain: this.form.get('mainUser')?.value === userId,
        folderId: isDirectory ? id : parentId == null ? null : parentId,
        id: null,
      }),
    );

    if (approvalsList.length === 0) {
      approvalsList.push({
        dataRoomId: dataRoomId ? dataRoomId : 0,
        approvedById: null,
        isMain: null,
        folderId: isDirectory ? id : parentId,
        id: null,
      });
    }

    try {
      if (await this.dataRoomService.submitApprovals(approvalsList)) {
        this.dialogData.dialogRef.close();
      }
    } catch (error) {
      return;
    }
  }
}
