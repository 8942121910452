<dialog
  #dialog
  [ngClass]="{ 'no-padding': backgroundImage }"
  [style.width]="width"
  [style.background-color]="backgroundColor"
>
  <div
    class="dialog-head"
    [class.image-part]="!!backgroundImage"
    [style.background-image]="getImage()"
  >
    <app-shared-circled-icon
      *ngIf="icon"
      [icon]="icon"
      [highlightIcon]="highlightIcon"
      [iconColor]="iconColor"
    />
    <div class="dialog-title" *ngIf="headline">{{ headline | async }}</div>
    <div class="dialog-subtitle" *ngIf="subHeadline">
      {{ subHeadline | async }}
    </div>
    <button
      *ngIf="!showCloseIcon"
      mat-icon-button
      type="button"
      class="close"
      [ngClass]="{ 'close-icon-background': closeIconBackground }"
      [style.background-color]="closeIconBackground"
      (click)="close()"
    >
      <mat-icon svgIcon="empira:x_close" />
    </button>
  </div>
  <div class="dialog-content">
    <ng-template appDialogContent />
  </div>
</dialog>
