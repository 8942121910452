import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  BehaviorSubject,
  lastValueFrom,
  map,
  Observable,
  shareReplay,
} from 'rxjs';
import { Response } from '../../shared/types/interfaces/response.interface';
import { environment } from 'src/environments/environment';
import { FormResource } from '../interfaces/form-resource.interface';
import { mapFormResourcesListToTranslationList } from '../../translation/mappers/map-form-resources-list-to-translation-list';
import { TranslationList } from '../../translation/interfaces/translation-list';
import { FormUrl } from '../../shared/constants/form-url';

@Injectable({
  providedIn: 'root',
})
export class FormResourcesService {
  private apiUrl = environment.apiUrl;
  private httpClient = inject(HttpClient);

  protected componentName$ = new BehaviorSubject<string | null>(null);

  setComponentName(name: string | null) {
    this.componentName$.next(name);
  }

  getComponentName() {
    return this.componentName$.getValue() ?? '';
  }

  loadFormResourcesByUrl(formUrl: FormUrl): Observable<TranslationList> {
    return this.httpClient
      .post<Response<FormResource[]>>(
        `${this.apiUrl}/FormResource/GetFormResourceByUrl`,
        { formUrl },
      )
      .pipe(
        map((response) => response.data),
        map(mapFormResourcesListToTranslationList),
        shareReplay(1),
      );
  }

  getFormResource(id: number) {
    return lastValueFrom(
      this.httpClient
        .get<Response<FormResource>>(
          `${this.apiUrl}/FormResource/GetById/${id}`,
        )
        .pipe(map((response) => response.data)),
    );
  }

  async createFormResource(formResource: FormResource) {
    return lastValueFrom(
      this.httpClient
        .post<Response<FormResource>>(
          `${this.apiUrl}/FormResource/Post`,
          formResource,
        )
        .pipe(map((response) => response.succeeded)),
    );
  }

  updateFormResource(formResource: FormResource) {
    const options = {
      params: { id: formResource.id },
    };
    return lastValueFrom(
      this.httpClient
        .put<Response<FormResource>>(
          `${this.apiUrl}/FormResource`,
          formResource,
          options,
        )
        .pipe(map((response) => response.succeeded)),
    );
  }

  async deleteFormResource(id: number) {
    const options = {
      params: { id },
    };
    return lastValueFrom(
      this.httpClient
        .delete<Response<FormResource>>(`${this.apiUrl}/FormResource`, options)
        .pipe(map((response) => response.succeeded)),
    );
  }
}
