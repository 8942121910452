import { LanguageItem } from 'src/app/translation/interfaces/language-item';

export interface TimeZoneData {
  id: number;
  displayName: string;
  displayNameDe: string;
  displayText: LanguageItem;
  baseUtcOffset: number;
  createdOn: Date;
  createdBy: string;
  changedOn: Date;
  changedBy: string;
}

export function timezoneDto(models: TimeZoneData[]): TimeZoneData[] {
  return models.map((model) => ({
    ...model,
    displayText: {
      EN: model.displayName,
      DE: model.displayNameDe,
    },
  }));
}
