import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { SafeUrl } from '@angular/platform-browser';
import { InitialsPipe } from '../../pipes/initials.pipe';

@Component({
  selector: 'app-shared-profile-picture',
  standalone: true,
  imports: [NgClass, NgIf, InitialsPipe],
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePictureComponent {
  @Input()
  profilePicture?: SafeUrl | string | null;

  @Input()
  name?: string;

  @Input()
  hasBorder = false;

  @Input()
  hasShadow = false;
}
