export enum TemplateSettingId {
  Country = 1,
  State = 2,
  City = 3,
  Currency = 4,
  FinancialEntityType = 5,
  GroupsOfInvestors = 6,
  Gender = 7,
  FundType = 8,
  ClassificationOfProduct = 9,
  StrategyOfTheProduct = 10,
  ProductSector = 11,
  LegalFundStructure = 12,
  ESGClassification = 13,
  ProductStatus = 14,
  FundStructureType = 15,
  AssetClass = 16,
  ProjectType = 17,
  ESGCertification = 18,
  FinancingPhase = 19,
  LoanType = 20,
  DealType = 21,
  StageDeal = 22,
  StatusComment = 23,
  ContactUsType = 24,
  DisplayStyle = 25,
  DealsUseType = 26,
  DealsSource = 27,
  SpaceUseAssetClassTypes = 28,
}
