import { UpdateDocumentDto } from '../dtos/update-document.dto';
import { getFileTypeFromFilename } from './helpers/get-file-type-from-filename';
import { blobToBase64File } from '../../shared/helpers/blob-to-base64-file';
import { BaseFile } from '../interfaces/base-file';
import { DocumentDto } from '../dtos/document.dto';

export async function mapUpdateFileToUpdateDocumentDto(
  baseFile: BaseFile,
  folderId: number,
  oldFile: DocumentDto,
): Promise<UpdateDocumentDto> {
  return {
    id: oldFile.id,
    fileName: baseFile.name,
    fileType: baseFile.file
      ? getFileTypeFromFilename(baseFile.file.name)
      : oldFile.fileType,
    mimeType: baseFile.file?.type || oldFile.mimeType,
    fileSize: baseFile.file?.size || oldFile.fileSize,
    description: baseFile.description || '',
    documentPath: oldFile.documentPath,
    doc: baseFile.file ? await blobToBase64File(baseFile.file) : oldFile.doc,
    isPublic: baseFile.isPublic,
    allowComment: baseFile.allowComment,
    effectiveFrom: baseFile.effectiveFrom ?? new Date(),
    effectiveTo: baseFile.effectiveTo,
    folderId,
  };
}
