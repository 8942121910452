import { inject, Injectable } from '@angular/core';
import { DEFAULT_LANGUAGE } from '../constants/default-language';
import { Language } from '../interfaces/language';
import { LanguageItem } from '../interfaces/language-item';
import { TranslationList } from '../interfaces/translation-list';
import { FormResourcesService } from '../../form-resources/services/form-resources.service';
import { FormUrl } from '../../shared/constants/form-url';
import { BehaviorSubject, combineLatest, lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private formResourcesService = inject(FormResourcesService);

  currentLanguageSubject = new BehaviorSubject(localStorage.getItem('lang'));
  currentLanguage$ = this.currentLanguageSubject.asObservable();

  private translationsDict$ = new BehaviorSubject<{
    [formUrl: string]: TranslationList;
  }>({});

  private loadedFormUrls: FormUrl[] = [];

  getTranslation(list: TranslationList, key: string): string {
    if (!list || !(key in list)) {
      return key;
    }

    return this.getTranslationByLanguageItem(list[key].value);
  }

  getDescription(list: TranslationList, key: string) {
    if (!list || !(key in list)) {
      return;
    }

    const descriptionItem = list[key].description;

    if (!descriptionItem) {
      return;
    }

    return this.getTranslationByLanguageItem(descriptionItem);
  }

  getTranslationByLanguageItem(item: LanguageItem): string {
    const currentLang = this.getCurrentLanguage();
    return item[currentLang] || item[DEFAULT_LANGUAGE];
  }

  updateCurrentLanguageName(lang: string) {
    this.currentLanguageSubject.next(lang);
  }

  getCurrentLanguageName() {
    return this.currentLanguage$.pipe(
      map((currentLang) => {
        switch (currentLang) {
          case 'DE':
            return 'Deutsch';
          case 'EN':
            return 'English';
          default:
            return 'English';
        }
      }),
    );
  }

  public selectTranslationByKey(key: string, formUrl: FormUrl) {
    this.loadTranslation(formUrl);

    return this.selectTranslationItems(formUrl).pipe(
      map((translationList) => this.getTranslation(translationList, key)),
    );
  }

  public selectDescriptionByKey(key: string, formUrl: FormUrl) {
    this.loadTranslation(formUrl);

    return this.selectTranslationItems(formUrl).pipe(
      map((translationList) => this.getDescription(translationList, key)),
    );
  }

  public selectTranslationListByKeyList(keyList: string[], formUrl: FormUrl) {
    return combineLatest(
      keyList.map((key) => this.selectTranslationByKey(key, formUrl)),
    );
  }

  public selectTranslationItems(formUrl: FormUrl) {
    return this.translationsDict$.pipe(map((dict) => dict[formUrl]));
  }

  public preloadTranslations(formUrlList: FormUrl[]) {
    formUrlList.forEach((formUrl) => this.loadTranslation(formUrl));
  }

  public async loadTranslation(formUrl: FormUrl) {
    if (this.loadedFormUrls.includes(formUrl)) {
      return;
    }

    this.loadedFormUrls.push(formUrl);
    const newTranslationList = await lastValueFrom(
      this.formResourcesService.loadFormResourcesByUrl(formUrl),
    );

    const newDictValue = this.translationsDict$.getValue();
    newDictValue[formUrl] = newTranslationList;

    this.translationsDict$.next(newDictValue);
  }

  public removeTranslationByFormUrl(formUrl: FormUrl) {
    const key = this.loadedFormUrls.findIndex((value) => value === formUrl);
    if (key >= 0) {
      delete this.loadedFormUrls[key];

      const newTranslate = this.translationsDict$.getValue();
      delete newTranslate[formUrl];

      this.translationsDict$.next(newTranslate);
    }
  }

  public resetLoadedTranslations() {
    this.loadedFormUrls = [];
    this.translationsDict$.next({});
  }

  private getCurrentLanguage() {
    return (localStorage.getItem('lang') as Language) || DEFAULT_LANGUAGE;
  }
}
