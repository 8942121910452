<form [formGroup]="form" (submit)="onFormSubmit()">
  <mat-form-field>
    <mat-label
      data-translation-key="data-rooms-folder-form-name-en"
      data-translation-value="Name en"
      >{{ "data-rooms-folder-form-name-en" | translateNew: formUrl }}</mat-label
    >
    <input matInput type="text" formControlName="nameEn" />
    <mat-error
      data-translation-key="data-rooms-folder-form-this-field-is-required"
      data-translation-value="This field is required"
      *ngIf="form.controls.nameEn.hasError('required')"
      >{{
        "data-rooms-folder-form-this-field-is-required" | translateNew: formUrl
      }}</mat-error
    >
    <mat-error
      data-translation-key="data-rooms-folder-form-this-field-is-required"
      data-translation-value="The folder name is not valid"
      *ngIf="form.controls.nameEn.hasError('filename')"
      >{{
        "data-rooms-folder-form-the-folder-name-is-not-valid"
          | translateNew: formUrl
      }}</mat-error
    >
  </mat-form-field>
  <mat-form-field>
    <mat-label
      data-translation-key="data-rooms-folder-form-name-de"
      data-translation-value="Name de"
      >{{ "data-rooms-folder-form-name-de" | translateNew: formUrl }}</mat-label
    >
    <input matInput type="text" formControlName="nameDe" />
    <mat-error
      data-translation-key="data-rooms-folder-form-this-field-is-required"
      data-translation-value="This field is required"
      *ngIf="form.controls.nameDe.hasError('required')"
    >
      {{
        "data-rooms-folder-form-this-field-is-required" | translateNew: formUrl
      }}
    </mat-error>
    <mat-error
      data-translation-key="data-rooms-folder-form-this-field-is-required"
      data-translation-value="The folder name is not valid"
      *ngIf="form.controls.nameDe.hasError('filename')"
    >
      {{
        "data-rooms-folder-form-the-folder-name-is-not-valid"
          | translateNew: formUrl
      }}
    </mat-error>
  </mat-form-field>
  <div class="checkbox-group">
    <mat-checkbox
      data-translation-key="data-rooms-folder-form-is-private"
      data-translation-value="Is private"
      >{{
        "data-rooms-folder-form-is-private" | translateNew: formUrl
      }}</mat-checkbox
    >
    <mat-checkbox
      data-translation-key="data-rooms-folder-form-has-approval"
      data-translation-value="Has approval"
      >{{
        "data-rooms-folder-form-has-approval" | translateNew: formUrl
      }}</mat-checkbox
    >
    <mat-checkbox
      data-translation-key="data-rooms-folder-form-allow-comment"
      data-translation-value="Allow comment"
      >{{
        "data-rooms-folder-form-allow-comment" | translateNew: formUrl
      }}</mat-checkbox
    >
  </div>
  <mat-form-field>
    <mat-label
      data-translation-key="data-rooms-folder-form-order-no"
      data-translation-value="Order no"
      >{{
        "data-rooms-folder-form-order-no" | translateNew: formUrl
      }}</mat-label
    >
    <input matInput type="number" formControlName="orderNo" />
  </mat-form-field>
  <div class="button-container">
    <button
      type="button"
      data-translation-key="btn-cancel;"
      data-translation-value="Cancel"
      (click)="closeDialog()"
      mat-flat-button
    >
      {{ "btn-cancel" | translateNew: formUrl }}
    </button>

    <button
      data-translation-key="save-btn"
      data-translation-value="Save"
      mat-flat-button
      color="primary"
    >
      {{ "save-btn" | translateNew: formUrl }}
    </button>
  </div>
</form>
