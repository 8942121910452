import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Observable, of } from 'rxjs';
import { DialogContentDirective } from '../../directives/dialog-content.directive';
import { DIALOG_DATA } from '../../tokens/DIALOG_DATA';
import { DialogColor } from '../../interfaces/dialog-color';
import { TranslatePipe } from '../../../translation/pipes/translate.pipe';
import { CircledIconComponent } from '../../../shared/components/circled-icon/circled-icon.component';
import { EmpiraIcon } from '../../../icons/icons';
import { IconService } from '../../../icons/services/icon.service';

@Component({
  selector: 'app-dialog',
  standalone: true,
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    DialogContentDirective,
    TranslatePipe,
    AsyncPipe,
    CircledIconComponent,
  ],
})
export class DialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public icon?: EmpiraIcon;

  @Input()
  public highlightIcon = false;

  @Input()
  public iconColor: DialogColor = 'primary';

  @Input()
  public backgroundImage?: string;

  @Input()
  public backgroundColor?: string;

  @Input()
  public set headline(headline) {
    this._headline = headline;
  }
  public get headline() {
    return typeof this._headline === 'string'
      ? of(this._headline)
      : this._headline;
  }
  private _headline?: string | Observable<string>;

  @Input()
  public set subHeadline(subHeadline) {
    this._subHeadline = subHeadline;
  }
  public get subHeadline() {
    return typeof this._subHeadline === 'string'
      ? of(this._subHeadline)
      : this._subHeadline;
  }
  private _subHeadline?: string | Observable<string>;

  @Input()
  public width?: string;

  @Input()
  public showCloseIcon = false;

  @Input()
  public closeIconBackground?: string;

  @ViewChild(DialogContentDirective)
  private insertionPoint!: DialogContentDirective;

  public componentRef?: ComponentRef<unknown>;

  public childComponentType!: Type<unknown>;

  @ViewChild('dialog', { static: true })
  public dialog!: ElementRef<HTMLDialogElement>;

  private cdr = inject(ChangeDetectorRef);

  private dialogData = inject(DIALOG_DATA);

  constructor() {
    inject(IconService).registerIcon('x_close');
  }

  public ngOnInit() {
    this.dialog.nativeElement.addEventListener('close', () => {
      this.close();
    });
  }

  public ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cdr.detectChanges();
  }

  public ngOnDestroy() {
    this.componentRef?.destroy();
  }

  public open() {
    this.dialog.nativeElement.showModal();
  }

  protected close() {
    this.dialogData.dialogRef.close();
  }

  protected getImage() {
    if (this.backgroundImage) {
      return `url("${this.backgroundImage}")`;
    } else {
      return {};
    }
  }

  private loadChildComponent(componentType: Type<unknown>) {
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentType);
  }
}
