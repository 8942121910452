export function base64ToFile(
  base64: string,
  mimeType: string,
  filename = 'tmp',
) {
  const fileString = base64.startsWith('data:')
    ? base64
    : `data:${mimeType};base64,${base64}`;

  const stringArray = fileString.split(',');

  const byteString = atob(stringArray[stringArray.length - 1]);

  let n = byteString.length;

  const uint8Array = new Uint8Array(n);

  while (n--) {
    uint8Array[n] = byteString.charCodeAt(n);
  }

  return new File([uint8Array], filename, { type: mimeType });
}
