import { Component } from '@angular/core';
import { FormUrl } from '../../constants/form-url';
import { TranslateNewPipe } from '../../../translation/pipes/translate-new.pipe';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [TranslateNewPipe],
})
export class SpinnerComponent {
  protected formUrl = FormUrl.SpinnerComponent;
}
