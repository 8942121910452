import { NgForOf } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DxToolbarModule } from 'devextreme-angular';
import { IconService } from '../../../icons/services/icon.service';
import { TranslateNewPipe } from '../../../translation/pipes/translate-new.pipe';
import { FormUrl } from '../../constants/form-url';
import { SelectionToolbarAction } from '../../interfaces/selection-toolbar-action.interface';

@Component({
  selector: 'app-shared-selection-toolbar',
  standalone: true,
  imports: [
    NgForOf,
    DxToolbarModule,
    MatButtonModule,
    MatIconModule,
    TranslateNewPipe,
  ],
  templateUrl: './selection-toolbar.component.html',
  styleUrls: ['./selection-toolbar.component.scss'],
})
export class SelectionToolbarComponent {
  @Input() public selectedCount = 0;

  @Input() public set actions(actions) {
    this.iconService.registerIconList(actions.map((action) => action.icon));
    this._actions = actions;
  }
  public get actions() {
    return this._actions;
  }
  private _actions: SelectionToolbarAction[] = [];

  @Output() public deselectAll = new EventEmitter<void>();

  protected formUrl = FormUrl.SharedSelectionToolbar;

  private iconService = inject(IconService);

  constructor() {
    inject(IconService).registerIcon('x');
  }
}
