<ng-container *appSpinner="isSubmitting$ | async" />

<form [formGroup]="form" (ngSubmit)="sharePermission()">
  <div class="user-group">
    <mat-radio-group color="primary" formControlName="userType">
      <mat-radio-button [value]="1">{{
        "permission-internal-user" | translateNew: formUrl
      }}</mat-radio-button>
      <mat-radio-button [value]="2">{{
        "permission-external-user" | translateNew: formUrl
      }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <app-shared-form-field
    *ngIf="userOptions$ | async as userOptions"
    [condensed]="true"
  >
    <mat-label>{{
      "approval-selected-users" | translateNew: formUrl
    }}</mat-label>
    <app-shared-autocomplete-multiple
      formControlName="users"
      [options]="userOptions"
    />
  </app-shared-form-field>

  <div class="notify-user">
    <mat-checkbox formControlName="notifyByEmail" color="primary">{{
      "permission-notify-email" | translateNew: formUrl
    }}</mat-checkbox>
  </div>

  <div class="button-container">
    <button
      mat-flat-button
      type="button"
      (click)="closeDialog()"
      class="cancel"
      data-translation-key="cancel-btn"
      data-translation-value="Cancel"
    >
      {{ "cancel-btn" | translateNew: formUrl }}
    </button>

    <button
      mat-flat-button
      type="submit"
      color="primary"
      data-translation-value="Share permission"
      data-translation-key="data-rooms-permission-share-permission"
    >
      {{ "data-rooms-permission-share-permission" | translateNew: formUrl }}
    </button>
  </div>
</form>

<ng-container
  data-translation-key="permission-internal-user"
  data-translation-value="Internal Users"
/>

<ng-container
  data-translation-key="permission-external-user"
  data-translation-value="External Users"
/>

<ng-container
  data-translation-key="permission-notify-email"
  data-translation-value="Notify with email"
/>

<ng-container
  data-translation-key="approval-selected-users"
  data-translation-value="Selected Users"
/>
