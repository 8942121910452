import { FolderDto } from '../dtos/folder.dto';
import { BaseFolder } from '../interfaces/base-folder';

export function mapFolderDtoToBaseFolder(folderDto: FolderDto): BaseFolder {
  return {
    id: folderDto.id,
    nameEn: folderDto.nameEn,
    nameDe: folderDto.nameDe,
    parentId: folderDto.parentId,
    isPrivate: folderDto.isPrivate,
    allowComment: folderDto.allowComment,
    hasApproval: folderDto.hasApproval,
    orderNo: folderDto.orderNo,
  };
}
