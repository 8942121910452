import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Response } from '../../shared/types/interfaces/response.interface';
import { UserPreference } from '../interfaces/user-preference.interface';
import { map } from 'rxjs';
import { Language } from '../interfaces/language.interface';
import { TimeZoneData } from '../interfaces/timezone-data';
import { timezoneDto } from 'src/app/shared/interfaces/timezone-template';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  private apiUrl: string = environment.apiUrl;

  private http = inject(HttpClient);

  getAll() {
    return this.http
      .get<Response<UserPreference[]>>(`${this.apiUrl}/UserPreference/GetAll`)
      .pipe(map((response) => response.data));
  }

  getById(id: number) {
    return this.http
      .get<Response<UserPreference>>(
        `${this.apiUrl}/UserPreference/GetById/${id}`,
      )
      .pipe(map((response) => response.data));
  }

  getByUserId(userId: number) {
    return this.http
      .get<Response<UserPreference[]>>(
        `${this.apiUrl}/UserPreference/GetByUserId/${userId}`,
      )
      .pipe(map((response) => response.data));
  }

  getTimeZones() {
    return this.http
      .get<Response<TimeZoneData[]>>(`${this.apiUrl}/TimeZoneData`)
      .pipe(map((response) => timezoneDto(response.data)));
  }

  getLanguages() {
    return this.http
      .get<Response<Language[]>>(
        `${this.apiUrl}/UserPreference/GetLanguageList`,
      )
      .pipe(map((response) => response.data));
  }

  post(userPreferenceData: UserPreference) {
    return this.http
      .post<Response<UserPreference>>(
        `${this.apiUrl}/UserPreference/Post`,
        userPreferenceData,
      )
      .pipe(map((response) => response.data));
  }

  put(id: number, userPreferenceData: UserPreference) {
    const options = {
      params: { id },
    };
    return this.http
      .put<Response<UserPreference>>(
        `${this.apiUrl}/UserPreference`,
        userPreferenceData,
        options,
      )
      .pipe(map((response) => response.data));
  }

  putBatch(userPreferenceData: UserPreference[]) {
    return this.http
      .put<Response<UserPreference>>(
        `${this.apiUrl}/UserPreference/PutBatch`,
        userPreferenceData,
      )
      .pipe(map((response) => response.data));
  }

  delete(id: number) {
    const options = {
      params: { id },
    };
    return this.http
      .delete<Response<boolean>>(`${this.apiUrl}/UserPreference`, options)
      .pipe(map((response) => response.data));
  }

  resetToDefault(userPreferenceData: UserPreference[]) {
    return this.http
      .put<Response<UserPreference[]>>(
        `${this.apiUrl}/UserPreference/ResetToDefault`,
        userPreferenceData,
      )
      .pipe(map((response) => response.data));
  }
}
