<div (appDragAndDrop)="dropFiles($event)">
  <app-shared-selection-toolbar
    *ngIf="itemsSelected.length as selectedCount"
    [selectedCount]="selectedCount"
    [actions]="selectionActions"
    (deselectAll)="deselectAllItems()"
  />
  <dx-toolbar *ngIf="!itemsSelected.length">
    <dxi-item location="before">
      <button
        *ngIf="canApprove"
        mat-flat-button
        type="button"
        (click)="approvalContent()"
        data-translation-value="Approval"
        data-translation-key="data-rooms-list-approval"
      >
        {{ "data-rooms-list-approval" | translateNew: formUrl }}
      </button>
      <button
        mat-flat-button
        type="button"
        (click)="downloadContent()"
        data-translation-value="Download"
        data-translation-key="data-rooms-list-download"
      >
        {{ "data-rooms-list-download" | translateNew: formUrl }}
      </button>
      <button
        *ngIf="canSetPermissionsGlobally"
        mat-flat-button
        type="button"
        (click)="permissionContent()"
        data-translation-value="Permissions"
        data-translation-key="data-rooms-list-permissions"
      >
        {{ "data-rooms-list-permissions" | translateNew: formUrl }}
      </button>
    </dxi-item>
    <dxi-item location="after">
      <button
        *ngIf="canCreateFolder"
        mat-flat-button
        type="button"
        color="primary"
        (click)="openFolderCreateDialog()"
        data-translation-value="Add folder"
        data-translation-key="data-rooms-list-add-folder"
      >
        <mat-icon svgIcon="empira:plus" />
        {{ "data-rooms-list-add-folder" | translateNew: formUrl }}
      </button>
      <button
        *ngIf="canUploadFile || canUploadFolder"
        mat-flat-button
        type="button"
        color="primary"
        [matMenuTriggerFor]="uploadItemsMenu"
        data-translation-value="Upload"
        data-translation-key="data-rooms-list-upload"
      >
        <mat-icon svgIcon="empira:upload_02" />
        {{ "data-rooms-list-upload" | translateNew: formUrl }}
      </button>
      <mat-menu #uploadItemsMenu="matMenu">
        <button
          *ngIf="canUploadFile"
          mat-menu-item
          type="button"
          (click)="openFileCreateDialog()"
          data-translation-value="Files"
          data-translation-key="data-rooms-list-files"
        >
          <mat-icon svgIcon="empira:file_03" />
          {{ "data-rooms-list-files" | translateNew: formUrl }}
        </button>
        <button
          *ngIf="canUploadFolder"
          mat-menu-item
          type="button"
          (click)="openFolderUploadDialog()"
          data-translation-value="Folders"
          data-translation-key="data-rooms-list-folders"
        >
          <mat-icon svgIcon="empira:folder" />
          {{ "data-rooms-list-folders" | translateNew: formUrl }}
        </button>
      </mat-menu>
    </dxi-item>
  </dx-toolbar>
  <dx-data-grid
    [dataSource]="currentFolderContent$ | async"
    [selection]="{ mode: selectionMode, showCheckBoxesMode: 'always' }"
    [(selectedRowKeys)]="itemsSelected"
    filterRow="false"
  >
    <dxo-load-panel [enabled]="true" />
    <dxo-search-panel
      [visible]="true"
      [placeholder]="'data-rooms-list-search-grid' | translateNew: formUrl"
      data-translation-value="Search"
      data-translation-key="data-rooms-list-search-grid"
    />

    <dxo-toolbar>
      <dxi-item location="before">
        <app-data-rooms-breadcrumb
          [items]="(breadcrumb$ | async) || []"
          [canUpdate]="canUpdateFolder"
          [canDelete]="canDeleteFolder"
          [canSetPermissions]="canSetPermissions"
          (updatePermissions)="shareToOthers($event)"
          (deleteItem)="deleteFolder($event)"
          (downloadItem)="downloadFileOrFolder($event)"
          (editItem)="openFolderEditDialog($event)"
        />
      </dxi-item>
      <dxi-item location="after" name="searchPanel" />
    </dxo-toolbar>

    <dxi-column
      dataField="name"
      [caption]="'data-rooms-list-column-name' | translateNew: formUrl"
      cellTemplate="nameCellTemplate"
      data-translation-key="data-rooms-list-column-name"
      data-translation-value="Name"
    />
    <dxi-column
      dataField="type"
      [caption]="'data-rooms-list-column-type' | translateNew: formUrl"
      data-translation-key="data-rooms-list-column-type"
      data-translation-value="Type"
    />
    <dxi-column
      dataField="changedOn"
      [caption]="'data-rooms-list-column-changed-on' | translateNew: formUrl"
      format="dd.MM.yyyy"
      data-translation-key="data-rooms-list-column-changed-on"
      data-translation-value="Last update"
    />
    <dxi-column
      dataField="createdOn"
      [caption]="'data-rooms-list-column-added-on' | translateNew: formUrl"
      format="dd.MM.yyyy"
      data-translation-key="data-rooms-list-column-added-on"
      data-translation-value="Added on"
    />
    <dxi-column
      dataField="size"
      [caption]="'data-rooms-list-column-size' | translateNew: formUrl"
      alignment="left"
      cellTemplate="sizeCellTemplate"
      data-translation-key="data-rooms-list-column-size"
      data-translation-value="Size"
    />
    <dxi-column
      type="buttons"
      cellTemplate="buttonsCellTemplate"
      [fixed]="true"
      fixedPosition="right"
    />

    <div *dxTemplate="let cell of 'nameCellTemplate'">
      <div class="file-name">
        <ng-container *ngIf="cell.data.isDirectory">
          <mat-icon svgIcon="empira:folder" />
          <a routerLink="." [queryParams]="{ parentFolderId: cell.data.id }">
            {{ cell.data.name }}
          </a>
        </ng-container>
        <ng-container *ngIf="!cell.data.isDirectory">
          <ng-container *ngIf="cell.data.isPreviewable">
            <a
              *ngIf="cell.data.previewInNewTab"
              href="javascript:void(0);"
              (click)="openFilePreview(cell.data)"
              class="preview-click-container"
            >
              <ng-container
                [ngTemplateOutlet]="iconOrThumbnail"
                [ngTemplateOutletContext]="{ element: cell.data }"
              />
            </a>
            <a
              *ngIf="!cell.data.previewInNewTab"
              [appSharedFilePreview]="getImagePreview(cell.data)"
              class="preview-click-container"
            >
              <ng-container
                [ngTemplateOutlet]="iconOrThumbnail"
                [ngTemplateOutletContext]="{ element: cell.data }"
              />
            </a>
          </ng-container>
          <ng-container *ngIf="!cell.data.isPreviewable">
            <ng-container
              [ngTemplateOutlet]="iconOrThumbnail"
              [ngTemplateOutletContext]="{ element: cell.data }"
            />
          </ng-container>
          {{ cell.data.name }}
        </ng-container>
      </div>
    </div>

    <div *dxTemplate="let cell of 'sizeCellTemplate'">
      {{ cell.data.size | filesize }}
    </div>

    <div *dxTemplate="let cell of 'buttonsCellTemplate'">
      <button
        *ngIf="cell.data.isPreviewable && cell.data.previewInNewTab"
        mat-icon-button
        type="button"
        (click)="openFilePreview(cell.data)"
      >
        <mat-icon svgIcon="empira:eye" />
      </button>
      <button
        *ngIf="cell.data.isPreviewable && !cell.data.previewInNewTab"
        [appSharedFilePreview]="getImagePreview(cell.data)"
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="empira:eye" />
      </button>
      <button
        mat-icon-button
        type="button"
        (click)="downloadFileOrFolder(cell.data)"
      >
        <mat-icon svgIcon="empira:download_02" />
      </button>
      <ng-container>
        <button
          *ngIf="
            cell.data.isDirectory && (canShowAdditionalFolderOptions$ | async)
          "
          mat-icon-button
          type="button"
          [matMenuTriggerFor]="additionalFolderOptions"
          [matMenuTriggerData]="{ item: cell.data }"
        >
          <mat-icon svgIcon="empira:dots_vertical" />
        </button>

        <button
          *ngIf="
            (!cell.data.isDirectory &&
              (canShowAdditionalFileOptions$ | async)) ||
            cell.data.isPrintable
          "
          mat-icon-button
          type="button"
          [matMenuTriggerFor]="additionalFileOptions"
          [matMenuTriggerData]="{ item: cell.data }"
        >
          <mat-icon svgIcon="empira:dots_vertical" />
        </button>
      </ng-container>
    </div>
  </dx-data-grid>

  <div
    class="drop-overlay"
    data-translation-key="data-rooms-list-overlay-drop-it"
    data-translation-value="Drop it like it's hot"
  >
    {{ "data-rooms-list-overlay-drop-it" | translateNew: formUrl }}
  </div>
</div>

<mat-menu #additionalFileOptions="matMenu">
  <ng-template matMenuContent let-item="item">
    <button
      *ngIf="canSetPermissions"
      mat-menu-item
      type="button"
      (click)="shareToOthers(item)"
      data-translation-key="data-rooms-bread-crumb-list-permissions"
      data-translation-value="Permissions"
    >
      <mat-icon svgIcon="empira:users_01" />
      {{ "data-rooms-bread-crumb-list-permissions" | translateNew: formUrl }}
    </button>
    <button
      *ngIf="item.isPrintable"
      mat-menu-item
      type="button"
      (click)="print(item)"
      data-translation-key="data-rooms-bread-crumb-list-print"
      data-translation-value="Print"
    >
      <mat-icon svgIcon="empira:printer" />
      {{ "data-rooms-bread-crumb-list-print" | translateNew: formUrl }}
    </button>
    <button
      *ngIf="canUpdateFile"
      mat-menu-item
      type="button"
      (click)="openFileEditDialog(item.id, item.parentId)"
      data-translation-key="data-rooms-bread-crumb-list-edit"
      data-translation-value="Edit"
    >
      <mat-icon svgIcon="empira:edit_05" />
      {{ "data-rooms-bread-crumb-list-edit" | translateNew: formUrl }}
    </button>
    <button
      *ngIf="canDeleteFile"
      mat-menu-item
      type="button"
      (click)="deleteFile(item)"
      data-translation-key="data-rooms-bread-crumb-list-delete"
      data-translation-value="Delete"
    >
      <mat-icon svgIcon="empira:trash_01" />

      {{ "data-rooms-bread-crumb-list-delete" | translateNew: formUrl }}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #additionalFolderOptions="matMenu">
  <ng-template matMenuContent let-item="item">
    <button
      *ngIf="canSetPermissions"
      mat-menu-item
      type="button"
      (click)="shareToOthers(item)"
      data-translation-key="data-rooms-bread-crumb-list-group-permissions"
      data-translation-value="Permissions"
    >
      <mat-icon svgIcon="empira:users_01" />
      {{
        "data-rooms-bread-crumb-list-group-permissions" | translateNew: formUrl
      }}
    </button>
    <button
      *ngIf="canApprove"
      mat-menu-item
      type="button"
      (click)="approval(item)"
      data-translation-key="data-rooms-bread-crumb-list-group-approval"
      data-translation-value="Approval"
    >
      <mat-icon svgIcon="empira:check" />
      {{ "data-rooms-bread-crumb-list-group-approval" | translateNew: formUrl }}
    </button>
    <button
      *ngIf="canUpdateFolder"
      mat-menu-item
      type="button"
      (click)="openFolderEditDialog(item)"
      data-translation-key="data-rooms-bread-crumb-list-group-edit"
      data-translation-value="Edit"
    >
      <mat-icon svgIcon="empira:edit_05" />
      {{ "data-rooms-bread-crumb-list-group-edit" | translateNew: formUrl }}
    </button>
    <button
      *ngIf="canDeleteFolder"
      mat-menu-item
      type="button"
      (click)="deleteFolder(item)"
      data-translation-key="data-rooms-bread-crumb-list-group-delete"
      data-translation-value="Delete"
    >
      <mat-icon svgIcon="empira:trash_01" />
      {{ "data-rooms-bread-crumb-list-group-delete" | translateNew: formUrl }}
    </button>
  </ng-template>
</mat-menu>

<ng-template #iconOrThumbnail let-element="element">
  <mat-icon
    *ngIf="!element.thumbnail"
    [svgIcon]="element.mimeType | fileIcon"
  />
  <div *ngIf="element.thumbnail" class="thumbnail-wrap">
    <img [src]="getThumbnail(element)" class="thumbnail" [alt]="element.name" />
  </div>
</ng-template>

<ng-container
  data-translation-key="gallery-list-dialog-delete-file-title"
  data-translation-value="Delete file"
/>
<ng-container
  data-translation-key="gallery-list-dialog-delete-file-description"
  data-translation-value="Are you sure you want to delete this file? This action cannot be undone."
/>
<ng-container
  data-translation-key="gallery-list-dialog-delete-folder-title"
  data-translation-value="Delete folder"
/>
<ng-container
  data-translation-key="gallery-list-dialog-delete-folder-description"
  data-translation-value="Are you sure you want to delete this folder? This action cannot be undone."
/>
<ng-container
  data-translation-key="gallery-list-dialog-delete-multiple-title"
  data-translation-value="Delete"
/>
<ng-container
  data-translation-key="gallery-list-dialog-delete-multiple-description"
  data-translation-value="Are you sure you want to delete these? This action cannot be undone."
/>
<ng-container
  data-translation-key="gallery-list-dialog-create-folder"
  data-translation-value="Create folder"
/>
<ng-container
  data-translation-key="gallery-list-dialog-edit-folder"
  data-translation-value="Edit folder"
/>
<ng-container
  data-translation-key="gallery-list-dialog-headline-permissions"
  data-translation-value="Permission to other users"
/>
<ng-container
  data-translation-key="gallery-list-dialog-headline-approvals"
  data-translation-value="Approvals"
/>
<ng-container
  data-translation-key="gallery-list-dialog-headline-upload-files"
  data-translation-value="Upload files"
/>
<ng-container
  data-translation-key="gallery-list-dialog-headline-upload-folder"
  data-translation-value="Upload folder"
/>
<ng-container
  data-translation-key="gallery-list-dialog-headline-edit-file"
  data-translation-value="Edit file"
/>
