<dialog #dialog [class.is-open]="isOpen">
  <ng-container *ngIf="currentFile$ | async as currentFile">
    <div class="top-line">
      <button type="button" class="close" (click)="close()">
        <mat-icon svgIcon="empira:x_close" />
      </button>
    </div>
    <div class="image-wrap">
      <img [src]="currentFile.imagePath | async" alt="" />
    </div>
    <div class="bottom-line">
      <div class="image-description">
        <div
          class="title"
          *ngIf="currentFile.headline | translateLocal as headline"
        >
          {{ headline }}
        </div>
        <div
          class="subtitle"
          *ngIf="currentFile.subheadline | translateLocal as subheadline"
        >
          {{ subheadline }}
        </div>
      </div>
      <div *ngIf="files.length > 1" class="buttons">
        <button type="button" (click)="previous()">
          <mat-icon svgIcon="empira:arrow_left" />
        </button>
        <button type="button" (click)="next()">
          <mat-icon svgIcon="empira:arrow_right" />
        </button>
      </div>
    </div>
  </ng-container>
</dialog>
