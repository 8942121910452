<div *ngIf="user$ | async as user" class="profile-container">
  <div class="profile-picture2">
    <app-shared-profile-picture
      [hasBorder]="false"
      [hasShadow]="false"
      [name]="user | fullName"
      [profilePicture]="profilePicture$ | async"
    />
  </div>
  <div class="profile-info">
    <span class="profile-full-name">{{ user.name }} {{ user.surname }}</span>
    <p class="profile-email">{{ user.email }}</p>
  </div>
</div>
