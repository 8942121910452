import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { ErrorInterceptor } from './interceptors/error-interceptor';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomCdkOverlayContainer } from './shared/services/custom-cdk-overlay-container';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { EmpiraPaginatorIntl } from './shared/services/empira-paginator-intl';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from '../environments/environment';

export function tokenGetter() {
  return localStorage.getItem('accessToken');
}

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
      },
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        pageSize: 25,
        hidePageSize: true,
      },
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        stretchTabs: false,
        alignTabs: 'start',
      },
    },
    { provide: OverlayContainer, useClass: CustomCdkOverlayContainer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    JwtHelperService,
    { provide: MatPaginatorIntl, useClass: EmpiraPaginatorIntl },
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.apiToken,
    }),
    ToastrModule.forRoot(),
    MatMomentDateModule,
    BrowserAnimationsModule,
    // TODO: import everywhere where it is used
    MatFormFieldModule,
  ],
})
export class AppModule {}
