import { Injectable, inject } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from 'src/app/shared/types/interfaces/response.interface';
import { HttpClient } from '@angular/common/http';
import { UserGroupsUserFormPermissionsDto } from '../interfaces/user-groups-user-form-permissions.interface';
import { UserFormPermissionModel } from '../interfaces/user-form-permission-model.interface';
import { BusinessEntity } from '../interfaces/business-entity.interface';
import { mapNamingToDisplayName } from '../../user-form-permission/mappers/map-naming-to-display-name';

@Injectable({
  providedIn: 'root',
})
export class UserFormPermissionService {
  apiUrl = environment.apiUrl;
  httpClient = inject(HttpClient);

  getAllUserGroupsUserFormPermissions(id: number) {
    return this.httpClient
      .get<Response<UserGroupsUserFormPermissionsDto>>(
        `${this.apiUrl}/UserGroup/GetUserGroupFormPermission/${id}`,
      )
      .pipe(map((response) => response.data));
  }

  async createUserFormPermission(data: UserFormPermissionModel) {
    return await lastValueFrom(
      this.httpClient
        .post<Response<boolean>>(
          `${this.apiUrl}/UserFormPermission/PostRange`,
          data,
        )
        .pipe(map((response) => response.succeeded)),
    );
  }

  async getBusinessEntities() {
    return lastValueFrom(
      this.httpClient
        .get<Response<BusinessEntity[]>>(
          `${this.apiUrl}/BusinessEntity/GetAllByCondition`,
        )
        .pipe(
          map((response) => response.data),
          map((emailDtoList) => emailDtoList.map(mapNamingToDisplayName)),
          map((entities) => [
            ...entities,
            {
              id: 0,
              organization: '',
              firstname: '',
              surname: '',
              displayName: '',
            },
          ]),
        ),
    );
  }
}
