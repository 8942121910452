import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
  standalone: true,
})
export class FilesizePipe implements PipeTransform {
  transform(value?: number): string | undefined {
    if (value === undefined) {
      return;
    }

    const sizeStrings = ['B', 'kB', 'MB', 'GB'];
    let sizeStringIndex = 0;
    let currentSize = value;

    while (currentSize >= 10 && sizeStringIndex < 4) {
      sizeStringIndex++;
      currentSize /= 1024;
    }

    return `${currentSize.toFixed(2)} ${sizeStrings[sizeStringIndex]}`;
  }
}
