import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormComponent } from './user-form-permission/components/form/form.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./shell/shell.routes').then((r) => r.shellRoutes),
  },
  {
    path: '',
    loadComponent: () =>
      import('./shell/components/shell/shell.component').then(
        (x) => x.ShellComponent,
      ),
    children: [
      {
        path: 'administration/user-form-permission/:id/:isUser',
        component: FormComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
