import { DocumentDto } from '../dtos/document.dto';
import { FormFile } from '../interfaces/form-file';

export function mapDocumentDtoToFormFile(documentDto: DocumentDto): FormFile {
  return {
    name: documentDto.fileName,
    description: documentDto.description,
    effectiveFrom: documentDto.effectiveFrom,
    effectiveTo: documentDto.effectiveTo ?? undefined,
    isPublic: documentDto.isPublic,
    allowComment: documentDto.allowComment,
  };
}
