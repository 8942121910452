import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  Directive,
  HostListener,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { DialogRef } from '../services/dialog-ref';
import { FilePreviewComponent } from '../components/file-preview/file-preview.component';
import { DOCUMENT } from '@angular/common';
import { FilePreview } from '../interfaces/file-preview';
import { isObservable, Observable, of } from 'rxjs';

@Directive({
  selector: '[appSharedFilePreview]',
  standalone: true,
})
export class FilePreviewDirective implements OnDestroy {
  @Input({ required: true })
  public set appSharedFilePreview(
    appSharedFilePreview:
      | string
      | Observable<string>
      | FilePreview
      | FilePreview[],
  ) {
    if (
      typeof appSharedFilePreview === 'string' ||
      isObservable(appSharedFilePreview)
    ) {
      this._appSharedFilePreview = [
        {
          imagePath: this.getImagePathAsObservable(appSharedFilePreview),
          headline: { EN: '', DE: '' },
          subheadline: { EN: '', DE: '' },
        },
      ];
      return;
    }

    if (Array.isArray(appSharedFilePreview)) {
      this._appSharedFilePreview = appSharedFilePreview.map(
        (singleAppSharedFilePreview) => ({
          ...singleAppSharedFilePreview,
          imagePath: this.getImagePathAsObservable(
            singleAppSharedFilePreview.imagePath,
          ),
        }),
      );
      return;
    }

    this._appSharedFilePreview = [
      {
        ...appSharedFilePreview,
        imagePath: this.getImagePathAsObservable(
          appSharedFilePreview.imagePath,
        ),
      },
    ];
  }
  public get appSharedFilePreview(): FilePreview[] {
    return this._appSharedFilePreview;
  }
  private _appSharedFilePreview: FilePreview[] = [];

  private getImagePathAsObservable(imagePath: string | Observable<string>) {
    return typeof imagePath === 'string' ? of(imagePath) : imagePath;
  }

  @Input()
  public startingIndex = 0; // The text for the tooltip to display

  private appRef = inject(ApplicationRef);

  private document = inject(DOCUMENT);

  private filePreviewComponentRef?: ComponentRef<FilePreviewComponent>;

  ngOnDestroy() {
    if (this.filePreviewComponentRef) {
      this.filePreviewComponentRef.instance.close();
    }
  }

  @HostListener('click')
  public open() {
    const dialogRef = this.appendPreviewDialogToBody();
    if (!this.filePreviewComponentRef) {
      throw new Error('Creation of dialog failed');
    }

    this.filePreviewComponentRef.setInput('files', this._appSharedFilePreview);
    this.filePreviewComponentRef.setInput('startingIndex', this.startingIndex);

    this.filePreviewComponentRef.instance.open();
    return dialogRef;
  }

  private appendPreviewDialogToBody() {
    const dialogRef = new DialogRef();

    const sub = dialogRef.afterClosed.subscribe(() => {
      this.removeDialogFromBody();
      sub.unsubscribe();
    });

    const dialog = createComponent(FilePreviewComponent, {
      environmentInjector: this.appRef.injector,
    });

    dialogRef.element = dialog.instance.dialog.nativeElement;

    this.document.body.appendChild(dialog.location.nativeElement);
    this.appRef.attachView(dialog.hostView);

    this.filePreviewComponentRef = dialog;
    this.filePreviewComponentRef.instance.dialog.nativeElement.addEventListener(
      'close',
      () => {
        this.filePreviewComponentRef?.destroy();
        this.filePreviewComponentRef = undefined;
      },
    );

    return dialogRef;
  }

  private removeDialogFromBody() {
    if (!this.filePreviewComponentRef) {
      return;
    }

    this.appRef.detachView(this.filePreviewComponentRef.hostView);
    this.filePreviewComponentRef.destroy();
  }
}
