import { Component, inject, OnInit } from '@angular/core';
import { DataRoomService } from '../../services/data-room.service';
import { BaseFile } from '../../interfaces/base-file';
import { DIALOG_DATA } from '../../../dialog/tokens/DIALOG_DATA';
import { CreateFile } from '../../interfaces/create-file';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FileNameValidator } from '../../validators/filename.validator';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragAndDropFieldComponent } from '../../../shared/components/drag-and-drop-field/drag-and-drop-field.component';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { AppParameterService } from 'src/app/shared/services/appparameter.service';
import { FileSize } from 'src/app/shared/constants/file-size';
import { MessageAppService } from 'src/app/shared/services/messageapp.service';
import { ToastService } from 'src/app/dialog/services/toast.service';

interface DialogDataContent {
  parentFolderId: number;
  files?: File[];
}

@Component({
  selector: 'app-data-rooms-file-create',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    NgForOf,
    DragAndDropFieldComponent,
    SpinnerComponent,
  ],
  templateUrl: './file-create.component.html',
  styleUrls: ['./file-create.component.scss'],
  providers: [DataRoomService],
})
export class FileCreateComponent implements OnInit {
  private dataRoomService = inject(DataRoomService);
  protected appParameter = inject(AppParameterService);
  protected messageApp = inject(MessageAppService);
  protected toastr = inject(ToastService);
  protected messageError$ = new BehaviorSubject<string>('');

  protected allowedFileSize$ = new BehaviorSubject<string | null>(null);

  private dialogData = inject(DIALOG_DATA);

  protected folderId = (this.dialogData.data as DialogDataContent)
    .parentFolderId;

  protected preparedFiles = (this.dialogData.data as DialogDataContent).files;

  protected isUploading$ = new BehaviorSubject(false);

  protected form = new FormGroup({
    file: new FormControl<File | null>(null),
    fileList: new FormArray([]),
  });

  protected isFormSubmitDisabled$ = new BehaviorSubject(true);

  protected fileUpload = new FormControl<File[] | null>(null);

  ngOnInit() {
    this.dialogData.dialogRef.element.addEventListener('cancel', (event) => {
      if (this.isUploading$.getValue()) {
        event.preventDefault();
      }
    });

    if (this.preparedFiles) {
      this.preparedFiles.forEach((file) => this.addFile(file));
    }

    this.fileUpload.valueChanges.subscribe((value) => this.fileChange(value));

    this.appParameter.getById(FileSize.DataRoomFileSize).subscribe((res) => {
      this.allowedFileSize$.next(res.value);
    });
  }

  async onSubmit(files: BaseFile[]) {
    this.isUploading$.next(true);

    try {
      await this.dataRoomService.uploadFiles(
        files as CreateFile[],
        this.folderId,
      );
      this.dialogData.dialogRef.close(true);
    } catch (error) {
      console.error('An error occurred:', error);
    }

    this.isUploading$.next(false);
  }

  protected onFormSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const createFiles = this.fileList
        .getRawValue()
        .map((file: CreateFile, i: number) => {
          return {
            ...file,
            file: this.files[i],
            description: file.description ?? undefined,
            effectiveFrom: file.effectiveFrom ?? undefined,
            effectiveTo: file.effectiveTo ?? undefined,
          };
        });

      const fileSize = parseInt(this.allowedFileSize$.getValue()!, 10);
      if (createFiles && createFiles[0].file.size > fileSize) {
        this.messageApp.getMessageById(50).subscribe((res) => {
          this.messageError$.next(res.en);
          this.toastr.error(this.messageError$.getValue(), 'Error');
        });
        return;
      }

      this.onSubmit(createFiles);
    }
  }

  protected files: File[] = [];

  protected fileChange(fileList: File[] | null) {
    if (!fileList?.length) {
      return;
    }

    for (let i = 0; i < fileList.length; i++) {
      this.addFile(fileList[i]);
    }
  }

  private addFile(file: File) {
    this.files.push(file);

    const fileNameParts = file.name.split('.');
    fileNameParts.pop();

    this.addFileForm(fileNameParts.join('.'));
  }

  get fileList(): FormArray {
    return this.form.get('fileList') as FormArray;
  }

  protected addFileForm(fileName: string) {
    this.fileList.push(this.getFileForm(fileName));
    this.updateSubmitDisabledState();
  }

  protected removeFileForm(index: number) {
    this.fileList.removeAt(index);
    this.files.splice(index, 1);
    this.updateSubmitDisabledState();
  }

  protected closeDialog() {
    this.dialogData.dialogRef.close(false);
  }

  private updateSubmitDisabledState() {
    const folderListLength = (this.form.get('fileList') as FormArray).length;

    this.isFormSubmitDisabled$.next(!folderListLength);
  }

  private getFileForm(fileName: string) {
    return new FormGroup({
      name: new FormControl(fileName, {
        validators: [Validators.required, FileNameValidator()],
        nonNullable: true,
      }),
      description: new FormControl(''),
      isPublic: new FormControl(false, { nonNullable: true }),
      allowComment: new FormControl(false, { nonNullable: true }),
      effectiveFrom: new FormControl(new Date(), {
        validators: [Validators.required],
        nonNullable: true,
      }),
      effectiveTo: new FormControl<Date | null>(null),
    });
  }
}
