<div class="upload-wrap" (appDragAndDrop)="dropFiles($event)">
  <mat-icon class="file-upload-icon" svgIcon="empira:upload_cloud_02" />
  <div
    class="upload-text-part"
    data-translation-key="click-to-upload"
    data-translation-value="Click to upload"
  >
    {{ "click-to-upload" | translateNew: formUrl }}
    <span
      *ngIf="dragAndDropPossible"
      data-translation-key="or-drag-and-drop"
      data-translation-value="or drag and drop"
    >
      {{ "or-drag-and-drop" | translateNew: formUrl }}
    </span>
  </div>
  <div *ngIf="infotext" class="infotext">
    {{ infotext }}
  </div>
  <label *ngIf="!allowFolder" for="file-upload-file" class="file-upload-part">
    <input
      (change)="fileChange($event)"
      id="file-upload-file"
      type="file"
      [multiple]="multiple"
      [accept]="accept"
      [disabled]="disabled"
    />
  </label>
  <label *ngIf="allowFolder" for="file-upload-folder" class="file-upload-part">
    <input
      (change)="fileChange($event)"
      id="file-upload-folder"
      type="file"
      webkitdirectory
      mozdirectory
    />
  </label>
</div>
