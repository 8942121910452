<div class="breadcrumb-wrap">
  <a *ngIf="firstItem$ | async as firstItem" routerLink=".">
    {{ firstItem.name }}
  </a>
  <span *ngIf="hasTooManyItems$ | async" [matMenuTriggerFor]="hiddenItems">
    ...
  </span>
  <a
    *ngIf="lastItem$ | async as lastItem"
    [matMenuTriggerFor]="lastItemMenu"
    routerLink="."
    [queryParams]="{ parentFolderId: lastItem.id }"
  >
    {{ lastItem.name }}
  </a>
</div>

<mat-menu #hiddenItems="matMenu">
  <a
    *ngFor="let item of listItems$ | async"
    mat-menu-item
    routerLink="."
    [queryParams]="{ parentFolderId: item.id }"
  >
    {{ item.name }}
  </a>
</mat-menu>

<mat-menu #lastItemMenu="matMenu">
  <ng-container *ngIf="lastItem$ | async as item">
    <button
      data-translation-key="data-rooms-bread-crumb-download"
      data-translation-value="Download"
      mat-menu-item
      (click)="clickDownloadButton(item)"
    >
      <mat-icon svgIcon="empira:download_02" />
      <span>
        {{ "data-rooms-bread-crumb-download" | translateNew: formUrl }}
      </span>
    </button>
    <button
      data-translation-key="data-rooms-bread-crumb-permissions"
      data-translation-value="Permissions"
      *ngIf="canSetPermissions"
      mat-menu-item
      (click)="clickUpdatePermissionsButton(item)"
    >
      <mat-icon svgIcon="empira:users_01" />
      <span>
        {{ "data-rooms-bread-crumb-permissions" | translateNew: formUrl }}
      </span>
    </button>
    <button
      data-translation-key="data-rooms-bread-crumb-edit"
      data-translation-value="Edit"
      *ngIf="canUpdate"
      mat-menu-item
      (click)="clickEditButton(item)"
    >
      <mat-icon svgIcon="empira:edit_05" />
      <span>
        {{ "data-rooms-bread-crumb-edit" | translateNew: formUrl }}
      </span>
    </button>
    <button
      data-translation-key="data-rooms-bread-crumb-delete"
      data-translation-value="Delete"
      *ngIf="canDelete"
      mat-menu-item
      (click)="clickDeleteButton(item)"
    >
      <mat-icon svgIcon="empira:trash_01" />
      <span>
        {{ "data-rooms-bread-crumb-delete" | translateNew: formUrl }}
      </span>
    </button>
  </ng-container>
</mat-menu>
