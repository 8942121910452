import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormControl } from '@angular/forms';
import { UserService } from '../../../shared/services/users.service';
import { TemplateService } from '../../../template/services/template.service';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FormUrl } from '../../../shared/constants/form-url';
import { User } from '../../../shared/interfaces/user.interface';
import { IconService } from '../../../icons/services/icon.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { FormHeaderComponent } from '../../../shared/components/form-header/form-header.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AutocompleteMultipleComponent } from '../../../shared/components/autocomplete-multiple/autocomplete-multiple.component';
import { CountrySelectOptionComponent } from '../../../shared/components/country-select-option/country-select-option.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { ImageUploadComponent } from '../../../shared/components/image-upload/image-upload.component';
import { UserMenuComponent } from '../../../shared/components/user-menu/user-menu.component';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateNewPipe } from 'src/app/translation/pipes/translate-new.pipe';
import { Country } from '../../../template/interfaces/country';
import { State } from '../../../template/interfaces/state';
import { City } from '../../../template/interfaces/city';
import { Template } from '../../../template/interfaces/template';
import { PrefixPhoneNumberComponent } from 'src/app/shared/components/prefix-phone-number/prefix-phone-number.component';
import { PhonePrefixService } from 'src/app/shared/services/phone-perfix.service';
import { PhonePrefixes } from 'src/app/shared/interfaces/phone-prefixes.interface';
import { TemplateSettingId } from '../../../template/constants/template-setting-id';
import { UserRequestDetailService } from 'src/app/shared/services/userrequestdetail.service';

@Component({
  selector: 'app-user-details',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgIf,
    RouterLink,
    TranslateNewPipe,
    AsyncPipe,
    MatIconModule,
    FormHeaderComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormFieldComponent,
    ImageUploadComponent,
    CountrySelectOptionComponent,
    MatSlideToggleModule,
    AutocompleteMultipleComponent,
    UserMenuComponent,
    CommonModule,
    MatAutocompleteModule,
    PrefixPhoneNumberComponent,
  ],
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input({ required: true }) userId!: number;
  @Input({ required: true }) isUser!: boolean;
  @Input({ required: true }) otherUser = false;

  private userService = inject(UserService);
  private templateService = inject(TemplateService);
  private iconService = inject(IconService);
  protected countries$ = new BehaviorSubject<Country[]>([]);
  protected formUrl = FormUrl.UserProfileForm;
  private phonePrefixesService = inject(PhonePrefixService);
  private userRequestService = inject(UserRequestDetailService);

  private stop$ = new Subject<void>();

  selectedCountryCode?: string | null;
  phonePrefix?: PhonePrefixes;
  mobilePhonePrefix?: PhonePrefixes;

  stateListSubject = new BehaviorSubject<State[]>([]);
  cityListSubject = new BehaviorSubject<City[]>([]);

  templateKeys = [TemplateSettingId.State, TemplateSettingId.City];

  constructor() {
    inject(IconService).registerIcon('earth');
  }

  protected userForm = new FormGroup({
    id: new FormControl<number>(0),
    name: new FormControl<string>('', {}),
    surname: new FormControl<string>('', {}),
    email: new FormControl(''),
    mobilePhone: new FormControl(''),
    phoneNumber: new FormControl(''),
    jobTitle: new FormControl(''),
    country: new FormControl<string | null>(null, {}),
    department: new FormControl(''),
    streetAddress: new FormControl(''),
    city: new FormControl<string | null>(null, {}),
    postalCode: new FormControl<string>('', {}),
    stateOrProvince: new FormControl<string | null>(null, {}),
    manager: new FormControl<boolean>(false, {}),
    isDirector: new FormControl<boolean>(false, {}),
    inActiveDirectory: new FormControl<boolean>(false, {}),
  });

  ngOnInit() {
    this.userForm.disable();
    if (this.otherUser) {
      if (this.isUser) {
        this.handleUserData(this.userService.getById(this.userId));
      } else {
        this.handleUserData(this.userRequestService.getUserData(this.userId));
      }
    } else {
      this.handleUserData(this.userService.selectUser());
    }
    this.loadUserAndProductData();
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  isInternal$ = this.userService
    .selectUser()
    .pipe(map((user) => user?.isInternal));

  private handleUserData(userObservable: Observable<User | null>): void {
    userObservable
      .pipe(
        takeUntil(this.stop$),
        filter((user) => !!user),
        take(1),
        tap((user) => {
          this.loadMetaData(user as User);
        }),
      )
      .subscribe();
  }

  protected getFlagUrl(countryCode: string): string {
    return this.iconService.registerFlagByCountryCode(countryCode);
  }

  async loadUserAndProductData() {
    const [templateList] = await Promise.all([this.loadTemplateData()]);
    this.templateKeys.forEach((key) => {
      const template = templateList.find((x) => x.id === key);
      if (template) {
        this.updateSubjectForKey(key, template.templateList ?? []);
      }
    });
  }

  private updateSubjectForKey(
    key: TemplateSettingId,
    templateList: Template[],
  ) {
    switch (key) {
      case TemplateSettingId.State:
        this.stateListSubject.next(templateList as State[]);
        break;
      case TemplateSettingId.City:
        this.cityListSubject.next(templateList as City[]);
        break;
    }
  }

  async loadTemplateData() {
    return await this.templateService.getByList(this.templateKeys);
  }

  private async loadMetaData(userData: User) {
    this.selectedCountryCode = userData.countryTemplate?.code;
    this.phonePrefixesService.loadPhonePrefixes().subscribe((response) => {
      this.mobilePhonePrefix = response.find(
        (x) => x.id === userData.mobilePhonePrefixId,
      );
      this.phonePrefix = response.find((x) => x.id === userData.phonePrefixId);
    });

    this.userForm.patchValue({
      ...userData,
      stateOrProvince: userData.stateOrProvince,
      city: userData.city,
    });
  }
}
