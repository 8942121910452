import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { LanguageItem } from '../interfaces/language-item';

@Pipe({
  name: 'translateLocal',
  pure: false,
  standalone: true,
})
export class TranslateLocalPipe implements PipeTransform {
  translateService = inject(TranslationService);

  transform(value: LanguageItem): string {
    return this.translateService.getTranslationByLanguageItem(value);
  }
}
