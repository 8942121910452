import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-form-header',
  standalone: true,
  imports: [MatButtonModule, NgIf, MatDividerModule],
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHeaderComponent {
  @Input({ required: true })
  headline!: string;

  @HostBinding('class.contain-tab')
  @Input()
  containTab = false;

  @Input()
  description?: string;
}
