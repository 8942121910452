import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class EmpiraPaginatorIntl extends MatPaginatorIntl {
  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    const numberOfPages = Math.max(Math.ceil(length / pageSize));
    const currentPage = page + 1;

    // TODO: Add translation
    return `Page ${currentPage} of ${numberOfPages}`;
  };
}
