import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { UserPhotoService } from 'src/app/shared/services/userphoto.service';
import { UserService } from 'src/app/shared/services/users.service';
import { FullNamePipe } from 'src/app/shared/pipes/full-name.pipe';
import { BehaviorSubject, tap } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';
import { ProfilePictureComponent } from 'src/app/shared/components/profile-picture/profile-picture.component';
import { SafeUrl } from '@angular/platform-browser';
import { UserRequestDetailService } from 'src/app/shared/services/userrequestdetail.service';

@Component({
  selector: 'app-user-profile-permissions',
  standalone: true,
  imports: [CommonModule, AsyncPipe, FullNamePipe, ProfilePictureComponent],
  templateUrl: './user-profile-permissions.component.html',
  styleUrls: ['./user-profile-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfilePermissionsComponent implements OnInit {
  @Input({ required: true }) userId!: number;
  @Input({ required: true }) isUser!: boolean;
  @Output() dataRoomExists = new EventEmitter<boolean>();

  private userService = inject(UserService);
  private userRequestService = inject(UserRequestDetailService);
  private photoService = inject(UserPhotoService);
  protected user$ = new BehaviorSubject<User | null>(null);
  protected profilePicture$ = new BehaviorSubject<SafeUrl | undefined>(
    undefined,
  );

  ngOnInit(): void {
    if (this.isUser) {
      this.userService
        .getById(this.userId)
        .pipe(
          tap((res) => {
            const hasDataRoomList = !!res.dataRoomList?.length;
            this.dataRoomExists.emit(hasDataRoomList);
          }),
        )
        .subscribe((res) => this.user$.next(res));
    } else {
      this.userRequestService
        .getUserData(this.userId)
        .subscribe((res) => this.user$.next(res));
    }

    this.photoService
      .selectCurrentUserProfilePicture(this.userId)
      .subscribe((res) => this.profilePicture$.next(res));
  }
}
