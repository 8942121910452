import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';
import { ToastType } from '../../constants/toast-type';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [NgIf, MatIconModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  @Input()
  public title?: string;

  @Input({ required: true })
  public message!: string;

  @Input()
  public type: ToastType = ToastType.Info;

  @ViewChild('dialog', { static: true })
  private dialog!: ElementRef<HTMLDialogElement>;

  public show() {
    this.dialog.nativeElement.show();
  }

  public close() {
    this.dialog.nativeElement.close();
  }

  public get open() {
    return this.dialog.nativeElement.open;
  }

  protected get typeClass() {
    switch (this.type) {
      case ToastType.Info:
        return 'app-toast-info';
      case ToastType.Success:
        return 'app-toast-success';
      case ToastType.Warning:
        return 'app-toast-warning';
      case ToastType.Error:
        return 'app-toast-error';
    }
  }

  protected get icon() {
    switch (this.type) {
      case ToastType.Info:
        return 'info';
      case ToastType.Success:
        return 'check';
      case ToastType.Warning:
        return 'warning';
      case ToastType.Error:
        return 'error';
    }
  }
}
