/* 🤖 this file was generated by svg-to-ts */

export type EmpiraIcon =
  | 'activity_heart'
  | 'activity'
  | 'airplay'
  | 'airpods'
  | 'alarm_clock_check'
  | 'alarm_clock_minus'
  | 'alarm_clock_off'
  | 'alarm_clock_plus'
  | 'alarm_clock'
  | 'alert_circle'
  | 'alert_hexagon'
  | 'alert_octagon'
  | 'alert_square'
  | 'alert_triangle'
  | 'align_bottom_01'
  | 'align_bottom_02'
  | 'align_center'
  | 'align_horizontal_centre_01'
  | 'align_horizontal_centre_02'
  | 'align_justify'
  | 'align_left_01'
  | 'align_left_02'
  | 'align_left'
  | 'align_right_01'
  | 'align_right_02'
  | 'align_right'
  | 'align_top_arrow_01'
  | 'align_top_arrow_02'
  | 'align_vertical_center_01'
  | 'align_vertical_center_02'
  | 'anchor'
  | 'annotation_alert'
  | 'annotation_check'
  | 'annotation_dots'
  | 'annotation_heart'
  | 'annotation_info'
  | 'annotation_plus'
  | 'annotation_question'
  | 'annotation_x'
  | 'annotation'
  | 'announcement_01'
  | 'announcement_02'
  | 'announcement_03'
  | 'archive'
  | 'arrow_block_down'
  | 'arrow_block_left'
  | 'arrow_block_right'
  | 'arrow_block_up'
  | 'arrow_circle_broken_down_left'
  | 'arrow_circle_broken_down_right'
  | 'arrow_circle_broken_down'
  | 'arrow_circle_broken_left'
  | 'arrow_circle_broken_right'
  | 'arrow_circle_broken_up_left'
  | 'arrow_circle_broken_up_right'
  | 'arrow_circle_broken_up'
  | 'arrow_circle_down_left'
  | 'arrow_circle_down_right'
  | 'arrow_circle_down'
  | 'arrow_circle_left'
  | 'arrow_circle_right'
  | 'arrow_circle_up_left'
  | 'arrow_circle_up_right'
  | 'arrow_circle_up'
  | 'arrow_down_left'
  | 'arrow_down_right'
  | 'arrow_down'
  | 'arrow_left'
  | 'arrow_narrow_down_left'
  | 'arrow_narrow_down_right'
  | 'arrow_narrow_down'
  | 'arrow_narrow_left'
  | 'arrow_narrow_right'
  | 'arrow_narrow_up_left'
  | 'arrow_narrow_up_right'
  | 'arrow_narrow_up'
  | 'arrow_right'
  | 'arrow_square_down_left'
  | 'arrow_square_down_right'
  | 'arrow_square_down'
  | 'arrow_square_left'
  | 'arrow_square_right'
  | 'arrow_square_up_left'
  | 'arrow_square_up_right'
  | 'arrow_square_up'
  | 'arrow_up_left'
  | 'arrow_up_right'
  | 'arrow_up'
  | 'arrows_down'
  | 'arrows_left'
  | 'arrows_right'
  | 'arrows_triangle'
  | 'arrows_up'
  | 'asterisk_01'
  | 'asterisk_02'
  | 'at_sign'
  | 'atom_01'
  | 'atom_02'
  | 'attachment_01'
  | 'attachment_02'
  | 'award_01'
  | 'award_02'
  | 'award_03'
  | 'award_04'
  | 'award_05'
  | 'backpack'
  | 'bank_note_01'
  | 'bank_note_02'
  | 'bank_note_03'
  | 'bank'
  | 'bar_chart_01'
  | 'bar_chart_02'
  | 'bar_chart_03'
  | 'bar_chart_04'
  | 'bar_chart_05'
  | 'bar_chart_06'
  | 'bar_chart_07'
  | 'bar_chart_08'
  | 'bar_chart_09'
  | 'bar_chart_10'
  | 'bar_chart_11'
  | 'bar_chart_12'
  | 'bar_chart_circle_01'
  | 'bar_chart_circle_02'
  | 'bar_chart_circle_03'
  | 'bar_chart_square_01'
  | 'bar_chart_square_02'
  | 'bar_chart_square_03'
  | 'bar_chart_square_down'
  | 'bar_chart_square_minus'
  | 'bar_chart_square_plus'
  | 'bar_chart_square_up'
  | 'bar_line_chart'
  | 'battery_charging_01'
  | 'battery_charging_02'
  | 'battery_empty'
  | 'battery_full'
  | 'battery_low'
  | 'battery_mid'
  | 'beaker_01'
  | 'beaker_02'
  | 'bell_01'
  | 'bell_02'
  | 'bell_03'
  | 'bell_04'
  | 'bell_minus'
  | 'bell_off_01'
  | 'bell_off_02'
  | 'bell_off_03'
  | 'bell_plus'
  | 'bell_ringing_01'
  | 'bell_ringing_02'
  | 'bell_ringing_03'
  | 'bell_ringing_04'
  | 'bezier_curve_01'
  | 'bezier_curve_02'
  | 'bezier_curve_03'
  | 'bluetooth_connect'
  | 'bluetooth_off'
  | 'bluetooth_on'
  | 'bluetooth_signal'
  | 'bold_01'
  | 'bold_02'
  | 'bold_square'
  | 'book_closed'
  | 'book_open_01'
  | 'book_open_02'
  | 'bookmark_add'
  | 'bookmark_check'
  | 'bookmark_minus'
  | 'bookmark_x'
  | 'bookmark'
  | 'box'
  | 'brackets_check'
  | 'brackets_ellipses'
  | 'brackets_minus'
  | 'brackets_plus'
  | 'brackets_slash'
  | 'brackets_x'
  | 'brackets'
  | 'briefcase_01'
  | 'briefcase_02'
  | 'browser'
  | 'brush_01'
  | 'brush_02'
  | 'brush_03'
  | 'building_01'
  | 'building_02'
  | 'building_03'
  | 'building_04'
  | 'building_05'
  | 'building_06'
  | 'building_07'
  | 'building_08'
  | 'bus'
  | 'calculator'
  | 'calendar_check_01'
  | 'calendar_check_02'
  | 'calendar_date'
  | 'calendar_heart_01'
  | 'calendar_heart_02'
  | 'calendar_minus_01'
  | 'calendar_minus_02'
  | 'calendar_plus_01'
  | 'calendar_plus_02'
  | 'calendar'
  | 'camera_01'
  | 'camera_02'
  | 'camera_03'
  | 'camera_lens'
  | 'camera_off'
  | 'camera_plus'
  | 'car_01'
  | 'car_02'
  | 'certificate_01'
  | 'certificate_02'
  | 'chart_breakout_circle'
  | 'chart_breakout_square'
  | 'check_circle_broken'
  | 'check_circle'
  | 'check_done_01'
  | 'check_done_02'
  | 'check_heart'
  | 'check_square_broken'
  | 'check_square'
  | 'check_verified_01'
  | 'check_verified_02'
  | 'check_verified_03'
  | 'check'
  | 'chevron_down_double'
  | 'chevron_down'
  | 'chevron_left_double'
  | 'chevron_left'
  | 'chevron_right_double'
  | 'chevron_right'
  | 'chevron_selector_horizontal'
  | 'chevron_selector_vertical'
  | 'chevron_up_double'
  | 'chevron_up'
  | 'chrome_cast'
  | 'circle_cut'
  | 'circle'
  | 'clapperboard'
  | 'clipboard_attachment'
  | 'clipboard_check'
  | 'clipboard_download'
  | 'clipboard_minus'
  | 'clipboard_plus'
  | 'clipboard_x'
  | 'clipboard'
  | 'clock_check'
  | 'clock_fast_forward'
  | 'clock_plus'
  | 'clock_refresh'
  | 'clock_rewind'
  | 'clock_snooze'
  | 'clock_stopwatch'
  | 'clock'
  | 'cloud_01'
  | 'cloud_02'
  | 'cloud_03'
  | 'cloud_blank_01'
  | 'cloud_blank_02'
  | 'cloud_lightning'
  | 'cloud_moon'
  | 'cloud_off'
  | 'cloud_raining_01'
  | 'cloud_raining_02'
  | 'cloud_raining_03'
  | 'cloud_raining_04'
  | 'cloud_raining_05'
  | 'cloud_raining_06'
  | 'cloud_snowing_01'
  | 'cloud_snowing_02'
  | 'cloud_sun_01'
  | 'cloud_sun_02'
  | 'cloud_sun_03'
  | 'code_01'
  | 'code_02'
  | 'code_browser'
  | 'code_circle_01'
  | 'code_circle_02'
  | 'code_circle_03'
  | 'code_snippet_01'
  | 'code_snippet_02'
  | 'code_square_01'
  | 'code_square_02'
  | 'codepen'
  | 'coins_01'
  | 'coins_02'
  | 'coins_03'
  | 'coins_04'
  | 'coins_hand'
  | 'coins_stacked_01'
  | 'coins_stacked_02'
  | 'coins_stacked_03'
  | 'coins_stacked_04'
  | 'coins_swap_01'
  | 'coins_swap_02'
  | 'colors_1'
  | 'colors'
  | 'columns_01'
  | 'columns_02'
  | 'columns_03'
  | 'command'
  | 'compass_01'
  | 'compass_02'
  | 'compass_03'
  | 'compass'
  | 'container'
  | 'contrast_01'
  | 'contrast_02'
  | 'contrast_03'
  | 'copy_01'
  | 'copy_02'
  | 'copy_03'
  | 'copy_04'
  | 'copy_05'
  | 'copy_06'
  | 'copy_07'
  | 'corner_down_left'
  | 'corner_down_right'
  | 'corner_left_down'
  | 'corner_left_up'
  | 'corner_right_down'
  | 'corner_right_up'
  | 'corner_up_left'
  | 'corner_up_right'
  | 'cpu_chip_01'
  | 'cpu_chip_02'
  | 'credit_card_01'
  | 'credit_card_02'
  | 'credit_card_check'
  | 'credit_card_down'
  | 'credit_card_download'
  | 'credit_card_edit'
  | 'credit_card_lock'
  | 'credit_card_minus'
  | 'credit_card_plus'
  | 'credit_card_refresh'
  | 'credit_card_search'
  | 'credit_card_shield'
  | 'credit_card_up'
  | 'credit_card_upload'
  | 'credit_card_x'
  | 'crop_01'
  | 'crop_02'
  | 'cryptocurrency_01'
  | 'cryptocurrency_02'
  | 'cryptocurrency_03'
  | 'cryptocurrency_04'
  | 'cube_01'
  | 'cube_02'
  | 'cube_03'
  | 'cube_04'
  | 'cube_outline'
  | 'currency_bitcoin_circle'
  | 'currency_bitcoin'
  | 'currency_dollar_circle'
  | 'currency_dollar'
  | 'currency_ethereum_circle'
  | 'currency_ethereum'
  | 'currency_euro_circle'
  | 'currency_euro'
  | 'currency_pound_circle'
  | 'currency_pound'
  | 'currency_ruble_circle'
  | 'currency_ruble'
  | 'currency_rupee_circle'
  | 'currency_rupee'
  | 'currency_yen_circle'
  | 'currency_yen'
  | 'cursor_01'
  | 'cursor_02'
  | 'cursor_03'
  | 'cursor_04'
  | 'cursor_box'
  | 'cursor_click_01'
  | 'cursor_click_02'
  | 'data'
  | 'database_01'
  | 'database_02'
  | 'database_03'
  | 'dataflow_01'
  | 'dataflow_02'
  | 'dataflow_03'
  | 'dataflow_04'
  | 'delete'
  | 'diamond_01'
  | 'diamond_02'
  | 'dice_1'
  | 'dice_2'
  | 'dice_3'
  | 'dice_4'
  | 'dice_5'
  | 'dice_6'
  | 'disc_01'
  | 'disc_02'
  | 'distribute_spacing_horizontal'
  | 'distribute_spacing_vertical'
  | 'divide_01'
  | 'divide_02'
  | 'divide_03'
  | 'divider'
  | 'dotpoints_01'
  | 'dotpoints_02'
  | 'dots_grid'
  | 'dots_horizontal'
  | 'dots_vertical'
  | 'download_01'
  | 'download_02'
  | 'download_03'
  | 'download_04'
  | 'download_cloud_01'
  | 'download_cloud_02'
  | 'drop'
  | 'droplets_01'
  | 'droplets_02'
  | 'droplets_03'
  | 'dropper'
  | 'earth'
  | 'edit_01'
  | 'edit_02'
  | 'edit_03'
  | 'edit_04'
  | 'edit_05'
  | 'equal_not'
  | 'equal'
  | 'eraser'
  | 'expand_01'
  | 'expand_02'
  | 'expand_03'
  | 'expand_04'
  | 'expand_05'
  | 'expand_06'
  | 'eye_off'
  | 'eye'
  | 'face_content'
  | 'face_frown'
  | 'face_happy'
  | 'face_id_square'
  | 'face_id'
  | 'face_neutral'
  | 'face_sad'
  | 'face_smile'
  | 'face_wink'
  | 'fast_backward'
  | 'fast_forward'
  | 'feather'
  | 'figma'
  | 'file_01'
  | 'file_02'
  | 'file_03'
  | 'file_04'
  | 'file_05'
  | 'file_06'
  | 'file_07'
  | 'file_attachment_01'
  | 'file_attachment_02'
  | 'file_attachment_03'
  | 'file_attachment_04'
  | 'file_attachment_05'
  | 'file_check_01'
  | 'file_check_02'
  | 'file_check_03'
  | 'file_code_01'
  | 'file_code_02'
  | 'file_download_01'
  | 'file_download_02'
  | 'file_download_03'
  | 'file_heart_01'
  | 'file_heart_02'
  | 'file_heart_03'
  | 'file_lock_01'
  | 'file_lock_02'
  | 'file_lock_03'
  | 'file_minus_01'
  | 'file_minus_02'
  | 'file_minus_03'
  | 'file_plus_01'
  | 'file_plus_02'
  | 'file_plus_03'
  | 'file_question_01'
  | 'file_question_02'
  | 'file_question_03'
  | 'file_search_01'
  | 'file_search_02'
  | 'file_search_03'
  | 'file_shield_01'
  | 'file_shield_02'
  | 'file_shield_03'
  | 'file_type_audio'
  | 'file_type_avi'
  | 'file_type_code'
  | 'file_type_csv'
  | 'file_type_doc'
  | 'file_type_document'
  | 'file_type_docx'
  | 'file_type_empty'
  | 'file_type_eps'
  | 'file_type_gif'
  | 'file_type_image'
  | 'file_type_img'
  | 'file_type_jpeg'
  | 'file_type_jpg'
  | 'file_type_mkv'
  | 'file_type_mp_3'
  | 'file_type_mp_4'
  | 'file_type_mpeg'
  | 'file_type_pdf'
  | 'file_type_png'
  | 'file_type_ppt'
  | 'file_type_pptx'
  | 'file_type_rar'
  | 'file_type_spreadsheet'
  | 'file_type_svg'
  | 'file_type_tiff'
  | 'file_type_txt'
  | 'file_type_video'
  | 'file_type_wav'
  | 'file_type_webp'
  | 'file_type_xls'
  | 'file_type_xlsx'
  | 'file_type_zip'
  | 'file_x_01'
  | 'file_x_02'
  | 'file_x_03'
  | 'film_01'
  | 'film_02'
  | 'film_03'
  | 'filter_funnel_01'
  | 'filter_funnel_02'
  | 'filter_lines'
  | 'fingerprint_01'
  | 'fingerprint_02'
  | 'fingerprint_03'
  | 'fingerprint_04'
  | 'flag_01'
  | 'flag_02'
  | 'flag_03'
  | 'flag_04'
  | 'flag_05'
  | 'flag_06'
  | 'flash_off'
  | 'flash'
  | 'flex_align_bottom'
  | 'flex_align_left'
  | 'flex_align_right'
  | 'flex_align_top'
  | 'flip_backward'
  | 'flip_forward'
  | 'folder_check'
  | 'folder_closed'
  | 'folder_code'
  | 'folder_download'
  | 'folder_lock'
  | 'folder_minus'
  | 'folder_plus'
  | 'folder_question'
  | 'folder_search'
  | 'folder_shield'
  | 'folder_x'
  | 'folder'
  | 'frame'
  | 'framer'
  | 'gaming_pad_01'
  | 'gaming_pad_02'
  | 'gift_01'
  | 'gift_02'
  | 'git_branch_01'
  | 'git_branch_02'
  | 'git_commit'
  | 'git_merge'
  | 'git_pull_request'
  | 'glasses_01'
  | 'glasses_02'
  | 'globe_01'
  | 'globe_02'
  | 'globe_03'
  | 'globe_04'
  | 'globe_05'
  | 'globe_06'
  | 'globe_slated_01'
  | 'globe_slated_02'
  | 'google_chrome'
  | 'graduation_hat_01'
  | 'graduation_hat_02'
  | 'grid_01'
  | 'grid_02'
  | 'grid_03'
  | 'grid_dots_blank'
  | 'grid_dots_bottom'
  | 'grid_dots_horizontal_center'
  | 'grid_dots_left'
  | 'grid_dots_outer'
  | 'grid_dots_right'
  | 'grid_dots_top'
  | 'grid_dots_vertical_center'
  | 'hand'
  | 'hard_drive'
  | 'hash_01'
  | 'hash_02'
  | 'heading_01'
  | 'heading_02'
  | 'heading_square'
  | 'headphones_01'
  | 'headphones_02'
  | 'heart_circle'
  | 'heart_hand'
  | 'heart_hexagon'
  | 'heart_octagon'
  | 'heart_rounded'
  | 'heart_square'
  | 'heart'
  | 'hearts'
  | 'help_circle'
  | 'help_hexagon'
  | 'help_octagon'
  | 'help_square'
  | 'hexagon_01'
  | 'hexagon_02'
  | 'home_01'
  | 'home_02'
  | 'home_03'
  | 'home_04'
  | 'home_05'
  | 'home_line'
  | 'home_smile'
  | 'horizontal_bar_chart_01'
  | 'horizontal_bar_chart_02'
  | 'horizontal_bar_chart_03'
  | 'hourglass_01'
  | 'hourglass_02'
  | 'hourglass_03'
  | 'hurricane_01'
  | 'hurricane_02'
  | 'hurricane_03'
  | 'image_01'
  | 'image_02'
  | 'image_03'
  | 'image_04'
  | 'image_05'
  | 'image_check'
  | 'image_down'
  | 'image_indent_left'
  | 'image_indent_right'
  | 'image_left'
  | 'image_plus'
  | 'image_right'
  | 'image_up'
  | 'image_user_check'
  | 'image_user_down'
  | 'image_user_left'
  | 'image_user_plus'
  | 'image_user_right'
  | 'image_user_up'
  | 'image_user_x'
  | 'image_user'
  | 'image_x'
  | 'inbox_01'
  | 'inbox_02'
  | 'infinity'
  | 'info_circle'
  | 'info_hexagon'
  | 'info_octagon'
  | 'info_square'
  | 'intersect_circle'
  | 'intersect_square'
  | 'italic_01'
  | 'italic_02'
  | 'italic_square'
  | 'key_01'
  | 'key_02'
  | 'keyboard_01'
  | 'keyboard_02'
  | 'laptop_01'
  | 'laptop_02'
  | 'layer_single'
  | 'layers_three_01'
  | 'layers_three_02'
  | 'layers_two_01'
  | 'layers_two_02'
  | 'layout_alt_01'
  | 'layout_alt_02'
  | 'layout_alt_03'
  | 'layout_alt_04'
  | 'layout_bottom'
  | 'layout_grid_01'
  | 'layout_grid_02'
  | 'layout_left'
  | 'layout_right'
  | 'layout_top'
  | 'left_indent_01'
  | 'left_indent_02'
  | 'letter_spacing_01'
  | 'letter_spacing_02'
  | 'life_buoy_01'
  | 'life_buoy_02'
  | 'lightbulb_01'
  | 'lightbulb_02'
  | 'lightbulb_03'
  | 'lightbulb_04'
  | 'lightbulb_05'
  | 'lightning_01'
  | 'lightning_02'
  | 'line_chart_down_01'
  | 'line_chart_down_02'
  | 'line_chart_down_03'
  | 'line_chart_down_04'
  | 'line_chart_down_05'
  | 'line_chart_up_01'
  | 'line_chart_up_02'
  | 'line_chart_up_03'
  | 'line_chart_up_04'
  | 'line_chart_up_05'
  | 'line_height'
  | 'link_01'
  | 'link_02'
  | 'link_03'
  | 'link_04'
  | 'link_05'
  | 'link_broken_01'
  | 'link_broken_02'
  | 'link_external_01'
  | 'link_external_02'
  | 'list'
  | 'loading_01'
  | 'loading_02'
  | 'loading_03'
  | 'lock_01'
  | 'lock_02'
  | 'lock_03'
  | 'lock_04'
  | 'lock_keyhole_circle'
  | 'lock_keyhole_square'
  | 'lock_unlocked_01'
  | 'lock_unlocked_02'
  | 'lock_unlocked_03'
  | 'lock_unlocked_04'
  | 'log_in_01'
  | 'log_in_02'
  | 'log_in_03'
  | 'log_in_04'
  | 'log_out_01'
  | 'log_out_02'
  | 'log_out_03'
  | 'log_out_04'
  | 'luggage_01'
  | 'luggage_02'
  | 'luggage_03'
  | 'magic_wand_01'
  | 'magic_wand_02'
  | 'mail_01'
  | 'mail_02'
  | 'mail_03'
  | 'mail_04'
  | 'mail_05'
  | 'map_01'
  | 'map_02'
  | 'mark'
  | 'marker_pin_01'
  | 'marker_pin_02'
  | 'marker_pin_03'
  | 'marker_pin_04'
  | 'marker_pin_05'
  | 'marker_pin_06'
  | 'maximize_01'
  | 'maximize_02'
  | 'medical_circle'
  | 'medical_cross'
  | 'medical_square'
  | 'menu_01'
  | 'menu_02'
  | 'menu_03'
  | 'menu_04'
  | 'menu_05'
  | 'message_alert_circle'
  | 'message_alert_square'
  | 'message_chat_circle'
  | 'message_chat_square'
  | 'message_check_circle'
  | 'message_check_square'
  | 'message_circle_01'
  | 'message_circle_02'
  | 'message_dots_circle'
  | 'message_dots_square'
  | 'message_heart_circle'
  | 'message_heart_square'
  | 'message_notification_circle'
  | 'message_notification_square'
  | 'message_plus_circle'
  | 'message_plus_square'
  | 'message_question_circle'
  | 'message_question_square'
  | 'message_smile_circle'
  | 'message_smile_square'
  | 'message_square_01'
  | 'message_square_02'
  | 'message_text_circle_01'
  | 'message_text_circle_02'
  | 'message_text_square_01'
  | 'message_text_square_02'
  | 'message_x_circle'
  | 'message_x_square'
  | 'microphone_01'
  | 'microphone_02'
  | 'microphone_off_01'
  | 'microphone_off_02'
  | 'microscope'
  | 'minimize_01'
  | 'minimize_02'
  | 'minus_circle'
  | 'minus_square'
  | 'minus'
  | 'modem_01'
  | 'modem_02'
  | 'monitor_01'
  | 'monitor_02'
  | 'monitor_03'
  | 'monitor_04'
  | 'monitor_05'
  | 'moon_01'
  | 'moon_02'
  | 'moon_eclipse'
  | 'moon_star'
  | 'mouse'
  | 'move'
  | 'music_note_01'
  | 'music_note_02'
  | 'music_note_plus'
  | 'navigation_pointer_01'
  | 'navigation_pointer_02'
  | 'navigation_pointer_off_01'
  | 'navigation_pointer_off_02'
  | 'notification_box'
  | 'notification_message'
  | 'notification_text'
  | 'octagon'
  | 'package_check'
  | 'package_minus'
  | 'package_plus'
  | 'package_search'
  | 'package_x'
  | 'package'
  | 'paint_pour'
  | 'paint'
  | 'palette'
  | 'paperclip'
  | 'paragraph_spacing'
  | 'paragraph_wrap'
  | 'passcode_lock'
  | 'passcode'
  | 'passport'
  | 'pause_circle'
  | 'pause_square'
  | 'pen_tool_01'
  | 'pen_tool_02'
  | 'pen_tool_minus'
  | 'pen_tool_plus'
  | 'pencil_01'
  | 'pencil_02'
  | 'pencil_line'
  | 'pentagon'
  | 'percent_01'
  | 'percent_02'
  | 'percent_03'
  | 'perspective_01'
  | 'perspective_02'
  | 'phone_01'
  | 'phone_02'
  | 'phone_call_01'
  | 'phone_call_02'
  | 'phone_hang_up'
  | 'phone_incoming_01'
  | 'phone_incoming_02'
  | 'phone_outgoing_01'
  | 'phone_outgoing_02'
  | 'phone_pause'
  | 'phone_plus'
  | 'phone_x'
  | 'phone'
  | 'pie_chart_01'
  | 'pie_chart_02'
  | 'pie_chart_03'
  | 'pie_chart_04'
  | 'piggy_bank_01'
  | 'piggy_bank_02'
  | 'pilcrow_01'
  | 'pilcrow_02'
  | 'pilcrow_square'
  | 'pin_01'
  | 'pin_02'
  | 'placeholder'
  | 'plane'
  | 'play_circle'
  | 'play_square'
  | 'play'
  | 'plus_circle'
  | 'plus_square'
  | 'plus'
  | 'podcast'
  | 'power_01'
  | 'power_02'
  | 'power_03'
  | 'presentation_chart_01'
  | 'presentation_chart_02'
  | 'presentation_chart_03'
  | 'printer'
  | 'puzzle_piece_01'
  | 'puzzle_piece_02'
  | 'qr_code_01'
  | 'qr_code_02'
  | 'receipt_check'
  | 'receipt'
  | 'recording_01'
  | 'recording_02'
  | 'recording_03'
  | 'reflect_01'
  | 'reflect_02'
  | 'refresh_ccw_01'
  | 'refresh_ccw_02'
  | 'refresh_ccw_03'
  | 'refresh_ccw_04'
  | 'refresh_ccw_05'
  | 'refresh_cw_01'
  | 'refresh_cw_02'
  | 'refresh_cw_03'
  | 'refresh_cw_04'
  | 'refresh_cw_05'
  | 'repeat_01'
  | 'repeat_02'
  | 'repeat_03'
  | 'repeat_04'
  | 'reverse_left'
  | 'reverse_right'
  | 'right_indent_01'
  | 'right_indent_02'
  | 'rocket_01'
  | 'rocket_02'
  | 'roller_brush'
  | 'route'
  | 'rows_01'
  | 'rows_02'
  | 'rows_03'
  | 'rss_01'
  | 'rss_02'
  | 'ruler'
  | 'safe'
  | 'sale_01'
  | 'sale_02'
  | 'sale_03'
  | 'sale_04'
  | 'save_01'
  | 'save_02'
  | 'save_03'
  | 'scale_01'
  | 'scale_02'
  | 'scale_03'
  | 'scales_01'
  | 'scales_02'
  | 'scan'
  | 'scissors_01'
  | 'scissors_02'
  | 'scissors_cut_01'
  | 'scissors_cut_02'
  | 'search_lg'
  | 'search_md'
  | 'search_refraction'
  | 'search_sm'
  | 'send_01'
  | 'send_02'
  | 'send_03'
  | 'server_01'
  | 'server_02'
  | 'server_03'
  | 'server_04'
  | 'server_05'
  | 'server_06'
  | 'settings_01'
  | 'settings_02'
  | 'settings_03'
  | 'settings_04'
  | 'share_01'
  | 'share_02'
  | 'share_03'
  | 'share_04'
  | 'share_05'
  | 'share_06'
  | 'share_07'
  | 'shield_01'
  | 'shield_02'
  | 'shield_03'
  | 'shield_dollar'
  | 'shield_off'
  | 'shield_plus'
  | 'shield_tick'
  | 'shield_zap'
  | 'shopping_bag_01'
  | 'shopping_bag_02'
  | 'shopping_bag_03'
  | 'shopping_cart_01'
  | 'shopping_cart_02'
  | 'shopping_cart_03'
  | 'shuffle_01'
  | 'shuffle_02'
  | 'signal_01'
  | 'signal_02'
  | 'signal_03'
  | 'simcard'
  | 'skew'
  | 'skip_back'
  | 'skip_forward'
  | 'slash_circle_01'
  | 'slash_circle_02'
  | 'slash_divider'
  | 'slash_octagon'
  | 'sliders_01'
  | 'sliders_02'
  | 'sliders_03'
  | 'sliders_04'
  | 'snowflake_01'
  | 'snowflake_02'
  | 'spacing_height_01'
  | 'spacing_height_02'
  | 'spacing_width_01'
  | 'spacing_width_02'
  | 'speaker_01'
  | 'speaker_02'
  | 'speaker_03'
  | 'speedometer_01'
  | 'speedometer_02'
  | 'speedometer_03'
  | 'speedometer_04'
  | 'square'
  | 'stand'
  | 'star_01'
  | 'star_02'
  | 'star_03'
  | 'star_04'
  | 'star_05'
  | 'star_06'
  | 'star_07'
  | 'stars_01'
  | 'stars_02'
  | 'stars_03'
  | 'sticker_circle'
  | 'sticker_square'
  | 'stop_circle'
  | 'stop_square'
  | 'stop'
  | 'strikethrough_01'
  | 'strikethrough_02'
  | 'strikethrough_square'
  | 'subscript'
  | 'sun_setting_01'
  | 'sun_setting_02'
  | 'sun_setting_03'
  | 'sun'
  | 'sunrise'
  | 'sunset'
  | 'switch_horizontal_01'
  | 'switch_horizontal_02'
  | 'switch_vertical_01'
  | 'switch_vertical_02'
  | 'tab_placeholder'
  | 'table'
  | 'tablet_01'
  | 'tablet_02'
  | 'tag_01'
  | 'tag_02'
  | 'tag_03'
  | 'target_01'
  | 'target_02'
  | 'target_03'
  | 'target_04'
  | 'target_05'
  | 'telescope'
  | 'terminal_browser'
  | 'terminal_circle'
  | 'terminal_square'
  | 'terminal'
  | 'text_input'
  | 'thermometer_01'
  | 'thermometer_02'
  | 'thermometer_03'
  | 'thermometer_cold'
  | 'thermometer_warm'
  | 'thumbs_down'
  | 'thumbs_up'
  | 'ticket_01'
  | 'ticket_02'
  | 'toggle_01_left'
  | 'toggle_01_right'
  | 'toggle_02_left'
  | 'toggle_02_right'
  | 'toggle_03_left'
  | 'toggle_03_right'
  | 'tool_01'
  | 'tool_02'
  | 'train'
  | 'tram'
  | 'transform'
  | 'translate_01'
  | 'translate_02'
  | 'trash_01'
  | 'trash_02'
  | 'trash_03'
  | 'trash_04'
  | 'trend_down_01'
  | 'trend_down_02'
  | 'trend_up_01'
  | 'trend_up_02'
  | 'triangle'
  | 'trophy_01'
  | 'trophy_02'
  | 'truck_01'
  | 'truck_02'
  | 'tv_01'
  | 'tv_02'
  | 'tv_03'
  | 'type_01'
  | 'type_02'
  | 'type_square'
  | 'type_strikethrough_01'
  | 'type_strikethrough_02'
  | 'umbrella_01'
  | 'umbrella_02'
  | 'umbrella_03'
  | 'underline_01'
  | 'underline_02'
  | 'underline_square'
  | 'upload_01'
  | 'upload_02'
  | 'upload_03'
  | 'upload_04'
  | 'upload_cloud_01'
  | 'upload_cloud_02'
  | 'usb_flash_drive'
  | 'user_01'
  | 'user_02'
  | 'user_03'
  | 'user_check_01'
  | 'user_check_02'
  | 'user_circle'
  | 'user_down_01'
  | 'user_down_02'
  | 'user_edit'
  | 'user_left_01'
  | 'user_left_02'
  | 'user_minus_01'
  | 'user_minus_02'
  | 'user_plus_01'
  | 'user_plus_02'
  | 'user_right_01'
  | 'user_right_02'
  | 'user_square'
  | 'user_up_01'
  | 'user_up_02'
  | 'user_x_01'
  | 'user_x_02'
  | 'users_01'
  | 'users_02'
  | 'users_03'
  | 'users_check'
  | 'users_down'
  | 'users_edit'
  | 'users_left'
  | 'users_minus'
  | 'users_plus'
  | 'users_right'
  | 'users_up'
  | 'users_x'
  | 'variable'
  | 'video_recorder_off'
  | 'video_recorder'
  | 'virus'
  | 'voicemail'
  | 'volume_max'
  | 'volume_min'
  | 'volume_minus'
  | 'volume_plus'
  | 'volume_x'
  | 'wallet_01'
  | 'wallet_02'
  | 'wallet_03'
  | 'wallet_04'
  | 'wallet_05'
  | 'watch_circle'
  | 'watch_square'
  | 'waves'
  | 'webcam_01'
  | 'webcam_02'
  | 'wifi_off'
  | 'wifi'
  | 'wind_01'
  | 'wind_02'
  | 'wind_03'
  | 'x_circle'
  | 'x_close'
  | 'x_square'
  | 'x'
  | 'youtube'
  | 'zap_circle'
  | 'zap_fast'
  | 'zap_off'
  | 'zap_square'
  | 'zap'
  | 'zoom_in'
  | 'zoom_out';
export interface EmpiraIconInterface {
  name: EmpiraIcon;
  data: string;
}
