<mat-form-field>
  <mat-chip-grid [disabled]="isDisabled" #chipGrid>
    <mat-chip-row
      *ngFor="let option of selectedOptions"
      (removed)="remove(option.key)"
    >
      {{ option.text }}
      <button matChipRemove class="icon-remove">
        <mat-icon svgIcon="empira:x_close" />
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    matInput
    [formControl]="inputControl"
    [matChipInputFor]="chipGrid"
    [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
    (matChipInputTokenEnd)="add($event)"
    [matAutocomplete]="autocomplete"
  />
  <mat-autocomplete
    #autocomplete="matAutocomplete"
    (optionSelected)="select($event)"
  >
    <mat-option
      *ngFor="let option of selectableOptions$ | async"
      [value]="option.key"
    >
      <mat-icon matIconPrefix *ngIf="option.icon" [svgIcon]="option.icon" />
      {{ option.text }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
