import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPermissionComponent } from '../user-permission-form/user-permission.component';
import { UserFormPermissionService } from 'src/app/user-form-permission/services/user-form-permission.service';
import { ActivatedRoute } from '@angular/router';
import { FormUrl } from 'src/app/shared/constants/form-url';
import { UserDetailsComponent } from '../../../user-profile/components/user-details/user-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ListComponent } from '../../../user-profile/components/user-preference-management/list/list.component';
import { ResetPasswordComponent } from '../../../user-profile/components/reset-password/reset-password.component';
import { UserPictureComponent } from '../../../user-profile/components/user-picture/user-picture.component';
import { TranslateNewPipe } from 'src/app/translation/pipes/translate-new.pipe';
import { UserProfilePermissionsComponent } from '../user-profile-permissions/user-profile-permissions.component';
import { UserDataRoomComponent } from '../user-permission-data-room/user-data-room.component';
import { UserService } from 'src/app/shared/services/users.service';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.interface';

@Component({
  selector: 'app-form',
  standalone: true,
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  imports: [
    CommonModule,
    UserPermissionComponent,
    UserDetailsComponent,
    TranslateNewPipe,
    MatTabsModule,
    ListComponent,
    ResetPasswordComponent,
    UserPictureComponent,
    UserDataRoomComponent,
    UserProfilePermissionsComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent {
  userFormPermissionService = inject(UserFormPermissionService);
  activatedRoute = inject(ActivatedRoute);
  userService = inject(UserService);
  protected user$ = new BehaviorSubject<User | null>(null);

  protected formUrl = FormUrl.UserFormPermissionForm;
  protected backlink = '../../..';
  protected dataRoomExists = false;

  idUser = parseInt(
    (this.activatedRoute.snapshot.params as { id: string })['id'],
    10,
  );
  isUser = this.activatedRoute.snapshot.paramMap.get('isUser') === 'true';

  onDataRoomExist(exists: boolean) {
    this.dataRoomExists = exists;
  }
}
