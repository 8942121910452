import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Response } from '../shared/types/interfaces/response.interface';
import { ToastService } from '../dialog/services/toast.service'; // Import the Response<T> interface

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private toastService = inject(ToastService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          // If response is a Blob, skip toastr error checking
          if (event.body instanceof Blob) {
            return;
          }

          const response = event.body as Response<unknown>;
          if (
            response.message &&
            response.statusCode == 200 &&
            response.succeeded
          ) {
            this.toastService.clear();
            this.toastService.success(response.message, '');
          } else if (
            (!response.data || response.data === null) &&
            !response.succeeded
          ) {
            this.toastService.clear();
            if (response.message !== '') {
              if (event.status === 200) {
                return;
              }

              this.toastService.error(response.message, '');
            } else {
              this.toastService.error('', 'Error');
            }
          }
        }
      }),
      catchError((error) => {
        if (error.status !== 404 || error.status !== 400) {
          this.toastService.error(
            error.error?.message ?? 'An error occured',
            'Error',
          );
        }

        return throwError(error);
      }),
    );
  }
}
