import { ListItem } from '../interfaces/list-item';
import { FolderDto } from '../dtos/folder.dto';

export function mapFolderDtoToListItem(folderDto: FolderDto): ListItem {
  return {
    id: folderDto.id,
    allowComment: folderDto.allowComment,
    index: folderDto.orderNo,
    hasApproval: folderDto.hasApproval,
    // TODO: language
    name: folderDto.nameEn,
    isPrivate: folderDto.isPrivate,
    isPublic: false,
    isDirectory: true,
    parentId: folderDto.parentId,
    createdOn: new Date(folderDto.createdOn),
    changedOn: folderDto.changedOn ? new Date(folderDto.changedOn) : undefined,
    isPrintable: false,
    isPreviewable: false,
    previewInNewTab: false,
  };
}
