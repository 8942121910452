import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePreview } from '../../interfaces/file-preview';
import { IconService } from '../../../icons/services/icon.service';
import { MatIconModule } from '@angular/material/icon';
import { ReplaySubject } from 'rxjs';
import { TranslateLocalPipe } from '../../../translation/pipes/translate-local.pipe';

@Component({
  selector: 'app-dialog-file-preview',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateLocalPipe],
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewComponent implements AfterViewInit {
  @Input({ required: true })
  public files: FilePreview[] = [];

  @Input()
  public startingIndex = 0;

  @ViewChild('dialog', { static: true })
  public dialog!: ElementRef<HTMLDialogElement>;

  protected isOpen = false;

  protected currentFile$ = new ReplaySubject<FilePreview>(1);

  private currentIndex = 0;

  constructor() {
    inject(IconService).registerIconList([
      'arrow_left',
      'arrow_right',
      'x_close',
    ]);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.currentIndex = this.files[this.startingIndex]
        ? this.startingIndex
        : 0;
      this.currentFile$.next(this.files[this.currentIndex]);
    });
  }

  @HostListener('keyup.arrowright')
  protected next() {
    if (this.currentIndex < this.files.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }

    this.currentFile$.next(this.files[this.currentIndex]);
  }

  @HostListener('keyup.arrowleft')
  protected previous() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.files.length - 1;
    } else {
      this.currentIndex--;
    }

    this.currentFile$.next(this.files[this.currentIndex]);
  }

  public open() {
    this.dialog.nativeElement.showModal();
    this.isOpen = true;
  }

  public close() {
    setTimeout(() => this.dialog.nativeElement.close(), 500);
    this.isOpen = false;
  }
}
