import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Response } from '../types/interfaces/response.interface';
import { map } from 'rxjs';
import { PhonePrefixes } from '../interfaces/phone-prefixes.interface';

@Injectable({
  providedIn: 'root',
})
export class PhonePrefixService {
  private apiUrl: string = environment.apiUrl;
  private http = inject(HttpClient);

  loadPhonePrefixes() {
    const url = `${this.apiUrl}/PhonePrefix`;
    return this.http
      .get<Response<PhonePrefixes[]>>(url)
      .pipe(map((response) => response.data));
  }
}
