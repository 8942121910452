import { ListItem } from '../interfaces/list-item';
import { DocumentDto } from '../dtos/document.dto';

export function mapDocumentDtoToListItem(documentDto: DocumentDto): ListItem {
  return {
    id: documentDto.id,
    allowComment: documentDto.allowComment,
    hasApproval: true,
    name: documentDto.fileName,
    isPrivate: false,
    isPublic: documentDto.isPublic,
    isDirectory: false,
    description: documentDto.description,
    size: documentDto.fileSize,
    type: documentDto.fileType,
    mimeType: documentDto.mimeType,
    parentId: documentDto.folderId,
    createdOn: new Date(documentDto.createdOn),
    changedOn: documentDto.changedOn
      ? new Date(documentDto.changedOn)
      : undefined,
    isPrintable: isFilePrintable(documentDto.mimeType),
    isPreviewable: isFilePreviewable(documentDto.mimeType),
    previewInNewTab: previewInNewTab(documentDto.mimeType),
    thumbnail: documentDto.thumbnail ?? undefined,
  };
}

function isFilePrintable(mimeType: string) {
  return mimeType.includes('application/pdf') || mimeType.includes('image/');
}

function isFilePreviewable(mimeType: string) {
  return mimeType.includes('application/pdf') || mimeType.includes('image/');
}

function previewInNewTab(mimeType: string) {
  return mimeType.includes('application/pdf');
}
