import { FormResource } from '../../form-resources/interfaces/form-resource.interface';
import { TranslationList } from '../interfaces/translation-list';

export function mapFormResourcesListToTranslationList(
  formResourcesList: FormResource[],
): TranslationList {
  const translations: TranslationList = {};

  for (const formResource of formResourcesList) {
    translations[formResource.name] = {
      value: {
        DE: formResource.de || '',
        EN: formResource.en || '',
      },
      description: {
        DE: formResource.explanationDe || '',
        EN: formResource.explanationEn || '',
      },
    };
  }

  return translations;
}
