<form [formGroup]="userPhotoForm" (ngSubmit)="onSubmit()">
  <app-form-header
    [headline]="'user-picture-headline' | translateNew: formUrl"
    [description]="'user-picture-description' | translateNew: formUrl"
    [containTab]="true"
  >
    <a
      [routerLink]="backlink"
      mat-flat-button
      data-translation-key="btn-cancel"
      data-translation-value="Cancel"
      >{{ "btn-cancel" | translateNew: formUrl }}</a
    >
    <button
      mat-flat-button
      color="primary"
      type="submit"
      data-translation-key="save-btn"
      data-translation-value="Save"
    >
      {{ "save-btn" | translateNew: formUrl }}
    </button>
  </app-form-header>
  <app-shared-form-field>
    <mat-label
      data-translation-value="User photo"
      data-translation-key="user-picture-user-photo"
      >{{ "user-picture-user-photo" | translateNew: formUrl }}</mat-label
    >
    <app-shared-image-upload
      [allowedFileSize]="allowedFileSize$ | async"
      formControlName="profilePicture"
    />
  </app-shared-form-field>
</form>
<ng-container
  data-translation-key="user-picture-headline"
  data-translation-value="Profile Picture"
/>
<ng-container
  data-translation-key="user-picture-description"
  data-translation-value="Update user photo"
/>
