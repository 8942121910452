import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserService } from 'src/app/shared/services/users.service';
import { BehaviorSubject, ReplaySubject, map } from 'rxjs';
import { User } from 'src/app/authentication/components/login/interfaces/user.interface';
import { MatButtonModule } from '@angular/material/button';
import { DIALOG_DATA } from 'src/app/dialog/tokens/DIALOG_DATA';
import { ListItem } from 'src/app/data-rooms/interfaces/list-item';
import { DataRoomPermission } from 'src/app/data-rooms/interfaces/data-room-permission';
import { DataRoomService } from 'src/app/data-rooms/services/data-room.service';
import { MatInputModule } from '@angular/material/input';
import { DataRoomPermissionSharing } from 'src/app/data-rooms/interfaces/data-room-permission-sharing.interface';
import { FormUrl } from 'src/app/shared/constants/form-url';
import { TranslateNewPipe } from 'src/app/translation/pipes/translate-new.pipe';
import { AutocompleteMultipleComponent } from 'src/app/shared/components/autocomplete-multiple/autocomplete-multiple.component';
import { FormFieldComponent } from 'src/app/shared/components/form-field/form-field.component';
import { AutocompleteOption } from 'src/app/shared/interfaces/autocomplete-option';
import { SpinnerDirective } from 'src/app/shared/directives/loading-spinner.directive';

@Component({
  selector: 'app-permission',
  standalone: true,
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
  imports: [
    CommonModule,
    TranslateNewPipe,
    SpinnerDirective,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    AutocompleteMultipleComponent,
    FormFieldComponent,
  ],
  providers: [DataRoomService],
})
export class PermissionComponent implements OnInit {
  private userService = inject(UserService);
  private dataRoomService = inject(DataRoomService);

  private usersData$ = new ReplaySubject<User[]>(1);

  protected userOptions$ = this.selectUserOptions();

  private dialogData = inject(DIALOG_DATA);
  protected itemInformation = this.dialogData.data as ListItem;

  formUrl = FormUrl.DataRoomsPermission;

  isSubmitting$ = new BehaviorSubject(true);

  protected form = new FormGroup({
    userType: new FormControl(2, { nonNullable: true }),
    users: new FormControl<number[]>([], { nonNullable: true }),
    notifyByEmail: new FormControl(false, { nonNullable: true }),
  });

  constructor() {
    this.loadUsers(false);
  }

  ngOnInit(): void {
    this.form.get('userType')?.valueChanges.subscribe((value: number) => {
      this.loadUsers(value === 1 ? true : false);
    });
  }

  loadPermissions() {
    if (this.itemInformation.dataRoomId) {
      this.dataRoomService
        .loadPermissions(
          this.itemInformation.dataRoomId,
          this.itemInformation.id == 0 ? undefined : this.itemInformation.id,
          this.itemInformation.isDirectory,
        )
        .subscribe((res) => {
          const userIds = res.map((permission) => permission.userId);
          this.form.get('users')?.setValue(userIds);
        });
    }
  }

  private selectUserOptions() {
    return this.usersData$.pipe(
      map((users) =>
        users.map(
          (user): AutocompleteOption => ({
            key: user.id,
            text: `${user.name} ${user.surname}`,
          }),
        ),
      ),
    );
  }

  protected async loadUsers(internalUser: boolean) {
    try {
      this.usersData$.next(await this.userService.GetAllUsers(internalUser));
      this.loadPermissions();
      this.isSubmitting$.next(false);
    } catch (error) {
      return;
    }
  }

  protected async closeDialog() {
    this.dialogData.dialogRef.close({
      status: false,
    });
  }

  protected sharePermission() {
    this.form.markAllAsTouched();
    const userSelected: number[] = this.form.get('users')?.value || [];

    const { dataRoomId, isDirectory, id, parentId } = this.itemInformation;
    const items: DataRoomPermission[] = userSelected.map((userId) => ({
      dataRoomId: dataRoomId ? dataRoomId : 0,
      userId: userId,
      documentId: isDirectory ? undefined : id == 0 ? undefined : id,
      folderId: isDirectory ? id : parentId == null ? undefined : parentId,
    }));

    const model: DataRoomPermissionSharing = {
      dataRoomPermissions: items,
      notifyWithEmail: this.form.get('notifyByEmail')?.value as boolean,
      status: true,
    };
    this.dialogData.dialogRef.close(model);
  }
}
