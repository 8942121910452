import { Component, inject } from '@angular/core';
import { DataRoomService } from '../../services/data-room.service';
import { FolderFormComponent } from '../folder-form/folder-form.component';
import { CreateFolder } from '../../interfaces/create-folder';
import { DIALOG_DATA } from '../../../dialog/tokens/DIALOG_DATA';

@Component({
  selector: 'app-data-rooms-folder-create',
  standalone: true,
  providers: [DataRoomService],
  imports: [FolderFormComponent],
  templateUrl: './folder-create.component.html',
  styleUrls: ['./folder-create.component.scss'],
})
export class FolderCreateComponent {
  private dataRoomService = inject(DataRoomService);

  private dialogData = inject(DIALOG_DATA);

  private dialogDataDetails = this.dialogData.data as {
    dataRoomId: number;
    parentFolderId: number;
  };

  async onSubmit(folder: CreateFolder) {
    try {
      await this.dataRoomService.createFolder(
        folder,
        this.dialogDataDetails.dataRoomId,
        this.dialogDataDetails.parentFolderId,
      );
      this.dialogData.dialogRef.close(true);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }
}
