<form [formGroup]="userForm">
  <app-form-header
    [headline]="'user-details-details-headline' | translateNew: formUrl"
    [description]="
      'user-details-description-description' | translateNew: formUrl
    "
    [containTab]="true"
  >
  </app-form-header>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Name"
      data-translation-key="user-details-name"
      >{{ "user-details-name" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <input matInput formControlName="name" />
      <mat-error
        *ngIf="userForm.get('name')?.invalid"
        data-translation-key="required-field"
        data-translation-value="This field is required"
      >
        {{ "required-field" | translateNew: formUrl }}
      </mat-error>
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Surname"
      data-translation-key="user-details-surname"
      >{{ "user-details-surname" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <input matInput formControlName="surname" />
      <mat-error
        *ngIf="userForm.get('surname')?.invalid"
        data-translation-key="required-field"
        data-translation-value="This field is required"
      >
        {{ "required-field" | translateNew: formUrl }}
      </mat-error>
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Email"
      data-translation-key="user-details-email"
      >{{ "user-details-email" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <input matInput formControlName="email" />
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Phone Number"
      data-translation-key="user-details-phone-number"
      >{{ "user-details-phone-number" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <span class="prefix-text" *ngIf="phonePrefix"
        >{{ phonePrefix?.prefix }}
      </span>
      <input matInput formControlName="phoneNumber" />
      <mat-icon
        matPrefix
        [svgIcon]="
          phonePrefix?.template.code
            ? getFlagUrl(phonePrefix?.template.code)
            : 'empira:earth'
        "
      />
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Mobile Phone"
      data-translation-key="user-details-mobile-number"
      >{{ "user-details-mobile-number" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <span class="prefix-text" *ngIf="mobilePhonePrefix"
        >{{ mobilePhonePrefix?.prefix }}
      </span>
      <input matInput formControlName="mobilePhone" />
      <mat-icon
        matPrefix
        [svgIcon]="
          mobilePhonePrefix?.template.code
            ? getFlagUrl(mobilePhonePrefix?.template.code)
            : 'empira:earth'
        "
      />
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Job Title"
      data-translation-key="user-details-job-title"
      >{{ "user-details-job-title" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <input matInput formControlName="jobTitle" />
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Department"
      data-translation-key="user-details-department"
      >{{ "user-details-department" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <input matInput formControlName="department" />
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Street Address"
      data-translation-key="user-details-street-address"
      >{{ "user-details-street-address" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <input matInput formControlName="streetAddress" />
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="Postal Code"
      data-translation-key="user-details-postal-code"
      >{{ "user-details-postal-code" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <input matInput formControlName="postalCode" />
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-key="user-details-country"
      data-translation-value="Country"
      >{{ "user-details-country" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field>
      <input matInput formControlName="country" />
      <mat-icon
        matPrefix
        [svgIcon]="
          selectedCountryCode ? getFlagUrl(selectedCountryCode) : 'empira:earth'
        "
      />
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="State/Province"
      data-translation-key="user-details-state-or-province"
      >{{ "user-details-state-or-province" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field class="wide-field">
      <input
        type="text"
        aria-label="Autocomplete"
        matInput
        formControlName="stateOrProvince"
        [matAutocomplete]="state"
      />
      <mat-autocomplete #state="matAutocomplete"> </mat-autocomplete>
    </mat-form-field>
  </app-shared-form-field>
  <app-shared-form-field>
    <mat-label
      data-translation-value="City"
      data-translation-key="user-details-city"
      >{{ "user-details-city" | translateNew: formUrl }}</mat-label
    >
    <mat-form-field class="wide-field">
      <input
        type="text"
        aria-label="Autocomplete"
        matInput
        formControlName="city"
        [matAutocomplete]="city"
      />
      <mat-autocomplete #city="matAutocomplete"> </mat-autocomplete>
    </mat-form-field>
  </app-shared-form-field>
  <ng-container *ngIf="isInternal$ | async">
    <app-shared-form-field>
      <mat-label
        data-translation-value="Manager"
        data-translation-key="user-details-manager"
        >{{ "user-details-manager" | translateNew: formUrl }}</mat-label
      >
      <mat-slide-toggle formControlName="manager" />
    </app-shared-form-field>
    <app-shared-form-field>
      <mat-label
        data-translation-value="Chairman"
        data-translation-key="user-details-chairman"
        >{{ "user-details-chairman" | translateNew: formUrl }}</mat-label
      >
      <mat-slide-toggle formControlName="isDirector" />
    </app-shared-form-field>
    <app-shared-form-field>
      <mat-label
        data-translation-value="Part of Empira Active Directory"
        data-translation-key="user-details-part-of-empira-active-directory"
        >{{
          "user-details-part-of-empira-active-directory" | translateNew: formUrl
        }}</mat-label
      >
      <mat-slide-toggle formControlName="inActiveDirectory" />
    </app-shared-form-field>
  </ng-container>
</form>
<ng-container
  data-translation-key="user-details-details-headline"
  data-translation-value="Personal info"
/>
<ng-container
  data-translation-key="user-details-description-description"
  data-translation-value="The detailed information is managed in Azure"
/>
