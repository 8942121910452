import {
  Directive,
  inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SpinnerComponent } from '../components/spinner/spinner.component';

@Directive({
  selector: '[appSpinner]',
  standalone: true,
})
export class SpinnerDirective {
  @Input()
  set appSpinner(data: boolean) {
    this.viewContainer.clear();

    if (!data) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.createComponent(SpinnerComponent);
  }

  private templateRef = inject(TemplateRef);

  private viewContainer = inject(ViewContainerRef);
}
