import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EmpiraIcon } from '../interfaces/empira-icon';
import { empiraIconList } from '../icons';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  public readonly namespace = 'empira';

  private iconRegistry = inject(MatIconRegistry);

  private domSanitizer = inject(DomSanitizer);

  iconSetLoaded = false;

  public registerIcon(name: EmpiraIcon) {
    const iconData = empiraIconList.find(
      (empiraIcon) => empiraIcon.name === name,
    );

    if (!iconData) {
      console.warn(`Icon with name ${name} was not found`);
      return;
    }

    this.iconRegistry.addSvgIconLiteralInNamespace(
      this.namespace,
      name,
      this.domSanitizer.bypassSecurityTrustHtml(iconData.data),
    );
  }

  public registerIconList(names: EmpiraIcon[]) {
    names.forEach((name) => this.registerIcon(name));
  }

  public registerBase64Icon(icon: string, name: string) {
    const base64Icon = `data:image/svg+xml;base64,${icon}`;
    const sanitizedIcon =
      this.domSanitizer.bypassSecurityTrustResourceUrl(base64Icon);

    this.iconRegistry.addSvgIcon(name, sanitizedIcon);
  }

  // TODO: find a dynamic way to register placeholders
  public registerFlagByCountryCode(countryCode: string) {
    if (countryCode === '') {
      this.registerIcon('earth');

      return 'empira:earth';
    }

    const namespace = `${this.namespace}-flag`;
    const flagIcon = `${namespace}:${countryCode}`;

    // activate to generate new content for assets/flags/flags.svg
    // generateIconDefsList(countryCode);

    if (!this.iconSetLoaded) {
      this.iconRegistry.addSvgIconSetInNamespace(
        namespace,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/flags/flags.svg`,
        ),
      );

      this.iconSetLoaded = true;
    }
    return flagIcon;
  }
}

// activate to generate new content for assets/flags/flags.svg
//
// const countryCodePathList: Record<string, string> = {};
// const svgContentList: string[] = [];
//
// function getFlagPathByCountryCode(countryCode: string) {
//   if (countryCodePathList[countryCode]) {
//     return countryCodePathList[countryCode];
//   }
//
//   const filepath = `assets/flags/${countryCode}.svg`;
//   const xhr = new XMLHttpRequest();
//   xhr.open('HEAD', filepath, false);
//   xhr.send();
//
//   countryCodePathList[countryCode] =
//     xhr.status === 200 ? filepath : 'assets/flags/placeholder.svg';
//
//   return countryCodePathList[countryCode];
// }
//
// function generateIconDefsList(countryCode: string) {
//   const path = getFlagPathByCountryCode(countryCode);
//
//   const xhr = new XMLHttpRequest();
//   xhr.open('GET', path, false);
//   xhr.send();
//   svgContentList.push(
//     `<defs>${xhr.response.replace(
//       'xmlns="http://www.w3.org/2000/svg"',
//       `xmlns="http://www.w3.org/2000/svg" id="${countryCode}"`,
//     )}</defs>`,
//   );
//
//   // 240 is the number of ALL icons
//   if (svgContentList.length === 240) {
//     console.log(
//       `<svg xmlns="http://www.w3.org/2000/svg">${svgContentList.join(
//         '\n',
//       )}</svg>`,
//     );
//   }
// }
