import { inject, Pipe, PipeTransform } from '@angular/core';
import { IconService } from '../../icons/services/icon.service';
import { EmpiraIcon } from '../../icons/interfaces/empira-icon';

@Pipe({ name: 'fileIcon', standalone: true })
export class FileIconPipe implements PipeTransform {
  private iconService = inject(IconService);

  private readonly availableAudioFileTypes = ['mp3', 'wav'];

  private readonly availableImageFileTypes = [
    'eps',
    'gif',
    'img',
    'jpeg',
    'jpg',
    'png',
    'svg',
    'tiff',
    'webp',
  ];

  private readonly availableVideoFileTypes = ['avi', 'mp4', 'mpeg'];

  transform(fileType: string): string {
    if (fileType.includes('image/')) {
      return this.getImageTypeIcon(fileType);
    }

    if (fileType.includes('video/')) {
      return this.getVideoTypeIcon(fileType);
    }

    if (fileType.includes('audio/')) {
      return this.getAudioTypeIcon(fileType);
    }

    if (fileType === 'application/pdf') {
      return this.registerIcon('pdf');
    }

    if (fileType === 'application/rar') {
      return this.registerIcon('rar');
    }

    if (fileType === 'application/zip') {
      return this.registerIcon('zip');
    }

    if (fileType === 'text/csv') {
      return this.registerIcon('csv');
    }

    if (fileType === 'text/plain') {
      return this.registerIcon('txt');
    }

    if (fileType === 'application/msword') {
      return this.registerIcon('doc');
    }

    if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return this.registerIcon('docx');
    }

    if (fileType === 'application/mspowerpoint') {
      return this.registerIcon('ppt');
    }

    if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      return this.registerIcon('pptx');
    }

    if (fileType === 'application/vnd.oasis.opendocument.spreadsheet') {
      return this.registerIcon('spreadsheet');
    }

    if (fileType === 'application/msexcel') {
      return this.registerIcon('xls');
    }

    if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return this.registerIcon('xlsx');
    }

    return this.registerIcon('empty');
  }

  private getAudioTypeIcon(mimeType: string) {
    const fileType = this.availableAudioFileTypes.find((fileType) =>
      mimeType.includes(`audio/${fileType}`),
    );

    return this.registerIcon(fileType || 'audio');
  }

  private getImageTypeIcon(mimeType: string) {
    const fileType = this.availableImageFileTypes.find((fileType) =>
      mimeType.includes(`image/${fileType}`),
    );

    return this.registerIcon(fileType || 'image');
  }

  private getVideoTypeIcon(mimeType: string) {
    let fileType = this.availableVideoFileTypes.find((fileType) =>
      mimeType.includes(`video/${fileType}`),
    );

    if (mimeType === 'video/x-msvideo') {
      fileType = 'avi';
    }

    return this.registerIcon(fileType || 'video');
  }

  private registerIcon(icon: string) {
    const iconName = `file_type_${icon}` as EmpiraIcon;

    this.iconService.registerIcon(iconName);

    return `${this.iconService.namespace}:${iconName}`;
  }
}
