<div class="app-shared-form-field-item">
  <div class="app-shared-form-field-label" *ngIf="showLabel">
    <ng-content select="mat-label, app-shared-label" />
  </div>
  <div class="app-shared-form-field-input-wrap">
    <div [class.flex-container]="flexContainer">
      <ng-content />
    </div>
  </div>
</div>
<mat-divider />
