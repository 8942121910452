import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Response } from '../types/interfaces/response.interface';
import { lastValueFrom, map, Observable } from 'rxjs';
import { UserRequestDetailResponse } from '../interfaces/user-request-detail-response';
import { User } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserRequestDetailService {
  private apiUrl: string = environment.apiUrl;

  private http = inject(HttpClient);

  getUserData(id: number): Observable<User> {
    return this.http
      .get<Response<User>>(
        `${this.apiUrl}/UserRequestDetail/GetUserDataById/${id}`,
      )
      .pipe(map((response) => response.data));
  }

  async post(userRequestDetailData: UserRequestDetailResponse) {
    return await lastValueFrom(
      this.http.post<Response<boolean>>(
        `${this.apiUrl}/UserRequestDetail`,
        userRequestDetailData,
      ),
    );
  }

  verifyToken(token: string, userRequestId: number) {
    return this.http
      .post<Response<UserRequestDetailResponse>>(
        `${this.apiUrl}/UserRequestDetail/VerifyToken`,
        {
          token,
          userRequestId,
        },
      )
      .pipe(map((response) => response.data));
  }
}
