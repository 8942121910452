import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { DragAndDropFieldComponent } from '../drag-and-drop-field/drag-and-drop-field.component';
import { FormUrl } from '../../constants/form-url';
import { blobToBase64File } from '../../helpers/blob-to-base64-file';
import { TranslateNewPipe } from '../../../translation/pipes/translate-new.pipe';

@Component({
  selector: 'app-shared-image-upload',
  standalone: true,
  imports: [
    CommonModule,
    DragAndDropFieldComponent,
    ReactiveFormsModule,
    TranslateNewPipe,
  ],
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true,
    },
  ],
})
export class ImageUploadComponent implements ControlValueAccessor {
  @Input()
  allowedFileTypes = ['image/png', 'image/jpeg', 'image/gif'];

  @Input()
  mimeTypeError = 'Invalid file type. Only PNG, JPEG, and GIF are allowed.';

  @Input()
  allowedFileSize = 0;

  uploadField = new FormControl<File[] | null>(null);

  preview: string | null = null;

  formUrl = FormUrl.DataRoomsList;

  private cdr = inject(ChangeDetectorRef);

  constructor() {
    this.uploadField.valueChanges.subscribe((value) => {
      if (value === null) {
        return this.writeValue(null);
      }
      this.writeValue(value[0]);
    });
  }

  private _value: File | null = null;

  writeValue(value: File | null) {
    this._value = value;
    this.updatePreview();
    this._onChange(value);
    this._onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _onChange = (_value: File | null) => undefined;

  public registerOnChange(fn: (value: File | null) => undefined): void {
    this._onChange = fn;
  }

  private _onTouched = () => undefined;

  public registerOnTouched(fn: () => undefined): void {
    this._onTouched = fn;
  }

  private async updatePreview() {
    if (!this._value) {
      this.preview = null;

      return;
    }

    const fileType = this._value.type;
    const base64File = await blobToBase64File(this._value);

    this.preview = `data:${fileType};base64,${base64File}`;
    this.cdr.detectChanges();
  }
}
