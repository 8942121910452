export enum FileSize {
  DealsGalleryCoverImage = 'Deals.GalleryCoverImageSizeBytes',
  DealsGalleryImage = 'Deals.GalleryImageSizeBytes',
  DealsGalleryThumbnail = 'Deals.GalleryThumbnailSizeBytes',
  ProductGalleryCoverImage = 'Product.GalleryCoverImageSizeBytes',
  ProductGalleryImage = 'Product.GalleryImageSizeBytes',
  ProductGalleryThumbnail = 'Product.GalleryThumbnailSizeBytes',
  ContactUsFileSize = 'ContactUs.FileSizeBytes',
  CompanyDataLogoSize = 'CompanyData.LogoSizeBytes',
  UserPhotoSize = 'User.PhotoSizeBytes',
  DataRoomFileSize = 'DataRoom.FileSizeBytes',
  DataRoomPhotoSize = 'DataRoom.PhotoSizeBytes',
  MenuIconSize = 'Menu.IconSizeBytes',
  InvestorLogoSize = 'Investor.LogoSizeBytes',
  NDAFileSize = 'NDA.FileSizeBytes',
}
