import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true,
})
export class InitialsPipe implements PipeTransform {
  transform(value: string) {
    const acronym = value
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), '');

    return acronym.toUpperCase();
  }
}
