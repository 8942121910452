import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DialogColor } from '../../../dialog/interfaces/dialog-color';
import { IconService } from '../../../icons/services/icon.service';
import { EmpiraIcon } from '../../../icons/icons';

@Component({
  selector: 'app-shared-circled-icon',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './circled-icon.component.html',
  styleUrls: ['./circled-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircledIconComponent {
  @Input({ required: true })
  public set icon(icon) {
    this.iconService.registerIcon(icon);
    this._icon = icon;
  }
  public get icon() {
    return this._icon;
  }
  private _icon!: EmpiraIcon;

  @HostBinding('class.circled-icon-highlight')
  @Input()
  public highlightIcon = false;

  @Input()
  public iconColor: DialogColor = 'primary';

  @HostBinding('class')
  private get iconColorClass() {
    return `circled-icon-color-${this.iconColor}`;
  }

  private iconService = inject(IconService);

  getIconName(): string {
    return `empira:${this.icon}`;
  }
}
