import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { UserPhotoService } from '../../../shared/services/userphoto.service';
import { AsyncPipe, CommonModule, NgOptimizedImage } from '@angular/common';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FormUrl } from '../../../shared/constants/form-url';
import { MatButtonModule } from '@angular/material/button';
import { FormHeaderComponent } from 'src/app/shared/components/form-header/form-header.component';
import { ImageUploadComponent } from 'src/app/shared/components/image-upload/image-upload.component';
import { FormFieldComponent } from 'src/app/shared/components/form-field/form-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { blobToBase64File } from 'src/app/shared/helpers/blob-to-base64-file';
import { UserPhoto } from '../../interfaces/user-photo.interface';
import { TranslateNewPipe } from 'src/app/translation/pipes/translate-new.pipe';
import { AppParameterService } from 'src/app/shared/services/appparameter.service';
import { FileSize } from 'src/app/shared/constants/file-size';

@Component({
  selector: 'app-user-picture',
  standalone: true,
  templateUrl: './user-picture.component.html',
  styleUrls: ['./user-picture.component.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    TranslateNewPipe,
    AsyncPipe,
    MatButtonModule,
    ImageUploadComponent,
    FormHeaderComponent,
    FormFieldComponent,
    MatFormFieldModule,
    RouterLink,
  ],
})
export class UserPictureComponent implements OnInit {
  @Input({ required: true }) userId!: number;

  private userPhotoService = inject(UserPhotoService);

  protected formUrl = FormUrl.UserPictureForm;
  protected allowedFileSize$ = new BehaviorSubject<string | null>(null);
  protected appParameter = inject(AppParameterService);

  userPhotoForm = new FormGroup({
    profilePicture: new FormControl<File | null>(null),
  });

  @Input()
  backlink = '..';

  ngOnInit(): void {
    this.loaderUserPicture();
    this.appParameter.getById(FileSize.UserPhotoSize).subscribe((res) => {
      this.allowedFileSize$.next(res.value);
    });
  }

  async loaderUserPicture() {
    const userProfilePicture = await lastValueFrom(
      this.userPhotoService.selectCurrentUserProfilePictureAsBlob(this.userId),
    );

    if (!userProfilePicture) {
      return;
    }

    const file = new File([userProfilePicture], '');
    this.userPhotoForm.get('profilePicture')?.setValue(file);
  }

  protected async onSubmit() {
    if (!this.userPhotoForm.valid) {
      return;
    }
    const formValue = this.userPhotoForm.getRawValue();
    const profilePicture = formValue.profilePicture;
    if (!profilePicture) {
      console.error('No photo selected for upload');
      return;
    }

    const file = await blobToBase64File(profilePicture);
    try {
      const fileValue: UserPhoto = {
        photoBase64: file,
        photoFormat: 'png',
      };
      await lastValueFrom(
        this.userPhotoService.updateUserPhoto(fileValue, this.userId),
      );
    } catch (error) {
      console.error('Error uploading photo:', error);
    }
  }
}
