import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Response } from '../../shared/types/interfaces/response.interface';
import { Country } from '../interfaces/country';
import { Template } from '../interfaces/template';
import { TemplateSettingId } from '../constants/template-setting-id';
import { TemplateSettingDto } from '../dto/template-setting.dto';
import { mapTemplateSettingDtoToTemplateSetting } from '../mappers/map-template-setting-dto-to-template-setting';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private apiUrl: string = environment.apiUrl;

  private http = inject(HttpClient);

  getCountryList(): Observable<Country[]> {
    return this.getListByTemplateSettingId(TemplateSettingId.Country);
  }

  private getListByTemplateSettingId<T = Template>(
    templateSettingId: TemplateSettingId,
  ) {
    return this.http
      .get<Response<TemplateSettingDto>>(
        `${this.apiUrl}/TemplateSetting/GetByExpression/${templateSettingId}`,
      )
      .pipe(
        map((response) => response.data),
        map(mapTemplateSettingDtoToTemplateSetting),
        map((templateSetting) => templateSetting.templateList),
        map((templateList) => templateList as T[]),
      );
  }

  async getByList(settingIds: number[]) {
    return lastValueFrom(
      this.http
        .post<Response<TemplateSettingDto[]>>(
          `${this.apiUrl}/TemplateSetting/GetByList`,
          settingIds,
        )
        .pipe(
          map((response) => response.data),
          map((templateSettingDtoList) =>
            templateSettingDtoList.map(mapTemplateSettingDtoToTemplateSetting),
          ),
        ),
    );
  }

  selectByExpression(id: number) {
    return this.http
      .get<Response<TemplateSettingDto>>(
        `${this.apiUrl}/TemplateSetting/GetByExpression/${id}`,
      )
      .pipe(
        map((response) => response.data),
        map(mapTemplateSettingDtoToTemplateSetting),
      );
  }

  getByExpression(id: number) {
    return lastValueFrom(this.selectByExpression(id));
  }
}
