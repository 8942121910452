import { Component, inject, OnInit } from '@angular/core';
import { DataRoomService } from '../../services/data-room.service';
import { DIALOG_DATA } from '../../../dialog/tokens/DIALOG_DATA';
import { CreateFile } from '../../interfaces/create-file';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FileNameValidator } from '../../validators/filename.validator';
import { MatExpansionModule } from '@angular/material/expansion';
import { CreateFolder } from '../../interfaces/create-folder';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatIconModule } from '@angular/material/icon';
import { DragAndDropFieldComponent } from '../../../shared/components/drag-and-drop-field/drag-and-drop-field.component';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { AppParameterService } from 'src/app/shared/services/appparameter.service';
import { FileSize } from 'src/app/shared/constants/file-size';

interface DialogDataContent {
  parentFolderId: number;
  dataRoomId: number;
  fileList: File[];
}

interface FolderNode {
  name: string;
  children?: FolderNode[];
  hasChildren: boolean;
}

@Component({
  selector: 'app-data-rooms-folder-upload',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    NgForOf,
    MatTreeModule,
    MatIconModule,
    DragAndDropFieldComponent,
    SpinnerComponent,
  ],
  templateUrl: './folder-upload.component.html',
  styleUrls: ['./folder-upload.component.scss'],
  providers: [DataRoomService],
})
export class FolderUploadComponent implements OnInit {
  protected folder?: CreateFolder;

  protected isFormSubmitDisabled$ = new BehaviorSubject(true);
  protected allowedFileSize$ = new BehaviorSubject<string | null>(null);

  protected isUploading$ = new BehaviorSubject(false);

  protected form = new FormGroup({
    nameEn: new FormControl(
      { value: '', disabled: !!this.folder },
      {
        validators: [Validators.required, FileNameValidator()],
        nonNullable: true,
      },
    ),
    nameDe: new FormControl('', {
      validators: [Validators.required, FileNameValidator()],
      nonNullable: true,
    }),
    isPrivate: new FormControl(false, { nonNullable: true }),
    hasApproval: new FormControl(false, { nonNullable: true }),
    allowComment: new FormControl(false, { nonNullable: true }),
    orderNo: new FormControl(0, {
      validators: [Validators.required],
      nonNullable: true,
    }),
  });

  protected treeControl = new NestedTreeControl<FolderNode>(
    (node) => node.children,
  );

  protected dataSource = new MatTreeNestedDataSource<FolderNode>();

  protected folderUpload = new FormControl<File[] | null>(null);

  private dataRoomService = inject(DataRoomService);

  protected appParameter = inject(AppParameterService);

  private dialogData = inject(DIALOG_DATA);

  private dialogContent = this.dialogData.data as DialogDataContent;

  ngOnInit() {
    this.appParameter.getById(FileSize.DataRoomFileSize).subscribe((res) => {
      this.allowedFileSize$.next(res.value);
    });
    this.dialogData.dialogRef.element.addEventListener('cancel', (event) => {
      if (this.isUploading$.getValue()) {
        event.preventDefault();
      }
    });

    this.folderUpload.valueChanges.subscribe((files) => this.fileChange(files));

    if (this.dialogContent.fileList) {
      this.buildFolderList(this.dialogContent.fileList);
    }
  }

  protected onFormSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const createFolder = this.form.getRawValue();

      this.submitForm({ ...this.folder, ...createFolder });
    }
  }

  private async submitForm(folder: CreateFolder) {
    this.isUploading$.next(true);

    try {
      await this.dataRoomService.createFolder(
        folder,
        this.dialogContent.dataRoomId,
        this.dialogContent.parentFolderId,
      );
      this.dialogData.dialogRef.close(true);
    } catch (error) {
      console.error('An error occurred:', error);
    }

    this.isUploading$.next(false);
  }

  protected fileChange(fileList: File[] | null) {
    if (!fileList?.length) {
      return;
    }

    this.buildFolderList(fileList);
  }

  private buildFolderList(fileList: File[]) {
    this.folder = undefined;

    for (let i = 0; i < fileList.length; i++) {
      const currentFile = fileList[i];

      if (currentFile.type === '') {
        continue;
      }

      const newFile: CreateFile = {
        name: this.getFileNameFromFile(currentFile),
        file: currentFile,
        isPublic: false,
        allowComment: false,
        effectiveFrom: new Date(),
      };

      this.addFileToFolder(newFile, currentFile.webkitRelativePath);
    }

    this.dataSource.data = this.getFolderNodesFromFolder(this.folder);
  }

  private getFileNameFromFile(file: File) {
    const fileNameParts = file.name.split('.').filter((name) => name !== '');

    if (fileNameParts.length > 1) {
      fileNameParts.pop();
    }

    return fileNameParts.join('.');
  }

  private addFileToFolder(file: CreateFile, path: string) {
    const folders = path.split('/');
    folders.pop();

    if (!this.folder) {
      const rootFolder = this.createFolderFromName(folders[0]);
      this.folder = rootFolder;
      this.form.patchValue(rootFolder);
    }

    folders.shift();

    let currentFolder = this.folder;

    folders.forEach((folderName) => {
      if (!currentFolder.subfolderList) {
        currentFolder.subfolderList = [];
      }

      const currentFolderFromList = currentFolder.subfolderList.find(
        (folder) => folder.nameEn === folderName,
      );

      if (currentFolderFromList) {
        currentFolder = currentFolderFromList;

        return;
      }

      const newFolder = this.createFolderFromName(folderName);

      currentFolder.subfolderList.push(newFolder);

      currentFolder = newFolder;
    });

    if (!currentFolder.documentList) {
      currentFolder.documentList = [];
    }

    currentFolder.documentList.push(file);
    this.isFormSubmitDisabled$.next(false);
  }

  private createFolderFromName(name: string): CreateFolder {
    return {
      nameEn: name,
      nameDe: name,
      isPrivate: false,
      hasApproval: false,
      allowComment: false,
      orderNo: 0,
      subfolderList: [],
      documentList: [],
    };
  }

  protected closeDialog() {
    this.dialogData.dialogRef.close(false);
  }

  protected hasChild = (_: number, node: FolderNode) => node.hasChildren;

  private getFolderNodesFromFolder(folder?: CreateFolder): FolderNode[] {
    if (!this.folder) {
      return [];
    }

    const subfolders: FolderNode[] =
      folder?.subfolderList?.map((folda) => ({
        name: folda.nameEn,
        children: this.getFolderNodesFromFolder(folda),
        hasChildren: true,
      })) ?? [];

    const subfiles: FolderNode[] =
      folder?.documentList?.map((file) => ({
        name: file.name,
        hasChildren: false,
      })) ?? [];

    return [...subfolders, ...subfiles];
  }
}
