import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { Auth } from '../authentication/interfaces/auth.interface';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Initially clone the request to ensure withCredentials is always included
    let modifiedRequest = request.clone({
      withCredentials: environment.withCredentials,
    });

    // Retrieve user data from AuthService
    const userData: Auth | undefined = this.authService.getUserData();

    // Check if there's an access token and add the Authorization header if present
    if (userData && userData.accessToken) {
      modifiedRequest = modifiedRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      });
    }

    // Pass the modified request to the next handler in the chain
    return next.handle(modifiedRequest);
  }
}
