import { Component, HostBinding, inject, OnDestroy } from '@angular/core';
import DataGrid, {
  ExportingEvent,
  Properties as GridProperties,
} from 'devextreme/ui/data_grid';
import TreeList, {
  Properties as TreeListProperties,
} from 'devextreme/ui/tree_list';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Workbook } from 'exceljs';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToExcel } from 'devextreme/excel_exporter';
import jsPDF from 'jspdf';
import * as saveAs from 'file-saver';
import dxDataGrid from 'devextreme/ui/data_grid';
import { environment } from '../environments/environment';
import config from 'devextreme/core/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', '../styles/_grid.scss'],
})
export class AppComponent implements OnDestroy {
  @HostBinding('class.enable-nicholas-toggle-everywhere')
  private isNtActive = false;

  private stop$ = new Subject<void>();

  constructor() {
    inject(ActivatedRoute)
      .queryParams.pipe(takeUntil(this.stop$))
      .subscribe((params) => {
        if ('ente1994' in params) {
          this.isNtActive = true;
        }
      });

    config({
      licenseKey: environment.devExtremeLicenseKey,
    });

    DataGrid.defaultOptions<GridProperties>({
      options: {
        columnAutoWidth: true,
        allowColumnResizing: true,
        editing: {
          mode: 'row',
          useIcons: true,
        },
        export: {
          enabled: true,
          formats: ['pdf', 'xlsx'],
        },
        onExporting: this.handleExporting,
        filterRow: {
          visible: true,
        },
        loadPanel: {
          enabled: false,
        },
        pager: {
          visible: true,
          displayMode: 'full',
          showInfo: true,
          infoText: 'Page {0} of {1}',
          showNavigationButtons: true,
        },
        paging: {
          // TODO: Should be same as for material
          pageSize: 25,
        },
        scrolling: {
          columnRenderingMode: 'virtual',
          rowRenderingMode: 'virtual',
        },
        showBorders: true,
        showColumnLines: false,
      },
    });

    TreeList.defaultOptions<TreeListProperties>({
      options: {
        columnAutoWidth: true,
        allowColumnResizing: true,
        editing: {
          mode: 'row',
          useIcons: true,
        },
        filterRow: {
          visible: true,
        },
        loadPanel: {
          enabled: false,
        },
        pager: {
          visible: true,
          displayMode: 'full',
          showInfo: true,
          infoText: 'Page {0} of {1}',
          showNavigationButtons: true,
        },
        paging: {
          // TODO: Should be same as for material
          pageSize: 25,
        },
        scrolling: {
          columnRenderingMode: 'virtual',
          rowRenderingMode: 'virtual',
        },
        showBorders: true,
        showColumnLines: false,
        showRowLines: true,
      },
    });
  }

  ngOnDestroy() {
    this.stop$.next();
    this.stop$.complete();
  }

  private handleExporting = async (e: ExportingEvent) => {
    e.component.beginUpdate();
    const visibilityChangedColums = this.displayInvisibleColumns(e.component);
    e.component.endUpdate();

    const filename = e.fileName !== 'DataGrid' ? e.fileName : 'Empira';

    if (e.format === 'pdf') {
      const width = this.getComponentWidth(e.component);
      const height = this.getComponentHeight(e.component);
      const orientation = height > width ? 'portrait' : 'landscape';
      const format = [1000, Math.ceil(height)];

      const doc = new jsPDF({ orientation, unit: 'px', format });

      await exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      });

      doc.save(filename);
    } else if (e.format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('EmpiraSheet');

      await exportDataGridToExcel({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${filename}.xlsx`,
          { autoBom: false },
        );
      });
    }

    e.component.beginUpdate();
    this.hideInvisibleColumnsAfterExport(e.component, visibilityChangedColums);
    e.component.endUpdate();
  };

  private getComponentWidth(grid: dxDataGrid) {
    let width = 0;

    grid.getVisibleColumns().forEach((column) => {
      const columnIndex = column.visibleIndex;

      if (columnIndex === undefined || column.name === 'buttons') {
        return;
      }

      width += grid.columnOption(columnIndex, 'visibleWidth') ?? 0;
    });

    return width;
  }

  private getComponentHeight(grid: dxDataGrid) {
    let height = 0;

    grid.getVisibleRows().forEach((row) => {
      const rowElement = grid.getRowElement(row.rowIndex);

      if (rowElement === undefined) {
        return;
      }

      height += rowElement[0].scrollHeight;
    });

    return height;
  }

  private displayInvisibleColumns(component: dxDataGrid) {
    const visibilityChangedColums: number[] = [];

    for (let i = 0; i < component.columnCount(); i++) {
      if (!component.columnOption(i, 'visible')) {
        visibilityChangedColums.push(i);
        component.columnOption(i, 'visible', true);
      }
    }

    return visibilityChangedColums;
  }

  private hideInvisibleColumnsAfterExport(
    component: dxDataGrid,
    indexList: number[],
  ) {
    indexList.forEach((index) => {
      component.columnOption(index, 'visible', false);
    });
  }
}
