<div class="loader-wrap">
  <svg width="100%" height="100%" viewBox="0 0 66 66" class="circular-progress">
    <circle class="background" />
    <circle class="foreground" />
  </svg>
  <div class="box__loading">
    <span
      class="spinner__text"
      data-translation-value="Loading..."
      data-translation-key="spinner-loading-text"
    >
      {{ "spinner-loading-text" | translateNew: formUrl }}
    </span>
  </div>
</div>
