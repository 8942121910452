import { environment } from '../../../environments/environment';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map, Observable, take } from 'rxjs';
import { Response } from '../../shared/types/interfaces/response.interface';
import { FolderDto } from '../dtos/folder.dto';
import { CreateFolder } from '../interfaces/create-folder';
import { mapCreateFolderToCreateFolderDto } from '../mappers/map-create-folder-to-create-folder-dto';
import { mapCreateFolderToUpdateFolderDto } from '../mappers/map-create-folder-to-update-folder-dto';
import { mapFolderDtoToBaseFolder } from '../mappers/map-folder-dto-to-base-folder';
import { BaseFile } from '../interfaces/base-file';
import { DocumentDto } from '../dtos/document.dto';
import { mapCreateFileToCreateDocumentDto } from '../mappers/map-create-file-to-create-document-dto';
import { CreateFile } from '../interfaces/create-file';
import { mapDocumentDtoToFormFile } from '../mappers/map-document-dto-to-form-file';
import { DataRoomPermission } from '../interfaces/data-room-permission';
import { mapUpdateFileToUpdateDocumentDto } from '../mappers/map-update-file-to-update-document-dto';
import { DownloadType } from '../enum/download-type.interface';
import { DataRoomPermissionSharing } from '../interfaces/data-room-permission-sharing.interface';
import { DataRoomApproval } from '../interfaces/data-room-approval.interface';
import { DataRoomDto } from '../dtos/data-room.dto';
import { mapDataRoomDtoToNewDataRoom } from '../mappers/map-data-room-dto-to-new-data-room';
import { FolderContentDto } from '../dtos/folder-content.dto';
import { mapFolderContentDtoToFolderContent } from '../mappers/map-folder-content-dto-to-folder-content';

@Injectable()
export class DataRoomService {
  apiUrl = environment.apiUrl;

  http = inject(HttpClient);

  public selectDataRoomById(id: number) {
    return this.http
      .get<Response<DataRoomDto>>(
        `${this.apiUrl}/DataRoom/GetDataRoomByExpression/true/${id}`,
      )
      .pipe(
        map((response) => response.data),
        map(mapDataRoomDtoToNewDataRoom),
      );
  }

  public selectFolderContentByFolderId(folderId: number) {
    return this.http
      .get<Response<FolderContentDto | null>>(
        `${this.apiUrl}/Folder/GetSubFolderAndDocumentsByFolderId/${folderId}`,
      )
      .pipe(
        map(
          (response) =>
            response.data ?? {
              folderList: [],
              breadcrumbFolderList: [],
              documentList: [],
            },
        ),
        map(mapFolderContentDtoToFolderContent),
      );
  }

  public selectFolderContentByDataRoomId(dataRoomId: number) {
    return this.http
      .get<Response<FolderContentDto | null>>(
        `${this.apiUrl}/DataRoom/GetDataRoomByExpression/true/${dataRoomId}`,
      )
      .pipe(
        map(
          (response) =>
            response.data ?? {
              folderList: [],
              breadcrumbFolderList: [],
              documentList: [],
            },
        ),
        map(mapFolderContentDtoToFolderContent),
      );
  }

  public selectBaseFolder(folderId: number) {
    return lastValueFrom(
      this.http
        .get<Response<FolderDto>>(`${this.apiUrl}/Folder/${folderId}`)
        .pipe(
          map((response) => response.data),
          map(mapFolderDtoToBaseFolder),
        ),
    );
  }

  public async createFolder(
    folder: CreateFolder,
    dataRoomId: number,
    parentId: number,
  ) {
    await lastValueFrom(
      this.http.post(
        `${this.apiUrl}/Folder/PostRange`,
        await mapCreateFolderToCreateFolderDto(folder, dataRoomId, parentId),
      ),
    );
  }

  public async updateFolder(
    folder: CreateFolder,
    dataRoomId: number,
    folderId: number,
    parentId: number,
  ) {
    await lastValueFrom(
      this.http.put(
        `${this.apiUrl}/Folder`,
        mapCreateFolderToUpdateFolderDto(
          folder,
          dataRoomId,
          folderId,
          parentId,
        ),
        { params: { id: folderId } },
      ),
    );
  }

  public async deleteFolder(folderId: number) {
    await lastValueFrom(
      this.http.delete(`${this.apiUrl}/Folder`, {
        params: { id: folderId },
      }),
    );
  }

  public async deleteMultiple(documentIds: number[], folderIds: number[]) {
    await lastValueFrom(
      this.http.delete(`${this.apiUrl}/Folder/DeleteFolderIdsAndDocumentsIds`, {
        body: { folderIds, documentIds },
      }),
    );
  }

  public async uploadFiles(files: CreateFile[], folderId: number) {
    return lastValueFrom(
      this.http.post(
        `${this.apiUrl}/Document/PostRange`,
        await Promise.all(
          files.map(async (file) =>
            mapCreateFileToCreateDocumentDto(file, folderId),
          ),
        ),
      ),
    );
  }

  download(id: number, downloadType: DownloadType) {
    let downloadUrl: string;

    switch (downloadType) {
      case DownloadType.DataRoom:
        downloadUrl = `${this.apiUrl}/DataRoom/DownloadByDataRoom/${id}`;
        break;
      case DownloadType.Folder:
        downloadUrl = `${this.apiUrl}/Folder/DownloadByFolder/${id}`;
        break;
      case DownloadType.Document:
        downloadUrl = `${this.apiUrl}/Document/DownloadDocument/${id}`;
        break;
      default:
        throw new Error('Invalid downloadType provided');
    }

    return this.http.get(downloadUrl, {
      responseType: 'blob',
    });
  }

  public async updateFile(file: BaseFile, fileId: number, folderId: number) {
    const oldFile = await lastValueFrom(
      this.http
        .get<Response<DocumentDto>>(`${this.apiUrl}/Document/${fileId}`)
        .pipe(map((response) => response.data)),
    );

    return lastValueFrom(
      this.http.put(
        `${this.apiUrl}/Document?id=${fileId}`,
        await mapUpdateFileToUpdateDocumentDto(file, folderId, oldFile),
      ),
    );
  }

  public loadFile(fileId: number) {
    return lastValueFrom(
      this.http
        .get<Response<DocumentDto>>(`${this.apiUrl}/Document/${fileId}`)
        .pipe(
          map((response) => response.data),
          map(mapDocumentDtoToFormFile),
        ),
    );
  }

  public async deleteFile(fileId: number) {
    await lastValueFrom(
      this.http.delete(`${this.apiUrl}/Document?id=${fileId}`),
    );
  }

  getFilePreview(fileId: number) {
    return this.http
      .get<Response<DocumentDto>>(`${this.apiUrl}/Document/${fileId}`)
      .pipe(
        map((response) => response.data),
        map((file) => `data:${file.mimeType};base64,${file.doc}`),
      );
  }

  public loadPermissions(
    dataRoomId: number,
    id: number | undefined,
    isFolder: boolean,
  ) {
    return this.http
      .get<Response<DataRoomPermission[]>>(
        `${this.apiUrl}/DataRoomPermission/GetDataRoomPermissionById/${dataRoomId}/${id}/${isFolder}`,
      )
      .pipe(
        take(1),
        map((response) => response.data),
      );
  }

  public async share(request: DataRoomPermissionSharing) {
    return await lastValueFrom(
      this.http
        .post<Response<DataRoomPermission>>(
          `${this.apiUrl}/DataRoomPermission/PostRange`,
          request,
        )
        .pipe(map((response) => response.succeeded)),
    );
  }

  public loadApprovals(
    folderId: number,
    dataRoomId?: number,
  ): Observable<DataRoomApproval[]> {
    return this.http
      .get<Response<DataRoomApproval[]>>(
        `${this.apiUrl}/ApprovalConfig/GetApprovalsById/${folderId}/${dataRoomId}`,
      )
      .pipe(
        take(1),
        map((response) => response.data),
      );
  }

  public async submitApprovals(model: DataRoomApproval[]) {
    return await lastValueFrom(
      this.http
        .post<Response<boolean>>(
          `${this.apiUrl}/ApprovalConfig/PostRange`,
          model,
        )
        .pipe(map((response) => response.succeeded)),
    );
  }
}
