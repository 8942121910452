import { CreateFolder } from '../interfaces/create-folder';
import { CreateFolderDto } from '../dtos/create-folder.dto';
import { CreateFile } from '../interfaces/create-file';
import { mapCreateFileToCreateDocumentDto } from './map-create-file-to-create-document-dto';

export async function mapCreateFolderToCreateFolderDto(
  createFolder: CreateFolder,
  dataRoomId: number,
  parentId: number | null,
): Promise<CreateFolderDto> {
  return {
    nameEn: createFolder.nameEn,
    nameDe: createFolder.nameDe,
    parentId,
    dataRoomId,
    isPrivate: createFolder.isPrivate,
    allowComment: createFolder.allowComment,
    hasApproval: createFolder.hasApproval,
    // TODO: find the correct index
    orderNo: createFolder.orderNo,
    indexNo: createFolder.orderNo.toString(10),
    subfolderList: await getSubfolderList(
      dataRoomId,
      createFolder.subfolderList,
    ),
    documentList: await getDocumentList(createFolder.documentList),
  };
}

function getSubfolderList(dataRoomId: number, subfolderList?: CreateFolder[]) {
  if (!subfolderList) {
    return undefined;
  }

  return Promise.all(
    subfolderList.map((subfolder) =>
      mapCreateFolderToCreateFolderDto(subfolder, dataRoomId, null),
    ),
  );
}

function getDocumentList(documentList?: CreateFile[]) {
  if (!documentList) {
    return undefined;
  }

  return Promise.all(
    documentList.map((document) =>
      mapCreateFileToCreateDocumentDto(document, null),
    ),
  );
}
