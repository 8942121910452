<form [formGroup]="form" (submit)="onFormSubmit()">
  <app-shared-drag-and-drop-field
    [allowFolder]="true"
    [formControl]="folderUpload"
    [allowedFileSize]="allowedFileSize$ | async"
  />
  <mat-form-field>
    <mat-label>Name (En)</mat-label>
    <input matInput type="text" formControlName="nameEn" />
    <mat-error *ngIf="form.get('nameEn')?.hasError('required')"
      >This field is required</mat-error
    >
    <mat-error *ngIf="form.get('nameEn')?.hasError('filename')"
      >The folder name is not valid</mat-error
    >
  </mat-form-field>
  <mat-form-field>
    <mat-label>Name (De)</mat-label>
    <input matInput type="text" formControlName="nameDe" />
    <mat-error *ngIf="form.get('nameDe')?.hasError('required')">
      This field is required
    </mat-error>
    <mat-error *ngIf="form.get('nameDe')?.hasError('filename')">
      The folder name is not valid
    </mat-error>
  </mat-form-field>
  <div class="checkbox-group">
    <mat-checkbox formControlName="isPrivate">is Private</mat-checkbox>
    <mat-checkbox formControlName="hasApproval">has Approval</mat-checkbox>
    <mat-checkbox formControlName="allowComment">allow Comment</mat-checkbox>
  </div>
  <mat-form-field>
    <mat-label>Order No</mat-label>
    <input matInput type="number" formControlName="orderNo" />
  </mat-form-field>
  <div *ngIf="folder">
    <h5>Content</h5>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <mat-icon class="file-type-icon">description</mat-icon>
        {{ node.name }}
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <button
            type="button"
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}
            </mat-icon>
          </button>
          <mat-icon class="file-type-icon">folder</mat-icon>
          {{ node.name }}
        </div>
        <div
          [class.tree-invisible]="!treeControl.isExpanded(node)"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </div>

  <div class="button-container">
    <button
      [disabled]="!!(isUploading$ | async)"
      type="button"
      (click)="closeDialog()"
      mat-flat-button
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!!(isFormSubmitDisabled$ | async) || !!(isUploading$ | async)"
    >
      Save
    </button>
  </div>

  <app-loading-spinner *ngIf="isUploading$ | async" />
</form>
