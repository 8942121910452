import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ListComponent } from '../../../data-rooms/components/list/list.component';
import { UserService } from 'src/app/shared/services/users.service';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-user-data-room',
  standalone: true,
  imports: [CommonModule, ListComponent, AsyncPipe],
  templateUrl: './user-data-room.component.html',
})
export class UserDataRoomComponent implements OnChanges {
  protected userService = inject(UserService);
  protected dataRoomId$!: Observable<number | null>;
  @Input({ required: true }) userId!: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId']) {
      this.dataRoomId$ = this.selectDataRoomId();
    }
  }

  private selectDataRoomId() {
    return this.userService
      .getById(this.userId)
      .pipe(
        map((response) =>
          response.dataRoomList?.length ? response.dataRoomList[0].id : null,
        ),
      );
  }
}
