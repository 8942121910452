<ng-container *appSpinner="isSubmitting$ | async" />

<form [formGroup]="form" (ngSubmit)="approval()">
  <app-shared-form-field
    *ngIf="userOptions$ | async as userOptions"
    [condensed]="true"
  >
    <mat-label>{{
      "approval-selected-users" | translateNew: formUrl
    }}</mat-label>
    <app-shared-autocomplete-multiple
      [formControl]="users"
      [options]="userOptions"
    />
  </app-shared-form-field>

  <ng-container *ngIf="possibleMainUsersList$ | async as possibleMainUsersList">
    <mat-form-field *ngIf="possibleMainUsersList.length">
      <mat-label>{{ "approval-main-user" | translateNew: formUrl }}</mat-label>
      <mat-select formControlName="mainUser">
        <mat-option
          *ngFor="let user of possibleMainUsersList"
          [value]="user.id"
        >
          {{ user.name }} {{ user.surname }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <section class="pt-3">
    <div class="example-button-row">
      <button mat-raised-button color="primary" type="submit">Submit</button>
    </div>
  </section>
</form>

<ng-container
  data-translation-key="approval-selected-users"
  data-translation-value="Selected Users"
/>
<ng-container
  data-translation-key="approval-main-user"
  data-translation-value="Main User"
/>
