<form [formGroup]="form" (submit)="onFormSubmit()">
  <app-shared-drag-and-drop-field [formControl]="fileUpload" />
  <mat-form-field *ngIf="fileUpload.value as fileValue">
    <mat-label>New file</mat-label>
    <input matInput type="text" disabled [value]="fileValue[0].name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="name" />
    <mat-error *ngIf="form.controls.name.hasError('required')"
      >This field is required</mat-error
    >
    <mat-error *ngIf="form.controls.name.hasError('filename')"
      >The file name is not valid</mat-error
    >
  </mat-form-field>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <input matInput type="text" formControlName="description" />
  </mat-form-field>
  <div class="checkbox-group">
    <mat-checkbox formControlName="isPublic">Is public</mat-checkbox>
    <mat-checkbox formControlName="allowComment">Allow comment</mat-checkbox>
  </div>
  <mat-form-field>
    <mat-label>Effective from</mat-label>
    <input
      matInput
      type="text"
      formControlName="effectiveFrom"
      [matDatepicker]="effectiveFrom"
    />
    <mat-datepicker-toggle matIconSuffix [for]="effectiveFrom" />
    <mat-datepicker #effectiveFrom />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Effective to</mat-label>
    <input
      matInput
      type="text"
      formControlName="effectiveTo"
      [matDatepicker]="effectiveTo"
      [min]="form.getRawValue().effectiveFrom"
    />
    <mat-datepicker-toggle matIconSuffix [for]="effectiveTo" />
    <mat-datepicker #effectiveTo />
  </mat-form-field>
  <div class="button-container">
    <button
      [disabled]="!!(isUploading$ | async)"
      type="button"
      (click)="closeDialog()"
      mat-flat-button
    >
      Cancel
    </button>
    <button
      [disabled]="!!(isUploading$ | async)"
      mat-flat-button
      color="primary"
    >
      Save
    </button>
  </div>

  <app-loading-spinner *ngIf="isUploading$ | async" />
</form>
