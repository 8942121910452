import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { DropFiles } from '../interfaces/drop-files';

@Directive({
  standalone: true,
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
  @Output('appDragAndDrop')
  fileDrop = new EventEmitter<DropFiles>();

  @Input()
  preventBodyDrop = true;

  @HostBinding('class.drag-and-drop-active')
  active = false;

  @HostListener('drop', ['$event'])
  async onDrop(event: DragEvent) {
    event.preventDefault();
    this.active = false;

    const { dataTransfer } = event;

    if (!dataTransfer) {
      return;
    }

    if (dataTransfer.items) {
      const filePromises: Promise<File[]>[] = [];
      const isDirectory = this.doFilesContainDirectory(dataTransfer.items);

      for (let i = 0; i < dataTransfer.items.length; i++) {
        const item = dataTransfer.items[i].webkitGetAsEntry();

        if (!item) {
          return;
        }

        const fileTree = this.traverseFileTree(item);

        filePromises.push(fileTree);
      }

      const files = (await Promise.all(filePromises)).flat();

      this.fileDrop.emit({ files, isDirectory });
      dataTransfer.items.clear();
    } else {
      const files = dataTransfer?.files;
      dataTransfer?.clearData();
      this.fileDrop.emit({ files: Array.from(files), isDirectory: false });
    }
  }

  private doFilesContainDirectory(items: DataTransferItemList) {
    return !!Array.from(items).filter(
      (item) => (item.webkitGetAsEntry() as FileSystemEntry).isDirectory,
    ).length;
  }

  private async traverseFileTree(item: FileSystemEntry) {
    if (item.isFile) {
      return [await this.getFile(item as FileSystemFileEntry)];
    }

    const fileList: File[] = [];

    const files = await this.getDirectory(item as FileSystemDirectoryEntry);

    for (const file of files) {
      fileList.push(...(await this.traverseFileTree(file)));
    }

    return fileList;
  }

  private async getFile(fileEntry: FileSystemFileEntry) {
    const file = await new Promise<File>((resolve, reject) =>
      fileEntry.file(resolve, reject),
    );

    Object.defineProperty(file, 'webkitRelativePath', {
      value: fileEntry.fullPath.substring(1),
    });

    return file;
  }

  private getDirectory(fileEntry: FileSystemDirectoryEntry) {
    return new Promise<FileSystemEntry[]>((resolve, reject) =>
      fileEntry.createReader().readEntries(resolve, reject),
    );
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.active = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave() {
    this.active = false;
  }

  @HostListener('body:dragover', ['$event'])
  onBodyDragOver(event: DragEvent) {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  @HostListener('body:drop', ['$event'])
  onBodyDrop(event: DragEvent) {
    if (this.preventBodyDrop) {
      event.preventDefault();
    }
  }
}
