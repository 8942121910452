import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  inject,
  Injectable,
  Injector,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ToastComponent } from '../components/toast/toast.component';
import { ToastType } from '../constants/toast-type';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private appRef = inject(ApplicationRef);

  private document = inject(DOCUMENT);

  private dialogList: ComponentRef<ToastComponent>[] = [];

  public showMessage(message: string, title: string, type: ToastType) {
    const injector = Injector.create({
      providers: [],
    });

    const dialog = createComponent(ToastComponent, {
      environmentInjector: this.appRef.injector,
      elementInjector: injector,
    });

    dialog.setInput('title', title);
    dialog.setInput('message', message);
    dialog.setInput('type', type);
    dialog.changeDetectorRef.detectChanges();

    const openModal = this.document.body.querySelectorAll(
      'app-dialog > dialog[open]',
    )[0];

    const parentContainer = openModal ?? this.document.body;

    parentContainer.append(dialog.location.nativeElement);

    dialog.instance.show();

    this.dialogList.push(dialog);

    setTimeout(() => this.closeDialog(dialog), 5000);
  }

  info(message: string, title: string) {
    this.showMessage(message, title, ToastType.Info);
  }

  success(message: string, title: string) {
    this.showMessage(message, title, ToastType.Success);
  }

  warning(message: string, title: string) {
    this.showMessage(message, title, ToastType.Warning);
  }

  error(message: string, title: string) {
    this.showMessage(message, title, ToastType.Error);
  }

  clear() {
    this.dialogList.forEach((dialog) => this.closeDialog(dialog));
  }

  private closeDialog(dialog: ComponentRef<ToastComponent>) {
    dialog.instance.close();
    dialog.location.nativeElement.remove();
    this.cleanupDialogList();
  }

  private cleanupDialogList() {
    this.dialogList = this.dialogList.filter((dialog) => dialog.instance.open);
  }
}
