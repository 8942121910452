<app-data-room-list
  [dataRoomId]="dataRoomId$ | async"
  [canCreateFolder]="true"
  [canUpdateFolder]="true"
  [canDeleteFolder]="false"
  [canUploadFolder]="true"
  [canUploadFile]="true"
  [canUpdateFile]="true"
  [canDeleteFile]="false"
  [canApprove]="true"
  [canSetPermissions]="false"
/>
