import {
  ChangeDetectorRef,
  inject,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { FormUrl } from '../../shared/constants/form-url';
import { AsyncPipe } from '@angular/common';

@Pipe({
  name: 'translateNew',
  pure: false,
  standalone: true,
})
export class TranslateNewPipe implements PipeTransform, OnDestroy {
  private translateService = inject(TranslationService);

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  private asyncPipe = new AsyncPipe(this.cdr);

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }

  public transform(value: string, formUrl: FormUrl) {
    const translation = this.asyncPipe.transform(
      this.translateService.selectTranslationByKey(value, formUrl),
    );

    return translation !== null ? translation : value;
  }
}
