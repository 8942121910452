export enum FormUrl {
  AccountRequestManagementCreate = 'account-request-management\\components\\create\\create.component.html',
  AccountRequestManagementList = 'account-request-management\\components\\list\\list.component.html',
  ApplicationMessagesForm = 'application-messages\\components\\form\\form.component.html',
  ApplicationMessagesList = 'application-messages\\components\\list\\list.component.html',
  ApplicationParameterForm = 'application-parameter\\components\\form\\form.component.html',
  ApplicationParameterList = 'application-parameter\\components\\list\\list.component.html',
  ApprovalFormDebt = 'approval-form-debt\\components\\list\\list.component.html',
  ApprovalDataRoom = 'approval-data-room\\components\\list\\list.component.html',
  ApprovalFormClubDeal = 'approval-form-club-deal\\components\\list\\list.component.html',
  AuthenticationAzureAuth = 'authentication\\components\\azure-auth\\azure-auth.component.html',
  AuthenticationForgotPassword = 'authentication\\components\\forgot-password\\forgot-password.component.html',
  AuthenticationLogin = 'authentication\\components\\login\\login.component.html',
  AuthenticationResetPassword = 'user-profile\\components\\reset-password\\reset-password.component.html',
  BusinessEntitiesCreate = 'business-entities\\components\\create\\create.component.html',
  BusinessEntitiesEdit = 'business-entities\\components\\edit\\edit.component.html',
  BusinessEntitiesForm = 'business-entities\\components\\form\\form.component.html',
  BusinessEntitiesList = 'business-entities\\components\\list\\list.component.html',
  BusinessEntitiesUserForm = 'business-entities\\ui\\user-form\\user-form.component.html',
  BusinessEntitiesUserList = 'business-entities\\ui\\users\\users.component.html',
  CompanyDataList = 'company-data\\components\\list\\list.component.html',
  CompanyDataDetails = 'company-data\\components\\tabs\\company-details\\company-details.component.html',
  CompanyDataLocation = 'company-data\\components\\tabs\\location\\location.component.html',
  CompanyDataSubsidiaryCompany = 'company-data\\components\\tabs\\subsidiary-company\\subsidiary-company.component.html',
  ClulbApprovalList = 'club-deal\\components\\list\\list.component.html',
  ClubDealApprovalCreate = 'club-deal\\components\\create\\create.component.html',
  ClubDealList = 'club-deal-management-form\\components\\list\\list.component.html',
  ClubDealForm = 'club-deal-management-form\\components\\tabs\\club-deal\\club-deal.component.html',
  ClubDealFormTabs = 'club-deal-management-form\\components\\form\\form.component.html',
  ClubDealAssetAreaForm = 'club-deal-management-form\\components\\club-deal-asset-area\\club-deal-asset-area.component.html',
  ClubDealCostForm = 'club-deal-management-form\\components\\club-deal-cost\\club-deal-cost.component.html',
  ClubDealDetailForm = 'club-deal-management-form\\components\\tabs\\club-deal-detail\\club-deal-detail.component.html',
  ClubDealGalleryEdit = 'club-deal-management-form\\components\\gallery-edit\\gallery-edit.component.html',
  ClubDealLocationForm = 'club-deal-management-form\\components\\tabs\\club-deal-location\\club-deal-location.component.html',
  ClubDealUseForm = 'club-deal-management-form\\components\\tabs\\club-deal-use\\club-deal-use.component.html',
  ClubDealCapitalStackForm = 'club-deal-management-form\\components\\tabs\\club-deal-capital-stack\\club-deal-capital-stack.component.html',
  ClubDealPropertyDetailForm = 'club-deal-management-form\\components\\tabs\\club-deal-property-detail\\club-deal-property-detail.component.html',
  ClubDealKPIForm = 'club-deal-management-form\\components\\tabs\\club-deal-kpi\\club-deal-kpi.component.html',
  ClubDealResponsiblePersonForm = 'club-deal-management-form\\components\\tabs\\club-deal-responsible-person\\club-deal-responsible-person.component.html',
  ClubDealBusinessPlanForm = 'club-deal-management-form\\components\\tabs\\club-deal-business-plan\\club-deal-business-plan.component.html',
  ClubDealFinancialForm = 'club-deal-management-form\\components\\tabs\\club-deal-financial\\club-deal-financial.component.html',
  ClubDealMarketFactForm = 'club-deal-management-form\\components\\tabs\\club-deal-market-fact\\club-deal-market-fact.component.html',
  DealManagementFormTabsPublishHistory = 'deal-management\\ui\\publish-history\\publish-history.component.html',
  DealManagementMain = 'deal-management\\ui\\deal-main\\deal-main.component.html',
  CockpitForm = 'cockpit-form\\components\\shared-form\\shared-form.component.html',
  CockpitList = 'cockpit-form\\components\\list\\list.component.html',
  CockpitDataRoomRequestTooltip = 'cockpit-form\\ui\\data-room-request-tooltip\\data-room-request-tooltip.component.html',
  CockpitListColumnButtons = 'cockpit-form\\ui\\list-column-buttons\\list-column-buttons.component.html',
  CockpitSlideShow = 'cockpit-form\\ui\\slideshow\\slideshow.component.html',
  CockpitListGrid = 'cockpit-form\\ui\\list-grid\\list-grid.component.html',
  CockpitListMap = 'cockpit-form\\ui\\list-map\\list-map.component.html',
  DealTypeTag = 'cockpit-form\\ui\\deal-type-tag\\deal-type-tag.component.html',
  DealAccessManagementForm = 'deal-access-management\\components\\form\\form.component.html',
  DealAccessManagementList = 'deal-access-management\\ui\\list\\list.component.html',
  DealAccessManagementVerdictRequest = 'deal-access-management\\ui\\verdict-request\\verdict-request.component.html',
  ErrorPageNotFound = 'error-page\\components\\not-found\\not-found.component.html',
  Investments = 'cockpit-form\\components\\investments\\investments.component.html',
  MapAddressFields = 'map\\components\\address-fields\\address-fields.component.html',
  MapAddressCustomFields = 'shared\\components\\address-fields-custom\\address-fields-custom.component.html',
  UserRequestDetailsList = 'user-request-details\\components\\list\\list.component.html',
  UserPreferenceManagementList = 'user-profile\\components\\user-preference-management\\list\\list.component.html',
  UserGroupForm = 'user-group\\components\\form\\form\\form.component.html',
  UserGroupList = 'user-group\\components\\list\\list\\list.component.html',
  UserFormPermissionForm = 'user-form-permission\\components\\form\\form.component.html',
  UserFormPermissionUserDataForm = 'user-form-permission\\components\\user-data-form\\user-data\\user-data.component.html',
  UserFormPermissionUserPermissionForm = 'user-form-permission\\components\\user-permission-form\\user-permission.component.html',
  SyncAzureData = 'sync-azure-data\\components\\sync-azure-data\\sync-azure-data.component.html',
  ShellNavbarTop = 'shell\\components\\navbar-top\\navbar-top.component.html',
  ReportVisits = 'report-visits\\components\\report-visits\\report-visits.component.html',
  ReportVideoCalls = 'report-video-calls\\components\\report-video-calls\\report-video-calls.component.html',
  ReportEmployee = 'report-employee\\components\\report-employee\\report-employee.component.html',
  ReportAumDevelopment = 'report-aum-development\\components\\development\\development.component.html',
  ReportEquityDebtAllocation = 'report-aum-development\\components\\equity-debt-allocation\\equity-debt-allocation.component.html',
  ReportAumByProduct = 'report-aum-by-product\\components\\report-aum-by-product\\report-aum-by-product.component.html',
  ReportAumByCategory = 'report-aum-by-category\\components\\report-aum-by-category\\report-aum-by-category.component.html',
  RegisterExternal = 'register-external\\register-external.component.html',
  RegionList = 'regions\\components\\list\\list.component.html',
  RegionForm = 'regions\\components\\form\\form.component.html',
  DataRoomsFileForm = 'data-rooms\\components\\file-form\\file-form.component.html',
  DataRoomsFolderForm = 'data-rooms\\components\\folder-form\\folder-form.component.html',
  DataRoomsList = 'data-rooms\\components\\list\\list.component.html',
  DataRoomsApproval = 'data-rooms\\components\\approval\\approval.component.html',
  DialogConfirm = 'dialog\\components\\confirm\\confirm.component.html',
  DisclaimerCreate = 'disclaimer\\components\\create\\create.component.html',
  DisclaimerForm = 'disclaimer\\components\\form\\form.component.html',
  DisclaimerDisclaimer = 'disclaimer\\components\\disclaimer\\disclaimer.component.html',
  DisclaimerEdit = 'disclaimer\\components\\edit\\preview.component.html',
  DebtApprovalList = 'debt\\components\\list\\list.component.html',
  DebtApprovalCreate = 'debt\\components\\create\\create.component.html',
  DebtList = 'debt-management-form\\components\\list\\list.component.html',
  DebtFormTabs = 'debt-management-form\\components\\form\\form.component.html',
  DebtForm = 'debt-management-form\\components\\tabs\\debt\\debt.component.html',
  DebtAssetAreaForm = 'debt-management-form\\components\\tabs\\debt-asset-area\\debt-asset-area.component.html',
  DebtBankForm = 'debt-management-form\\components\\tabs\\debt-bank\\debt-bank.component.html',
  DebtCostForm = 'debt-management-form\\components\\tabs\\debt-cost\\debt-cost.component.html',
  DebtDetailForm = 'debt-management-form\\components\\tabs\\debt-detail\\debt-detail.component.html',
  DebtCapitalStackForm = 'debt-management-form\\components\\tabs\\debt-capital-stack\\debt-capital-stack.component.html',
  DebtKPIForm = 'debt-management-form\\components\\tabs\\debt-kpi\\debt-kpi.component.html',
  DebtUseForm = 'debt-management-form\\components\\tabs\\debt-use\\debt-use.component.html',
  DebtPropertyDetailForm = 'debt-management-form\\components\\tabs\\debt-property-detail\\debt-property-detail.component.html',
  DebtMarketFactForm = 'debt-management-form\\components\\tabs\\debt-market-fact\\debt-market-fact.component.html',
  DebtBusinessPlanForm = 'debt-management-form\\components\\tabs\\debt-business-plan\\debt-business-plan.component.html',
  DebtFinancialForm = 'debt-management-form\\components\\tabs\\debt-financial\\debt-financial.component.html',
  DebtCreditFacilityForm = 'debt-management-form\\ui\\credit-facility\\credit-facility.component.html',
  DealCapitalStackSourceForm = 'debt-management-form\\ui\\deal-source\\deal-source.component.html',
  DealCapitalStackUseForm = 'debt-management-form\\ui\\deal-use\\deal-use.component.html',
  DebtSpaceUseForm = 'debt-management-form\\ui\\space-use\\space-use.component.html',
  DebtParkingSpaceForm = 'debt-management-form\\ui\\parking-spaces\\parking-spaces.component.html',
  DebtLocationForm = 'debt-management-form\\components\\tabs\\debt-location\\debt-location.component.html',
  DebtGalleryEdit = 'debt-management-form\\components\\gallery-edit\\gallery-edit.component.html',
  DebtResponsiblePersonForm = 'debt-management-form\\components\\tabs\\debt-responsible-person\\debt-responsible-person.component.html',
  DragAndDropField = 'shared\\components\\drag-and-drop-field\\drag-and-drop-field.component.html',
  AddMultipleValues = 'shared\\components\\add-multiple-values\\add-multiple-values\\add-multiple-values.component.html',
  EmailCreate = 'email\\components\\create\\create.component.html',
  EmailEdit = 'email\\components\\edit\\edit.component.html',
  EmailForm = 'email\\components\\form\\form.component.html',
  EmailList = 'email\\components\\list\\list.component.html',
  ExternalUsers = 'external-users\\components\\external-users\\external-users.component.html',
  InternalUsers = 'internal-users\\components\\internal-users\\internal-users.component.html',
  FormResources = 'form-resources\\components\\list\\list.component.html',
  FormResourcesForm = 'form-resources\\components\\form\\form.component.html',
  FormPermissionForm = 'form-permission\\components\\form\\form\\form.component.html',
  FundsGalleryEdit = 'funds\\components\\gallery-edit\\gallery-edit.component.html',
  FundsHeaderPreview = 'shared\\components\\funds-header-preview\\funds-header-preview.component.html',
  GalleryListPreview = 'shared-gallery\\ui\\list-preview\\list-preview.component.html',
  GalleryListPreviewItem = 'shared-gallery\\ui\\list-preview-item\\list-preview-item.component.html',
  LogoPageAnimation = 'shared\\components\\logo-page-animation\\logo-page-animation.component.html',
  TemplateManagement = 'template-management\\components\\list\\list.component.html',
  TranslationForm = 'translation-form\\components\\list\\list.component.html',
  ModulesCreate = 'modules\\components\\create\\create.component.html',
  ModulesEdit = 'modules\\components\\edit\\preview.component.html',
  ModulesForm = 'modules\\components\\form\\form.component.html',
  ModulesList = 'modules\\components\\list\\list.component.html',
  MyInvestmentsList = 'my-investments\\components\\list\\list.component.html',
  FormList = 'forms\\components\\form-list\\list.component.html',
  FormCreate = 'forms\\components\\form-create\\create.component.html',
  FormEdit = 'forms\\components\\form-edit\\preview.component.html',
  FormForm = 'forms\\components\\form\\form.component.html',
  FolderManagementCreate = 'folder-management\\components\\create\\create.component.html',
  FolderManagementEdit = 'folder-management\\components\\edit\\preview.component.html',
  FolderManagementForm = 'folder-management\\components\\form\\form.component.html',
  TemplateManagementCreateFolder = 'folder-management\\components\\create-folder\\create-folder.component.html',
  TemplateManagementEditFolder = 'folder-management\\components\\edit-folder\\edit-folder.component.html',
  TemplateManagementFormFolder = 'folder-management\\components\\folder-form\\folder-form.component.html',
  FolderManagementList = 'folder-management\\components\\list\\list.component.html',
  FolderManagementDataRoomsStructure = 'folder-management\\components\\data-rooms-structure\\form.component.html',
  FolderManagementViewStructure = 'folder-management\\components\\view-structure\\view-structure.component.html',
  FooterSettings = 'footer-settings\\footer-settings.component.html',
  ForecastedVisits = 'forecasted-visits\\components\\forecasted-visits\\forecasted-visits.component.html',
  FundsList = 'funds\\components\\list\\list.component.html',
  FundsForm = 'funds\\components\\form\\form.component.html',
  FundsDetails = 'funds\\components\\tabs\\funds-details\\funds-details.component.html',
  FundsLocation = 'funds\\components\\tabs\\funds-location\\funds-location.component.html',
  FundsAdministrators = 'fund-administrators\\components\\list\\list.component.html',
  FundsAdministratorsCreate = 'fund-administrators\\components\\create\\create.component.html',
  DataRoomsBreadCrumb = 'data-rooms\\components\\breadcrumb\\breadcrumb.component.html',
  DataRoomsPermission = 'data-rooms\\components\\permission\\permission\\permission.component.html',
  SessionStateList = 'session-state\\components\\session-state\\session-state.component.html',
  SharedDataRoomList = 'shared-data-room\\components\\list\\list\\list.component.html',
  SharedGalleryForm = 'shared-gallery\\ui\\form\\form.component.html',
  TemplateManagementCreate = 'template-management\\components\\create\\create.component.html',
  TemplateManagementForm = 'template-management\\components\\form\\form.component.html',
  TemplateManagementEdit = 'template-management\\components\\edit\\preview.component.html',
  UserPictureForm = 'user-profile\\components\\user-picture\\user-picture.component.html',
  UserProfileForm = 'user-profile\\components\\user-details\\user-details.component.html',
  UserProfileTabForm = 'user-profile\\components\\form\\form.component.html',
  UserMenu = 'shared\\components\\user-menu\\user-menu.component.html',
  ModalForm = 'shared\\features\\modal\\modal.component.html',
  NotMobileFriendlyForm = 'authentication\\components\\not-mobile-friendly\\not-mobile-friendly.component.html',
  DialogContact = 'contact\\components\\contact\\contact.component.html',
  DialogSessionTimeout = 'session-timeout\\session-timeout.component.html',
  SpinnerComponent = 'shared\\components\\spinner\\spinner.component.html',
  RefuseApproveRequest = 'approval-data-room\\components\\refuse-approve-request\\refuse-approve-request.component.html',
  RequestAccessDataRoom = 'cockpit-form\\components\\request-access-data-room\\request-access-data-room.component.html',
  RequestAccess = 'cockpit-form\\components\\request-access\\request-access.component.html',
  CockpitHeadline = 'cockpit-form\\components\\form\\form.component.html',
  ExternalDealOverview = 'external-deal\\ui\\overview\\overview.component.html',
  ExternalDealPageWrap = 'external-deal\\ui\\page-wrap\\page-wrap.component.html',
  PhotoEditDialog = 'shared-gallery\\ui\\photo-edit-dialog\\photo-edit-dialog.component.html',
  PhonePrefixForm = 'phone-prefix\\components\\form\\form.component.html',
  PhonePrefixList = 'phone-prefix\\components\\list\\list.component.html',
  SharedSelectionToolbar = 'shared\\components\\selection-toolbar\\selection-toolbar.component.html',
  Global = 'app.component.html',
  LeftFooterConfiguration = 'footer-configuration\\components\\left-footer\\left-footer.component.html',
  RightFooterConfiguration = 'footer-configuration\\components\\right-footer\\right-footer.component.html',
  FooterConfiguration = 'footer-configuration\\components\\form\\form.component.html',
  FooterCreateLink = 'create-footer-link\\create-footer-link.component.html',
  InitialFooter = 'shared\\components\\initial-footer\\initial-footer.component.html',
  ExpandedFooter = 'shared\\components\\expanded-footer\\expanded-footer.component.html',
  StaticPage = 'static-page\\static-page.component.html',
  DealManagementPublishDialog = 'deal-management\\ui\\publish-dialog\\publish-dialog.component.html',
  DealManagementSpaceUse = 'deal-management\\ui\\deal-space-use\\deal-space-use.component.html',
  DealManagementParkingSpace = 'deal-management\\ui\\deal-parking-space\\deal-parking-space.component.html',
  DealManagementCreditFacility = 'deal-management\\ui\\deal-credit-facility\\deal-credit-facility.component.html',
  DealManagementSource = 'deal-management\\ui\\deal-source\\deal-source.component.html',
  DealManagementDialogCreditFacility = 'deal-management\\ui\\dialogs\\credit-facility\\credit-facility.component.html',
  DealManagementDialogDealSource = 'deal-management\\ui\\dialogs\\deal-source\\deal-source.component.html',
  DealManagementDialogDealUse = 'deal-management\\ui\\dialogs\\deal-use\\deal-use.component.html',
  DealManagementDialogParkingSpaces = 'deal-management\\ui\\dialogs\\parking-spaces\\parking-spaces.component.html',
  DealManagementDialogSpaceUse = 'deal-management\\ui\\dialogs\\space-use\\space-use.component.html',
  DealManagementUse = 'deal-management\\ui\\deal-use\\deal-use.component.html',
  DealManagementCommentSection = 'deal-management\\ui\\comment-section\\comment-section.component.html',
  DealManagementCommentDialog = 'deal-management\\ui\\comment-dialog\\comment-dialog.component.html',
  ViewComment = 'shared\\components\\view-comment\\view-comment.component.html',
  FaqForm = 'faq\\components\\form\\form.component.html',
  FaqList = 'faq\\components\\list\\list.component.html',
  DataApprovalForm = 'approval-data-room\\components\\form\\form.component.html',
}
