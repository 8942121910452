<div class="inner-toolbar">
  <div class="selected-info">
    <button mat-icon-button type="button" (click)="deselectAll.emit()">
      <mat-icon svgIcon="empira:x" />
    </button>
    <div
      class="count"
      data-translation-key="shared-selection-toolbar-selected"
      data-translation-value="selected"
    >
      {{ selectedCount }}
      {{ "shared-selection-toolbar-selected" | translateNew: formUrl }}
    </div>
  </div>

  <button
    *ngFor="let action of actions"
    mat-icon-button
    type="button"
    (click)="action.action()"
  >
    <mat-icon [svgIcon]="'empira:' + action.icon" />
  </button>
</div>
