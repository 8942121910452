import { CreateFolder } from '../interfaces/create-folder';
import { UpdateFolderDto } from '../dtos/update-folder.dto';

export function mapCreateFolderToUpdateFolderDto(
  createFolder: CreateFolder,
  dataRoomId: number,
  folderId: number,
  parentId: number,
): UpdateFolderDto {
  return {
    id: folderId,
    nameEn: createFolder.nameEn,
    nameDe: createFolder.nameDe,
    parentId,
    dataRoomId,
    isPrivate: createFolder.isPrivate,
    allowComment: createFolder.allowComment,
    hasApproval: createFolder.hasApproval,
    // TODO: find the correct index
    orderNo: createFolder.orderNo,
  };
}
