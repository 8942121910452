import { TemplateDto } from '../dto/template.dto';
import { Template } from '../interfaces/template';

export function mapTemplateDtoToTemplate(templateDto: TemplateDto): Template {
  return {
    id: templateDto.id,
    templateSettingId: templateDto.templateSettingId,
    parentId: templateDto.parentId,
    code: templateDto.code,
    displayText: templateDto.displayText || '',
    description: templateDto.description,
    isActive: templateDto.isActive,
    isDefault: templateDto.isDefault,
  };
}
