import { Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { FolderFormComponent } from '../folder-form/folder-form.component';
import { DataRoomService } from '../../services/data-room.service';
import { CreateFolder } from '../../interfaces/create-folder';
import { DIALOG_DATA } from '../../../dialog/tokens/DIALOG_DATA';

type EditParams = {
  dataRoomId: number;
  folder: number;
  parentFolderId: number;
};

@Component({
  selector: 'app-data-rooms-folder-edit',
  standalone: true,
  providers: [DataRoomService],
  imports: [NgIf, FolderFormComponent, AsyncPipe],
  templateUrl: './folder-edit.component.html',
  styleUrls: ['./folder-edit.component.scss'],
})
export class FolderEditComponent {
  private dataRoomService = inject(DataRoomService);

  private dialogRef = inject(DIALOG_DATA);

  private params = this.dialogRef.data as EditParams;

  protected folder$ = this.dataRoomService.selectBaseFolder(this.params.folder);

  async onSubmit(folder: CreateFolder) {
    try {
      await this.dataRoomService.updateFolder(
        folder,
        this.params.dataRoomId,
        this.params.folder,
        this.params.parentFolderId,
      );
      this.dialogRef.dialogRef.close(true);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }
}
