import { Component, inject, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { DataRoomService } from '../../services/data-room.service';
import { BaseFile } from '../../interfaces/base-file';
import { DIALOG_DATA } from '../../../dialog/tokens/DIALOG_DATA';
import { BehaviorSubject } from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FileNameValidator } from '../../validators/filename.validator';
import { DragAndDropFieldComponent } from '../../../shared/components/drag-and-drop-field/drag-and-drop-field.component';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';

@Component({
  selector: 'app-data-rooms-file-edit',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    DragAndDropFieldComponent,
    SpinnerComponent,
  ],
  templateUrl: './file-edit.component.html',
  styleUrls: ['./file-edit.component.scss'],
  providers: [DataRoomService],
})
export class FileEditComponent implements OnInit {
  private dataRoomService = inject(DataRoomService);

  private dialogData = inject(DIALOG_DATA);

  private dialogDataContent = this.dialogData.data as {
    file: number;
    parentFolderId: number;
  };

  private fileId = this.dialogDataContent.file;

  private parentFolderId = this.dialogDataContent.parentFolderId;

  protected isUploading$ = new BehaviorSubject(false);

  protected fileUpload = new FormControl<File[] | null>(null);

  private selectedFile: File | null = null;

  ngOnInit() {
    this.dialogData.dialogRef.element.addEventListener('cancel', (event) => {
      if (this.isUploading$.getValue()) {
        event.preventDefault();
      }
    });

    this.loadFileData();

    this.fileUpload.valueChanges.subscribe((value) => this.fileChange(value));
  }

  async onSubmit(file: BaseFile) {
    this.isUploading$.next(true);

    try {
      await this.dataRoomService.updateFile(
        file,
        this.fileId,
        this.parentFolderId,
      );
      this.dialogData.dialogRef.close(true);
    } catch (error) {
      console.error('An error occurred:', error);
    }

    this.isUploading$.next(false);
  }

  private async loadFileData() {
    const file = await this.dataRoomService.loadFile(this.fileId);
    this.form.patchValue(file);
  }

  protected form = new FormGroup({
    file: new FormControl<File | null>(null),
    name: new FormControl('', {
      validators: [Validators.required, FileNameValidator()],
      nonNullable: true,
    }),
    description: new FormControl(''),
    isPublic: new FormControl(false, { nonNullable: true }),
    allowComment: new FormControl(false, { nonNullable: true }),
    effectiveFrom: new FormControl(new Date(), {
      validators: [Validators.required],
      nonNullable: true,
    }),
    effectiveTo: new FormControl<Date | null>(null),
  });

  protected onFormSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const value = this.form.getRawValue();

      this.onSubmit({
        ...value,
        file: this.selectedFile,
        description: value.description ?? undefined,
        effectiveFrom: value.effectiveFrom ?? undefined,
        effectiveTo: value.effectiveTo ?? undefined,
      });
    }
  }

  protected fileChange(fileList: File[] | null) {
    if (!fileList?.length) {
      return;
    }

    const file = fileList.length ? fileList[0] : null;
    const formValue = this.form.getRawValue();

    if (!file) {
      this.selectedFile = null;
      return;
    }

    this.selectedFile = file;

    if (!formValue.name) {
      const fileNameParts = file.name.split('.');
      fileNameParts.pop();

      this.form.get('name')?.setValue(fileNameParts.join('.'));
    }
  }

  protected closeDialog() {
    this.dialogData.dialogRef.close(false);
  }

  protected readonly File = File;
}
