import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  lastValueFrom,
  map,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import { User } from '../interfaces/user.interface';
import { Response } from '../types/interfaces/response.interface';
import { Login } from '../../authentication/components/login/interfaces/login.interface';
import { Auth } from '../../authentication/interfaces/auth.interface';
import { ForgotPassword } from '../../authentication/components/forgot-password/interfaces/forgot-password.interface';
import { Router } from '@angular/router';
import { IS_INTERNAL_STORAGE_KEY } from '../../authentication/constants/is-internal-storage-key';
import { Language } from '../../translation/interfaces/language';

const USER_STORAGE_KEY = 'user';
const SWITCHED_KEY = 'switched';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public logoutEvent = new Subject<void>();
  private apiUrl: string = environment.apiUrl;
  private http = inject(HttpClient);
  private router = inject(Router);
  authData: Auth | null = null;
  superAdminAppPart = false;

  isInternalNavbarSubject = new BehaviorSubject<boolean>(true);
  isInternalFooterSubject = new BehaviorSubject<boolean>(true);
  isInternalFooter$ = this.isInternalFooterSubject.asObservable();
  isInternalNavbar$ = this.isInternalNavbarSubject.asObservable();

  constructor() {
    if (!localStorage.getItem(SWITCHED_KEY)) {
      localStorage.setItem(SWITCHED_KEY, 'false');
    }
    this.isInternalNavbarSubject.next(this.getIsInternalPlatform());
    this.isInternalFooterSubject.next(this.getIsInternalPlatform());
  }

  login(login: Login) {
    return this.http
      .post<Response<Auth>>(`${this.apiUrl}/Auth/Login`, login)
      .pipe(
        map((response) => response.data),
        tap((data) => {
          this.authData = data;
        }),
      );
  }

  public acceptDisclaimer() {
    const storedData = this.getUserData();

    if (!storedData) {
      return;
    }

    this.saveUserData({
      ...storedData,
      hasAcceptedDisclaimer: true,
    });
  }

  verifyUserByEmail(email: string): Observable<User> {
    const url = `${this.apiUrl}/Auth/CheckEmail/${email}`;
    return this.http
      .get<Response<User>>(url)
      .pipe(map((response) => response.data));
  }

  deleteAccessToken(): void {
    localStorage.removeItem(USER_STORAGE_KEY);
  }

  logout(): void {
    this.deleteAccessToken();
    this.removeSwitchingPlatformData();
    this.logoutEvent.next();
    this.router.navigate(['/authentication/login']);
  }

  private removeSwitchingPlatformData() {
    localStorage.removeItem('switched');
    localStorage.removeItem(IS_INTERNAL_STORAGE_KEY);
  }

  hasAcceptedDisclaimer(): boolean {
    const userData = this.getUserData();
    return !!userData?.hasAcceptedDisclaimer;
  }

  hasAccessToken(): boolean {
    const userData = this.getUserData();
    if (
      !!this.getStorageData() &&
      userData?.expirationDate &&
      'expirationDate' in userData &&
      new Date() > new Date(userData.expirationDate)
    ) {
      return false;
    }
    return !!this.getStorageData();
  }

  getUserId(): number {
    const userData = this.getUserData();
    return userData ? userData.id : 0;
  }

  getIsInternalUser() {
    const userData = this.getUserData();
    return !!userData?.isInternal;
  }

  getIsInternalPlatform() {
    return localStorage.getItem(IS_INTERNAL_STORAGE_KEY) === 'true';
  }

  getIsSuperAdmin(): boolean {
    const userData = this.getUserData();
    return !!userData?.isSuperAdmin;
  }

  getAllowedSwitchPlatform(): boolean {
    const userData = this.getUserData();
    return !!userData?.allowSwitchingPlatform;
  }

  forgetPassword(email: string): Observable<Response<boolean>> {
    return this.http.post<Response<boolean>>(
      `${this.apiUrl}/Auth/ForgetPassword/${email}`,
      {},
    );
  }

  async resetForgetPassword(forgetPassword: ForgotPassword) {
    return lastValueFrom(
      this.http
        .post<Response<boolean>>(
          `${this.apiUrl}/Auth/ResetForgetPassword/`,
          forgetPassword,
        )
        .pipe(map((response) => response.succeeded)),
    );
  }

  isAuthenticated(): boolean {
    return this.hasAccessToken();
  }

  formatWithLeadingZero(number: number, length = 2) {
    return number.toString().padStart(length, '0');
  }

  getCurrentUtcDate() {
    const currentDate = new Date();

    const utcYear = currentDate.getUTCFullYear();
    const utcMonth = currentDate.getUTCMonth() + 1;
    const utcDay = currentDate.getUTCDate();
    const utcHours = currentDate.getUTCHours();
    const utcMinutes = currentDate.getUTCMinutes();
    const utcSeconds = currentDate.getUTCSeconds();
    return `${this.formatWithLeadingZero(utcYear)}-${this.formatWithLeadingZero(
      utcMonth,
    )}-${this.formatWithLeadingZero(utcDay)} ${this.formatWithLeadingZero(
      utcHours,
    )}:${this.formatWithLeadingZero(utcMinutes)}:${this.formatWithLeadingZero(
      utcSeconds,
    )}`;
  }

  getUserData(): Auth | undefined {
    const userCookie = this.getStorageData();

    return userCookie ? (JSON.parse(userCookie) as Auth) : undefined;
  }

  public setLanguage(language: Language) {
    localStorage.setItem('lang', language);
  }

  public logUserIn(authData: Auth, language: Language, isInternal: boolean) {
    this.setLanguage(language);
    this.setIsInternal(isInternal);
    this.saveUserData(authData);
  }

  private saveUserData(data: Auth) {
    const stringifyData = JSON.stringify(data);
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 10);
    this.deleteAccessToken();
    this.isInternalNavbarSubject.next(this.getIsInternalPlatform());
    localStorage.setItem(USER_STORAGE_KEY, stringifyData);
  }

  private setIsInternal(isInternal: boolean) {
    localStorage.setItem(
      IS_INTERNAL_STORAGE_KEY,
      isInternal ? 'true' : 'false',
    );
  }

  stringifyToken() {
    if (this.authData) {
      if (
        !this.authData.isSuperAdmin &&
        !this.authData.allowSwitchingPlatform
      ) {
        this.superAdminAppPart = this.authData.isInternal;
      }

      this.setIsInternal(this.superAdminAppPart);
      this.saveUserData(this.authData);
    }
    this.authData = null;
  }

  private getStorageData() {
    return localStorage.getItem(USER_STORAGE_KEY);
  }

  hasUserToken() {
    return localStorage.getItem(USER_STORAGE_KEY) !== null;
  }
}
