import {
  Component,
  forwardRef,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { FormUrl } from '../../constants/form-url';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DragAndDropDirective } from '../../directives/drag-and-drop.directive';
import { DropFiles } from '../../interfaces/drop-files';
import { IconService } from '../../../icons/services/icon.service';
import { MatIconModule } from '@angular/material/icon';
import { TranslateNewPipe } from '../../../translation/pipes/translate-new.pipe';
import { MessageAppService } from '../../services/messageapp.service';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from 'src/app/dialog/services/toast.service';

@Component({
  selector: 'app-shared-drag-and-drop-field',
  standalone: true,
  imports: [NgIf, DragAndDropDirective, MatIconModule, TranslateNewPipe],
  templateUrl: './drag-and-drop-field.component.html',
  styleUrls: ['./drag-and-drop-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragAndDropFieldComponent),
      multi: true,
    },
  ],
})
export class DragAndDropFieldComponent implements ControlValueAccessor {
  @Input()
  public multiple = false;

  @Input()
  public infotext?: string | null;

  @Input()
  public allowFolder = false;

  @Input()
  public allowedFileTypes: string[] | null = null;

  @Input()
  public mimeTypeError = 'Invalid file type.';

  @Input()
  public allowedFileSize = 0;

  @HostBinding('class.app-shared-drag-and-drop-field-disabled')
  @Input()
  public disabled = false;

  protected dragAndDropPossible = this.canDragAndDrop();

  private messageAppService = inject(MessageAppService);

  protected messageError$ = new BehaviorSubject<string>('');

  private toastr = inject(ToastService);

  protected formUrl = FormUrl.DragAndDropField;

  constructor() {
    inject(IconService).registerIcon('upload_cloud_02');
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  protected get accept() {
    return this.allowedFileTypes?.join(',');
  }

  protected async handleFiles(fileList: File[]) {
    if (this.disabled || !fileList.length) {
      return;
    }

    if (fileList.length > 1) {
      const folderAllowedSize = this.calculateFolderAllowedSize(
        fileList.length,
      );
      const totalFolderSize = this.calculateTotalFolderSize(fileList);
      if (totalFolderSize > folderAllowedSize) {
        this.handleExceedingFolderSize();
        return;
      }
    }

    if (
      fileList &&
      fileList[0].size > this.allowedFileSize &&
      fileList.length === 1
    ) {
      this.handleExceedingFolderSize();
      return;
    }

    const file = fileList[0];

    if (
      this.allowedFileTypes &&
      this.allowedFileTypes.length &&
      !this.allowedFileTypes.includes(file.type) &&
      !this.allowedFileTypes.includes(this.getFileEnding(file))
    ) {
      return alert(this.mimeTypeError);
    }

    this.writeValue(Array.from(fileList));
  }

  private getFileEnding(file: File) {
    const fileEnding = file.name.split('.').pop();

    return `.${fileEnding}`;
  }

  protected fileChange(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input?.files) {
      this.handleFiles(Array.from(input.files));
    }

    input.value = '';
  }

  protected dropFiles(dropFiles: DropFiles) {
    if (this.allowFolder && !dropFiles.isDirectory) {
      alert('You have to upload a folder.');
      return;
    }

    if (!this.multiple && dropFiles.files.length > 1) {
      alert('Please drop only one file.');
      return;
    }

    this.handleFiles(dropFiles.files);
  }

  calculateFolderAllowedSize(fileCount: number): number {
    return this.allowedFileSize * fileCount;
  }

  calculateTotalFolderSize(fileList: File[]): number {
    return fileList.reduce((total, file) => total + file.size, 0);
  }

  handleExceedingFolderSize(): void {
    this.messageAppService.getMessageById(50).subscribe((res) => {
      this.messageError$.next(res.en);
      this.toastr.error(this.messageError$.getValue(), 'Error');
    });
  }

  private _value: File[] | null = null;

  public writeValue(value: File[] | null) {
    this._value = value;

    this._onChange(value);
    this._onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _onChange = (value: File[] | null) => undefined;

  public registerOnChange(fn: (value: File[] | null) => undefined): void {
    this._onChange = fn;
  }

  private _onTouched = () => undefined;

  public registerOnTouched(fn: () => undefined): void {
    this._onTouched = fn;
  }

  private canDragAndDrop() {
    const div = document.createElement('div');

    return (
      ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
      'FormData' in window &&
      'FileReader' in window
    );
  }
}
