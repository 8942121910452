import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  inject,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DialogComponent } from '../components/dialog/dialog.component';
import { DialogConfig } from '../interfaces/dialog-config';
import { DIALOG_DATA } from '../tokens/DIALOG_DATA';
import { DialogRef } from './dialog-ref';
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { DialogColor } from '../interfaces/dialog-color';
import { ConfirmData } from '../interfaces/confirm-data';
import { IconService } from '../../icons/services/icon.service';
import { Observable } from 'rxjs';
import { DialogSettings } from '../interfaces/dialog-settings';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private appRef = inject(ApplicationRef);

  private document = inject(DOCUMENT);

  private dialogComponentRef?: ComponentRef<DialogComponent>;

  private iconService = inject(IconService);

  public deleteConfirm(
    headline: string | Observable<string>,
    content: string | Observable<string>,
    successTitle = 'Delete',
    cancelButton = true,
    width?: string,
  ) {
    this.iconService.registerIcon('trash_03');

    return this.confirm(
      headline,
      content,
      'trash_03',
      successTitle,
      true,
      'warn',
      cancelButton,
      width,
    );
  }

  public confirm(
    headline: string | Observable<string>,
    content: string | Observable<string>,
    icon?: string,
    successTitle = 'Ok',
    highlightIcon?: boolean,
    color?: DialogColor,
    cancelButton?: boolean,
    width?: string,
  ) {
    return this.open(ConfirmComponent, {
      headline,
      icon,
      highlightIcon,
      iconColor: color,
      width: width,
      data: { color, content, successTitle, cancelButton } as ConfirmData,
    });
  }

  public open<T = unknown>(
    componentType: Type<unknown>,
    config?: DialogConfig<T>,
  ) {
    const dialogRef = this.appendDialogToBody<T | unknown>(config?.data);
    if (!this.dialogComponentRef) {
      throw new Error('Creation of dialog failed');
    }

    this.dialogComponentRef.instance.childComponentType = componentType;

    this.updateDialogSettings(config);

    this.dialogComponentRef.instance.open();
    return dialogRef;
  }

  private updateDialogSettings(config?: Partial<DialogSettings>) {
    if (!config) {
      return;
    }

    const inputs: (keyof DialogSettings)[] = [
      'headline',
      'subHeadline',
      'icon',
      'highlightIcon',
      'backgroundImage',
      'iconColor',
      'backgroundColor',
      'width',
      'showCloseIcon',
      'closeIconBackground',
    ];

    inputs.forEach((inputName) => {
      if (config[inputName] !== undefined) {
        if (!this.dialogComponentRef) {
          throw new Error('Dialog not loaded');
        }

        this.dialogComponentRef.setInput(inputName, config[inputName]);
      }
    });
  }

  private appendDialogToBody<T = unknown>(config: T) {
    const dialogRef = new DialogRef();

    const sub = dialogRef.afterClosed.subscribe(() => {
      this.removeDialogFromBody();
      sub.unsubscribe();
    });

    const injector = Injector.create({
      providers: [
        {
          provide: DIALOG_DATA,
          useValue: {
            dialogRef,
            updateDialogSettings: (settings: DialogSettings) =>
              this.updateDialogSettings(settings),
            data: config,
          },
        },
      ],
    });

    const dialog = createComponent(DialogComponent, {
      environmentInjector: this.appRef.injector,
      elementInjector: injector,
    });

    dialogRef.element = dialog.instance.dialog.nativeElement;

    this.document.body.appendChild(dialog.location.nativeElement);
    this.appRef.attachView(dialog.hostView);

    this.dialogComponentRef = dialog;

    return dialogRef;
  }

  private removeDialogFromBody() {
    if (!this.dialogComponentRef) {
      return;
    }

    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}
