import { Component, forwardRef, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, Observable, startWith } from 'rxjs';
import { AutocompleteOption } from '../../interfaces/autocomplete-option';
import { IconService } from '../../../icons/services/icon.service';

@Component({
  selector: 'app-shared-autocomplete-multiple',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
  ],
  templateUrl: './autocomplete-multiple.component.html',
  styleUrls: ['./autocomplete-multiple.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteMultipleComponent),
      multi: true,
    },
  ],
})
export class AutocompleteMultipleComponent implements ControlValueAccessor {
  @Input({ required: true })
  set options(options) {
    this._options = options;
    this.writeValue(this._value);
    this.inputControl.setValue('');
  }
  get options() {
    return this._options;
  }
  private _options!: AutocompleteOption[];

  @Input() allowToAdd = false;

  protected separatorKeyCodes: number[] = [ENTER, COMMA];

  protected inputControl = new FormControl<string | unknown>('');

  protected selectedOptions: AutocompleteOption[] = [];

  protected selectableOptions$!: Observable<AutocompleteOption[]>;

  protected isDisabled = false;

  private _value: unknown[] | null = null;

  constructor() {
    inject(IconService).registerIcon('x_close');

    this.selectableOptions$ = this.inputControl.valueChanges.pipe(
      startWith(''),
      map((search) =>
        typeof search === 'string'
          ? this.filterOptions(search)
          : this.filterOptions(),
      ),
    );
  }

  protected add(event: MatChipInputEvent) {
    const search = (event.value || '').trim();

    if (search) {
      const option = this.options.find((option) =>
        this.isSelectable(option, search),
      );

      if (this.allowToAdd) {
        this.addSelectedOptions({
          key: search,
          text: search,
        });
      }

      if (option) {
        this.addSelectedOptions(option);
      }
    }

    event.chipInput.clear();
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  protected select(event: MatAutocompleteSelectedEvent) {
    const key = event.option.value;
    const foundOption = this.options.find((g) => g.key === key);

    if (!foundOption) {
      return;
    }

    this.addSelectedOptions(foundOption);
  }

  private filterOptions(search?: string) {
    return this.options.filter((option) => this.isSelectable(option, search));
  }

  protected remove(key: unknown) {
    this.selectedOptions = this.selectedOptions.filter(
      (group) => group.key !== key,
    );

    this.writeValue(this.selectedOptions.map((option) => option.key));
    this.inputControl.setValue(null);
  }

  private addSelectedOptions(option: AutocompleteOption, writeValue = true) {
    this.selectedOptions.push(option);
    this.inputControl.setValue(null);

    if (writeValue) {
      this.writeValue(this.selectedOptions.map((option) => option.key));
    }
  }

  writeValue(value: unknown[] | null) {
    if (value && value.length !== this.selectedOptions.length) {
      const newValues = value.filter(
        (key) => !this.selectedOptions.find((option) => option.key === key),
      );

      newValues.forEach((key) => {
        const optionToAdd = this.options.find((option) => option.key === key);

        if (!optionToAdd) {
          return;
        }

        this.addSelectedOptions(optionToAdd, false);
      });
    }
    this._value = value;

    this._onChange(value);
    this._onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _onChange = (value: unknown[] | null) => undefined;

  public registerOnChange(fn: (value: unknown[] | null) => undefined) {
    this._onChange = fn;
  }

  private _onTouched = () => undefined;

  public registerOnTouched(fn: () => undefined): void {
    this._onTouched = fn;
  }

  private isSelectable(option: AutocompleteOption, search?: string) {
    const searchTerm = search?.toLowerCase() || '';

    return (
      (!search || option.text.toLowerCase().includes(searchTerm)) &&
      !this.selectedOptions.find(
        (selectedOption) => selectedOption.key === option.key,
      )
    );
  }
}
