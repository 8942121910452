import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomCdkOverlayContainer extends OverlayContainer {
  override getContainerElement() {
    if (this.doesDialogExist()) {
      return this.getDialogContainerElement();
    }

    return super.getContainerElement();
  }

  private getDialogContainerElement() {
    const containerClass = 'cdk-overlay-container';

    const dialog = this.selectDialog()[0];
    const containerElement = dialog.getElementsByClassName(containerClass);

    if (containerElement.length) {
      return containerElement[0] as HTMLElement;
    }

    const container = document.createElement('div');
    container.classList.add(containerClass);

    dialog.appendChild(container);

    return container;
  }

  private doesDialogExist() {
    return !!this.selectDialog().length;
  }

  private selectDialog() {
    return document.body.querySelectorAll('app-dialog > dialog[open]');
  }
}
