import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, lastValueFrom, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from '../types/interfaces/response.interface';
import { MessageApp } from '../interfaces/message-app.interface';
import { MessageAppCreate } from '../interfaces/message-app-create.interface';

@Injectable({
  providedIn: 'root',
})
export class MessageAppService {
  private apiUrl: string = environment.apiUrl;
  private http = inject(HttpClient);

  getAll(): Observable<MessageApp[]> {
    return this.http
      .get<Response<MessageApp[]>>(`${this.apiUrl}/MessageApp/GetAll`)
      .pipe(map((response) => response.data));
  }

  getMessageById(id: number) {
    return this.http
      .get<Response<MessageApp>>(`${this.apiUrl}/MessageApp/GetById/${id}`)
      .pipe(map((response) => response.data));
  }

  async post(messageApp: MessageAppCreate) {
    return await lastValueFrom(
      this.http
        .post<Response<MessageApp>>(
          `${this.apiUrl}/MessageApp/Post`,
          messageApp,
        )
        .pipe(map((response) => response.succeeded)),
    );
  }

  async put(data: MessageApp) {
    return await lastValueFrom(
      this.http
        .put<Response<MessageApp>>(`${this.apiUrl}/MessageApp/${data.id}`, data)
        .pipe(map((response) => response.succeeded)),
    );
  }

  async delete(id: number) {
    const options = {
      params: { id },
    };
    return lastValueFrom(
      this.http
        .delete<Response<boolean>>(`${this.apiUrl}/MessageApp`, options)
        .pipe(map((response) => response.data)),
    );
  }
}
