/* 🤖 this file was generated by svg-to-ts */

import { EmpiraIconInterface } from './build/empira-icon';

export type EmpiraIconInterfaceNameSubset<
  T extends Readonly<EmpiraIconInterface[]>,
> = T[number]['name'];

export * from './build/empira-activity-heart.icon';
export * from './build/empira-activity.icon';
export * from './build/empira-airplay.icon';
export * from './build/empira-airpods.icon';
export * from './build/empira-alarm-clock-check.icon';
export * from './build/empira-alarm-clock-minus.icon';
export * from './build/empira-alarm-clock-off.icon';
export * from './build/empira-alarm-clock-plus.icon';
export * from './build/empira-alarm-clock.icon';
export * from './build/empira-alert-circle.icon';
export * from './build/empira-alert-hexagon.icon';
export * from './build/empira-alert-octagon.icon';
export * from './build/empira-alert-square.icon';
export * from './build/empira-alert-triangle.icon';
export * from './build/empira-align-bottom-01.icon';
export * from './build/empira-align-bottom-02.icon';
export * from './build/empira-align-center.icon';
export * from './build/empira-align-horizontal-centre-01.icon';
export * from './build/empira-align-horizontal-centre-02.icon';
export * from './build/empira-align-justify.icon';
export * from './build/empira-align-left-01.icon';
export * from './build/empira-align-left-02.icon';
export * from './build/empira-align-left.icon';
export * from './build/empira-align-right-01.icon';
export * from './build/empira-align-right-02.icon';
export * from './build/empira-align-right.icon';
export * from './build/empira-align-top-arrow-01.icon';
export * from './build/empira-align-top-arrow-02.icon';
export * from './build/empira-align-vertical-center-01.icon';
export * from './build/empira-align-vertical-center-02.icon';
export * from './build/empira-anchor.icon';
export * from './build/empira-annotation-alert.icon';
export * from './build/empira-annotation-check.icon';
export * from './build/empira-annotation-dots.icon';
export * from './build/empira-annotation-heart.icon';
export * from './build/empira-annotation-info.icon';
export * from './build/empira-annotation-plus.icon';
export * from './build/empira-annotation-question.icon';
export * from './build/empira-annotation-x.icon';
export * from './build/empira-annotation.icon';
export * from './build/empira-announcement-01.icon';
export * from './build/empira-announcement-02.icon';
export * from './build/empira-announcement-03.icon';
export * from './build/empira-archive.icon';
export * from './build/empira-arrow-block-down.icon';
export * from './build/empira-arrow-block-left.icon';
export * from './build/empira-arrow-block-right.icon';
export * from './build/empira-arrow-block-up.icon';
export * from './build/empira-arrow-circle-broken-down-left.icon';
export * from './build/empira-arrow-circle-broken-down-right.icon';
export * from './build/empira-arrow-circle-broken-down.icon';
export * from './build/empira-arrow-circle-broken-left.icon';
export * from './build/empira-arrow-circle-broken-right.icon';
export * from './build/empira-arrow-circle-broken-up-left.icon';
export * from './build/empira-arrow-circle-broken-up-right.icon';
export * from './build/empira-arrow-circle-broken-up.icon';
export * from './build/empira-arrow-circle-down-left.icon';
export * from './build/empira-arrow-circle-down-right.icon';
export * from './build/empira-arrow-circle-down.icon';
export * from './build/empira-arrow-circle-left.icon';
export * from './build/empira-arrow-circle-right.icon';
export * from './build/empira-arrow-circle-up-left.icon';
export * from './build/empira-arrow-circle-up-right.icon';
export * from './build/empira-arrow-circle-up.icon';
export * from './build/empira-arrow-down-left.icon';
export * from './build/empira-arrow-down-right.icon';
export * from './build/empira-arrow-down.icon';
export * from './build/empira-arrow-left.icon';
export * from './build/empira-arrow-narrow-down-left.icon';
export * from './build/empira-arrow-narrow-down-right.icon';
export * from './build/empira-arrow-narrow-down.icon';
export * from './build/empira-arrow-narrow-left.icon';
export * from './build/empira-arrow-narrow-right.icon';
export * from './build/empira-arrow-narrow-up-left.icon';
export * from './build/empira-arrow-narrow-up-right.icon';
export * from './build/empira-arrow-narrow-up.icon';
export * from './build/empira-arrow-right.icon';
export * from './build/empira-arrow-square-down-left.icon';
export * from './build/empira-arrow-square-down-right.icon';
export * from './build/empira-arrow-square-down.icon';
export * from './build/empira-arrow-square-left.icon';
export * from './build/empira-arrow-square-right.icon';
export * from './build/empira-arrow-square-up-left.icon';
export * from './build/empira-arrow-square-up-right.icon';
export * from './build/empira-arrow-square-up.icon';
export * from './build/empira-arrow-up-left.icon';
export * from './build/empira-arrow-up-right.icon';
export * from './build/empira-arrow-up.icon';
export * from './build/empira-arrows-down.icon';
export * from './build/empira-arrows-left.icon';
export * from './build/empira-arrows-right.icon';
export * from './build/empira-arrows-triangle.icon';
export * from './build/empira-arrows-up.icon';
export * from './build/empira-asterisk-01.icon';
export * from './build/empira-asterisk-02.icon';
export * from './build/empira-at-sign.icon';
export * from './build/empira-atom-01.icon';
export * from './build/empira-atom-02.icon';
export * from './build/empira-attachment-01.icon';
export * from './build/empira-attachment-02.icon';
export * from './build/empira-award-01.icon';
export * from './build/empira-award-02.icon';
export * from './build/empira-award-03.icon';
export * from './build/empira-award-04.icon';
export * from './build/empira-award-05.icon';
export * from './build/empira-backpack.icon';
export * from './build/empira-bank-note-01.icon';
export * from './build/empira-bank-note-02.icon';
export * from './build/empira-bank-note-03.icon';
export * from './build/empira-bank.icon';
export * from './build/empira-bar-chart-01.icon';
export * from './build/empira-bar-chart-02.icon';
export * from './build/empira-bar-chart-03.icon';
export * from './build/empira-bar-chart-04.icon';
export * from './build/empira-bar-chart-05.icon';
export * from './build/empira-bar-chart-06.icon';
export * from './build/empira-bar-chart-07.icon';
export * from './build/empira-bar-chart-08.icon';
export * from './build/empira-bar-chart-09.icon';
export * from './build/empira-bar-chart-10.icon';
export * from './build/empira-bar-chart-11.icon';
export * from './build/empira-bar-chart-12.icon';
export * from './build/empira-bar-chart-circle-01.icon';
export * from './build/empira-bar-chart-circle-02.icon';
export * from './build/empira-bar-chart-circle-03.icon';
export * from './build/empira-bar-chart-square-01.icon';
export * from './build/empira-bar-chart-square-02.icon';
export * from './build/empira-bar-chart-square-03.icon';
export * from './build/empira-bar-chart-square-down.icon';
export * from './build/empira-bar-chart-square-minus.icon';
export * from './build/empira-bar-chart-square-plus.icon';
export * from './build/empira-bar-chart-square-up.icon';
export * from './build/empira-bar-line-chart.icon';
export * from './build/empira-battery-charging-01.icon';
export * from './build/empira-battery-charging-02.icon';
export * from './build/empira-battery-empty.icon';
export * from './build/empira-battery-full.icon';
export * from './build/empira-battery-low.icon';
export * from './build/empira-battery-mid.icon';
export * from './build/empira-beaker-01.icon';
export * from './build/empira-beaker-02.icon';
export * from './build/empira-bell-01.icon';
export * from './build/empira-bell-02.icon';
export * from './build/empira-bell-03.icon';
export * from './build/empira-bell-04.icon';
export * from './build/empira-bell-minus.icon';
export * from './build/empira-bell-off-01.icon';
export * from './build/empira-bell-off-02.icon';
export * from './build/empira-bell-off-03.icon';
export * from './build/empira-bell-plus.icon';
export * from './build/empira-bell-ringing-01.icon';
export * from './build/empira-bell-ringing-02.icon';
export * from './build/empira-bell-ringing-03.icon';
export * from './build/empira-bell-ringing-04.icon';
export * from './build/empira-bezier-curve-01.icon';
export * from './build/empira-bezier-curve-02.icon';
export * from './build/empira-bezier-curve-03.icon';
export * from './build/empira-bluetooth-connect.icon';
export * from './build/empira-bluetooth-off.icon';
export * from './build/empira-bluetooth-on.icon';
export * from './build/empira-bluetooth-signal.icon';
export * from './build/empira-bold-01.icon';
export * from './build/empira-bold-02.icon';
export * from './build/empira-bold-square.icon';
export * from './build/empira-book-closed.icon';
export * from './build/empira-book-open-01.icon';
export * from './build/empira-book-open-02.icon';
export * from './build/empira-bookmark-add.icon';
export * from './build/empira-bookmark-check.icon';
export * from './build/empira-bookmark-minus.icon';
export * from './build/empira-bookmark-x.icon';
export * from './build/empira-bookmark.icon';
export * from './build/empira-box.icon';
export * from './build/empira-brackets-check.icon';
export * from './build/empira-brackets-ellipses.icon';
export * from './build/empira-brackets-minus.icon';
export * from './build/empira-brackets-plus.icon';
export * from './build/empira-brackets-slash.icon';
export * from './build/empira-brackets-x.icon';
export * from './build/empira-brackets.icon';
export * from './build/empira-briefcase-01.icon';
export * from './build/empira-briefcase-02.icon';
export * from './build/empira-browser.icon';
export * from './build/empira-brush-01.icon';
export * from './build/empira-brush-02.icon';
export * from './build/empira-brush-03.icon';
export * from './build/empira-building-01.icon';
export * from './build/empira-building-02.icon';
export * from './build/empira-building-03.icon';
export * from './build/empira-building-04.icon';
export * from './build/empira-building-05.icon';
export * from './build/empira-building-06.icon';
export * from './build/empira-building-07.icon';
export * from './build/empira-building-08.icon';
export * from './build/empira-bus.icon';
export * from './build/empira-calculator.icon';
export * from './build/empira-calendar-check-01.icon';
export * from './build/empira-calendar-check-02.icon';
export * from './build/empira-calendar-date.icon';
export * from './build/empira-calendar-heart-01.icon';
export * from './build/empira-calendar-heart-02.icon';
export * from './build/empira-calendar-minus-01.icon';
export * from './build/empira-calendar-minus-02.icon';
export * from './build/empira-calendar-plus-01.icon';
export * from './build/empira-calendar-plus-02.icon';
export * from './build/empira-calendar.icon';
export * from './build/empira-camera-01.icon';
export * from './build/empira-camera-02.icon';
export * from './build/empira-camera-03.icon';
export * from './build/empira-camera-lens.icon';
export * from './build/empira-camera-off.icon';
export * from './build/empira-camera-plus.icon';
export * from './build/empira-car-01.icon';
export * from './build/empira-car-02.icon';
export * from './build/empira-certificate-01.icon';
export * from './build/empira-certificate-02.icon';
export * from './build/empira-chart-breakout-circle.icon';
export * from './build/empira-chart-breakout-square.icon';
export * from './build/empira-check-circle-broken.icon';
export * from './build/empira-check-circle.icon';
export * from './build/empira-check-done-01.icon';
export * from './build/empira-check-done-02.icon';
export * from './build/empira-check-heart.icon';
export * from './build/empira-check-square-broken.icon';
export * from './build/empira-check-square.icon';
export * from './build/empira-check-verified-01.icon';
export * from './build/empira-check-verified-02.icon';
export * from './build/empira-check-verified-03.icon';
export * from './build/empira-check.icon';
export * from './build/empira-chevron-down-double.icon';
export * from './build/empira-chevron-down.icon';
export * from './build/empira-chevron-left-double.icon';
export * from './build/empira-chevron-left.icon';
export * from './build/empira-chevron-right-double.icon';
export * from './build/empira-chevron-right.icon';
export * from './build/empira-chevron-selector-horizontal.icon';
export * from './build/empira-chevron-selector-vertical.icon';
export * from './build/empira-chevron-up-double.icon';
export * from './build/empira-chevron-up.icon';
export * from './build/empira-chrome-cast.icon';
export * from './build/empira-circle-cut.icon';
export * from './build/empira-circle.icon';
export * from './build/empira-clapperboard.icon';
export * from './build/empira-clipboard-attachment.icon';
export * from './build/empira-clipboard-check.icon';
export * from './build/empira-clipboard-download.icon';
export * from './build/empira-clipboard-minus.icon';
export * from './build/empira-clipboard-plus.icon';
export * from './build/empira-clipboard-x.icon';
export * from './build/empira-clipboard.icon';
export * from './build/empira-clock-check.icon';
export * from './build/empira-clock-fast-forward.icon';
export * from './build/empira-clock-plus.icon';
export * from './build/empira-clock-refresh.icon';
export * from './build/empira-clock-rewind.icon';
export * from './build/empira-clock-snooze.icon';
export * from './build/empira-clock-stopwatch.icon';
export * from './build/empira-clock.icon';
export * from './build/empira-cloud-01.icon';
export * from './build/empira-cloud-02.icon';
export * from './build/empira-cloud-03.icon';
export * from './build/empira-cloud-blank-01.icon';
export * from './build/empira-cloud-blank-02.icon';
export * from './build/empira-cloud-lightning.icon';
export * from './build/empira-cloud-moon.icon';
export * from './build/empira-cloud-off.icon';
export * from './build/empira-cloud-raining-01.icon';
export * from './build/empira-cloud-raining-02.icon';
export * from './build/empira-cloud-raining-03.icon';
export * from './build/empira-cloud-raining-04.icon';
export * from './build/empira-cloud-raining-05.icon';
export * from './build/empira-cloud-raining-06.icon';
export * from './build/empira-cloud-snowing-01.icon';
export * from './build/empira-cloud-snowing-02.icon';
export * from './build/empira-cloud-sun-01.icon';
export * from './build/empira-cloud-sun-02.icon';
export * from './build/empira-cloud-sun-03.icon';
export * from './build/empira-code-01.icon';
export * from './build/empira-code-02.icon';
export * from './build/empira-code-browser.icon';
export * from './build/empira-code-circle-01.icon';
export * from './build/empira-code-circle-02.icon';
export * from './build/empira-code-circle-03.icon';
export * from './build/empira-code-snippet-01.icon';
export * from './build/empira-code-snippet-02.icon';
export * from './build/empira-code-square-01.icon';
export * from './build/empira-code-square-02.icon';
export * from './build/empira-codepen.icon';
export * from './build/empira-coins-01.icon';
export * from './build/empira-coins-02.icon';
export * from './build/empira-coins-03.icon';
export * from './build/empira-coins-04.icon';
export * from './build/empira-coins-hand.icon';
export * from './build/empira-coins-stacked-01.icon';
export * from './build/empira-coins-stacked-02.icon';
export * from './build/empira-coins-stacked-03.icon';
export * from './build/empira-coins-stacked-04.icon';
export * from './build/empira-coins-swap-01.icon';
export * from './build/empira-coins-swap-02.icon';
export * from './build/empira-colors-1.icon';
export * from './build/empira-colors.icon';
export * from './build/empira-columns-01.icon';
export * from './build/empira-columns-02.icon';
export * from './build/empira-columns-03.icon';
export * from './build/empira-command.icon';
export * from './build/empira-compass-01.icon';
export * from './build/empira-compass-02.icon';
export * from './build/empira-compass-03.icon';
export * from './build/empira-compass.icon';
export * from './build/empira-container.icon';
export * from './build/empira-contrast-01.icon';
export * from './build/empira-contrast-02.icon';
export * from './build/empira-contrast-03.icon';
export * from './build/empira-copy-01.icon';
export * from './build/empira-copy-02.icon';
export * from './build/empira-copy-03.icon';
export * from './build/empira-copy-04.icon';
export * from './build/empira-copy-05.icon';
export * from './build/empira-copy-06.icon';
export * from './build/empira-copy-07.icon';
export * from './build/empira-corner-down-left.icon';
export * from './build/empira-corner-down-right.icon';
export * from './build/empira-corner-left-down.icon';
export * from './build/empira-corner-left-up.icon';
export * from './build/empira-corner-right-down.icon';
export * from './build/empira-corner-right-up.icon';
export * from './build/empira-corner-up-left.icon';
export * from './build/empira-corner-up-right.icon';
export * from './build/empira-cpu-chip-01.icon';
export * from './build/empira-cpu-chip-02.icon';
export * from './build/empira-credit-card-01.icon';
export * from './build/empira-credit-card-02.icon';
export * from './build/empira-credit-card-check.icon';
export * from './build/empira-credit-card-down.icon';
export * from './build/empira-credit-card-download.icon';
export * from './build/empira-credit-card-edit.icon';
export * from './build/empira-credit-card-lock.icon';
export * from './build/empira-credit-card-minus.icon';
export * from './build/empira-credit-card-plus.icon';
export * from './build/empira-credit-card-refresh.icon';
export * from './build/empira-credit-card-search.icon';
export * from './build/empira-credit-card-shield.icon';
export * from './build/empira-credit-card-up.icon';
export * from './build/empira-credit-card-upload.icon';
export * from './build/empira-credit-card-x.icon';
export * from './build/empira-crop-01.icon';
export * from './build/empira-crop-02.icon';
export * from './build/empira-cryptocurrency-01.icon';
export * from './build/empira-cryptocurrency-02.icon';
export * from './build/empira-cryptocurrency-03.icon';
export * from './build/empira-cryptocurrency-04.icon';
export * from './build/empira-cube-01.icon';
export * from './build/empira-cube-02.icon';
export * from './build/empira-cube-03.icon';
export * from './build/empira-cube-04.icon';
export * from './build/empira-cube-outline.icon';
export * from './build/empira-currency-bitcoin-circle.icon';
export * from './build/empira-currency-bitcoin.icon';
export * from './build/empira-currency-dollar-circle.icon';
export * from './build/empira-currency-dollar.icon';
export * from './build/empira-currency-ethereum-circle.icon';
export * from './build/empira-currency-ethereum.icon';
export * from './build/empira-currency-euro-circle.icon';
export * from './build/empira-currency-euro.icon';
export * from './build/empira-currency-pound-circle.icon';
export * from './build/empira-currency-pound.icon';
export * from './build/empira-currency-ruble-circle.icon';
export * from './build/empira-currency-ruble.icon';
export * from './build/empira-currency-rupee-circle.icon';
export * from './build/empira-currency-rupee.icon';
export * from './build/empira-currency-yen-circle.icon';
export * from './build/empira-currency-yen.icon';
export * from './build/empira-cursor-01.icon';
export * from './build/empira-cursor-02.icon';
export * from './build/empira-cursor-03.icon';
export * from './build/empira-cursor-04.icon';
export * from './build/empira-cursor-box.icon';
export * from './build/empira-cursor-click-01.icon';
export * from './build/empira-cursor-click-02.icon';
export * from './build/empira-data.icon';
export * from './build/empira-database-01.icon';
export * from './build/empira-database-02.icon';
export * from './build/empira-database-03.icon';
export * from './build/empira-dataflow-01.icon';
export * from './build/empira-dataflow-02.icon';
export * from './build/empira-dataflow-03.icon';
export * from './build/empira-dataflow-04.icon';
export * from './build/empira-delete.icon';
export * from './build/empira-diamond-01.icon';
export * from './build/empira-diamond-02.icon';
export * from './build/empira-dice-1.icon';
export * from './build/empira-dice-2.icon';
export * from './build/empira-dice-3.icon';
export * from './build/empira-dice-4.icon';
export * from './build/empira-dice-5.icon';
export * from './build/empira-dice-6.icon';
export * from './build/empira-disc-01.icon';
export * from './build/empira-disc-02.icon';
export * from './build/empira-distribute-spacing-horizontal.icon';
export * from './build/empira-distribute-spacing-vertical.icon';
export * from './build/empira-divide-01.icon';
export * from './build/empira-divide-02.icon';
export * from './build/empira-divide-03.icon';
export * from './build/empira-divider.icon';
export * from './build/empira-dotpoints-01.icon';
export * from './build/empira-dotpoints-02.icon';
export * from './build/empira-dots-grid.icon';
export * from './build/empira-dots-horizontal.icon';
export * from './build/empira-dots-vertical.icon';
export * from './build/empira-download-01.icon';
export * from './build/empira-download-02.icon';
export * from './build/empira-download-03.icon';
export * from './build/empira-download-04.icon';
export * from './build/empira-download-cloud-01.icon';
export * from './build/empira-download-cloud-02.icon';
export * from './build/empira-drop.icon';
export * from './build/empira-droplets-01.icon';
export * from './build/empira-droplets-02.icon';
export * from './build/empira-droplets-03.icon';
export * from './build/empira-dropper.icon';
export * from './build/empira-earth.icon';
export * from './build/empira-edit-01.icon';
export * from './build/empira-edit-02.icon';
export * from './build/empira-edit-03.icon';
export * from './build/empira-edit-04.icon';
export * from './build/empira-edit-05.icon';
export * from './build/empira-equal-not.icon';
export * from './build/empira-equal.icon';
export * from './build/empira-eraser.icon';
export * from './build/empira-expand-01.icon';
export * from './build/empira-expand-02.icon';
export * from './build/empira-expand-03.icon';
export * from './build/empira-expand-04.icon';
export * from './build/empira-expand-05.icon';
export * from './build/empira-expand-06.icon';
export * from './build/empira-eye-off.icon';
export * from './build/empira-eye.icon';
export * from './build/empira-face-content.icon';
export * from './build/empira-face-frown.icon';
export * from './build/empira-face-happy.icon';
export * from './build/empira-face-id-square.icon';
export * from './build/empira-face-id.icon';
export * from './build/empira-face-neutral.icon';
export * from './build/empira-face-sad.icon';
export * from './build/empira-face-smile.icon';
export * from './build/empira-face-wink.icon';
export * from './build/empira-fast-backward.icon';
export * from './build/empira-fast-forward.icon';
export * from './build/empira-feather.icon';
export * from './build/empira-figma.icon';
export * from './build/empira-file-01.icon';
export * from './build/empira-file-02.icon';
export * from './build/empira-file-03.icon';
export * from './build/empira-file-04.icon';
export * from './build/empira-file-05.icon';
export * from './build/empira-file-06.icon';
export * from './build/empira-file-07.icon';
export * from './build/empira-file-attachment-01.icon';
export * from './build/empira-file-attachment-02.icon';
export * from './build/empira-file-attachment-03.icon';
export * from './build/empira-file-attachment-04.icon';
export * from './build/empira-file-attachment-05.icon';
export * from './build/empira-file-check-01.icon';
export * from './build/empira-file-check-02.icon';
export * from './build/empira-file-check-03.icon';
export * from './build/empira-file-code-01.icon';
export * from './build/empira-file-code-02.icon';
export * from './build/empira-file-download-01.icon';
export * from './build/empira-file-download-02.icon';
export * from './build/empira-file-download-03.icon';
export * from './build/empira-file-heart-01.icon';
export * from './build/empira-file-heart-02.icon';
export * from './build/empira-file-heart-03.icon';
export * from './build/empira-file-lock-01.icon';
export * from './build/empira-file-lock-02.icon';
export * from './build/empira-file-lock-03.icon';
export * from './build/empira-file-minus-01.icon';
export * from './build/empira-file-minus-02.icon';
export * from './build/empira-file-minus-03.icon';
export * from './build/empira-file-plus-01.icon';
export * from './build/empira-file-plus-02.icon';
export * from './build/empira-file-plus-03.icon';
export * from './build/empira-file-question-01.icon';
export * from './build/empira-file-question-02.icon';
export * from './build/empira-file-question-03.icon';
export * from './build/empira-file-search-01.icon';
export * from './build/empira-file-search-02.icon';
export * from './build/empira-file-search-03.icon';
export * from './build/empira-file-shield-01.icon';
export * from './build/empira-file-shield-02.icon';
export * from './build/empira-file-shield-03.icon';
export * from './build/empira-file-type-audio.icon';
export * from './build/empira-file-type-avi.icon';
export * from './build/empira-file-type-code.icon';
export * from './build/empira-file-type-csv.icon';
export * from './build/empira-file-type-doc.icon';
export * from './build/empira-file-type-document.icon';
export * from './build/empira-file-type-docx.icon';
export * from './build/empira-file-type-empty.icon';
export * from './build/empira-file-type-eps.icon';
export * from './build/empira-file-type-gif.icon';
export * from './build/empira-file-type-image.icon';
export * from './build/empira-file-type-img.icon';
export * from './build/empira-file-type-jpeg.icon';
export * from './build/empira-file-type-jpg.icon';
export * from './build/empira-file-type-mkv.icon';
export * from './build/empira-file-type-mp3.icon';
export * from './build/empira-file-type-mp4.icon';
export * from './build/empira-file-type-mpeg.icon';
export * from './build/empira-file-type-pdf.icon';
export * from './build/empira-file-type-png.icon';
export * from './build/empira-file-type-ppt.icon';
export * from './build/empira-file-type-pptx.icon';
export * from './build/empira-file-type-rar.icon';
export * from './build/empira-file-type-spreadsheet.icon';
export * from './build/empira-file-type-svg.icon';
export * from './build/empira-file-type-tiff.icon';
export * from './build/empira-file-type-txt.icon';
export * from './build/empira-file-type-video.icon';
export * from './build/empira-file-type-wav.icon';
export * from './build/empira-file-type-webp.icon';
export * from './build/empira-file-type-xls.icon';
export * from './build/empira-file-type-xlsx.icon';
export * from './build/empira-file-type-zip.icon';
export * from './build/empira-file-x-01.icon';
export * from './build/empira-file-x-02.icon';
export * from './build/empira-file-x-03.icon';
export * from './build/empira-film-01.icon';
export * from './build/empira-film-02.icon';
export * from './build/empira-film-03.icon';
export * from './build/empira-filter-funnel-01.icon';
export * from './build/empira-filter-funnel-02.icon';
export * from './build/empira-filter-lines.icon';
export * from './build/empira-fingerprint-01.icon';
export * from './build/empira-fingerprint-02.icon';
export * from './build/empira-fingerprint-03.icon';
export * from './build/empira-fingerprint-04.icon';
export * from './build/empira-flag-01.icon';
export * from './build/empira-flag-02.icon';
export * from './build/empira-flag-03.icon';
export * from './build/empira-flag-04.icon';
export * from './build/empira-flag-05.icon';
export * from './build/empira-flag-06.icon';
export * from './build/empira-flash-off.icon';
export * from './build/empira-flash.icon';
export * from './build/empira-flex-align-bottom.icon';
export * from './build/empira-flex-align-left.icon';
export * from './build/empira-flex-align-right.icon';
export * from './build/empira-flex-align-top.icon';
export * from './build/empira-flip-backward.icon';
export * from './build/empira-flip-forward.icon';
export * from './build/empira-folder-check.icon';
export * from './build/empira-folder-closed.icon';
export * from './build/empira-folder-code.icon';
export * from './build/empira-folder-download.icon';
export * from './build/empira-folder-lock.icon';
export * from './build/empira-folder-minus.icon';
export * from './build/empira-folder-plus.icon';
export * from './build/empira-folder-question.icon';
export * from './build/empira-folder-search.icon';
export * from './build/empira-folder-shield.icon';
export * from './build/empira-folder-x.icon';
export * from './build/empira-folder.icon';
export * from './build/empira-frame.icon';
export * from './build/empira-framer.icon';
export * from './build/empira-gaming-pad-01.icon';
export * from './build/empira-gaming-pad-02.icon';
export * from './build/empira-gift-01.icon';
export * from './build/empira-gift-02.icon';
export * from './build/empira-git-branch-01.icon';
export * from './build/empira-git-branch-02.icon';
export * from './build/empira-git-commit.icon';
export * from './build/empira-git-merge.icon';
export * from './build/empira-git-pull-request.icon';
export * from './build/empira-glasses-01.icon';
export * from './build/empira-glasses-02.icon';
export * from './build/empira-globe-01.icon';
export * from './build/empira-globe-02.icon';
export * from './build/empira-globe-03.icon';
export * from './build/empira-globe-04.icon';
export * from './build/empira-globe-05.icon';
export * from './build/empira-globe-06.icon';
export * from './build/empira-globe-slated-01.icon';
export * from './build/empira-globe-slated-02.icon';
export * from './build/empira-google-chrome.icon';
export * from './build/empira-graduation-hat-01.icon';
export * from './build/empira-graduation-hat-02.icon';
export * from './build/empira-grid-01.icon';
export * from './build/empira-grid-02.icon';
export * from './build/empira-grid-03.icon';
export * from './build/empira-grid-dots-blank.icon';
export * from './build/empira-grid-dots-bottom.icon';
export * from './build/empira-grid-dots-horizontal-center.icon';
export * from './build/empira-grid-dots-left.icon';
export * from './build/empira-grid-dots-outer.icon';
export * from './build/empira-grid-dots-right.icon';
export * from './build/empira-grid-dots-top.icon';
export * from './build/empira-grid-dots-vertical-center.icon';
export * from './build/empira-hand.icon';
export * from './build/empira-hard-drive.icon';
export * from './build/empira-hash-01.icon';
export * from './build/empira-hash-02.icon';
export * from './build/empira-heading-01.icon';
export * from './build/empira-heading-02.icon';
export * from './build/empira-heading-square.icon';
export * from './build/empira-headphones-01.icon';
export * from './build/empira-headphones-02.icon';
export * from './build/empira-heart-circle.icon';
export * from './build/empira-heart-hand.icon';
export * from './build/empira-heart-hexagon.icon';
export * from './build/empira-heart-octagon.icon';
export * from './build/empira-heart-rounded.icon';
export * from './build/empira-heart-square.icon';
export * from './build/empira-heart.icon';
export * from './build/empira-hearts.icon';
export * from './build/empira-help-circle.icon';
export * from './build/empira-help-hexagon.icon';
export * from './build/empira-help-octagon.icon';
export * from './build/empira-help-square.icon';
export * from './build/empira-hexagon-01.icon';
export * from './build/empira-hexagon-02.icon';
export * from './build/empira-home-01.icon';
export * from './build/empira-home-02.icon';
export * from './build/empira-home-03.icon';
export * from './build/empira-home-04.icon';
export * from './build/empira-home-05.icon';
export * from './build/empira-home-line.icon';
export * from './build/empira-home-smile.icon';
export * from './build/empira-horizontal-bar-chart-01.icon';
export * from './build/empira-horizontal-bar-chart-02.icon';
export * from './build/empira-horizontal-bar-chart-03.icon';
export * from './build/empira-hourglass-01.icon';
export * from './build/empira-hourglass-02.icon';
export * from './build/empira-hourglass-03.icon';
export * from './build/empira-hurricane-01.icon';
export * from './build/empira-hurricane-02.icon';
export * from './build/empira-hurricane-03.icon';
export * from './build/empira-image-01.icon';
export * from './build/empira-image-02.icon';
export * from './build/empira-image-03.icon';
export * from './build/empira-image-04.icon';
export * from './build/empira-image-05.icon';
export * from './build/empira-image-check.icon';
export * from './build/empira-image-down.icon';
export * from './build/empira-image-indent-left.icon';
export * from './build/empira-image-indent-right.icon';
export * from './build/empira-image-left.icon';
export * from './build/empira-image-plus.icon';
export * from './build/empira-image-right.icon';
export * from './build/empira-image-up.icon';
export * from './build/empira-image-user-check.icon';
export * from './build/empira-image-user-down.icon';
export * from './build/empira-image-user-left.icon';
export * from './build/empira-image-user-plus.icon';
export * from './build/empira-image-user-right.icon';
export * from './build/empira-image-user-up.icon';
export * from './build/empira-image-user-x.icon';
export * from './build/empira-image-user.icon';
export * from './build/empira-image-x.icon';
export * from './build/empira-inbox-01.icon';
export * from './build/empira-inbox-02.icon';
export * from './build/empira-infinity.icon';
export * from './build/empira-info-circle.icon';
export * from './build/empira-info-hexagon.icon';
export * from './build/empira-info-octagon.icon';
export * from './build/empira-info-square.icon';
export * from './build/empira-intersect-circle.icon';
export * from './build/empira-intersect-square.icon';
export * from './build/empira-italic-01.icon';
export * from './build/empira-italic-02.icon';
export * from './build/empira-italic-square.icon';
export * from './build/empira-key-01.icon';
export * from './build/empira-key-02.icon';
export * from './build/empira-keyboard-01.icon';
export * from './build/empira-keyboard-02.icon';
export * from './build/empira-laptop-01.icon';
export * from './build/empira-laptop-02.icon';
export * from './build/empira-layer-single.icon';
export * from './build/empira-layers-three-01.icon';
export * from './build/empira-layers-three-02.icon';
export * from './build/empira-layers-two-01.icon';
export * from './build/empira-layers-two-02.icon';
export * from './build/empira-layout-alt-01.icon';
export * from './build/empira-layout-alt-02.icon';
export * from './build/empira-layout-alt-03.icon';
export * from './build/empira-layout-alt-04.icon';
export * from './build/empira-layout-bottom.icon';
export * from './build/empira-layout-grid-01.icon';
export * from './build/empira-layout-grid-02.icon';
export * from './build/empira-layout-left.icon';
export * from './build/empira-layout-right.icon';
export * from './build/empira-layout-top.icon';
export * from './build/empira-left-indent-01.icon';
export * from './build/empira-left-indent-02.icon';
export * from './build/empira-letter-spacing-01.icon';
export * from './build/empira-letter-spacing-02.icon';
export * from './build/empira-life-buoy-01.icon';
export * from './build/empira-life-buoy-02.icon';
export * from './build/empira-lightbulb-01.icon';
export * from './build/empira-lightbulb-02.icon';
export * from './build/empira-lightbulb-03.icon';
export * from './build/empira-lightbulb-04.icon';
export * from './build/empira-lightbulb-05.icon';
export * from './build/empira-lightning-01.icon';
export * from './build/empira-lightning-02.icon';
export * from './build/empira-line-chart-down-01.icon';
export * from './build/empira-line-chart-down-02.icon';
export * from './build/empira-line-chart-down-03.icon';
export * from './build/empira-line-chart-down-04.icon';
export * from './build/empira-line-chart-down-05.icon';
export * from './build/empira-line-chart-up-01.icon';
export * from './build/empira-line-chart-up-02.icon';
export * from './build/empira-line-chart-up-03.icon';
export * from './build/empira-line-chart-up-04.icon';
export * from './build/empira-line-chart-up-05.icon';
export * from './build/empira-line-height.icon';
export * from './build/empira-link-01.icon';
export * from './build/empira-link-02.icon';
export * from './build/empira-link-03.icon';
export * from './build/empira-link-04.icon';
export * from './build/empira-link-05.icon';
export * from './build/empira-link-broken-01.icon';
export * from './build/empira-link-broken-02.icon';
export * from './build/empira-link-external-01.icon';
export * from './build/empira-link-external-02.icon';
export * from './build/empira-list.icon';
export * from './build/empira-loading-01.icon';
export * from './build/empira-loading-02.icon';
export * from './build/empira-loading-03.icon';
export * from './build/empira-lock-01.icon';
export * from './build/empira-lock-02.icon';
export * from './build/empira-lock-03.icon';
export * from './build/empira-lock-04.icon';
export * from './build/empira-lock-keyhole-circle.icon';
export * from './build/empira-lock-keyhole-square.icon';
export * from './build/empira-lock-unlocked-01.icon';
export * from './build/empira-lock-unlocked-02.icon';
export * from './build/empira-lock-unlocked-03.icon';
export * from './build/empira-lock-unlocked-04.icon';
export * from './build/empira-log-in-01.icon';
export * from './build/empira-log-in-02.icon';
export * from './build/empira-log-in-03.icon';
export * from './build/empira-log-in-04.icon';
export * from './build/empira-log-out-01.icon';
export * from './build/empira-log-out-02.icon';
export * from './build/empira-log-out-03.icon';
export * from './build/empira-log-out-04.icon';
export * from './build/empira-luggage-01.icon';
export * from './build/empira-luggage-02.icon';
export * from './build/empira-luggage-03.icon';
export * from './build/empira-magic-wand-01.icon';
export * from './build/empira-magic-wand-02.icon';
export * from './build/empira-mail-01.icon';
export * from './build/empira-mail-02.icon';
export * from './build/empira-mail-03.icon';
export * from './build/empira-mail-04.icon';
export * from './build/empira-mail-05.icon';
export * from './build/empira-map-01.icon';
export * from './build/empira-map-02.icon';
export * from './build/empira-mark.icon';
export * from './build/empira-marker-pin-01.icon';
export * from './build/empira-marker-pin-02.icon';
export * from './build/empira-marker-pin-03.icon';
export * from './build/empira-marker-pin-04.icon';
export * from './build/empira-marker-pin-05.icon';
export * from './build/empira-marker-pin-06.icon';
export * from './build/empira-maximize-01.icon';
export * from './build/empira-maximize-02.icon';
export * from './build/empira-medical-circle.icon';
export * from './build/empira-medical-cross.icon';
export * from './build/empira-medical-square.icon';
export * from './build/empira-menu-01.icon';
export * from './build/empira-menu-02.icon';
export * from './build/empira-menu-03.icon';
export * from './build/empira-menu-04.icon';
export * from './build/empira-menu-05.icon';
export * from './build/empira-message-alert-circle.icon';
export * from './build/empira-message-alert-square.icon';
export * from './build/empira-message-chat-circle.icon';
export * from './build/empira-message-chat-square.icon';
export * from './build/empira-message-check-circle.icon';
export * from './build/empira-message-check-square.icon';
export * from './build/empira-message-circle-01.icon';
export * from './build/empira-message-circle-02.icon';
export * from './build/empira-message-dots-circle.icon';
export * from './build/empira-message-dots-square.icon';
export * from './build/empira-message-heart-circle.icon';
export * from './build/empira-message-heart-square.icon';
export * from './build/empira-message-notification-circle.icon';
export * from './build/empira-message-notification-square.icon';
export * from './build/empira-message-plus-circle.icon';
export * from './build/empira-message-plus-square.icon';
export * from './build/empira-message-question-circle.icon';
export * from './build/empira-message-question-square.icon';
export * from './build/empira-message-smile-circle.icon';
export * from './build/empira-message-smile-square.icon';
export * from './build/empira-message-square-01.icon';
export * from './build/empira-message-square-02.icon';
export * from './build/empira-message-text-circle-01.icon';
export * from './build/empira-message-text-circle-02.icon';
export * from './build/empira-message-text-square-01.icon';
export * from './build/empira-message-text-square-02.icon';
export * from './build/empira-message-x-circle.icon';
export * from './build/empira-message-x-square.icon';
export * from './build/empira-microphone-01.icon';
export * from './build/empira-microphone-02.icon';
export * from './build/empira-microphone-off-01.icon';
export * from './build/empira-microphone-off-02.icon';
export * from './build/empira-microscope.icon';
export * from './build/empira-minimize-01.icon';
export * from './build/empira-minimize-02.icon';
export * from './build/empira-minus-circle.icon';
export * from './build/empira-minus-square.icon';
export * from './build/empira-minus.icon';
export * from './build/empira-modem-01.icon';
export * from './build/empira-modem-02.icon';
export * from './build/empira-monitor-01.icon';
export * from './build/empira-monitor-02.icon';
export * from './build/empira-monitor-03.icon';
export * from './build/empira-monitor-04.icon';
export * from './build/empira-monitor-05.icon';
export * from './build/empira-moon-01.icon';
export * from './build/empira-moon-02.icon';
export * from './build/empira-moon-eclipse.icon';
export * from './build/empira-moon-star.icon';
export * from './build/empira-mouse.icon';
export * from './build/empira-move.icon';
export * from './build/empira-music-note-01.icon';
export * from './build/empira-music-note-02.icon';
export * from './build/empira-music-note-plus.icon';
export * from './build/empira-navigation-pointer-01.icon';
export * from './build/empira-navigation-pointer-02.icon';
export * from './build/empira-navigation-pointer-off-01.icon';
export * from './build/empira-navigation-pointer-off-02.icon';
export * from './build/empira-notification-box.icon';
export * from './build/empira-notification-message.icon';
export * from './build/empira-notification-text.icon';
export * from './build/empira-octagon.icon';
export * from './build/empira-package-check.icon';
export * from './build/empira-package-minus.icon';
export * from './build/empira-package-plus.icon';
export * from './build/empira-package-search.icon';
export * from './build/empira-package-x.icon';
export * from './build/empira-package.icon';
export * from './build/empira-paint-pour.icon';
export * from './build/empira-paint.icon';
export * from './build/empira-palette.icon';
export * from './build/empira-paperclip.icon';
export * from './build/empira-paragraph-spacing.icon';
export * from './build/empira-paragraph-wrap.icon';
export * from './build/empira-passcode-lock.icon';
export * from './build/empira-passcode.icon';
export * from './build/empira-passport.icon';
export * from './build/empira-pause-circle.icon';
export * from './build/empira-pause-square.icon';
export * from './build/empira-pen-tool-01.icon';
export * from './build/empira-pen-tool-02.icon';
export * from './build/empira-pen-tool-minus.icon';
export * from './build/empira-pen-tool-plus.icon';
export * from './build/empira-pencil-01.icon';
export * from './build/empira-pencil-02.icon';
export * from './build/empira-pencil-line.icon';
export * from './build/empira-pentagon.icon';
export * from './build/empira-percent-01.icon';
export * from './build/empira-percent-02.icon';
export * from './build/empira-percent-03.icon';
export * from './build/empira-perspective-01.icon';
export * from './build/empira-perspective-02.icon';
export * from './build/empira-phone-01.icon';
export * from './build/empira-phone-02.icon';
export * from './build/empira-phone-call-01.icon';
export * from './build/empira-phone-call-02.icon';
export * from './build/empira-phone-hang-up.icon';
export * from './build/empira-phone-incoming-01.icon';
export * from './build/empira-phone-incoming-02.icon';
export * from './build/empira-phone-outgoing-01.icon';
export * from './build/empira-phone-outgoing-02.icon';
export * from './build/empira-phone-pause.icon';
export * from './build/empira-phone-plus.icon';
export * from './build/empira-phone-x.icon';
export * from './build/empira-phone.icon';
export * from './build/empira-pie-chart-01.icon';
export * from './build/empira-pie-chart-02.icon';
export * from './build/empira-pie-chart-03.icon';
export * from './build/empira-pie-chart-04.icon';
export * from './build/empira-piggy-bank-01.icon';
export * from './build/empira-piggy-bank-02.icon';
export * from './build/empira-pilcrow-01.icon';
export * from './build/empira-pilcrow-02.icon';
export * from './build/empira-pilcrow-square.icon';
export * from './build/empira-pin-01.icon';
export * from './build/empira-pin-02.icon';
export * from './build/empira-placeholder.icon';
export * from './build/empira-plane.icon';
export * from './build/empira-play-circle.icon';
export * from './build/empira-play-square.icon';
export * from './build/empira-play.icon';
export * from './build/empira-plus-circle.icon';
export * from './build/empira-plus-square.icon';
export * from './build/empira-plus.icon';
export * from './build/empira-podcast.icon';
export * from './build/empira-power-01.icon';
export * from './build/empira-power-02.icon';
export * from './build/empira-power-03.icon';
export * from './build/empira-presentation-chart-01.icon';
export * from './build/empira-presentation-chart-02.icon';
export * from './build/empira-presentation-chart-03.icon';
export * from './build/empira-printer.icon';
export * from './build/empira-puzzle-piece-01.icon';
export * from './build/empira-puzzle-piece-02.icon';
export * from './build/empira-qr-code-01.icon';
export * from './build/empira-qr-code-02.icon';
export * from './build/empira-receipt-check.icon';
export * from './build/empira-receipt.icon';
export * from './build/empira-recording-01.icon';
export * from './build/empira-recording-02.icon';
export * from './build/empira-recording-03.icon';
export * from './build/empira-reflect-01.icon';
export * from './build/empira-reflect-02.icon';
export * from './build/empira-refresh-ccw-01.icon';
export * from './build/empira-refresh-ccw-02.icon';
export * from './build/empira-refresh-ccw-03.icon';
export * from './build/empira-refresh-ccw-04.icon';
export * from './build/empira-refresh-ccw-05.icon';
export * from './build/empira-refresh-cw-01.icon';
export * from './build/empira-refresh-cw-02.icon';
export * from './build/empira-refresh-cw-03.icon';
export * from './build/empira-refresh-cw-04.icon';
export * from './build/empira-refresh-cw-05.icon';
export * from './build/empira-repeat-01.icon';
export * from './build/empira-repeat-02.icon';
export * from './build/empira-repeat-03.icon';
export * from './build/empira-repeat-04.icon';
export * from './build/empira-reverse-left.icon';
export * from './build/empira-reverse-right.icon';
export * from './build/empira-right-indent-01.icon';
export * from './build/empira-right-indent-02.icon';
export * from './build/empira-rocket-01.icon';
export * from './build/empira-rocket-02.icon';
export * from './build/empira-roller-brush.icon';
export * from './build/empira-route.icon';
export * from './build/empira-rows-01.icon';
export * from './build/empira-rows-02.icon';
export * from './build/empira-rows-03.icon';
export * from './build/empira-rss-01.icon';
export * from './build/empira-rss-02.icon';
export * from './build/empira-ruler.icon';
export * from './build/empira-safe.icon';
export * from './build/empira-sale-01.icon';
export * from './build/empira-sale-02.icon';
export * from './build/empira-sale-03.icon';
export * from './build/empira-sale-04.icon';
export * from './build/empira-save-01.icon';
export * from './build/empira-save-02.icon';
export * from './build/empira-save-03.icon';
export * from './build/empira-scale-01.icon';
export * from './build/empira-scale-02.icon';
export * from './build/empira-scale-03.icon';
export * from './build/empira-scales-01.icon';
export * from './build/empira-scales-02.icon';
export * from './build/empira-scan.icon';
export * from './build/empira-scissors-01.icon';
export * from './build/empira-scissors-02.icon';
export * from './build/empira-scissors-cut-01.icon';
export * from './build/empira-scissors-cut-02.icon';
export * from './build/empira-search-lg.icon';
export * from './build/empira-search-md.icon';
export * from './build/empira-search-refraction.icon';
export * from './build/empira-search-sm.icon';
export * from './build/empira-send-01.icon';
export * from './build/empira-send-02.icon';
export * from './build/empira-send-03.icon';
export * from './build/empira-server-01.icon';
export * from './build/empira-server-02.icon';
export * from './build/empira-server-03.icon';
export * from './build/empira-server-04.icon';
export * from './build/empira-server-05.icon';
export * from './build/empira-server-06.icon';
export * from './build/empira-settings-01.icon';
export * from './build/empira-settings-02.icon';
export * from './build/empira-settings-03.icon';
export * from './build/empira-settings-04.icon';
export * from './build/empira-share-01.icon';
export * from './build/empira-share-02.icon';
export * from './build/empira-share-03.icon';
export * from './build/empira-share-04.icon';
export * from './build/empira-share-05.icon';
export * from './build/empira-share-06.icon';
export * from './build/empira-share-07.icon';
export * from './build/empira-shield-01.icon';
export * from './build/empira-shield-02.icon';
export * from './build/empira-shield-03.icon';
export * from './build/empira-shield-dollar.icon';
export * from './build/empira-shield-off.icon';
export * from './build/empira-shield-plus.icon';
export * from './build/empira-shield-tick.icon';
export * from './build/empira-shield-zap.icon';
export * from './build/empira-shopping-bag-01.icon';
export * from './build/empira-shopping-bag-02.icon';
export * from './build/empira-shopping-bag-03.icon';
export * from './build/empira-shopping-cart-01.icon';
export * from './build/empira-shopping-cart-02.icon';
export * from './build/empira-shopping-cart-03.icon';
export * from './build/empira-shuffle-01.icon';
export * from './build/empira-shuffle-02.icon';
export * from './build/empira-signal-01.icon';
export * from './build/empira-signal-02.icon';
export * from './build/empira-signal-03.icon';
export * from './build/empira-simcard.icon';
export * from './build/empira-skew.icon';
export * from './build/empira-skip-back.icon';
export * from './build/empira-skip-forward.icon';
export * from './build/empira-slash-circle-01.icon';
export * from './build/empira-slash-circle-02.icon';
export * from './build/empira-slash-divider.icon';
export * from './build/empira-slash-octagon.icon';
export * from './build/empira-sliders-01.icon';
export * from './build/empira-sliders-02.icon';
export * from './build/empira-sliders-03.icon';
export * from './build/empira-sliders-04.icon';
export * from './build/empira-snowflake-01.icon';
export * from './build/empira-snowflake-02.icon';
export * from './build/empira-spacing-height-01.icon';
export * from './build/empira-spacing-height-02.icon';
export * from './build/empira-spacing-width-01.icon';
export * from './build/empira-spacing-width-02.icon';
export * from './build/empira-speaker-01.icon';
export * from './build/empira-speaker-02.icon';
export * from './build/empira-speaker-03.icon';
export * from './build/empira-speedometer-01.icon';
export * from './build/empira-speedometer-02.icon';
export * from './build/empira-speedometer-03.icon';
export * from './build/empira-speedometer-04.icon';
export * from './build/empira-square.icon';
export * from './build/empira-stand.icon';
export * from './build/empira-star-01.icon';
export * from './build/empira-star-02.icon';
export * from './build/empira-star-03.icon';
export * from './build/empira-star-04.icon';
export * from './build/empira-star-05.icon';
export * from './build/empira-star-06.icon';
export * from './build/empira-star-07.icon';
export * from './build/empira-stars-01.icon';
export * from './build/empira-stars-02.icon';
export * from './build/empira-stars-03.icon';
export * from './build/empira-sticker-circle.icon';
export * from './build/empira-sticker-square.icon';
export * from './build/empira-stop-circle.icon';
export * from './build/empira-stop-square.icon';
export * from './build/empira-stop.icon';
export * from './build/empira-strikethrough-01.icon';
export * from './build/empira-strikethrough-02.icon';
export * from './build/empira-strikethrough-square.icon';
export * from './build/empira-subscript.icon';
export * from './build/empira-sun-setting-01.icon';
export * from './build/empira-sun-setting-02.icon';
export * from './build/empira-sun-setting-03.icon';
export * from './build/empira-sun.icon';
export * from './build/empira-sunrise.icon';
export * from './build/empira-sunset.icon';
export * from './build/empira-switch-horizontal-01.icon';
export * from './build/empira-switch-horizontal-02.icon';
export * from './build/empira-switch-vertical-01.icon';
export * from './build/empira-switch-vertical-02.icon';
export * from './build/empira-tab_placeholder.icon';
export * from './build/empira-table.icon';
export * from './build/empira-tablet-01.icon';
export * from './build/empira-tablet-02.icon';
export * from './build/empira-tag-01.icon';
export * from './build/empira-tag-02.icon';
export * from './build/empira-tag-03.icon';
export * from './build/empira-target-01.icon';
export * from './build/empira-target-02.icon';
export * from './build/empira-target-03.icon';
export * from './build/empira-target-04.icon';
export * from './build/empira-target-05.icon';
export * from './build/empira-telescope.icon';
export * from './build/empira-terminal-browser.icon';
export * from './build/empira-terminal-circle.icon';
export * from './build/empira-terminal-square.icon';
export * from './build/empira-terminal.icon';
export * from './build/empira-text-input.icon';
export * from './build/empira-thermometer-01.icon';
export * from './build/empira-thermometer-02.icon';
export * from './build/empira-thermometer-03.icon';
export * from './build/empira-thermometer-cold.icon';
export * from './build/empira-thermometer-warm.icon';
export * from './build/empira-thumbs-down.icon';
export * from './build/empira-thumbs-up.icon';
export * from './build/empira-ticket-01.icon';
export * from './build/empira-ticket-02.icon';
export * from './build/empira-toggle-01-left.icon';
export * from './build/empira-toggle-01-right.icon';
export * from './build/empira-toggle-02-left.icon';
export * from './build/empira-toggle-02-right.icon';
export * from './build/empira-toggle-03-left.icon';
export * from './build/empira-toggle-03-right.icon';
export * from './build/empira-tool-01.icon';
export * from './build/empira-tool-02.icon';
export * from './build/empira-train.icon';
export * from './build/empira-tram.icon';
export * from './build/empira-transform.icon';
export * from './build/empira-translate-01.icon';
export * from './build/empira-translate-02.icon';
export * from './build/empira-trash-01.icon';
export * from './build/empira-trash-02.icon';
export * from './build/empira-trash-03.icon';
export * from './build/empira-trash-04.icon';
export * from './build/empira-trend-down-01.icon';
export * from './build/empira-trend-down-02.icon';
export * from './build/empira-trend-up-01.icon';
export * from './build/empira-trend-up-02.icon';
export * from './build/empira-triangle.icon';
export * from './build/empira-trophy-01.icon';
export * from './build/empira-trophy-02.icon';
export * from './build/empira-truck-01.icon';
export * from './build/empira-truck-02.icon';
export * from './build/empira-tv-01.icon';
export * from './build/empira-tv-02.icon';
export * from './build/empira-tv-03.icon';
export * from './build/empira-type-01.icon';
export * from './build/empira-type-02.icon';
export * from './build/empira-type-square.icon';
export * from './build/empira-type-strikethrough-01.icon';
export * from './build/empira-type-strikethrough-02.icon';
export * from './build/empira-umbrella-01.icon';
export * from './build/empira-umbrella-02.icon';
export * from './build/empira-umbrella-03.icon';
export * from './build/empira-underline-01.icon';
export * from './build/empira-underline-02.icon';
export * from './build/empira-underline-square.icon';
export * from './build/empira-upload-01.icon';
export * from './build/empira-upload-02.icon';
export * from './build/empira-upload-03.icon';
export * from './build/empira-upload-04.icon';
export * from './build/empira-upload-cloud-01.icon';
export * from './build/empira-upload-cloud-02.icon';
export * from './build/empira-usb-flash-drive.icon';
export * from './build/empira-user-01.icon';
export * from './build/empira-user-02.icon';
export * from './build/empira-user-03.icon';
export * from './build/empira-user-check-01.icon';
export * from './build/empira-user-check-02.icon';
export * from './build/empira-user-circle.icon';
export * from './build/empira-user-down-01.icon';
export * from './build/empira-user-down-02.icon';
export * from './build/empira-user-edit.icon';
export * from './build/empira-user-left-01.icon';
export * from './build/empira-user-left-02.icon';
export * from './build/empira-user-minus-01.icon';
export * from './build/empira-user-minus-02.icon';
export * from './build/empira-user-plus-01.icon';
export * from './build/empira-user-plus-02.icon';
export * from './build/empira-user-right-01.icon';
export * from './build/empira-user-right-02.icon';
export * from './build/empira-user-square.icon';
export * from './build/empira-user-up-01.icon';
export * from './build/empira-user-up-02.icon';
export * from './build/empira-user-x-01.icon';
export * from './build/empira-user-x-02.icon';
export * from './build/empira-users-01.icon';
export * from './build/empira-users-02.icon';
export * from './build/empira-users-03.icon';
export * from './build/empira-users-check.icon';
export * from './build/empira-users-down.icon';
export * from './build/empira-users-edit.icon';
export * from './build/empira-users-left.icon';
export * from './build/empira-users-minus.icon';
export * from './build/empira-users-plus.icon';
export * from './build/empira-users-right.icon';
export * from './build/empira-users-up.icon';
export * from './build/empira-users-x.icon';
export * from './build/empira-variable.icon';
export * from './build/empira-video-recorder-off.icon';
export * from './build/empira-video-recorder.icon';
export * from './build/empira-virus.icon';
export * from './build/empira-voicemail.icon';
export * from './build/empira-volume-max.icon';
export * from './build/empira-volume-min.icon';
export * from './build/empira-volume-minus.icon';
export * from './build/empira-volume-plus.icon';
export * from './build/empira-volume-x.icon';
export * from './build/empira-wallet-01.icon';
export * from './build/empira-wallet-02.icon';
export * from './build/empira-wallet-03.icon';
export * from './build/empira-wallet-04.icon';
export * from './build/empira-wallet-05.icon';
export * from './build/empira-watch-circle.icon';
export * from './build/empira-watch-square.icon';
export * from './build/empira-waves.icon';
export * from './build/empira-webcam-01.icon';
export * from './build/empira-webcam-02.icon';
export * from './build/empira-wifi-off.icon';
export * from './build/empira-wifi.icon';
export * from './build/empira-wind-01.icon';
export * from './build/empira-wind-02.icon';
export * from './build/empira-wind-03.icon';
export * from './build/empira-x-circle.icon';
export * from './build/empira-x-close.icon';
export * from './build/empira-x-square.icon';
export * from './build/empira-x.icon';
export * from './build/empira-youtube.icon';
export * from './build/empira-zap-circle.icon';
export * from './build/empira-zap-fast.icon';
export * from './build/empira-zap-off.icon';
export * from './build/empira-zap-square.icon';
export * from './build/empira-zap.icon';
export * from './build/empira-zoom-in.icon';
export * from './build/empira-zoom-out.icon';
export * from './build/empiraIconList';
export * from './build/empira-icon';
