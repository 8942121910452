import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { BehaviorSubject, map } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TranslateNewPipe } from '../../../translation/pipes/translate-new.pipe';
import { FormUrl } from 'src/app/shared/constants/form-url';
import { IconService } from '../../../icons/services/icon.service';
import { ListItem } from '../../interfaces/list-item';

@Component({
  selector: 'app-data-rooms-breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterLink,
    NgForOf,
    AsyncPipe,
    NgIf,
    MatIconModule,
    MatMenuModule,
    TranslateNewPipe,
  ],
})
export class BreadcrumbComponent {
  @Input({ required: true })
  public set items(items: ListItem[]) {
    const tmpItems = [...items];

    const firstItem = tmpItems.shift();
    const lastItem = tmpItems.pop();

    this.listItems$.next(tmpItems);
    this.firstItem$.next(firstItem);
    this.lastItem$.next(lastItem);
  }

  @Input()
  canDelete = false;

  @Input()
  canUpdate = false;

  @Input()
  canSetPermissions = false;

  @Output()
  public updatePermissions = new EventEmitter<ListItem>();

  @Output()
  public deleteItem = new EventEmitter<ListItem>();

  @Output()
  public downloadItem = new EventEmitter<ListItem>();

  @Output()
  public editItem = new EventEmitter<ListItem>();

  protected formUrl = FormUrl.DataRoomsBreadCrumb;

  protected listItems$ = new BehaviorSubject<ListItem[]>([]);

  protected firstItem$ = new BehaviorSubject<ListItem | undefined>(undefined);

  protected lastItem$ = new BehaviorSubject<ListItem | undefined>(undefined);

  protected hasTooManyItems$ = this.listItems$.pipe(
    map((listItems) => !!listItems.length),
  );

  constructor() {
    inject(IconService).registerIconList([
      'download_02',
      'edit_05',
      'trash_01',
      'users_01',
    ]);
  }

  protected clickUpdatePermissionsButton(item: ListItem) {
    this.updatePermissions.emit(item);
  }

  protected clickDeleteButton(item: ListItem) {
    this.deleteItem.emit(item);
  }

  protected clickDownloadButton(item: ListItem) {
    this.downloadItem.emit(item);
  }

  protected clickEditButton(item: ListItem) {
    this.editItem.emit(item);
  }
}
