export function blobToBase64File(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string;
      const parts = result.split(',');

      resolve(parts.slice(1)[0]);
    };
    reader.readAsDataURL(blob);
  });
}
