import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName',
  standalone: true,
})
export class FullNamePipe implements PipeTransform {
  transform(user: { name: string; surname: string }) {
    return `${user.name} ${user.surname}`;
  }
}
