import { TemplateSettingDto } from '../dto/template-setting.dto';
import { TemplateSetting } from '../interfaces/template-setting';
import { mapTemplateDtoToTemplate } from './map-template-dto-to-template';

export function mapTemplateSettingDtoToTemplateSetting(
  templateSettingDto: TemplateSettingDto,
): TemplateSetting {
  return {
    id: templateSettingDto.id,
    nameEn: templateSettingDto.nameEn || '',
    nameDe: templateSettingDto.nameDe || '',
    description: templateSettingDto.description,
    code: templateSettingDto.code,
    templateList: templateSettingDto.templateList.map(mapTemplateDtoToTemplate),
    dependOn: templateSettingDto.dependOn,
  };
}
