import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DxDataGridModule, DxTreeListModule } from 'devextreme-angular';
import { UserPreferenceService } from '../../../services/user-preference.service';
import { UserPreference } from '../../../interfaces/user-preference.interface';
import { lastValueFrom, map } from 'rxjs';
import { FormUrl } from 'src/app/shared/constants/form-url';
import { TranslateLocalPipe } from '../../../../translation/pipes/translate-local.pipe';
import { FormFieldComponent } from 'src/app/shared/components/form-field/form-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { FormHeaderComponent } from 'src/app/shared/components/form-header/form-header.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { TranslateNewPipe } from 'src/app/translation/pipes/translate-new.pipe';
import { TranslationService } from 'src/app/translation/services/translation.service';

@Component({
  selector: 'app-list',
  standalone: true,
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [
    CommonModule,
    DxDataGridModule,
    DxTreeListModule,
    ReactiveFormsModule,
    TranslateNewPipe,
    TranslateLocalPipe,
    MatFormFieldModule,
    MatInputModule,
    FormFieldComponent,
    MatSlideToggleModule,
    MatTabsModule,
    FormHeaderComponent,
    MatButtonModule,
    RouterLink,
    MatSelectModule,
  ],
})
export class ListComponent implements OnInit {
  @Input({ required: true }) userId!: number;
  @Input() backlink = '..';

  protected userPreferenceService = inject(UserPreferenceService);
  protected translationService = inject(TranslationService);

  protected languages$ = this.userPreferenceService.getLanguages();
  protected timeZones$ = this.userPreferenceService.getTimeZones();

  protected preferences: { [key: string]: UserPreference } = {}; // Declare the type explicitly
  protected formUrl = FormUrl.UserPreferenceManagementList;

  protected preferenceForm = new FormGroup({
    id: new FormControl(0, [Validators.required]),
    userId: new FormControl(''),
    keyIdentifier: new FormControl(''),
    value: new FormControl(''),
    timeZone: new FormControl(''),
    language: new FormControl(''),
    notifications: new FormControl(false),
  });

  ngOnInit() {
    this.languages$ = this.languages$.pipe(
      map((languages) => languages.sort((a, b) => b.orderNo - a.orderNo)),
    );
    this.userPreferenceService.getByUserId(this.userId).subscribe((prefs) => {
      prefs.forEach((pref) => {
        this.preferences[pref.keyIdentifier] = pref;
      });
      this.preferenceForm.patchValue({
        timeZone: this.preferences['TimeZone']?.value || '',
        language: this.preferences['Language']?.value || '',
        notifications: this.preferences['Notifications']?.value === 'True',
      });
    });
  }

  onSubmit() {
    const updatedPrefs: UserPreference[] = [
      this.mapUserPreference(
        'TimeZone',
        this.preferenceForm.get('timeZone')?.value || '',
      ),
      this.mapUserPreference(
        'Language',
        this.preferenceForm.get('language')?.value || '',
      ),
      this.mapUserPreference(
        'Notifications',
        this.preferenceForm.get('notifications')?.value,
      ),
    ];

    lastValueFrom(this.userPreferenceService.putBatch(updatedPrefs));
    this.updateLocalStorageLanguage();
  }

  private mapUserPreference(
    key: string,
    value: string | boolean | null | undefined,
  ): UserPreference {
    return {
      id: this.preferences[key]?.id || 0,
      userId: this.userId,
      keyIdentifier: key,
      value:
        typeof value === 'boolean' ? (value ? 'True' : 'False') : value || '',
    };
  }

  private updateLocalStorageLanguage() {
    const selectedLanguage = this.preferenceForm.get('language')?.value;
    const languageCode = selectedLanguage === 'en-US' ? 'EN' : 'DE';
    localStorage.setItem('lang', languageCode);
    this.translationService.updateCurrentLanguageName(languageCode);
  }
}
