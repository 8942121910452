import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, of, startWith, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from '../types/interfaces/response.interface';
import { UserPicture } from '../interfaces/user-picture';
import { AuthService } from './auth.service';
import { UserPhoto } from 'src/app/user-profile/interfaces/user-photo.interface';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class UserPhotoService {
  private apiUrl: string = environment.apiUrl;
  private http = inject(HttpClient);

  private authService = inject(AuthService);

  private sanitizer = inject(DomSanitizer);

  createUserPhoto(userId: number, userPhoto: File) {
    return this.http
      .post<Response<UserPicture>>(
        `${this.apiUrl}/UserPhoto/Post`,
        this.getFormDataFromData(userId, userPhoto),
      )
      .pipe(map((response) => response.data));
  }

  getPhotoById(id: number) {
    return this.http.get(`${this.apiUrl}/UserPhoto/GetPhoto/${id}`, {
      responseType: 'blob',
    });
  }

  updateUserPhoto(model: UserPhoto, id?: number) {
    const endpoint = id
      ? `${this.apiUrl}/UserPhoto/Put/${id}`
      : `${this.apiUrl}/UserPhoto/Put`;
    return this.http
      .put<Response<UserPicture>>(endpoint, model)
      .pipe(map((response) => response.data));
  }

  checkUserPhotoExist(id: number) {
    return this.http
      .get<Response<boolean>>(
        `${this.apiUrl}/UserPhoto/CheckUserPhotoExist/${id}`,
      )
      .pipe(map((response) => response.data));
  }

  public selectProfilePicture(userId: number) {
    return this.checkUserPhotoExist(userId).pipe(
      switchMap((photoExists) =>
        photoExists ? this.getPhotoById(userId) : of(undefined),
      ),
    );
  }

  public selectCurrentUserProfilePictureAsBlob(userId?: number) {
    const currentUserId = userId || this.authService.getUserId();
    return this.selectProfilePicture(currentUserId);
  }

  public selectCurrentUserProfilePicture(userId?: number) {
    const currentUserId = userId || this.authService.getUserId();
    return this.selectCurrentUserProfilePictureAsBlob(currentUserId).pipe(
      startWith(undefined),
      map((photo) =>
        photo
          ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(photo))
          : undefined,
      ),
    );
  }

  private getFormDataFromData(userId: number, photo: File) {
    const formData = new FormData();
    formData.append('file', photo, photo.name);
    formData.append('id', String(userId));

    const photoFormat = photo.name.split('.').pop();
    if (photoFormat) {
      formData.append('photo', photoFormat);
    }

    return formData;
  }
}
