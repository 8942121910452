import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function superAdminValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control.parent as FormGroup;
    if (!formGroup) {
      return null;
    }

    const isSuperAdminControl = formGroup.get('isSuperAdmin');
    if (!isSuperAdminControl) {
      return null;
    }

    const isSuperAdmin = isSuperAdminControl.value;
    const allowSwitching = control.value;

    if (!isSuperAdmin && allowSwitching) {
      return {
        invalidSwitch: 'Cannot allow switching unless user is a super admin',
      };
    }

    return null;
  };
}
