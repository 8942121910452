<form [formGroup]="form" (submit)="onFormSubmit()">
  <app-shared-drag-and-drop-field
    [multiple]="true"
    [formControl]="fileUpload"
    [allowedFileSize]="allowedFileSize$ | async"
  />
  <mat-accordion>
    <mat-expansion-panel
      *ngFor="let t of fileList.controls; let i = index"
      formArrayName="fileList"
    >
      <mat-expansion-panel-header>
        {{ fileList.controls[i].get("name")?.value }}
      </mat-expansion-panel-header>
      <ng-container [formGroupName]="i">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error
            *ngIf="fileList.controls[i].get('name')?.hasError('required')"
            >This field is required</mat-error
          >
          <mat-error
            *ngIf="fileList.controls[i].get('name')?.hasError('filename')"
            >The file name is not valid</mat-error
          >
        </mat-form-field>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input matInput type="text" formControlName="description" />
        </mat-form-field>
        <div class="checkbox-group">
          <mat-checkbox formControlName="isPublic">Is public</mat-checkbox>
          <mat-checkbox formControlName="allowComment"
            >Allow comment</mat-checkbox
          >
        </div>
        <mat-form-field>
          <mat-label>Effective from</mat-label>
          <input
            matInput
            type="text"
            formControlName="effectiveFrom"
            [matDatepicker]="effectiveFrom"
          />
          <mat-datepicker-toggle matIconSuffix [for]="effectiveFrom" />
          <mat-datepicker #effectiveFrom />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Effective to</mat-label>
          <input
            matInput
            type="text"
            formControlName="effectiveTo"
            [matDatepicker]="effectiveTo"
            [min]="fileList.controls[i].get('effectiveFrom')?.value"
          />
          <mat-datepicker-toggle matIconSuffix [for]="effectiveTo" />
          <mat-datepicker #effectiveTo />
        </mat-form-field>
        <mat-action-row>
          <button
            mat-flat-button
            color="warn"
            type="button"
            (click)="removeFileForm(i)"
          >
            Remove
          </button>
        </mat-action-row>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="button-container">
    <button
      [disabled]="!!(isUploading$ | async)"
      type="button"
      (click)="closeDialog()"
      mat-flat-button
    >
      Cancel
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!!(isFormSubmitDisabled$ | async) || !!(isUploading$ | async)"
    >
      Save
    </button>
  </div>

  <app-loading-spinner *ngIf="isUploading$ | async" />
</form>
