import { Component, Input, OnInit, inject } from '@angular/core';
import { AsyncPipe, CommonModule, NgForOf, NgIf } from '@angular/common';
import { UserFormPermissionService } from 'src/app/user-form-permission/services/user-form-permission.service';
import { UserGroupsUserFormPermissionsDto } from 'src/app/user-form-permission/interfaces/user-groups-user-form-permissions.interface';
import { DxTreeListModule } from 'devextreme-angular';
import { FormPermission } from 'src/app/user-group/interfaces/form-permission';
import { UserFormPermission } from 'src/app/user-group/interfaces/user-form-permission';
import { UserGroup } from 'src/app/user-group/interfaces/user-group.interface';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SelectionChangedEvent } from 'devextreme/ui/tree_list';
import { BehaviorSubject, map } from 'rxjs';
import { BusinessEntity } from 'src/app/user-form-permission/interfaces/business-entity.interface';
import { FormUrl } from 'src/app/shared/constants/form-url';
import { SpinnerDirective } from 'src/app/shared/directives/loading-spinner.directive';
import { PageTitleComponent } from 'src/app/shared/components/page-title/page-title.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterLink } from '@angular/router';
import { FormFieldComponent } from 'src/app/shared/components/form-field/form-field.component';
import { FormHeaderComponent } from 'src/app/shared/components/form-header/form-header.component';
import { TranslateNewPipe } from 'src/app/translation/pipes/translate-new.pipe';
import { UserFormPermissionModel } from '../../interfaces/user-form-permission-model.interface';
import { superAdminValidator } from 'src/app/shared/validators/super-admin.validator';
import { UserService } from 'src/app/shared/services/users.service';
import { UserRequestDetailService } from 'src/app/shared/services/userrequestdetail.service';

@Component({
  selector: 'app-user-permission',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgForOf,
    ReactiveFormsModule,
    DxTreeListModule,
    TranslateNewPipe,
    SpinnerDirective,
    CommonModule,
    FormHeaderComponent,
    RouterLink,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormFieldComponent,
    MatSlideToggleModule,
    MatSelectModule,
    MatOptionModule,
    PageTitleComponent,
  ],
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.scss'],
})
export class UserPermissionComponent implements OnInit {
  @Input({ required: true }) userId!: number;
  @Input() isUser = false;
  @Input() backlink = '..';

  protected userFormPermissionService = inject(UserFormPermissionService);
  protected userService = inject(UserService);
  protected userRequestService = inject(UserRequestDetailService);
  protected SpinnerSubject = new BehaviorSubject<boolean>(true);
  protected formUrl = FormUrl.UserFormPermissionUserPermissionForm;
  protected isInternal = false;
  protected isSubmitting$ = new BehaviorSubject(false);

  data = {} as UserGroupsUserFormPermissionsDto;
  selectedFormPermissions: UserFormPermission[] = [];

  selectedValues: string[] = [];

  formPermissionList: FormPermission[] = [];

  form = new FormGroup({
    userGroupId: new FormControl(0, { nonNullable: true }),
    isAdmin: new FormControl<boolean>(false, { nonNullable: true }),
    isSuperAdmin: new FormControl<boolean>(false, { nonNullable: true }),
    allowSwitchingPlatform: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [superAdminValidator()],
    }),
    isActive: new FormControl<boolean>(false, { nonNullable: true }),
    businessEntityId: new FormControl(0, [Validators.required]),
  });

  treeDataSource: FormPermission[] = [];

  parentsSelectedValues: string[] = [];

  userGroupSelected!: UserGroup;

  businessEntitiesSubject = new BehaviorSubject<BusinessEntity[]>([]);
  businessEntities$ = this.businessEntitiesSubject.asObservable();

  ngOnInit(): void {
    this.userService
      .selectUser()
      .pipe(map((user) => user?.isSuperAdmin))
      .subscribe((isSuperAdmin) => {
        if (!isSuperAdmin) {
          this.form.get('allowSwitchingPlatform')?.disable();
        }
      });
    if (this.isUser) {
      this.userService.getById(this.userId).subscribe((res) => {
        this.isInternal = res.isInternal;
        this.form.patchValue({
          isActive: res?.isActive,
          allowSwitchingPlatform: res?.allowSwitchingPlatform,
          isSuperAdmin: res?.isSuperAdmin,
          isAdmin: res?.isAdmin,
          businessEntityId: res?.businessEntityId,
        });
      });
    } else {
      this.userRequestService.getUserData(this.userId).subscribe((res) => {
        this.form.patchValue({
          businessEntityId: res?.businessEntityId,
        });
      });
    }

    this.userFormPermissionService
      .getAllUserGroupsUserFormPermissions(this.isUser ? this.userId : 0)
      .subscribe((response) => {
        this.loadBusinessEntities();
        this.data = response;
        this.formPermissionList = this.data.formPermissions;
        for (let i = 0; i < this.formPermissionList.length; i++) {
          this.parentsSelectedValues.push(this.formPermissionList[i].id);
        }
        this.treeDataSource = this.formPermissionList.filter(
          (x) => x.isInternal === this.data.isInternal,
        );
        if (
          this.data.userFormPermission &&
          this.data.userFormPermission.length > 0
        ) {
          const hasUserGroup =
            this.data.userFormPermission.find((x) => x.userGroupId !== null) !=
            undefined;
          if (hasUserGroup) {
            this.userGroupSelected = this.data.userGroups.filter(
              (x) =>
                x.id ===
                this.data.userFormPermission.find((x) => x.userGroupId !== null)
                  ?.userGroupId,
            )[0];

            if (this.userGroupSelected) {
              this.form.patchValue({
                userGroupId: this.userGroupSelected.id,
              });
            }
          }

          this.selectedValues = this.data.userFormPermission.map(
            (item) => item.selectedIds as string,
          );

          for (let i = 0; i < this.data.userFormPermission.length; i++) {
            const newUserFormPermission: UserFormPermission = {
              formPermissionId: parseInt(
                (this.data.userFormPermission[i].selectedIds as string).split(
                  'L',
                )[0],
              ),
              userGroupId:
                this.userGroupSelected != null
                  ? this.userGroupSelected.id
                  : null,
              userId: this.userId,
            };

            this.selectedFormPermissions.push(newUserFormPermission);

            this.getParentFormPermissions(
              this.data.userFormPermission[i].parentId as string,
            );
          }
        }
        (
          this.form.get('userGroupId') as AbstractControl<number>
        ).valueChanges.subscribe((value) => {
          this.groupChange(value);
        });
      });
  }

  protected async loadBusinessEntities() {
    this.businessEntitiesSubject.next(
      await this.userFormPermissionService.getBusinessEntities(),
    );
  }

  getLoadingStatus(): boolean {
    return this.SpinnerSubject.getValue();
  }

  toggleLoading(): void {
    this.SpinnerSubject.next(false);
  }

  private groupChange(group: number) {
    const groupData = this.data.userGroups.filter((x) => x.id === +group)[0];
    this.userGroupSelected = groupData;
    if (groupData === undefined) {
      this.treeDataSource = this.formPermissionList.filter(
        (x) => x.isInternal === this.data.isInternal,
      );
      this.selectedValues = [];
      return;
    }
    this.parentsSelectedValues = [];
    this.treeDataSource = this.formPermissionList.filter(
      (x) => x.isInternal === groupData.isInternal,
    );

    const userFormPermissionList =
      groupData.userFormPermissionList as UserFormPermission[];

    this.selectedValues = userFormPermissionList.map(
      (item) => item.selectedIds as string,
    );

    for (let i = 0; i < userFormPermissionList.length; i++) {
      this.getParentFormPermissions(userFormPermissionList[i].parentId);
    }
  }

  getParentFormPermissions(id?: string): void {
    if (!id) {
      return;
    }

    const foundParent = this.data.formPermissions.find(
      (formPermission) => formPermission.id === id,
    );

    if (foundParent && !this.parentsSelectedValues.includes(foundParent.id)) {
      this.parentsSelectedValues.push(foundParent.id);
      this.getParentFormPermissions(foundParent.formId || undefined);
    }
  }

  insertIntoSelectedFormPermission(id: string) {
    const formPermissionId = parseInt(id.split('L')[0]);
    const newUserFormPermission: UserFormPermission = {
      formPermissionId: formPermissionId,
      userGroupId:
        this.userGroupSelected !== undefined ? this.userGroupSelected.id : null,
    };
    this.selectedFormPermissions.push(newUserFormPermission);
  }

  selectRow(e: SelectionChangedEvent) {
    this.selectedFormPermissions = [];
    e.selectedRowKeys.map((id: string) => {
      if (id.includes('M')) {
        const filterForms = this.formPermissionList.filter(
          (x) => x.formId === id,
        );
        for (let i = 0; i < filterForms.length; i++) {
          const filterPermissionList = this.formPermissionList.filter(
            (x) => x.formId === filterForms[i].id,
          );
          for (let j = 0; j < filterPermissionList.length; j++) {
            this.insertIntoSelectedFormPermission(filterPermissionList[j].id);
          }
        }
      } else if (id.includes('F')) {
        const filterPermissionList = this.formPermissionList.filter(
          (x) => x.formId === id,
        );
        for (let j = 0; j < filterPermissionList.length; j++) {
          this.insertIntoSelectedFormPermission(filterPermissionList[j].id);
        }
      } else if (id.includes('L')) {
        this.insertIntoSelectedFormPermission(id);
      }
    });
  }

  async onSubmit() {
    try {
      if (this.selectedFormPermissions.length === 0) {
        return;
      }
      this.isSubmitting$.next(true);
      const formData = this.form.getRawValue();
      const model: UserFormPermissionModel = {
        userFormPermission: this.selectedFormPermissions,
        id: this.userId,
        isRequestAccount: !this.isUser,
        businessEntityId: formData.businessEntityId,
        allowSwitchingPlatform: formData.allowSwitchingPlatform,
        isAdmin: formData.isAdmin,
        isSuperAdmin: formData.isSuperAdmin,
        isActive: formData.isActive,
      };
      if (
        await this.userFormPermissionService.createUserFormPermission(model)
      ) {
        window.history.back();
      }
    } catch (error) {
      this.isSubmitting$.next(false);
    }
  }
}
