import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Response } from '../types/interfaces/response.interface';
import { AppParameter } from '../interfaces/appparameter.interface';

@Injectable({
  providedIn: 'root',
})
export class AppParameterService {
  private apiUrl: string = environment.apiUrl;

  private http = inject(HttpClient);

  getById(id: string) {
    return this.http
      .get<Response<AppParameter>>(`${this.apiUrl}/AppParameter/GetById/${id}`)
      .pipe(map((response) => response.data));
  }

  getAll(): Observable<AppParameter[]> {
    return this.http
      .get<Response<AppParameter[]>>(this.apiUrl + '/AppParameter/GetAll')
      .pipe(map((response) => response.data));
  }

  parameterExistList(id: string) {
    return this.http
      .get<Response<boolean>>(
        `${this.apiUrl}/AppParameter/ParameterExistList/${id}`,
      )
      .pipe(map((response) => response.data));
  }

  async post(parameter: AppParameter) {
    return await lastValueFrom(
      this.http
        .post<Response<AppParameter>>(`${this.apiUrl}/AppParameter`, parameter)
        .pipe(map((response) => response.data)),
    );
  }

  async put(parameter: AppParameter) {
    return await lastValueFrom(
      this.http
        .put<Response<AppParameter>>(
          `${this.apiUrl}/AppParameter/${parameter.id}`,
          parameter,
        )
        .pipe(map((response) => response.succeeded)),
    );
  }

  async delete(id: string) {
    return lastValueFrom(
      this.http
        .delete<Response<boolean>>(`${this.apiUrl}/AppParameter/${id}`)
        .pipe(map((response) => response.data)),
    );
  }
}
