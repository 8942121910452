import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function FileNameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string; // give the value inside the field

    if (!value) {
      return null;
    }

    return /[:/?\\*"<>|[\]{}%]/g.test(value) ? { filename: true } : null;
  };
}
