/* 🤖 this file was generated by svg-to-ts */
import { EmpiraIconInterface } from './empira-icon';
import { empiraActivityHeart } from './empira-activity-heart.icon';
import { empiraActivity } from './empira-activity.icon';
import { empiraAirplay } from './empira-airplay.icon';
import { empiraAirpods } from './empira-airpods.icon';
import { empiraAlarmClockCheck } from './empira-alarm-clock-check.icon';
import { empiraAlarmClockMinus } from './empira-alarm-clock-minus.icon';
import { empiraAlarmClockOff } from './empira-alarm-clock-off.icon';
import { empiraAlarmClockPlus } from './empira-alarm-clock-plus.icon';
import { empiraAlarmClock } from './empira-alarm-clock.icon';
import { empiraAlertCircle } from './empira-alert-circle.icon';
import { empiraAlertHexagon } from './empira-alert-hexagon.icon';
import { empiraAlertOctagon } from './empira-alert-octagon.icon';
import { empiraAlertSquare } from './empira-alert-square.icon';
import { empiraAlertTriangle } from './empira-alert-triangle.icon';
import { empiraAlignBottom01 } from './empira-align-bottom-01.icon';
import { empiraAlignBottom02 } from './empira-align-bottom-02.icon';
import { empiraAlignCenter } from './empira-align-center.icon';
import { empiraAlignHorizontalCentre01 } from './empira-align-horizontal-centre-01.icon';
import { empiraAlignHorizontalCentre02 } from './empira-align-horizontal-centre-02.icon';
import { empiraAlignJustify } from './empira-align-justify.icon';
import { empiraAlignLeft01 } from './empira-align-left-01.icon';
import { empiraAlignLeft02 } from './empira-align-left-02.icon';
import { empiraAlignLeft } from './empira-align-left.icon';
import { empiraAlignRight01 } from './empira-align-right-01.icon';
import { empiraAlignRight02 } from './empira-align-right-02.icon';
import { empiraAlignRight } from './empira-align-right.icon';
import { empiraAlignTopArrow01 } from './empira-align-top-arrow-01.icon';
import { empiraAlignTopArrow02 } from './empira-align-top-arrow-02.icon';
import { empiraAlignVerticalCenter01 } from './empira-align-vertical-center-01.icon';
import { empiraAlignVerticalCenter02 } from './empira-align-vertical-center-02.icon';
import { empiraAnchor } from './empira-anchor.icon';
import { empiraAnnotationAlert } from './empira-annotation-alert.icon';
import { empiraAnnotationCheck } from './empira-annotation-check.icon';
import { empiraAnnotationDots } from './empira-annotation-dots.icon';
import { empiraAnnotationHeart } from './empira-annotation-heart.icon';
import { empiraAnnotationInfo } from './empira-annotation-info.icon';
import { empiraAnnotationPlus } from './empira-annotation-plus.icon';
import { empiraAnnotationQuestion } from './empira-annotation-question.icon';
import { empiraAnnotationX } from './empira-annotation-x.icon';
import { empiraAnnotation } from './empira-annotation.icon';
import { empiraAnnouncement01 } from './empira-announcement-01.icon';
import { empiraAnnouncement02 } from './empira-announcement-02.icon';
import { empiraAnnouncement03 } from './empira-announcement-03.icon';
import { empiraArchive } from './empira-archive.icon';
import { empiraArrowBlockDown } from './empira-arrow-block-down.icon';
import { empiraArrowBlockLeft } from './empira-arrow-block-left.icon';
import { empiraArrowBlockRight } from './empira-arrow-block-right.icon';
import { empiraArrowBlockUp } from './empira-arrow-block-up.icon';
import { empiraArrowCircleBrokenDownLeft } from './empira-arrow-circle-broken-down-left.icon';
import { empiraArrowCircleBrokenDownRight } from './empira-arrow-circle-broken-down-right.icon';
import { empiraArrowCircleBrokenDown } from './empira-arrow-circle-broken-down.icon';
import { empiraArrowCircleBrokenLeft } from './empira-arrow-circle-broken-left.icon';
import { empiraArrowCircleBrokenRight } from './empira-arrow-circle-broken-right.icon';
import { empiraArrowCircleBrokenUpLeft } from './empira-arrow-circle-broken-up-left.icon';
import { empiraArrowCircleBrokenUpRight } from './empira-arrow-circle-broken-up-right.icon';
import { empiraArrowCircleBrokenUp } from './empira-arrow-circle-broken-up.icon';
import { empiraArrowCircleDownLeft } from './empira-arrow-circle-down-left.icon';
import { empiraArrowCircleDownRight } from './empira-arrow-circle-down-right.icon';
import { empiraArrowCircleDown } from './empira-arrow-circle-down.icon';
import { empiraArrowCircleLeft } from './empira-arrow-circle-left.icon';
import { empiraArrowCircleRight } from './empira-arrow-circle-right.icon';
import { empiraArrowCircleUpLeft } from './empira-arrow-circle-up-left.icon';
import { empiraArrowCircleUpRight } from './empira-arrow-circle-up-right.icon';
import { empiraArrowCircleUp } from './empira-arrow-circle-up.icon';
import { empiraArrowDownLeft } from './empira-arrow-down-left.icon';
import { empiraArrowDownRight } from './empira-arrow-down-right.icon';
import { empiraArrowDown } from './empira-arrow-down.icon';
import { empiraArrowLeft } from './empira-arrow-left.icon';
import { empiraArrowNarrowDownLeft } from './empira-arrow-narrow-down-left.icon';
import { empiraArrowNarrowDownRight } from './empira-arrow-narrow-down-right.icon';
import { empiraArrowNarrowDown } from './empira-arrow-narrow-down.icon';
import { empiraArrowNarrowLeft } from './empira-arrow-narrow-left.icon';
import { empiraArrowNarrowRight } from './empira-arrow-narrow-right.icon';
import { empiraArrowNarrowUpLeft } from './empira-arrow-narrow-up-left.icon';
import { empiraArrowNarrowUpRight } from './empira-arrow-narrow-up-right.icon';
import { empiraArrowNarrowUp } from './empira-arrow-narrow-up.icon';
import { empiraArrowRight } from './empira-arrow-right.icon';
import { empiraArrowSquareDownLeft } from './empira-arrow-square-down-left.icon';
import { empiraArrowSquareDownRight } from './empira-arrow-square-down-right.icon';
import { empiraArrowSquareDown } from './empira-arrow-square-down.icon';
import { empiraArrowSquareLeft } from './empira-arrow-square-left.icon';
import { empiraArrowSquareRight } from './empira-arrow-square-right.icon';
import { empiraArrowSquareUpLeft } from './empira-arrow-square-up-left.icon';
import { empiraArrowSquareUpRight } from './empira-arrow-square-up-right.icon';
import { empiraArrowSquareUp } from './empira-arrow-square-up.icon';
import { empiraArrowUpLeft } from './empira-arrow-up-left.icon';
import { empiraArrowUpRight } from './empira-arrow-up-right.icon';
import { empiraArrowUp } from './empira-arrow-up.icon';
import { empiraArrowsDown } from './empira-arrows-down.icon';
import { empiraArrowsLeft } from './empira-arrows-left.icon';
import { empiraArrowsRight } from './empira-arrows-right.icon';
import { empiraArrowsTriangle } from './empira-arrows-triangle.icon';
import { empiraArrowsUp } from './empira-arrows-up.icon';
import { empiraAsterisk01 } from './empira-asterisk-01.icon';
import { empiraAsterisk02 } from './empira-asterisk-02.icon';
import { empiraAtSign } from './empira-at-sign.icon';
import { empiraAtom01 } from './empira-atom-01.icon';
import { empiraAtom02 } from './empira-atom-02.icon';
import { empiraAttachment01 } from './empira-attachment-01.icon';
import { empiraAttachment02 } from './empira-attachment-02.icon';
import { empiraAward01 } from './empira-award-01.icon';
import { empiraAward02 } from './empira-award-02.icon';
import { empiraAward03 } from './empira-award-03.icon';
import { empiraAward04 } from './empira-award-04.icon';
import { empiraAward05 } from './empira-award-05.icon';
import { empiraBackpack } from './empira-backpack.icon';
import { empiraBankNote01 } from './empira-bank-note-01.icon';
import { empiraBankNote02 } from './empira-bank-note-02.icon';
import { empiraBankNote03 } from './empira-bank-note-03.icon';
import { empiraBank } from './empira-bank.icon';
import { empiraBarChart01 } from './empira-bar-chart-01.icon';
import { empiraBarChart02 } from './empira-bar-chart-02.icon';
import { empiraBarChart03 } from './empira-bar-chart-03.icon';
import { empiraBarChart04 } from './empira-bar-chart-04.icon';
import { empiraBarChart05 } from './empira-bar-chart-05.icon';
import { empiraBarChart06 } from './empira-bar-chart-06.icon';
import { empiraBarChart07 } from './empira-bar-chart-07.icon';
import { empiraBarChart08 } from './empira-bar-chart-08.icon';
import { empiraBarChart09 } from './empira-bar-chart-09.icon';
import { empiraBarChart10 } from './empira-bar-chart-10.icon';
import { empiraBarChart11 } from './empira-bar-chart-11.icon';
import { empiraBarChart12 } from './empira-bar-chart-12.icon';
import { empiraBarChartCircle01 } from './empira-bar-chart-circle-01.icon';
import { empiraBarChartCircle02 } from './empira-bar-chart-circle-02.icon';
import { empiraBarChartCircle03 } from './empira-bar-chart-circle-03.icon';
import { empiraBarChartSquare01 } from './empira-bar-chart-square-01.icon';
import { empiraBarChartSquare02 } from './empira-bar-chart-square-02.icon';
import { empiraBarChartSquare03 } from './empira-bar-chart-square-03.icon';
import { empiraBarChartSquareDown } from './empira-bar-chart-square-down.icon';
import { empiraBarChartSquareMinus } from './empira-bar-chart-square-minus.icon';
import { empiraBarChartSquarePlus } from './empira-bar-chart-square-plus.icon';
import { empiraBarChartSquareUp } from './empira-bar-chart-square-up.icon';
import { empiraBarLineChart } from './empira-bar-line-chart.icon';
import { empiraBatteryCharging01 } from './empira-battery-charging-01.icon';
import { empiraBatteryCharging02 } from './empira-battery-charging-02.icon';
import { empiraBatteryEmpty } from './empira-battery-empty.icon';
import { empiraBatteryFull } from './empira-battery-full.icon';
import { empiraBatteryLow } from './empira-battery-low.icon';
import { empiraBatteryMid } from './empira-battery-mid.icon';
import { empiraBeaker01 } from './empira-beaker-01.icon';
import { empiraBeaker02 } from './empira-beaker-02.icon';
import { empiraBell01 } from './empira-bell-01.icon';
import { empiraBell02 } from './empira-bell-02.icon';
import { empiraBell03 } from './empira-bell-03.icon';
import { empiraBell04 } from './empira-bell-04.icon';
import { empiraBellMinus } from './empira-bell-minus.icon';
import { empiraBellOff01 } from './empira-bell-off-01.icon';
import { empiraBellOff02 } from './empira-bell-off-02.icon';
import { empiraBellOff03 } from './empira-bell-off-03.icon';
import { empiraBellPlus } from './empira-bell-plus.icon';
import { empiraBellRinging01 } from './empira-bell-ringing-01.icon';
import { empiraBellRinging02 } from './empira-bell-ringing-02.icon';
import { empiraBellRinging03 } from './empira-bell-ringing-03.icon';
import { empiraBellRinging04 } from './empira-bell-ringing-04.icon';
import { empiraBezierCurve01 } from './empira-bezier-curve-01.icon';
import { empiraBezierCurve02 } from './empira-bezier-curve-02.icon';
import { empiraBezierCurve03 } from './empira-bezier-curve-03.icon';
import { empiraBluetoothConnect } from './empira-bluetooth-connect.icon';
import { empiraBluetoothOff } from './empira-bluetooth-off.icon';
import { empiraBluetoothOn } from './empira-bluetooth-on.icon';
import { empiraBluetoothSignal } from './empira-bluetooth-signal.icon';
import { empiraBold01 } from './empira-bold-01.icon';
import { empiraBold02 } from './empira-bold-02.icon';
import { empiraBoldSquare } from './empira-bold-square.icon';
import { empiraBookClosed } from './empira-book-closed.icon';
import { empiraBookOpen01 } from './empira-book-open-01.icon';
import { empiraBookOpen02 } from './empira-book-open-02.icon';
import { empiraBookmarkAdd } from './empira-bookmark-add.icon';
import { empiraBookmarkCheck } from './empira-bookmark-check.icon';
import { empiraBookmarkMinus } from './empira-bookmark-minus.icon';
import { empiraBookmarkX } from './empira-bookmark-x.icon';
import { empiraBookmark } from './empira-bookmark.icon';
import { empiraBox } from './empira-box.icon';
import { empiraBracketsCheck } from './empira-brackets-check.icon';
import { empiraBracketsEllipses } from './empira-brackets-ellipses.icon';
import { empiraBracketsMinus } from './empira-brackets-minus.icon';
import { empiraBracketsPlus } from './empira-brackets-plus.icon';
import { empiraBracketsSlash } from './empira-brackets-slash.icon';
import { empiraBracketsX } from './empira-brackets-x.icon';
import { empiraBrackets } from './empira-brackets.icon';
import { empiraBriefcase01 } from './empira-briefcase-01.icon';
import { empiraBriefcase02 } from './empira-briefcase-02.icon';
import { empiraBrowser } from './empira-browser.icon';
import { empiraBrush01 } from './empira-brush-01.icon';
import { empiraBrush02 } from './empira-brush-02.icon';
import { empiraBrush03 } from './empira-brush-03.icon';
import { empiraBuilding01 } from './empira-building-01.icon';
import { empiraBuilding02 } from './empira-building-02.icon';
import { empiraBuilding03 } from './empira-building-03.icon';
import { empiraBuilding04 } from './empira-building-04.icon';
import { empiraBuilding05 } from './empira-building-05.icon';
import { empiraBuilding06 } from './empira-building-06.icon';
import { empiraBuilding07 } from './empira-building-07.icon';
import { empiraBuilding08 } from './empira-building-08.icon';
import { empiraBus } from './empira-bus.icon';
import { empiraCalculator } from './empira-calculator.icon';
import { empiraCalendarCheck01 } from './empira-calendar-check-01.icon';
import { empiraCalendarCheck02 } from './empira-calendar-check-02.icon';
import { empiraCalendarDate } from './empira-calendar-date.icon';
import { empiraCalendarHeart01 } from './empira-calendar-heart-01.icon';
import { empiraCalendarHeart02 } from './empira-calendar-heart-02.icon';
import { empiraCalendarMinus01 } from './empira-calendar-minus-01.icon';
import { empiraCalendarMinus02 } from './empira-calendar-minus-02.icon';
import { empiraCalendarPlus01 } from './empira-calendar-plus-01.icon';
import { empiraCalendarPlus02 } from './empira-calendar-plus-02.icon';
import { empiraCalendar } from './empira-calendar.icon';
import { empiraCamera01 } from './empira-camera-01.icon';
import { empiraCamera02 } from './empira-camera-02.icon';
import { empiraCamera03 } from './empira-camera-03.icon';
import { empiraCameraLens } from './empira-camera-lens.icon';
import { empiraCameraOff } from './empira-camera-off.icon';
import { empiraCameraPlus } from './empira-camera-plus.icon';
import { empiraCar01 } from './empira-car-01.icon';
import { empiraCar02 } from './empira-car-02.icon';
import { empiraCertificate01 } from './empira-certificate-01.icon';
import { empiraCertificate02 } from './empira-certificate-02.icon';
import { empiraChartBreakoutCircle } from './empira-chart-breakout-circle.icon';
import { empiraChartBreakoutSquare } from './empira-chart-breakout-square.icon';
import { empiraCheckCircleBroken } from './empira-check-circle-broken.icon';
import { empiraCheckCircle } from './empira-check-circle.icon';
import { empiraCheckDone01 } from './empira-check-done-01.icon';
import { empiraCheckDone02 } from './empira-check-done-02.icon';
import { empiraCheckHeart } from './empira-check-heart.icon';
import { empiraCheckSquareBroken } from './empira-check-square-broken.icon';
import { empiraCheckSquare } from './empira-check-square.icon';
import { empiraCheckVerified01 } from './empira-check-verified-01.icon';
import { empiraCheckVerified02 } from './empira-check-verified-02.icon';
import { empiraCheckVerified03 } from './empira-check-verified-03.icon';
import { empiraCheck } from './empira-check.icon';
import { empiraChevronDownDouble } from './empira-chevron-down-double.icon';
import { empiraChevronDown } from './empira-chevron-down.icon';
import { empiraChevronLeftDouble } from './empira-chevron-left-double.icon';
import { empiraChevronLeft } from './empira-chevron-left.icon';
import { empiraChevronRightDouble } from './empira-chevron-right-double.icon';
import { empiraChevronRight } from './empira-chevron-right.icon';
import { empiraChevronSelectorHorizontal } from './empira-chevron-selector-horizontal.icon';
import { empiraChevronSelectorVertical } from './empira-chevron-selector-vertical.icon';
import { empiraChevronUpDouble } from './empira-chevron-up-double.icon';
import { empiraChevronUp } from './empira-chevron-up.icon';
import { empiraChromeCast } from './empira-chrome-cast.icon';
import { empiraCircleCut } from './empira-circle-cut.icon';
import { empiraCircle } from './empira-circle.icon';
import { empiraClapperboard } from './empira-clapperboard.icon';
import { empiraClipboardAttachment } from './empira-clipboard-attachment.icon';
import { empiraClipboardCheck } from './empira-clipboard-check.icon';
import { empiraClipboardDownload } from './empira-clipboard-download.icon';
import { empiraClipboardMinus } from './empira-clipboard-minus.icon';
import { empiraClipboardPlus } from './empira-clipboard-plus.icon';
import { empiraClipboardX } from './empira-clipboard-x.icon';
import { empiraClipboard } from './empira-clipboard.icon';
import { empiraClockCheck } from './empira-clock-check.icon';
import { empiraClockFastForward } from './empira-clock-fast-forward.icon';
import { empiraClockPlus } from './empira-clock-plus.icon';
import { empiraClockRefresh } from './empira-clock-refresh.icon';
import { empiraClockRewind } from './empira-clock-rewind.icon';
import { empiraClockSnooze } from './empira-clock-snooze.icon';
import { empiraClockStopwatch } from './empira-clock-stopwatch.icon';
import { empiraClock } from './empira-clock.icon';
import { empiraCloud01 } from './empira-cloud-01.icon';
import { empiraCloud02 } from './empira-cloud-02.icon';
import { empiraCloud03 } from './empira-cloud-03.icon';
import { empiraCloudBlank01 } from './empira-cloud-blank-01.icon';
import { empiraCloudBlank02 } from './empira-cloud-blank-02.icon';
import { empiraCloudLightning } from './empira-cloud-lightning.icon';
import { empiraCloudMoon } from './empira-cloud-moon.icon';
import { empiraCloudOff } from './empira-cloud-off.icon';
import { empiraCloudRaining01 } from './empira-cloud-raining-01.icon';
import { empiraCloudRaining02 } from './empira-cloud-raining-02.icon';
import { empiraCloudRaining03 } from './empira-cloud-raining-03.icon';
import { empiraCloudRaining04 } from './empira-cloud-raining-04.icon';
import { empiraCloudRaining05 } from './empira-cloud-raining-05.icon';
import { empiraCloudRaining06 } from './empira-cloud-raining-06.icon';
import { empiraCloudSnowing01 } from './empira-cloud-snowing-01.icon';
import { empiraCloudSnowing02 } from './empira-cloud-snowing-02.icon';
import { empiraCloudSun01 } from './empira-cloud-sun-01.icon';
import { empiraCloudSun02 } from './empira-cloud-sun-02.icon';
import { empiraCloudSun03 } from './empira-cloud-sun-03.icon';
import { empiraCode01 } from './empira-code-01.icon';
import { empiraCode02 } from './empira-code-02.icon';
import { empiraCodeBrowser } from './empira-code-browser.icon';
import { empiraCodeCircle01 } from './empira-code-circle-01.icon';
import { empiraCodeCircle02 } from './empira-code-circle-02.icon';
import { empiraCodeCircle03 } from './empira-code-circle-03.icon';
import { empiraCodeSnippet01 } from './empira-code-snippet-01.icon';
import { empiraCodeSnippet02 } from './empira-code-snippet-02.icon';
import { empiraCodeSquare01 } from './empira-code-square-01.icon';
import { empiraCodeSquare02 } from './empira-code-square-02.icon';
import { empiraCodepen } from './empira-codepen.icon';
import { empiraCoins01 } from './empira-coins-01.icon';
import { empiraCoins02 } from './empira-coins-02.icon';
import { empiraCoins03 } from './empira-coins-03.icon';
import { empiraCoins04 } from './empira-coins-04.icon';
import { empiraCoinsHand } from './empira-coins-hand.icon';
import { empiraCoinsStacked01 } from './empira-coins-stacked-01.icon';
import { empiraCoinsStacked02 } from './empira-coins-stacked-02.icon';
import { empiraCoinsStacked03 } from './empira-coins-stacked-03.icon';
import { empiraCoinsStacked04 } from './empira-coins-stacked-04.icon';
import { empiraCoinsSwap01 } from './empira-coins-swap-01.icon';
import { empiraCoinsSwap02 } from './empira-coins-swap-02.icon';
import { empiraColors1 } from './empira-colors-1.icon';
import { empiraColors } from './empira-colors.icon';
import { empiraColumns01 } from './empira-columns-01.icon';
import { empiraColumns02 } from './empira-columns-02.icon';
import { empiraColumns03 } from './empira-columns-03.icon';
import { empiraCommand } from './empira-command.icon';
import { empiraCompass01 } from './empira-compass-01.icon';
import { empiraCompass02 } from './empira-compass-02.icon';
import { empiraCompass03 } from './empira-compass-03.icon';
import { empiraCompass } from './empira-compass.icon';
import { empiraContainer } from './empira-container.icon';
import { empiraContrast01 } from './empira-contrast-01.icon';
import { empiraContrast02 } from './empira-contrast-02.icon';
import { empiraContrast03 } from './empira-contrast-03.icon';
import { empiraCopy01 } from './empira-copy-01.icon';
import { empiraCopy02 } from './empira-copy-02.icon';
import { empiraCopy03 } from './empira-copy-03.icon';
import { empiraCopy04 } from './empira-copy-04.icon';
import { empiraCopy05 } from './empira-copy-05.icon';
import { empiraCopy06 } from './empira-copy-06.icon';
import { empiraCopy07 } from './empira-copy-07.icon';
import { empiraCornerDownLeft } from './empira-corner-down-left.icon';
import { empiraCornerDownRight } from './empira-corner-down-right.icon';
import { empiraCornerLeftDown } from './empira-corner-left-down.icon';
import { empiraCornerLeftUp } from './empira-corner-left-up.icon';
import { empiraCornerRightDown } from './empira-corner-right-down.icon';
import { empiraCornerRightUp } from './empira-corner-right-up.icon';
import { empiraCornerUpLeft } from './empira-corner-up-left.icon';
import { empiraCornerUpRight } from './empira-corner-up-right.icon';
import { empiraCpuChip01 } from './empira-cpu-chip-01.icon';
import { empiraCpuChip02 } from './empira-cpu-chip-02.icon';
import { empiraCreditCard01 } from './empira-credit-card-01.icon';
import { empiraCreditCard02 } from './empira-credit-card-02.icon';
import { empiraCreditCardCheck } from './empira-credit-card-check.icon';
import { empiraCreditCardDown } from './empira-credit-card-down.icon';
import { empiraCreditCardDownload } from './empira-credit-card-download.icon';
import { empiraCreditCardEdit } from './empira-credit-card-edit.icon';
import { empiraCreditCardLock } from './empira-credit-card-lock.icon';
import { empiraCreditCardMinus } from './empira-credit-card-minus.icon';
import { empiraCreditCardPlus } from './empira-credit-card-plus.icon';
import { empiraCreditCardRefresh } from './empira-credit-card-refresh.icon';
import { empiraCreditCardSearch } from './empira-credit-card-search.icon';
import { empiraCreditCardShield } from './empira-credit-card-shield.icon';
import { empiraCreditCardUp } from './empira-credit-card-up.icon';
import { empiraCreditCardUpload } from './empira-credit-card-upload.icon';
import { empiraCreditCardX } from './empira-credit-card-x.icon';
import { empiraCrop01 } from './empira-crop-01.icon';
import { empiraCrop02 } from './empira-crop-02.icon';
import { empiraCryptocurrency01 } from './empira-cryptocurrency-01.icon';
import { empiraCryptocurrency02 } from './empira-cryptocurrency-02.icon';
import { empiraCryptocurrency03 } from './empira-cryptocurrency-03.icon';
import { empiraCryptocurrency04 } from './empira-cryptocurrency-04.icon';
import { empiraCube01 } from './empira-cube-01.icon';
import { empiraCube02 } from './empira-cube-02.icon';
import { empiraCube03 } from './empira-cube-03.icon';
import { empiraCube04 } from './empira-cube-04.icon';
import { empiraCubeOutline } from './empira-cube-outline.icon';
import { empiraCurrencyBitcoinCircle } from './empira-currency-bitcoin-circle.icon';
import { empiraCurrencyBitcoin } from './empira-currency-bitcoin.icon';
import { empiraCurrencyDollarCircle } from './empira-currency-dollar-circle.icon';
import { empiraCurrencyDollar } from './empira-currency-dollar.icon';
import { empiraCurrencyEthereumCircle } from './empira-currency-ethereum-circle.icon';
import { empiraCurrencyEthereum } from './empira-currency-ethereum.icon';
import { empiraCurrencyEuroCircle } from './empira-currency-euro-circle.icon';
import { empiraCurrencyEuro } from './empira-currency-euro.icon';
import { empiraCurrencyPoundCircle } from './empira-currency-pound-circle.icon';
import { empiraCurrencyPound } from './empira-currency-pound.icon';
import { empiraCurrencyRubleCircle } from './empira-currency-ruble-circle.icon';
import { empiraCurrencyRuble } from './empira-currency-ruble.icon';
import { empiraCurrencyRupeeCircle } from './empira-currency-rupee-circle.icon';
import { empiraCurrencyRupee } from './empira-currency-rupee.icon';
import { empiraCurrencyYenCircle } from './empira-currency-yen-circle.icon';
import { empiraCurrencyYen } from './empira-currency-yen.icon';
import { empiraCursor01 } from './empira-cursor-01.icon';
import { empiraCursor02 } from './empira-cursor-02.icon';
import { empiraCursor03 } from './empira-cursor-03.icon';
import { empiraCursor04 } from './empira-cursor-04.icon';
import { empiraCursorBox } from './empira-cursor-box.icon';
import { empiraCursorClick01 } from './empira-cursor-click-01.icon';
import { empiraCursorClick02 } from './empira-cursor-click-02.icon';
import { empiraData } from './empira-data.icon';
import { empiraDatabase01 } from './empira-database-01.icon';
import { empiraDatabase02 } from './empira-database-02.icon';
import { empiraDatabase03 } from './empira-database-03.icon';
import { empiraDataflow01 } from './empira-dataflow-01.icon';
import { empiraDataflow02 } from './empira-dataflow-02.icon';
import { empiraDataflow03 } from './empira-dataflow-03.icon';
import { empiraDataflow04 } from './empira-dataflow-04.icon';
import { empiraDelete } from './empira-delete.icon';
import { empiraDiamond01 } from './empira-diamond-01.icon';
import { empiraDiamond02 } from './empira-diamond-02.icon';
import { empiraDice1 } from './empira-dice-1.icon';
import { empiraDice2 } from './empira-dice-2.icon';
import { empiraDice3 } from './empira-dice-3.icon';
import { empiraDice4 } from './empira-dice-4.icon';
import { empiraDice5 } from './empira-dice-5.icon';
import { empiraDice6 } from './empira-dice-6.icon';
import { empiraDisc01 } from './empira-disc-01.icon';
import { empiraDisc02 } from './empira-disc-02.icon';
import { empiraDistributeSpacingHorizontal } from './empira-distribute-spacing-horizontal.icon';
import { empiraDistributeSpacingVertical } from './empira-distribute-spacing-vertical.icon';
import { empiraDivide01 } from './empira-divide-01.icon';
import { empiraDivide02 } from './empira-divide-02.icon';
import { empiraDivide03 } from './empira-divide-03.icon';
import { empiraDivider } from './empira-divider.icon';
import { empiraDotpoints01 } from './empira-dotpoints-01.icon';
import { empiraDotpoints02 } from './empira-dotpoints-02.icon';
import { empiraDotsGrid } from './empira-dots-grid.icon';
import { empiraDotsHorizontal } from './empira-dots-horizontal.icon';
import { empiraDotsVertical } from './empira-dots-vertical.icon';
import { empiraDownload01 } from './empira-download-01.icon';
import { empiraDownload02 } from './empira-download-02.icon';
import { empiraDownload03 } from './empira-download-03.icon';
import { empiraDownload04 } from './empira-download-04.icon';
import { empiraDownloadCloud01 } from './empira-download-cloud-01.icon';
import { empiraDownloadCloud02 } from './empira-download-cloud-02.icon';
import { empiraDrop } from './empira-drop.icon';
import { empiraDroplets01 } from './empira-droplets-01.icon';
import { empiraDroplets02 } from './empira-droplets-02.icon';
import { empiraDroplets03 } from './empira-droplets-03.icon';
import { empiraDropper } from './empira-dropper.icon';
import { empiraEarth } from './empira-earth.icon';
import { empiraEdit01 } from './empira-edit-01.icon';
import { empiraEdit02 } from './empira-edit-02.icon';
import { empiraEdit03 } from './empira-edit-03.icon';
import { empiraEdit04 } from './empira-edit-04.icon';
import { empiraEdit05 } from './empira-edit-05.icon';
import { empiraEqualNot } from './empira-equal-not.icon';
import { empiraEqual } from './empira-equal.icon';
import { empiraEraser } from './empira-eraser.icon';
import { empiraExpand01 } from './empira-expand-01.icon';
import { empiraExpand02 } from './empira-expand-02.icon';
import { empiraExpand03 } from './empira-expand-03.icon';
import { empiraExpand04 } from './empira-expand-04.icon';
import { empiraExpand05 } from './empira-expand-05.icon';
import { empiraExpand06 } from './empira-expand-06.icon';
import { empiraEyeOff } from './empira-eye-off.icon';
import { empiraEye } from './empira-eye.icon';
import { empiraFaceContent } from './empira-face-content.icon';
import { empiraFaceFrown } from './empira-face-frown.icon';
import { empiraFaceHappy } from './empira-face-happy.icon';
import { empiraFaceIdSquare } from './empira-face-id-square.icon';
import { empiraFaceId } from './empira-face-id.icon';
import { empiraFaceNeutral } from './empira-face-neutral.icon';
import { empiraFaceSad } from './empira-face-sad.icon';
import { empiraFaceSmile } from './empira-face-smile.icon';
import { empiraFaceWink } from './empira-face-wink.icon';
import { empiraFastBackward } from './empira-fast-backward.icon';
import { empiraFastForward } from './empira-fast-forward.icon';
import { empiraFeather } from './empira-feather.icon';
import { empiraFigma } from './empira-figma.icon';
import { empiraFile01 } from './empira-file-01.icon';
import { empiraFile02 } from './empira-file-02.icon';
import { empiraFile03 } from './empira-file-03.icon';
import { empiraFile04 } from './empira-file-04.icon';
import { empiraFile05 } from './empira-file-05.icon';
import { empiraFile06 } from './empira-file-06.icon';
import { empiraFile07 } from './empira-file-07.icon';
import { empiraFileAttachment01 } from './empira-file-attachment-01.icon';
import { empiraFileAttachment02 } from './empira-file-attachment-02.icon';
import { empiraFileAttachment03 } from './empira-file-attachment-03.icon';
import { empiraFileAttachment04 } from './empira-file-attachment-04.icon';
import { empiraFileAttachment05 } from './empira-file-attachment-05.icon';
import { empiraFileCheck01 } from './empira-file-check-01.icon';
import { empiraFileCheck02 } from './empira-file-check-02.icon';
import { empiraFileCheck03 } from './empira-file-check-03.icon';
import { empiraFileCode01 } from './empira-file-code-01.icon';
import { empiraFileCode02 } from './empira-file-code-02.icon';
import { empiraFileDownload01 } from './empira-file-download-01.icon';
import { empiraFileDownload02 } from './empira-file-download-02.icon';
import { empiraFileDownload03 } from './empira-file-download-03.icon';
import { empiraFileHeart01 } from './empira-file-heart-01.icon';
import { empiraFileHeart02 } from './empira-file-heart-02.icon';
import { empiraFileHeart03 } from './empira-file-heart-03.icon';
import { empiraFileLock01 } from './empira-file-lock-01.icon';
import { empiraFileLock02 } from './empira-file-lock-02.icon';
import { empiraFileLock03 } from './empira-file-lock-03.icon';
import { empiraFileMinus01 } from './empira-file-minus-01.icon';
import { empiraFileMinus02 } from './empira-file-minus-02.icon';
import { empiraFileMinus03 } from './empira-file-minus-03.icon';
import { empiraFilePlus01 } from './empira-file-plus-01.icon';
import { empiraFilePlus02 } from './empira-file-plus-02.icon';
import { empiraFilePlus03 } from './empira-file-plus-03.icon';
import { empiraFileQuestion01 } from './empira-file-question-01.icon';
import { empiraFileQuestion02 } from './empira-file-question-02.icon';
import { empiraFileQuestion03 } from './empira-file-question-03.icon';
import { empiraFileSearch01 } from './empira-file-search-01.icon';
import { empiraFileSearch02 } from './empira-file-search-02.icon';
import { empiraFileSearch03 } from './empira-file-search-03.icon';
import { empiraFileShield01 } from './empira-file-shield-01.icon';
import { empiraFileShield02 } from './empira-file-shield-02.icon';
import { empiraFileShield03 } from './empira-file-shield-03.icon';
import { empiraFileTypeAudio } from './empira-file-type-audio.icon';
import { empiraFileTypeAvi } from './empira-file-type-avi.icon';
import { empiraFileTypeCode } from './empira-file-type-code.icon';
import { empiraFileTypeCsv } from './empira-file-type-csv.icon';
import { empiraFileTypeDoc } from './empira-file-type-doc.icon';
import { empiraFileTypeDocument } from './empira-file-type-document.icon';
import { empiraFileTypeDocx } from './empira-file-type-docx.icon';
import { empiraFileTypeEmpty } from './empira-file-type-empty.icon';
import { empiraFileTypeEps } from './empira-file-type-eps.icon';
import { empiraFileTypeGif } from './empira-file-type-gif.icon';
import { empiraFileTypeImage } from './empira-file-type-image.icon';
import { empiraFileTypeImg } from './empira-file-type-img.icon';
import { empiraFileTypeJpeg } from './empira-file-type-jpeg.icon';
import { empiraFileTypeJpg } from './empira-file-type-jpg.icon';
import { empiraFileTypeMkv } from './empira-file-type-mkv.icon';
import { empiraFileTypeMp3 } from './empira-file-type-mp3.icon';
import { empiraFileTypeMp4 } from './empira-file-type-mp4.icon';
import { empiraFileTypeMpeg } from './empira-file-type-mpeg.icon';
import { empiraFileTypePdf } from './empira-file-type-pdf.icon';
import { empiraFileTypePng } from './empira-file-type-png.icon';
import { empiraFileTypePpt } from './empira-file-type-ppt.icon';
import { empiraFileTypePptx } from './empira-file-type-pptx.icon';
import { empiraFileTypeRar } from './empira-file-type-rar.icon';
import { empiraFileTypeSpreadsheet } from './empira-file-type-spreadsheet.icon';
import { empiraFileTypeSvg } from './empira-file-type-svg.icon';
import { empiraFileTypeTiff } from './empira-file-type-tiff.icon';
import { empiraFileTypeTxt } from './empira-file-type-txt.icon';
import { empiraFileTypeVideo } from './empira-file-type-video.icon';
import { empiraFileTypeWav } from './empira-file-type-wav.icon';
import { empiraFileTypeWebp } from './empira-file-type-webp.icon';
import { empiraFileTypeXls } from './empira-file-type-xls.icon';
import { empiraFileTypeXlsx } from './empira-file-type-xlsx.icon';
import { empiraFileTypeZip } from './empira-file-type-zip.icon';
import { empiraFileX01 } from './empira-file-x-01.icon';
import { empiraFileX02 } from './empira-file-x-02.icon';
import { empiraFileX03 } from './empira-file-x-03.icon';
import { empiraFilm01 } from './empira-film-01.icon';
import { empiraFilm02 } from './empira-film-02.icon';
import { empiraFilm03 } from './empira-film-03.icon';
import { empiraFilterFunnel01 } from './empira-filter-funnel-01.icon';
import { empiraFilterFunnel02 } from './empira-filter-funnel-02.icon';
import { empiraFilterLines } from './empira-filter-lines.icon';
import { empiraFingerprint01 } from './empira-fingerprint-01.icon';
import { empiraFingerprint02 } from './empira-fingerprint-02.icon';
import { empiraFingerprint03 } from './empira-fingerprint-03.icon';
import { empiraFingerprint04 } from './empira-fingerprint-04.icon';
import { empiraFlag01 } from './empira-flag-01.icon';
import { empiraFlag02 } from './empira-flag-02.icon';
import { empiraFlag03 } from './empira-flag-03.icon';
import { empiraFlag04 } from './empira-flag-04.icon';
import { empiraFlag05 } from './empira-flag-05.icon';
import { empiraFlag06 } from './empira-flag-06.icon';
import { empiraFlashOff } from './empira-flash-off.icon';
import { empiraFlash } from './empira-flash.icon';
import { empiraFlexAlignBottom } from './empira-flex-align-bottom.icon';
import { empiraFlexAlignLeft } from './empira-flex-align-left.icon';
import { empiraFlexAlignRight } from './empira-flex-align-right.icon';
import { empiraFlexAlignTop } from './empira-flex-align-top.icon';
import { empiraFlipBackward } from './empira-flip-backward.icon';
import { empiraFlipForward } from './empira-flip-forward.icon';
import { empiraFolderCheck } from './empira-folder-check.icon';
import { empiraFolderClosed } from './empira-folder-closed.icon';
import { empiraFolderCode } from './empira-folder-code.icon';
import { empiraFolderDownload } from './empira-folder-download.icon';
import { empiraFolderLock } from './empira-folder-lock.icon';
import { empiraFolderMinus } from './empira-folder-minus.icon';
import { empiraFolderPlus } from './empira-folder-plus.icon';
import { empiraFolderQuestion } from './empira-folder-question.icon';
import { empiraFolderSearch } from './empira-folder-search.icon';
import { empiraFolderShield } from './empira-folder-shield.icon';
import { empiraFolderX } from './empira-folder-x.icon';
import { empiraFolder } from './empira-folder.icon';
import { empiraFrame } from './empira-frame.icon';
import { empiraFramer } from './empira-framer.icon';
import { empiraGamingPad01 } from './empira-gaming-pad-01.icon';
import { empiraGamingPad02 } from './empira-gaming-pad-02.icon';
import { empiraGift01 } from './empira-gift-01.icon';
import { empiraGift02 } from './empira-gift-02.icon';
import { empiraGitBranch01 } from './empira-git-branch-01.icon';
import { empiraGitBranch02 } from './empira-git-branch-02.icon';
import { empiraGitCommit } from './empira-git-commit.icon';
import { empiraGitMerge } from './empira-git-merge.icon';
import { empiraGitPullRequest } from './empira-git-pull-request.icon';
import { empiraGlasses01 } from './empira-glasses-01.icon';
import { empiraGlasses02 } from './empira-glasses-02.icon';
import { empiraGlobe01 } from './empira-globe-01.icon';
import { empiraGlobe02 } from './empira-globe-02.icon';
import { empiraGlobe03 } from './empira-globe-03.icon';
import { empiraGlobe04 } from './empira-globe-04.icon';
import { empiraGlobe05 } from './empira-globe-05.icon';
import { empiraGlobe06 } from './empira-globe-06.icon';
import { empiraGlobeSlated01 } from './empira-globe-slated-01.icon';
import { empiraGlobeSlated02 } from './empira-globe-slated-02.icon';
import { empiraGoogleChrome } from './empira-google-chrome.icon';
import { empiraGraduationHat01 } from './empira-graduation-hat-01.icon';
import { empiraGraduationHat02 } from './empira-graduation-hat-02.icon';
import { empiraGrid01 } from './empira-grid-01.icon';
import { empiraGrid02 } from './empira-grid-02.icon';
import { empiraGrid03 } from './empira-grid-03.icon';
import { empiraGridDotsBlank } from './empira-grid-dots-blank.icon';
import { empiraGridDotsBottom } from './empira-grid-dots-bottom.icon';
import { empiraGridDotsHorizontalCenter } from './empira-grid-dots-horizontal-center.icon';
import { empiraGridDotsLeft } from './empira-grid-dots-left.icon';
import { empiraGridDotsOuter } from './empira-grid-dots-outer.icon';
import { empiraGridDotsRight } from './empira-grid-dots-right.icon';
import { empiraGridDotsTop } from './empira-grid-dots-top.icon';
import { empiraGridDotsVerticalCenter } from './empira-grid-dots-vertical-center.icon';
import { empiraHand } from './empira-hand.icon';
import { empiraHardDrive } from './empira-hard-drive.icon';
import { empiraHash01 } from './empira-hash-01.icon';
import { empiraHash02 } from './empira-hash-02.icon';
import { empiraHeading01 } from './empira-heading-01.icon';
import { empiraHeading02 } from './empira-heading-02.icon';
import { empiraHeadingSquare } from './empira-heading-square.icon';
import { empiraHeadphones01 } from './empira-headphones-01.icon';
import { empiraHeadphones02 } from './empira-headphones-02.icon';
import { empiraHeartCircle } from './empira-heart-circle.icon';
import { empiraHeartHand } from './empira-heart-hand.icon';
import { empiraHeartHexagon } from './empira-heart-hexagon.icon';
import { empiraHeartOctagon } from './empira-heart-octagon.icon';
import { empiraHeartRounded } from './empira-heart-rounded.icon';
import { empiraHeartSquare } from './empira-heart-square.icon';
import { empiraHeart } from './empira-heart.icon';
import { empiraHearts } from './empira-hearts.icon';
import { empiraHelpCircle } from './empira-help-circle.icon';
import { empiraHelpHexagon } from './empira-help-hexagon.icon';
import { empiraHelpOctagon } from './empira-help-octagon.icon';
import { empiraHelpSquare } from './empira-help-square.icon';
import { empiraHexagon01 } from './empira-hexagon-01.icon';
import { empiraHexagon02 } from './empira-hexagon-02.icon';
import { empiraHome01 } from './empira-home-01.icon';
import { empiraHome02 } from './empira-home-02.icon';
import { empiraHome03 } from './empira-home-03.icon';
import { empiraHome04 } from './empira-home-04.icon';
import { empiraHome05 } from './empira-home-05.icon';
import { empiraHomeLine } from './empira-home-line.icon';
import { empiraHomeSmile } from './empira-home-smile.icon';
import { empiraHorizontalBarChart01 } from './empira-horizontal-bar-chart-01.icon';
import { empiraHorizontalBarChart02 } from './empira-horizontal-bar-chart-02.icon';
import { empiraHorizontalBarChart03 } from './empira-horizontal-bar-chart-03.icon';
import { empiraHourglass01 } from './empira-hourglass-01.icon';
import { empiraHourglass02 } from './empira-hourglass-02.icon';
import { empiraHourglass03 } from './empira-hourglass-03.icon';
import { empiraHurricane01 } from './empira-hurricane-01.icon';
import { empiraHurricane02 } from './empira-hurricane-02.icon';
import { empiraHurricane03 } from './empira-hurricane-03.icon';
import { empiraImage01 } from './empira-image-01.icon';
import { empiraImage02 } from './empira-image-02.icon';
import { empiraImage03 } from './empira-image-03.icon';
import { empiraImage04 } from './empira-image-04.icon';
import { empiraImage05 } from './empira-image-05.icon';
import { empiraImageCheck } from './empira-image-check.icon';
import { empiraImageDown } from './empira-image-down.icon';
import { empiraImageIndentLeft } from './empira-image-indent-left.icon';
import { empiraImageIndentRight } from './empira-image-indent-right.icon';
import { empiraImageLeft } from './empira-image-left.icon';
import { empiraImagePlus } from './empira-image-plus.icon';
import { empiraImageRight } from './empira-image-right.icon';
import { empiraImageUp } from './empira-image-up.icon';
import { empiraImageUserCheck } from './empira-image-user-check.icon';
import { empiraImageUserDown } from './empira-image-user-down.icon';
import { empiraImageUserLeft } from './empira-image-user-left.icon';
import { empiraImageUserPlus } from './empira-image-user-plus.icon';
import { empiraImageUserRight } from './empira-image-user-right.icon';
import { empiraImageUserUp } from './empira-image-user-up.icon';
import { empiraImageUserX } from './empira-image-user-x.icon';
import { empiraImageUser } from './empira-image-user.icon';
import { empiraImageX } from './empira-image-x.icon';
import { empiraInbox01 } from './empira-inbox-01.icon';
import { empiraInbox02 } from './empira-inbox-02.icon';
import { empiraInfinity } from './empira-infinity.icon';
import { empiraInfoCircle } from './empira-info-circle.icon';
import { empiraInfoHexagon } from './empira-info-hexagon.icon';
import { empiraInfoOctagon } from './empira-info-octagon.icon';
import { empiraInfoSquare } from './empira-info-square.icon';
import { empiraIntersectCircle } from './empira-intersect-circle.icon';
import { empiraIntersectSquare } from './empira-intersect-square.icon';
import { empiraItalic01 } from './empira-italic-01.icon';
import { empiraItalic02 } from './empira-italic-02.icon';
import { empiraItalicSquare } from './empira-italic-square.icon';
import { empiraKey01 } from './empira-key-01.icon';
import { empiraKey02 } from './empira-key-02.icon';
import { empiraKeyboard01 } from './empira-keyboard-01.icon';
import { empiraKeyboard02 } from './empira-keyboard-02.icon';
import { empiraLaptop01 } from './empira-laptop-01.icon';
import { empiraLaptop02 } from './empira-laptop-02.icon';
import { empiraLayerSingle } from './empira-layer-single.icon';
import { empiraLayersThree01 } from './empira-layers-three-01.icon';
import { empiraLayersThree02 } from './empira-layers-three-02.icon';
import { empiraLayersTwo01 } from './empira-layers-two-01.icon';
import { empiraLayersTwo02 } from './empira-layers-two-02.icon';
import { empiraLayoutAlt01 } from './empira-layout-alt-01.icon';
import { empiraLayoutAlt02 } from './empira-layout-alt-02.icon';
import { empiraLayoutAlt03 } from './empira-layout-alt-03.icon';
import { empiraLayoutAlt04 } from './empira-layout-alt-04.icon';
import { empiraLayoutBottom } from './empira-layout-bottom.icon';
import { empiraLayoutGrid01 } from './empira-layout-grid-01.icon';
import { empiraLayoutGrid02 } from './empira-layout-grid-02.icon';
import { empiraLayoutLeft } from './empira-layout-left.icon';
import { empiraLayoutRight } from './empira-layout-right.icon';
import { empiraLayoutTop } from './empira-layout-top.icon';
import { empiraLeftIndent01 } from './empira-left-indent-01.icon';
import { empiraLeftIndent02 } from './empira-left-indent-02.icon';
import { empiraLetterSpacing01 } from './empira-letter-spacing-01.icon';
import { empiraLetterSpacing02 } from './empira-letter-spacing-02.icon';
import { empiraLifeBuoy01 } from './empira-life-buoy-01.icon';
import { empiraLifeBuoy02 } from './empira-life-buoy-02.icon';
import { empiraLightbulb01 } from './empira-lightbulb-01.icon';
import { empiraLightbulb02 } from './empira-lightbulb-02.icon';
import { empiraLightbulb03 } from './empira-lightbulb-03.icon';
import { empiraLightbulb04 } from './empira-lightbulb-04.icon';
import { empiraLightbulb05 } from './empira-lightbulb-05.icon';
import { empiraLightning01 } from './empira-lightning-01.icon';
import { empiraLightning02 } from './empira-lightning-02.icon';
import { empiraLineChartDown01 } from './empira-line-chart-down-01.icon';
import { empiraLineChartDown02 } from './empira-line-chart-down-02.icon';
import { empiraLineChartDown03 } from './empira-line-chart-down-03.icon';
import { empiraLineChartDown04 } from './empira-line-chart-down-04.icon';
import { empiraLineChartDown05 } from './empira-line-chart-down-05.icon';
import { empiraLineChartUp01 } from './empira-line-chart-up-01.icon';
import { empiraLineChartUp02 } from './empira-line-chart-up-02.icon';
import { empiraLineChartUp03 } from './empira-line-chart-up-03.icon';
import { empiraLineChartUp04 } from './empira-line-chart-up-04.icon';
import { empiraLineChartUp05 } from './empira-line-chart-up-05.icon';
import { empiraLineHeight } from './empira-line-height.icon';
import { empiraLink01 } from './empira-link-01.icon';
import { empiraLink02 } from './empira-link-02.icon';
import { empiraLink03 } from './empira-link-03.icon';
import { empiraLink04 } from './empira-link-04.icon';
import { empiraLink05 } from './empira-link-05.icon';
import { empiraLinkBroken01 } from './empira-link-broken-01.icon';
import { empiraLinkBroken02 } from './empira-link-broken-02.icon';
import { empiraLinkExternal01 } from './empira-link-external-01.icon';
import { empiraLinkExternal02 } from './empira-link-external-02.icon';
import { empiraList } from './empira-list.icon';
import { empiraLoading01 } from './empira-loading-01.icon';
import { empiraLoading02 } from './empira-loading-02.icon';
import { empiraLoading03 } from './empira-loading-03.icon';
import { empiraLock01 } from './empira-lock-01.icon';
import { empiraLock02 } from './empira-lock-02.icon';
import { empiraLock03 } from './empira-lock-03.icon';
import { empiraLock04 } from './empira-lock-04.icon';
import { empiraLockKeyholeCircle } from './empira-lock-keyhole-circle.icon';
import { empiraLockKeyholeSquare } from './empira-lock-keyhole-square.icon';
import { empiraLockUnlocked01 } from './empira-lock-unlocked-01.icon';
import { empiraLockUnlocked02 } from './empira-lock-unlocked-02.icon';
import { empiraLockUnlocked03 } from './empira-lock-unlocked-03.icon';
import { empiraLockUnlocked04 } from './empira-lock-unlocked-04.icon';
import { empiraLogIn01 } from './empira-log-in-01.icon';
import { empiraLogIn02 } from './empira-log-in-02.icon';
import { empiraLogIn03 } from './empira-log-in-03.icon';
import { empiraLogIn04 } from './empira-log-in-04.icon';
import { empiraLogOut01 } from './empira-log-out-01.icon';
import { empiraLogOut02 } from './empira-log-out-02.icon';
import { empiraLogOut03 } from './empira-log-out-03.icon';
import { empiraLogOut04 } from './empira-log-out-04.icon';
import { empiraLuggage01 } from './empira-luggage-01.icon';
import { empiraLuggage02 } from './empira-luggage-02.icon';
import { empiraLuggage03 } from './empira-luggage-03.icon';
import { empiraMagicWand01 } from './empira-magic-wand-01.icon';
import { empiraMagicWand02 } from './empira-magic-wand-02.icon';
import { empiraMail01 } from './empira-mail-01.icon';
import { empiraMail02 } from './empira-mail-02.icon';
import { empiraMail03 } from './empira-mail-03.icon';
import { empiraMail04 } from './empira-mail-04.icon';
import { empiraMail05 } from './empira-mail-05.icon';
import { empiraMap01 } from './empira-map-01.icon';
import { empiraMap02 } from './empira-map-02.icon';
import { empiraMark } from './empira-mark.icon';
import { empiraMarkerPin01 } from './empira-marker-pin-01.icon';
import { empiraMarkerPin02 } from './empira-marker-pin-02.icon';
import { empiraMarkerPin03 } from './empira-marker-pin-03.icon';
import { empiraMarkerPin04 } from './empira-marker-pin-04.icon';
import { empiraMarkerPin05 } from './empira-marker-pin-05.icon';
import { empiraMarkerPin06 } from './empira-marker-pin-06.icon';
import { empiraMaximize01 } from './empira-maximize-01.icon';
import { empiraMaximize02 } from './empira-maximize-02.icon';
import { empiraMedicalCircle } from './empira-medical-circle.icon';
import { empiraMedicalCross } from './empira-medical-cross.icon';
import { empiraMedicalSquare } from './empira-medical-square.icon';
import { empiraMenu01 } from './empira-menu-01.icon';
import { empiraMenu02 } from './empira-menu-02.icon';
import { empiraMenu03 } from './empira-menu-03.icon';
import { empiraMenu04 } from './empira-menu-04.icon';
import { empiraMenu05 } from './empira-menu-05.icon';
import { empiraMessageAlertCircle } from './empira-message-alert-circle.icon';
import { empiraMessageAlertSquare } from './empira-message-alert-square.icon';
import { empiraMessageChatCircle } from './empira-message-chat-circle.icon';
import { empiraMessageChatSquare } from './empira-message-chat-square.icon';
import { empiraMessageCheckCircle } from './empira-message-check-circle.icon';
import { empiraMessageCheckSquare } from './empira-message-check-square.icon';
import { empiraMessageCircle01 } from './empira-message-circle-01.icon';
import { empiraMessageCircle02 } from './empira-message-circle-02.icon';
import { empiraMessageDotsCircle } from './empira-message-dots-circle.icon';
import { empiraMessageDotsSquare } from './empira-message-dots-square.icon';
import { empiraMessageHeartCircle } from './empira-message-heart-circle.icon';
import { empiraMessageHeartSquare } from './empira-message-heart-square.icon';
import { empiraMessageNotificationCircle } from './empira-message-notification-circle.icon';
import { empiraMessageNotificationSquare } from './empira-message-notification-square.icon';
import { empiraMessagePlusCircle } from './empira-message-plus-circle.icon';
import { empiraMessagePlusSquare } from './empira-message-plus-square.icon';
import { empiraMessageQuestionCircle } from './empira-message-question-circle.icon';
import { empiraMessageQuestionSquare } from './empira-message-question-square.icon';
import { empiraMessageSmileCircle } from './empira-message-smile-circle.icon';
import { empiraMessageSmileSquare } from './empira-message-smile-square.icon';
import { empiraMessageSquare01 } from './empira-message-square-01.icon';
import { empiraMessageSquare02 } from './empira-message-square-02.icon';
import { empiraMessageTextCircle01 } from './empira-message-text-circle-01.icon';
import { empiraMessageTextCircle02 } from './empira-message-text-circle-02.icon';
import { empiraMessageTextSquare01 } from './empira-message-text-square-01.icon';
import { empiraMessageTextSquare02 } from './empira-message-text-square-02.icon';
import { empiraMessageXCircle } from './empira-message-x-circle.icon';
import { empiraMessageXSquare } from './empira-message-x-square.icon';
import { empiraMicrophone01 } from './empira-microphone-01.icon';
import { empiraMicrophone02 } from './empira-microphone-02.icon';
import { empiraMicrophoneOff01 } from './empira-microphone-off-01.icon';
import { empiraMicrophoneOff02 } from './empira-microphone-off-02.icon';
import { empiraMicroscope } from './empira-microscope.icon';
import { empiraMinimize01 } from './empira-minimize-01.icon';
import { empiraMinimize02 } from './empira-minimize-02.icon';
import { empiraMinusCircle } from './empira-minus-circle.icon';
import { empiraMinusSquare } from './empira-minus-square.icon';
import { empiraMinus } from './empira-minus.icon';
import { empiraModem01 } from './empira-modem-01.icon';
import { empiraModem02 } from './empira-modem-02.icon';
import { empiraMonitor01 } from './empira-monitor-01.icon';
import { empiraMonitor02 } from './empira-monitor-02.icon';
import { empiraMonitor03 } from './empira-monitor-03.icon';
import { empiraMonitor04 } from './empira-monitor-04.icon';
import { empiraMonitor05 } from './empira-monitor-05.icon';
import { empiraMoon01 } from './empira-moon-01.icon';
import { empiraMoon02 } from './empira-moon-02.icon';
import { empiraMoonEclipse } from './empira-moon-eclipse.icon';
import { empiraMoonStar } from './empira-moon-star.icon';
import { empiraMouse } from './empira-mouse.icon';
import { empiraMove } from './empira-move.icon';
import { empiraMusicNote01 } from './empira-music-note-01.icon';
import { empiraMusicNote02 } from './empira-music-note-02.icon';
import { empiraMusicNotePlus } from './empira-music-note-plus.icon';
import { empiraNavigationPointer01 } from './empira-navigation-pointer-01.icon';
import { empiraNavigationPointer02 } from './empira-navigation-pointer-02.icon';
import { empiraNavigationPointerOff01 } from './empira-navigation-pointer-off-01.icon';
import { empiraNavigationPointerOff02 } from './empira-navigation-pointer-off-02.icon';
import { empiraNotificationBox } from './empira-notification-box.icon';
import { empiraNotificationMessage } from './empira-notification-message.icon';
import { empiraNotificationText } from './empira-notification-text.icon';
import { empiraOctagon } from './empira-octagon.icon';
import { empiraPackageCheck } from './empira-package-check.icon';
import { empiraPackageMinus } from './empira-package-minus.icon';
import { empiraPackagePlus } from './empira-package-plus.icon';
import { empiraPackageSearch } from './empira-package-search.icon';
import { empiraPackageX } from './empira-package-x.icon';
import { empiraPackage } from './empira-package.icon';
import { empiraPaintPour } from './empira-paint-pour.icon';
import { empiraPaint } from './empira-paint.icon';
import { empiraPalette } from './empira-palette.icon';
import { empiraPaperclip } from './empira-paperclip.icon';
import { empiraParagraphSpacing } from './empira-paragraph-spacing.icon';
import { empiraParagraphWrap } from './empira-paragraph-wrap.icon';
import { empiraPasscodeLock } from './empira-passcode-lock.icon';
import { empiraPasscode } from './empira-passcode.icon';
import { empiraPassport } from './empira-passport.icon';
import { empiraPauseCircle } from './empira-pause-circle.icon';
import { empiraPauseSquare } from './empira-pause-square.icon';
import { empiraPenTool01 } from './empira-pen-tool-01.icon';
import { empiraPenTool02 } from './empira-pen-tool-02.icon';
import { empiraPenToolMinus } from './empira-pen-tool-minus.icon';
import { empiraPenToolPlus } from './empira-pen-tool-plus.icon';
import { empiraPencil01 } from './empira-pencil-01.icon';
import { empiraPencil02 } from './empira-pencil-02.icon';
import { empiraPencilLine } from './empira-pencil-line.icon';
import { empiraPentagon } from './empira-pentagon.icon';
import { empiraPercent01 } from './empira-percent-01.icon';
import { empiraPercent02 } from './empira-percent-02.icon';
import { empiraPercent03 } from './empira-percent-03.icon';
import { empiraPerspective01 } from './empira-perspective-01.icon';
import { empiraPerspective02 } from './empira-perspective-02.icon';
import { empiraPhone01 } from './empira-phone-01.icon';
import { empiraPhone02 } from './empira-phone-02.icon';
import { empiraPhoneCall01 } from './empira-phone-call-01.icon';
import { empiraPhoneCall02 } from './empira-phone-call-02.icon';
import { empiraPhoneHangUp } from './empira-phone-hang-up.icon';
import { empiraPhoneIncoming01 } from './empira-phone-incoming-01.icon';
import { empiraPhoneIncoming02 } from './empira-phone-incoming-02.icon';
import { empiraPhoneOutgoing01 } from './empira-phone-outgoing-01.icon';
import { empiraPhoneOutgoing02 } from './empira-phone-outgoing-02.icon';
import { empiraPhonePause } from './empira-phone-pause.icon';
import { empiraPhonePlus } from './empira-phone-plus.icon';
import { empiraPhoneX } from './empira-phone-x.icon';
import { empiraPhone } from './empira-phone.icon';
import { empiraPieChart01 } from './empira-pie-chart-01.icon';
import { empiraPieChart02 } from './empira-pie-chart-02.icon';
import { empiraPieChart03 } from './empira-pie-chart-03.icon';
import { empiraPieChart04 } from './empira-pie-chart-04.icon';
import { empiraPiggyBank01 } from './empira-piggy-bank-01.icon';
import { empiraPiggyBank02 } from './empira-piggy-bank-02.icon';
import { empiraPilcrow01 } from './empira-pilcrow-01.icon';
import { empiraPilcrow02 } from './empira-pilcrow-02.icon';
import { empiraPilcrowSquare } from './empira-pilcrow-square.icon';
import { empiraPin01 } from './empira-pin-01.icon';
import { empiraPin02 } from './empira-pin-02.icon';
import { empiraPlaceholder } from './empira-placeholder.icon';
import { empiraPlane } from './empira-plane.icon';
import { empiraPlayCircle } from './empira-play-circle.icon';
import { empiraPlaySquare } from './empira-play-square.icon';
import { empiraPlay } from './empira-play.icon';
import { empiraPlusCircle } from './empira-plus-circle.icon';
import { empiraPlusSquare } from './empira-plus-square.icon';
import { empiraPlus } from './empira-plus.icon';
import { empiraPodcast } from './empira-podcast.icon';
import { empiraPower01 } from './empira-power-01.icon';
import { empiraPower02 } from './empira-power-02.icon';
import { empiraPower03 } from './empira-power-03.icon';
import { empiraPresentationChart01 } from './empira-presentation-chart-01.icon';
import { empiraPresentationChart02 } from './empira-presentation-chart-02.icon';
import { empiraPresentationChart03 } from './empira-presentation-chart-03.icon';
import { empiraPrinter } from './empira-printer.icon';
import { empiraPuzzlePiece01 } from './empira-puzzle-piece-01.icon';
import { empiraPuzzlePiece02 } from './empira-puzzle-piece-02.icon';
import { empiraQrCode01 } from './empira-qr-code-01.icon';
import { empiraQrCode02 } from './empira-qr-code-02.icon';
import { empiraReceiptCheck } from './empira-receipt-check.icon';
import { empiraReceipt } from './empira-receipt.icon';
import { empiraRecording01 } from './empira-recording-01.icon';
import { empiraRecording02 } from './empira-recording-02.icon';
import { empiraRecording03 } from './empira-recording-03.icon';
import { empiraReflect01 } from './empira-reflect-01.icon';
import { empiraReflect02 } from './empira-reflect-02.icon';
import { empiraRefreshCcw01 } from './empira-refresh-ccw-01.icon';
import { empiraRefreshCcw02 } from './empira-refresh-ccw-02.icon';
import { empiraRefreshCcw03 } from './empira-refresh-ccw-03.icon';
import { empiraRefreshCcw04 } from './empira-refresh-ccw-04.icon';
import { empiraRefreshCcw05 } from './empira-refresh-ccw-05.icon';
import { empiraRefreshCw01 } from './empira-refresh-cw-01.icon';
import { empiraRefreshCw02 } from './empira-refresh-cw-02.icon';
import { empiraRefreshCw03 } from './empira-refresh-cw-03.icon';
import { empiraRefreshCw04 } from './empira-refresh-cw-04.icon';
import { empiraRefreshCw05 } from './empira-refresh-cw-05.icon';
import { empiraRepeat01 } from './empira-repeat-01.icon';
import { empiraRepeat02 } from './empira-repeat-02.icon';
import { empiraRepeat03 } from './empira-repeat-03.icon';
import { empiraRepeat04 } from './empira-repeat-04.icon';
import { empiraReverseLeft } from './empira-reverse-left.icon';
import { empiraReverseRight } from './empira-reverse-right.icon';
import { empiraRightIndent01 } from './empira-right-indent-01.icon';
import { empiraRightIndent02 } from './empira-right-indent-02.icon';
import { empiraRocket01 } from './empira-rocket-01.icon';
import { empiraRocket02 } from './empira-rocket-02.icon';
import { empiraRollerBrush } from './empira-roller-brush.icon';
import { empiraRoute } from './empira-route.icon';
import { empiraRows01 } from './empira-rows-01.icon';
import { empiraRows02 } from './empira-rows-02.icon';
import { empiraRows03 } from './empira-rows-03.icon';
import { empiraRss01 } from './empira-rss-01.icon';
import { empiraRss02 } from './empira-rss-02.icon';
import { empiraRuler } from './empira-ruler.icon';
import { empiraSafe } from './empira-safe.icon';
import { empiraSale01 } from './empira-sale-01.icon';
import { empiraSale02 } from './empira-sale-02.icon';
import { empiraSale03 } from './empira-sale-03.icon';
import { empiraSale04 } from './empira-sale-04.icon';
import { empiraSave01 } from './empira-save-01.icon';
import { empiraSave02 } from './empira-save-02.icon';
import { empiraSave03 } from './empira-save-03.icon';
import { empiraScale01 } from './empira-scale-01.icon';
import { empiraScale02 } from './empira-scale-02.icon';
import { empiraScale03 } from './empira-scale-03.icon';
import { empiraScales01 } from './empira-scales-01.icon';
import { empiraScales02 } from './empira-scales-02.icon';
import { empiraScan } from './empira-scan.icon';
import { empiraScissors01 } from './empira-scissors-01.icon';
import { empiraScissors02 } from './empira-scissors-02.icon';
import { empiraScissorsCut01 } from './empira-scissors-cut-01.icon';
import { empiraScissorsCut02 } from './empira-scissors-cut-02.icon';
import { empiraSearchLg } from './empira-search-lg.icon';
import { empiraSearchMd } from './empira-search-md.icon';
import { empiraSearchRefraction } from './empira-search-refraction.icon';
import { empiraSearchSm } from './empira-search-sm.icon';
import { empiraSend01 } from './empira-send-01.icon';
import { empiraSend02 } from './empira-send-02.icon';
import { empiraSend03 } from './empira-send-03.icon';
import { empiraServer01 } from './empira-server-01.icon';
import { empiraServer02 } from './empira-server-02.icon';
import { empiraServer03 } from './empira-server-03.icon';
import { empiraServer04 } from './empira-server-04.icon';
import { empiraServer05 } from './empira-server-05.icon';
import { empiraServer06 } from './empira-server-06.icon';
import { empiraSettings01 } from './empira-settings-01.icon';
import { empiraSettings02 } from './empira-settings-02.icon';
import { empiraSettings03 } from './empira-settings-03.icon';
import { empiraSettings04 } from './empira-settings-04.icon';
import { empiraShare01 } from './empira-share-01.icon';
import { empiraShare02 } from './empira-share-02.icon';
import { empiraShare03 } from './empira-share-03.icon';
import { empiraShare04 } from './empira-share-04.icon';
import { empiraShare05 } from './empira-share-05.icon';
import { empiraShare06 } from './empira-share-06.icon';
import { empiraShare07 } from './empira-share-07.icon';
import { empiraShield01 } from './empira-shield-01.icon';
import { empiraShield02 } from './empira-shield-02.icon';
import { empiraShield03 } from './empira-shield-03.icon';
import { empiraShieldDollar } from './empira-shield-dollar.icon';
import { empiraShieldOff } from './empira-shield-off.icon';
import { empiraShieldPlus } from './empira-shield-plus.icon';
import { empiraShieldTick } from './empira-shield-tick.icon';
import { empiraShieldZap } from './empira-shield-zap.icon';
import { empiraShoppingBag01 } from './empira-shopping-bag-01.icon';
import { empiraShoppingBag02 } from './empira-shopping-bag-02.icon';
import { empiraShoppingBag03 } from './empira-shopping-bag-03.icon';
import { empiraShoppingCart01 } from './empira-shopping-cart-01.icon';
import { empiraShoppingCart02 } from './empira-shopping-cart-02.icon';
import { empiraShoppingCart03 } from './empira-shopping-cart-03.icon';
import { empiraShuffle01 } from './empira-shuffle-01.icon';
import { empiraShuffle02 } from './empira-shuffle-02.icon';
import { empiraSignal01 } from './empira-signal-01.icon';
import { empiraSignal02 } from './empira-signal-02.icon';
import { empiraSignal03 } from './empira-signal-03.icon';
import { empiraSimcard } from './empira-simcard.icon';
import { empiraSkew } from './empira-skew.icon';
import { empiraSkipBack } from './empira-skip-back.icon';
import { empiraSkipForward } from './empira-skip-forward.icon';
import { empiraSlashCircle01 } from './empira-slash-circle-01.icon';
import { empiraSlashCircle02 } from './empira-slash-circle-02.icon';
import { empiraSlashDivider } from './empira-slash-divider.icon';
import { empiraSlashOctagon } from './empira-slash-octagon.icon';
import { empiraSliders01 } from './empira-sliders-01.icon';
import { empiraSliders02 } from './empira-sliders-02.icon';
import { empiraSliders03 } from './empira-sliders-03.icon';
import { empiraSliders04 } from './empira-sliders-04.icon';
import { empiraSnowflake01 } from './empira-snowflake-01.icon';
import { empiraSnowflake02 } from './empira-snowflake-02.icon';
import { empiraSpacingHeight01 } from './empira-spacing-height-01.icon';
import { empiraSpacingHeight02 } from './empira-spacing-height-02.icon';
import { empiraSpacingWidth01 } from './empira-spacing-width-01.icon';
import { empiraSpacingWidth02 } from './empira-spacing-width-02.icon';
import { empiraSpeaker01 } from './empira-speaker-01.icon';
import { empiraSpeaker02 } from './empira-speaker-02.icon';
import { empiraSpeaker03 } from './empira-speaker-03.icon';
import { empiraSpeedometer01 } from './empira-speedometer-01.icon';
import { empiraSpeedometer02 } from './empira-speedometer-02.icon';
import { empiraSpeedometer03 } from './empira-speedometer-03.icon';
import { empiraSpeedometer04 } from './empira-speedometer-04.icon';
import { empiraSquare } from './empira-square.icon';
import { empiraStand } from './empira-stand.icon';
import { empiraStar01 } from './empira-star-01.icon';
import { empiraStar02 } from './empira-star-02.icon';
import { empiraStar03 } from './empira-star-03.icon';
import { empiraStar04 } from './empira-star-04.icon';
import { empiraStar05 } from './empira-star-05.icon';
import { empiraStar06 } from './empira-star-06.icon';
import { empiraStar07 } from './empira-star-07.icon';
import { empiraStars01 } from './empira-stars-01.icon';
import { empiraStars02 } from './empira-stars-02.icon';
import { empiraStars03 } from './empira-stars-03.icon';
import { empiraStickerCircle } from './empira-sticker-circle.icon';
import { empiraStickerSquare } from './empira-sticker-square.icon';
import { empiraStopCircle } from './empira-stop-circle.icon';
import { empiraStopSquare } from './empira-stop-square.icon';
import { empiraStop } from './empira-stop.icon';
import { empiraStrikethrough01 } from './empira-strikethrough-01.icon';
import { empiraStrikethrough02 } from './empira-strikethrough-02.icon';
import { empiraStrikethroughSquare } from './empira-strikethrough-square.icon';
import { empiraSubscript } from './empira-subscript.icon';
import { empiraSunSetting01 } from './empira-sun-setting-01.icon';
import { empiraSunSetting02 } from './empira-sun-setting-02.icon';
import { empiraSunSetting03 } from './empira-sun-setting-03.icon';
import { empiraSun } from './empira-sun.icon';
import { empiraSunrise } from './empira-sunrise.icon';
import { empiraSunset } from './empira-sunset.icon';
import { empiraSwitchHorizontal01 } from './empira-switch-horizontal-01.icon';
import { empiraSwitchHorizontal02 } from './empira-switch-horizontal-02.icon';
import { empiraSwitchVertical01 } from './empira-switch-vertical-01.icon';
import { empiraSwitchVertical02 } from './empira-switch-vertical-02.icon';
import { empiraTabPlaceholder } from './empira-tab_placeholder.icon';
import { empiraTable } from './empira-table.icon';
import { empiraTablet01 } from './empira-tablet-01.icon';
import { empiraTablet02 } from './empira-tablet-02.icon';
import { empiraTag01 } from './empira-tag-01.icon';
import { empiraTag02 } from './empira-tag-02.icon';
import { empiraTag03 } from './empira-tag-03.icon';
import { empiraTarget01 } from './empira-target-01.icon';
import { empiraTarget02 } from './empira-target-02.icon';
import { empiraTarget03 } from './empira-target-03.icon';
import { empiraTarget04 } from './empira-target-04.icon';
import { empiraTarget05 } from './empira-target-05.icon';
import { empiraTelescope } from './empira-telescope.icon';
import { empiraTerminalBrowser } from './empira-terminal-browser.icon';
import { empiraTerminalCircle } from './empira-terminal-circle.icon';
import { empiraTerminalSquare } from './empira-terminal-square.icon';
import { empiraTerminal } from './empira-terminal.icon';
import { empiraTextInput } from './empira-text-input.icon';
import { empiraThermometer01 } from './empira-thermometer-01.icon';
import { empiraThermometer02 } from './empira-thermometer-02.icon';
import { empiraThermometer03 } from './empira-thermometer-03.icon';
import { empiraThermometerCold } from './empira-thermometer-cold.icon';
import { empiraThermometerWarm } from './empira-thermometer-warm.icon';
import { empiraThumbsDown } from './empira-thumbs-down.icon';
import { empiraThumbsUp } from './empira-thumbs-up.icon';
import { empiraTicket01 } from './empira-ticket-01.icon';
import { empiraTicket02 } from './empira-ticket-02.icon';
import { empiraToggle01Left } from './empira-toggle-01-left.icon';
import { empiraToggle01Right } from './empira-toggle-01-right.icon';
import { empiraToggle02Left } from './empira-toggle-02-left.icon';
import { empiraToggle02Right } from './empira-toggle-02-right.icon';
import { empiraToggle03Left } from './empira-toggle-03-left.icon';
import { empiraToggle03Right } from './empira-toggle-03-right.icon';
import { empiraTool01 } from './empira-tool-01.icon';
import { empiraTool02 } from './empira-tool-02.icon';
import { empiraTrain } from './empira-train.icon';
import { empiraTram } from './empira-tram.icon';
import { empiraTransform } from './empira-transform.icon';
import { empiraTranslate01 } from './empira-translate-01.icon';
import { empiraTranslate02 } from './empira-translate-02.icon';
import { empiraTrash01 } from './empira-trash-01.icon';
import { empiraTrash02 } from './empira-trash-02.icon';
import { empiraTrash03 } from './empira-trash-03.icon';
import { empiraTrash04 } from './empira-trash-04.icon';
import { empiraTrendDown01 } from './empira-trend-down-01.icon';
import { empiraTrendDown02 } from './empira-trend-down-02.icon';
import { empiraTrendUp01 } from './empira-trend-up-01.icon';
import { empiraTrendUp02 } from './empira-trend-up-02.icon';
import { empiraTriangle } from './empira-triangle.icon';
import { empiraTrophy01 } from './empira-trophy-01.icon';
import { empiraTrophy02 } from './empira-trophy-02.icon';
import { empiraTruck01 } from './empira-truck-01.icon';
import { empiraTruck02 } from './empira-truck-02.icon';
import { empiraTv01 } from './empira-tv-01.icon';
import { empiraTv02 } from './empira-tv-02.icon';
import { empiraTv03 } from './empira-tv-03.icon';
import { empiraType01 } from './empira-type-01.icon';
import { empiraType02 } from './empira-type-02.icon';
import { empiraTypeSquare } from './empira-type-square.icon';
import { empiraTypeStrikethrough01 } from './empira-type-strikethrough-01.icon';
import { empiraTypeStrikethrough02 } from './empira-type-strikethrough-02.icon';
import { empiraUmbrella01 } from './empira-umbrella-01.icon';
import { empiraUmbrella02 } from './empira-umbrella-02.icon';
import { empiraUmbrella03 } from './empira-umbrella-03.icon';
import { empiraUnderline01 } from './empira-underline-01.icon';
import { empiraUnderline02 } from './empira-underline-02.icon';
import { empiraUnderlineSquare } from './empira-underline-square.icon';
import { empiraUpload01 } from './empira-upload-01.icon';
import { empiraUpload02 } from './empira-upload-02.icon';
import { empiraUpload03 } from './empira-upload-03.icon';
import { empiraUpload04 } from './empira-upload-04.icon';
import { empiraUploadCloud01 } from './empira-upload-cloud-01.icon';
import { empiraUploadCloud02 } from './empira-upload-cloud-02.icon';
import { empiraUsbFlashDrive } from './empira-usb-flash-drive.icon';
import { empiraUser01 } from './empira-user-01.icon';
import { empiraUser02 } from './empira-user-02.icon';
import { empiraUser03 } from './empira-user-03.icon';
import { empiraUserCheck01 } from './empira-user-check-01.icon';
import { empiraUserCheck02 } from './empira-user-check-02.icon';
import { empiraUserCircle } from './empira-user-circle.icon';
import { empiraUserDown01 } from './empira-user-down-01.icon';
import { empiraUserDown02 } from './empira-user-down-02.icon';
import { empiraUserEdit } from './empira-user-edit.icon';
import { empiraUserLeft01 } from './empira-user-left-01.icon';
import { empiraUserLeft02 } from './empira-user-left-02.icon';
import { empiraUserMinus01 } from './empira-user-minus-01.icon';
import { empiraUserMinus02 } from './empira-user-minus-02.icon';
import { empiraUserPlus01 } from './empira-user-plus-01.icon';
import { empiraUserPlus02 } from './empira-user-plus-02.icon';
import { empiraUserRight01 } from './empira-user-right-01.icon';
import { empiraUserRight02 } from './empira-user-right-02.icon';
import { empiraUserSquare } from './empira-user-square.icon';
import { empiraUserUp01 } from './empira-user-up-01.icon';
import { empiraUserUp02 } from './empira-user-up-02.icon';
import { empiraUserX01 } from './empira-user-x-01.icon';
import { empiraUserX02 } from './empira-user-x-02.icon';
import { empiraUsers01 } from './empira-users-01.icon';
import { empiraUsers02 } from './empira-users-02.icon';
import { empiraUsers03 } from './empira-users-03.icon';
import { empiraUsersCheck } from './empira-users-check.icon';
import { empiraUsersDown } from './empira-users-down.icon';
import { empiraUsersEdit } from './empira-users-edit.icon';
import { empiraUsersLeft } from './empira-users-left.icon';
import { empiraUsersMinus } from './empira-users-minus.icon';
import { empiraUsersPlus } from './empira-users-plus.icon';
import { empiraUsersRight } from './empira-users-right.icon';
import { empiraUsersUp } from './empira-users-up.icon';
import { empiraUsersX } from './empira-users-x.icon';
import { empiraVariable } from './empira-variable.icon';
import { empiraVideoRecorderOff } from './empira-video-recorder-off.icon';
import { empiraVideoRecorder } from './empira-video-recorder.icon';
import { empiraVirus } from './empira-virus.icon';
import { empiraVoicemail } from './empira-voicemail.icon';
import { empiraVolumeMax } from './empira-volume-max.icon';
import { empiraVolumeMin } from './empira-volume-min.icon';
import { empiraVolumeMinus } from './empira-volume-minus.icon';
import { empiraVolumePlus } from './empira-volume-plus.icon';
import { empiraVolumeX } from './empira-volume-x.icon';
import { empiraWallet01 } from './empira-wallet-01.icon';
import { empiraWallet02 } from './empira-wallet-02.icon';
import { empiraWallet03 } from './empira-wallet-03.icon';
import { empiraWallet04 } from './empira-wallet-04.icon';
import { empiraWallet05 } from './empira-wallet-05.icon';
import { empiraWatchCircle } from './empira-watch-circle.icon';
import { empiraWatchSquare } from './empira-watch-square.icon';
import { empiraWaves } from './empira-waves.icon';
import { empiraWebcam01 } from './empira-webcam-01.icon';
import { empiraWebcam02 } from './empira-webcam-02.icon';
import { empiraWifiOff } from './empira-wifi-off.icon';
import { empiraWifi } from './empira-wifi.icon';
import { empiraWind01 } from './empira-wind-01.icon';
import { empiraWind02 } from './empira-wind-02.icon';
import { empiraWind03 } from './empira-wind-03.icon';
import { empiraXCircle } from './empira-x-circle.icon';
import { empiraXClose } from './empira-x-close.icon';
import { empiraXSquare } from './empira-x-square.icon';
import { empiraX } from './empira-x.icon';
import { empiraYoutube } from './empira-youtube.icon';
import { empiraZapCircle } from './empira-zap-circle.icon';
import { empiraZapFast } from './empira-zap-fast.icon';
import { empiraZapOff } from './empira-zap-off.icon';
import { empiraZapSquare } from './empira-zap-square.icon';
import { empiraZap } from './empira-zap.icon';
import { empiraZoomIn } from './empira-zoom-in.icon';
import { empiraZoomOut } from './empira-zoom-out.icon';
export const empiraIconList: EmpiraIconInterface[] = [
  empiraActivityHeart as EmpiraIconInterface,
  empiraActivity as EmpiraIconInterface,
  empiraAirplay as EmpiraIconInterface,
  empiraAirpods as EmpiraIconInterface,
  empiraAlarmClockCheck as EmpiraIconInterface,
  empiraAlarmClockMinus as EmpiraIconInterface,
  empiraAlarmClockOff as EmpiraIconInterface,
  empiraAlarmClockPlus as EmpiraIconInterface,
  empiraAlarmClock as EmpiraIconInterface,
  empiraAlertCircle as EmpiraIconInterface,
  empiraAlertHexagon as EmpiraIconInterface,
  empiraAlertOctagon as EmpiraIconInterface,
  empiraAlertSquare as EmpiraIconInterface,
  empiraAlertTriangle as EmpiraIconInterface,
  empiraAlignBottom01 as EmpiraIconInterface,
  empiraAlignBottom02 as EmpiraIconInterface,
  empiraAlignCenter as EmpiraIconInterface,
  empiraAlignHorizontalCentre01 as EmpiraIconInterface,
  empiraAlignHorizontalCentre02 as EmpiraIconInterface,
  empiraAlignJustify as EmpiraIconInterface,
  empiraAlignLeft01 as EmpiraIconInterface,
  empiraAlignLeft02 as EmpiraIconInterface,
  empiraAlignLeft as EmpiraIconInterface,
  empiraAlignRight01 as EmpiraIconInterface,
  empiraAlignRight02 as EmpiraIconInterface,
  empiraAlignRight as EmpiraIconInterface,
  empiraAlignTopArrow01 as EmpiraIconInterface,
  empiraAlignTopArrow02 as EmpiraIconInterface,
  empiraAlignVerticalCenter01 as EmpiraIconInterface,
  empiraAlignVerticalCenter02 as EmpiraIconInterface,
  empiraAnchor as EmpiraIconInterface,
  empiraAnnotationAlert as EmpiraIconInterface,
  empiraAnnotationCheck as EmpiraIconInterface,
  empiraAnnotationDots as EmpiraIconInterface,
  empiraAnnotationHeart as EmpiraIconInterface,
  empiraAnnotationInfo as EmpiraIconInterface,
  empiraAnnotationPlus as EmpiraIconInterface,
  empiraAnnotationQuestion as EmpiraIconInterface,
  empiraAnnotationX as EmpiraIconInterface,
  empiraAnnotation as EmpiraIconInterface,
  empiraAnnouncement01 as EmpiraIconInterface,
  empiraAnnouncement02 as EmpiraIconInterface,
  empiraAnnouncement03 as EmpiraIconInterface,
  empiraArchive as EmpiraIconInterface,
  empiraArrowBlockDown as EmpiraIconInterface,
  empiraArrowBlockLeft as EmpiraIconInterface,
  empiraArrowBlockRight as EmpiraIconInterface,
  empiraArrowBlockUp as EmpiraIconInterface,
  empiraArrowCircleBrokenDownLeft as EmpiraIconInterface,
  empiraArrowCircleBrokenDownRight as EmpiraIconInterface,
  empiraArrowCircleBrokenDown as EmpiraIconInterface,
  empiraArrowCircleBrokenLeft as EmpiraIconInterface,
  empiraArrowCircleBrokenRight as EmpiraIconInterface,
  empiraArrowCircleBrokenUpLeft as EmpiraIconInterface,
  empiraArrowCircleBrokenUpRight as EmpiraIconInterface,
  empiraArrowCircleBrokenUp as EmpiraIconInterface,
  empiraArrowCircleDownLeft as EmpiraIconInterface,
  empiraArrowCircleDownRight as EmpiraIconInterface,
  empiraArrowCircleDown as EmpiraIconInterface,
  empiraArrowCircleLeft as EmpiraIconInterface,
  empiraArrowCircleRight as EmpiraIconInterface,
  empiraArrowCircleUpLeft as EmpiraIconInterface,
  empiraArrowCircleUpRight as EmpiraIconInterface,
  empiraArrowCircleUp as EmpiraIconInterface,
  empiraArrowDownLeft as EmpiraIconInterface,
  empiraArrowDownRight as EmpiraIconInterface,
  empiraArrowDown as EmpiraIconInterface,
  empiraArrowLeft as EmpiraIconInterface,
  empiraArrowNarrowDownLeft as EmpiraIconInterface,
  empiraArrowNarrowDownRight as EmpiraIconInterface,
  empiraArrowNarrowDown as EmpiraIconInterface,
  empiraArrowNarrowLeft as EmpiraIconInterface,
  empiraArrowNarrowRight as EmpiraIconInterface,
  empiraArrowNarrowUpLeft as EmpiraIconInterface,
  empiraArrowNarrowUpRight as EmpiraIconInterface,
  empiraArrowNarrowUp as EmpiraIconInterface,
  empiraArrowRight as EmpiraIconInterface,
  empiraArrowSquareDownLeft as EmpiraIconInterface,
  empiraArrowSquareDownRight as EmpiraIconInterface,
  empiraArrowSquareDown as EmpiraIconInterface,
  empiraArrowSquareLeft as EmpiraIconInterface,
  empiraArrowSquareRight as EmpiraIconInterface,
  empiraArrowSquareUpLeft as EmpiraIconInterface,
  empiraArrowSquareUpRight as EmpiraIconInterface,
  empiraArrowSquareUp as EmpiraIconInterface,
  empiraArrowUpLeft as EmpiraIconInterface,
  empiraArrowUpRight as EmpiraIconInterface,
  empiraArrowUp as EmpiraIconInterface,
  empiraArrowsDown as EmpiraIconInterface,
  empiraArrowsLeft as EmpiraIconInterface,
  empiraArrowsRight as EmpiraIconInterface,
  empiraArrowsTriangle as EmpiraIconInterface,
  empiraArrowsUp as EmpiraIconInterface,
  empiraAsterisk01 as EmpiraIconInterface,
  empiraAsterisk02 as EmpiraIconInterface,
  empiraAtSign as EmpiraIconInterface,
  empiraAtom01 as EmpiraIconInterface,
  empiraAtom02 as EmpiraIconInterface,
  empiraAttachment01 as EmpiraIconInterface,
  empiraAttachment02 as EmpiraIconInterface,
  empiraAward01 as EmpiraIconInterface,
  empiraAward02 as EmpiraIconInterface,
  empiraAward03 as EmpiraIconInterface,
  empiraAward04 as EmpiraIconInterface,
  empiraAward05 as EmpiraIconInterface,
  empiraBackpack as EmpiraIconInterface,
  empiraBankNote01 as EmpiraIconInterface,
  empiraBankNote02 as EmpiraIconInterface,
  empiraBankNote03 as EmpiraIconInterface,
  empiraBank as EmpiraIconInterface,
  empiraBarChart01 as EmpiraIconInterface,
  empiraBarChart02 as EmpiraIconInterface,
  empiraBarChart03 as EmpiraIconInterface,
  empiraBarChart04 as EmpiraIconInterface,
  empiraBarChart05 as EmpiraIconInterface,
  empiraBarChart06 as EmpiraIconInterface,
  empiraBarChart07 as EmpiraIconInterface,
  empiraBarChart08 as EmpiraIconInterface,
  empiraBarChart09 as EmpiraIconInterface,
  empiraBarChart10 as EmpiraIconInterface,
  empiraBarChart11 as EmpiraIconInterface,
  empiraBarChart12 as EmpiraIconInterface,
  empiraBarChartCircle01 as EmpiraIconInterface,
  empiraBarChartCircle02 as EmpiraIconInterface,
  empiraBarChartCircle03 as EmpiraIconInterface,
  empiraBarChartSquare01 as EmpiraIconInterface,
  empiraBarChartSquare02 as EmpiraIconInterface,
  empiraBarChartSquare03 as EmpiraIconInterface,
  empiraBarChartSquareDown as EmpiraIconInterface,
  empiraBarChartSquareMinus as EmpiraIconInterface,
  empiraBarChartSquarePlus as EmpiraIconInterface,
  empiraBarChartSquareUp as EmpiraIconInterface,
  empiraBarLineChart as EmpiraIconInterface,
  empiraBatteryCharging01 as EmpiraIconInterface,
  empiraBatteryCharging02 as EmpiraIconInterface,
  empiraBatteryEmpty as EmpiraIconInterface,
  empiraBatteryFull as EmpiraIconInterface,
  empiraBatteryLow as EmpiraIconInterface,
  empiraBatteryMid as EmpiraIconInterface,
  empiraBeaker01 as EmpiraIconInterface,
  empiraBeaker02 as EmpiraIconInterface,
  empiraBell01 as EmpiraIconInterface,
  empiraBell02 as EmpiraIconInterface,
  empiraBell03 as EmpiraIconInterface,
  empiraBell04 as EmpiraIconInterface,
  empiraBellMinus as EmpiraIconInterface,
  empiraBellOff01 as EmpiraIconInterface,
  empiraBellOff02 as EmpiraIconInterface,
  empiraBellOff03 as EmpiraIconInterface,
  empiraBellPlus as EmpiraIconInterface,
  empiraBellRinging01 as EmpiraIconInterface,
  empiraBellRinging02 as EmpiraIconInterface,
  empiraBellRinging03 as EmpiraIconInterface,
  empiraBellRinging04 as EmpiraIconInterface,
  empiraBezierCurve01 as EmpiraIconInterface,
  empiraBezierCurve02 as EmpiraIconInterface,
  empiraBezierCurve03 as EmpiraIconInterface,
  empiraBluetoothConnect as EmpiraIconInterface,
  empiraBluetoothOff as EmpiraIconInterface,
  empiraBluetoothOn as EmpiraIconInterface,
  empiraBluetoothSignal as EmpiraIconInterface,
  empiraBold01 as EmpiraIconInterface,
  empiraBold02 as EmpiraIconInterface,
  empiraBoldSquare as EmpiraIconInterface,
  empiraBookClosed as EmpiraIconInterface,
  empiraBookOpen01 as EmpiraIconInterface,
  empiraBookOpen02 as EmpiraIconInterface,
  empiraBookmarkAdd as EmpiraIconInterface,
  empiraBookmarkCheck as EmpiraIconInterface,
  empiraBookmarkMinus as EmpiraIconInterface,
  empiraBookmarkX as EmpiraIconInterface,
  empiraBookmark as EmpiraIconInterface,
  empiraBox as EmpiraIconInterface,
  empiraBracketsCheck as EmpiraIconInterface,
  empiraBracketsEllipses as EmpiraIconInterface,
  empiraBracketsMinus as EmpiraIconInterface,
  empiraBracketsPlus as EmpiraIconInterface,
  empiraBracketsSlash as EmpiraIconInterface,
  empiraBracketsX as EmpiraIconInterface,
  empiraBrackets as EmpiraIconInterface,
  empiraBriefcase01 as EmpiraIconInterface,
  empiraBriefcase02 as EmpiraIconInterface,
  empiraBrowser as EmpiraIconInterface,
  empiraBrush01 as EmpiraIconInterface,
  empiraBrush02 as EmpiraIconInterface,
  empiraBrush03 as EmpiraIconInterface,
  empiraBuilding01 as EmpiraIconInterface,
  empiraBuilding02 as EmpiraIconInterface,
  empiraBuilding03 as EmpiraIconInterface,
  empiraBuilding04 as EmpiraIconInterface,
  empiraBuilding05 as EmpiraIconInterface,
  empiraBuilding06 as EmpiraIconInterface,
  empiraBuilding07 as EmpiraIconInterface,
  empiraBuilding08 as EmpiraIconInterface,
  empiraBus as EmpiraIconInterface,
  empiraCalculator as EmpiraIconInterface,
  empiraCalendarCheck01 as EmpiraIconInterface,
  empiraCalendarCheck02 as EmpiraIconInterface,
  empiraCalendarDate as EmpiraIconInterface,
  empiraCalendarHeart01 as EmpiraIconInterface,
  empiraCalendarHeart02 as EmpiraIconInterface,
  empiraCalendarMinus01 as EmpiraIconInterface,
  empiraCalendarMinus02 as EmpiraIconInterface,
  empiraCalendarPlus01 as EmpiraIconInterface,
  empiraCalendarPlus02 as EmpiraIconInterface,
  empiraCalendar as EmpiraIconInterface,
  empiraCamera01 as EmpiraIconInterface,
  empiraCamera02 as EmpiraIconInterface,
  empiraCamera03 as EmpiraIconInterface,
  empiraCameraLens as EmpiraIconInterface,
  empiraCameraOff as EmpiraIconInterface,
  empiraCameraPlus as EmpiraIconInterface,
  empiraCar01 as EmpiraIconInterface,
  empiraCar02 as EmpiraIconInterface,
  empiraCertificate01 as EmpiraIconInterface,
  empiraCertificate02 as EmpiraIconInterface,
  empiraChartBreakoutCircle as EmpiraIconInterface,
  empiraChartBreakoutSquare as EmpiraIconInterface,
  empiraCheckCircleBroken as EmpiraIconInterface,
  empiraCheckCircle as EmpiraIconInterface,
  empiraCheckDone01 as EmpiraIconInterface,
  empiraCheckDone02 as EmpiraIconInterface,
  empiraCheckHeart as EmpiraIconInterface,
  empiraCheckSquareBroken as EmpiraIconInterface,
  empiraCheckSquare as EmpiraIconInterface,
  empiraCheckVerified01 as EmpiraIconInterface,
  empiraCheckVerified02 as EmpiraIconInterface,
  empiraCheckVerified03 as EmpiraIconInterface,
  empiraCheck as EmpiraIconInterface,
  empiraChevronDownDouble as EmpiraIconInterface,
  empiraChevronDown as EmpiraIconInterface,
  empiraChevronLeftDouble as EmpiraIconInterface,
  empiraChevronLeft as EmpiraIconInterface,
  empiraChevronRightDouble as EmpiraIconInterface,
  empiraChevronRight as EmpiraIconInterface,
  empiraChevronSelectorHorizontal as EmpiraIconInterface,
  empiraChevronSelectorVertical as EmpiraIconInterface,
  empiraChevronUpDouble as EmpiraIconInterface,
  empiraChevronUp as EmpiraIconInterface,
  empiraChromeCast as EmpiraIconInterface,
  empiraCircleCut as EmpiraIconInterface,
  empiraCircle as EmpiraIconInterface,
  empiraClapperboard as EmpiraIconInterface,
  empiraClipboardAttachment as EmpiraIconInterface,
  empiraClipboardCheck as EmpiraIconInterface,
  empiraClipboardDownload as EmpiraIconInterface,
  empiraClipboardMinus as EmpiraIconInterface,
  empiraClipboardPlus as EmpiraIconInterface,
  empiraClipboardX as EmpiraIconInterface,
  empiraClipboard as EmpiraIconInterface,
  empiraClockCheck as EmpiraIconInterface,
  empiraClockFastForward as EmpiraIconInterface,
  empiraClockPlus as EmpiraIconInterface,
  empiraClockRefresh as EmpiraIconInterface,
  empiraClockRewind as EmpiraIconInterface,
  empiraClockSnooze as EmpiraIconInterface,
  empiraClockStopwatch as EmpiraIconInterface,
  empiraClock as EmpiraIconInterface,
  empiraCloud01 as EmpiraIconInterface,
  empiraCloud02 as EmpiraIconInterface,
  empiraCloud03 as EmpiraIconInterface,
  empiraCloudBlank01 as EmpiraIconInterface,
  empiraCloudBlank02 as EmpiraIconInterface,
  empiraCloudLightning as EmpiraIconInterface,
  empiraCloudMoon as EmpiraIconInterface,
  empiraCloudOff as EmpiraIconInterface,
  empiraCloudRaining01 as EmpiraIconInterface,
  empiraCloudRaining02 as EmpiraIconInterface,
  empiraCloudRaining03 as EmpiraIconInterface,
  empiraCloudRaining04 as EmpiraIconInterface,
  empiraCloudRaining05 as EmpiraIconInterface,
  empiraCloudRaining06 as EmpiraIconInterface,
  empiraCloudSnowing01 as EmpiraIconInterface,
  empiraCloudSnowing02 as EmpiraIconInterface,
  empiraCloudSun01 as EmpiraIconInterface,
  empiraCloudSun02 as EmpiraIconInterface,
  empiraCloudSun03 as EmpiraIconInterface,
  empiraCode01 as EmpiraIconInterface,
  empiraCode02 as EmpiraIconInterface,
  empiraCodeBrowser as EmpiraIconInterface,
  empiraCodeCircle01 as EmpiraIconInterface,
  empiraCodeCircle02 as EmpiraIconInterface,
  empiraCodeCircle03 as EmpiraIconInterface,
  empiraCodeSnippet01 as EmpiraIconInterface,
  empiraCodeSnippet02 as EmpiraIconInterface,
  empiraCodeSquare01 as EmpiraIconInterface,
  empiraCodeSquare02 as EmpiraIconInterface,
  empiraCodepen as EmpiraIconInterface,
  empiraCoins01 as EmpiraIconInterface,
  empiraCoins02 as EmpiraIconInterface,
  empiraCoins03 as EmpiraIconInterface,
  empiraCoins04 as EmpiraIconInterface,
  empiraCoinsHand as EmpiraIconInterface,
  empiraCoinsStacked01 as EmpiraIconInterface,
  empiraCoinsStacked02 as EmpiraIconInterface,
  empiraCoinsStacked03 as EmpiraIconInterface,
  empiraCoinsStacked04 as EmpiraIconInterface,
  empiraCoinsSwap01 as EmpiraIconInterface,
  empiraCoinsSwap02 as EmpiraIconInterface,
  empiraColors1 as EmpiraIconInterface,
  empiraColors as EmpiraIconInterface,
  empiraColumns01 as EmpiraIconInterface,
  empiraColumns02 as EmpiraIconInterface,
  empiraColumns03 as EmpiraIconInterface,
  empiraCommand as EmpiraIconInterface,
  empiraCompass01 as EmpiraIconInterface,
  empiraCompass02 as EmpiraIconInterface,
  empiraCompass03 as EmpiraIconInterface,
  empiraCompass as EmpiraIconInterface,
  empiraContainer as EmpiraIconInterface,
  empiraContrast01 as EmpiraIconInterface,
  empiraContrast02 as EmpiraIconInterface,
  empiraContrast03 as EmpiraIconInterface,
  empiraCopy01 as EmpiraIconInterface,
  empiraCopy02 as EmpiraIconInterface,
  empiraCopy03 as EmpiraIconInterface,
  empiraCopy04 as EmpiraIconInterface,
  empiraCopy05 as EmpiraIconInterface,
  empiraCopy06 as EmpiraIconInterface,
  empiraCopy07 as EmpiraIconInterface,
  empiraCornerDownLeft as EmpiraIconInterface,
  empiraCornerDownRight as EmpiraIconInterface,
  empiraCornerLeftDown as EmpiraIconInterface,
  empiraCornerLeftUp as EmpiraIconInterface,
  empiraCornerRightDown as EmpiraIconInterface,
  empiraCornerRightUp as EmpiraIconInterface,
  empiraCornerUpLeft as EmpiraIconInterface,
  empiraCornerUpRight as EmpiraIconInterface,
  empiraCpuChip01 as EmpiraIconInterface,
  empiraCpuChip02 as EmpiraIconInterface,
  empiraCreditCard01 as EmpiraIconInterface,
  empiraCreditCard02 as EmpiraIconInterface,
  empiraCreditCardCheck as EmpiraIconInterface,
  empiraCreditCardDown as EmpiraIconInterface,
  empiraCreditCardDownload as EmpiraIconInterface,
  empiraCreditCardEdit as EmpiraIconInterface,
  empiraCreditCardLock as EmpiraIconInterface,
  empiraCreditCardMinus as EmpiraIconInterface,
  empiraCreditCardPlus as EmpiraIconInterface,
  empiraCreditCardRefresh as EmpiraIconInterface,
  empiraCreditCardSearch as EmpiraIconInterface,
  empiraCreditCardShield as EmpiraIconInterface,
  empiraCreditCardUp as EmpiraIconInterface,
  empiraCreditCardUpload as EmpiraIconInterface,
  empiraCreditCardX as EmpiraIconInterface,
  empiraCrop01 as EmpiraIconInterface,
  empiraCrop02 as EmpiraIconInterface,
  empiraCryptocurrency01 as EmpiraIconInterface,
  empiraCryptocurrency02 as EmpiraIconInterface,
  empiraCryptocurrency03 as EmpiraIconInterface,
  empiraCryptocurrency04 as EmpiraIconInterface,
  empiraCube01 as EmpiraIconInterface,
  empiraCube02 as EmpiraIconInterface,
  empiraCube03 as EmpiraIconInterface,
  empiraCube04 as EmpiraIconInterface,
  empiraCubeOutline as EmpiraIconInterface,
  empiraCurrencyBitcoinCircle as EmpiraIconInterface,
  empiraCurrencyBitcoin as EmpiraIconInterface,
  empiraCurrencyDollarCircle as EmpiraIconInterface,
  empiraCurrencyDollar as EmpiraIconInterface,
  empiraCurrencyEthereumCircle as EmpiraIconInterface,
  empiraCurrencyEthereum as EmpiraIconInterface,
  empiraCurrencyEuroCircle as EmpiraIconInterface,
  empiraCurrencyEuro as EmpiraIconInterface,
  empiraCurrencyPoundCircle as EmpiraIconInterface,
  empiraCurrencyPound as EmpiraIconInterface,
  empiraCurrencyRubleCircle as EmpiraIconInterface,
  empiraCurrencyRuble as EmpiraIconInterface,
  empiraCurrencyRupeeCircle as EmpiraIconInterface,
  empiraCurrencyRupee as EmpiraIconInterface,
  empiraCurrencyYenCircle as EmpiraIconInterface,
  empiraCurrencyYen as EmpiraIconInterface,
  empiraCursor01 as EmpiraIconInterface,
  empiraCursor02 as EmpiraIconInterface,
  empiraCursor03 as EmpiraIconInterface,
  empiraCursor04 as EmpiraIconInterface,
  empiraCursorBox as EmpiraIconInterface,
  empiraCursorClick01 as EmpiraIconInterface,
  empiraCursorClick02 as EmpiraIconInterface,
  empiraData as EmpiraIconInterface,
  empiraDatabase01 as EmpiraIconInterface,
  empiraDatabase02 as EmpiraIconInterface,
  empiraDatabase03 as EmpiraIconInterface,
  empiraDataflow01 as EmpiraIconInterface,
  empiraDataflow02 as EmpiraIconInterface,
  empiraDataflow03 as EmpiraIconInterface,
  empiraDataflow04 as EmpiraIconInterface,
  empiraDelete as EmpiraIconInterface,
  empiraDiamond01 as EmpiraIconInterface,
  empiraDiamond02 as EmpiraIconInterface,
  empiraDice1 as EmpiraIconInterface,
  empiraDice2 as EmpiraIconInterface,
  empiraDice3 as EmpiraIconInterface,
  empiraDice4 as EmpiraIconInterface,
  empiraDice5 as EmpiraIconInterface,
  empiraDice6 as EmpiraIconInterface,
  empiraDisc01 as EmpiraIconInterface,
  empiraDisc02 as EmpiraIconInterface,
  empiraDistributeSpacingHorizontal as EmpiraIconInterface,
  empiraDistributeSpacingVertical as EmpiraIconInterface,
  empiraDivide01 as EmpiraIconInterface,
  empiraDivide02 as EmpiraIconInterface,
  empiraDivide03 as EmpiraIconInterface,
  empiraDivider as EmpiraIconInterface,
  empiraDotpoints01 as EmpiraIconInterface,
  empiraDotpoints02 as EmpiraIconInterface,
  empiraDotsGrid as EmpiraIconInterface,
  empiraDotsHorizontal as EmpiraIconInterface,
  empiraDotsVertical as EmpiraIconInterface,
  empiraDownload01 as EmpiraIconInterface,
  empiraDownload02 as EmpiraIconInterface,
  empiraDownload03 as EmpiraIconInterface,
  empiraDownload04 as EmpiraIconInterface,
  empiraDownloadCloud01 as EmpiraIconInterface,
  empiraDownloadCloud02 as EmpiraIconInterface,
  empiraDrop as EmpiraIconInterface,
  empiraDroplets01 as EmpiraIconInterface,
  empiraDroplets02 as EmpiraIconInterface,
  empiraDroplets03 as EmpiraIconInterface,
  empiraDropper as EmpiraIconInterface,
  empiraEarth as EmpiraIconInterface,
  empiraEdit01 as EmpiraIconInterface,
  empiraEdit02 as EmpiraIconInterface,
  empiraEdit03 as EmpiraIconInterface,
  empiraEdit04 as EmpiraIconInterface,
  empiraEdit05 as EmpiraIconInterface,
  empiraEqualNot as EmpiraIconInterface,
  empiraEqual as EmpiraIconInterface,
  empiraEraser as EmpiraIconInterface,
  empiraExpand01 as EmpiraIconInterface,
  empiraExpand02 as EmpiraIconInterface,
  empiraExpand03 as EmpiraIconInterface,
  empiraExpand04 as EmpiraIconInterface,
  empiraExpand05 as EmpiraIconInterface,
  empiraExpand06 as EmpiraIconInterface,
  empiraEyeOff as EmpiraIconInterface,
  empiraEye as EmpiraIconInterface,
  empiraFaceContent as EmpiraIconInterface,
  empiraFaceFrown as EmpiraIconInterface,
  empiraFaceHappy as EmpiraIconInterface,
  empiraFaceIdSquare as EmpiraIconInterface,
  empiraFaceId as EmpiraIconInterface,
  empiraFaceNeutral as EmpiraIconInterface,
  empiraFaceSad as EmpiraIconInterface,
  empiraFaceSmile as EmpiraIconInterface,
  empiraFaceWink as EmpiraIconInterface,
  empiraFastBackward as EmpiraIconInterface,
  empiraFastForward as EmpiraIconInterface,
  empiraFeather as EmpiraIconInterface,
  empiraFigma as EmpiraIconInterface,
  empiraFile01 as EmpiraIconInterface,
  empiraFile02 as EmpiraIconInterface,
  empiraFile03 as EmpiraIconInterface,
  empiraFile04 as EmpiraIconInterface,
  empiraFile05 as EmpiraIconInterface,
  empiraFile06 as EmpiraIconInterface,
  empiraFile07 as EmpiraIconInterface,
  empiraFileAttachment01 as EmpiraIconInterface,
  empiraFileAttachment02 as EmpiraIconInterface,
  empiraFileAttachment03 as EmpiraIconInterface,
  empiraFileAttachment04 as EmpiraIconInterface,
  empiraFileAttachment05 as EmpiraIconInterface,
  empiraFileCheck01 as EmpiraIconInterface,
  empiraFileCheck02 as EmpiraIconInterface,
  empiraFileCheck03 as EmpiraIconInterface,
  empiraFileCode01 as EmpiraIconInterface,
  empiraFileCode02 as EmpiraIconInterface,
  empiraFileDownload01 as EmpiraIconInterface,
  empiraFileDownload02 as EmpiraIconInterface,
  empiraFileDownload03 as EmpiraIconInterface,
  empiraFileHeart01 as EmpiraIconInterface,
  empiraFileHeart02 as EmpiraIconInterface,
  empiraFileHeart03 as EmpiraIconInterface,
  empiraFileLock01 as EmpiraIconInterface,
  empiraFileLock02 as EmpiraIconInterface,
  empiraFileLock03 as EmpiraIconInterface,
  empiraFileMinus01 as EmpiraIconInterface,
  empiraFileMinus02 as EmpiraIconInterface,
  empiraFileMinus03 as EmpiraIconInterface,
  empiraFilePlus01 as EmpiraIconInterface,
  empiraFilePlus02 as EmpiraIconInterface,
  empiraFilePlus03 as EmpiraIconInterface,
  empiraFileQuestion01 as EmpiraIconInterface,
  empiraFileQuestion02 as EmpiraIconInterface,
  empiraFileQuestion03 as EmpiraIconInterface,
  empiraFileSearch01 as EmpiraIconInterface,
  empiraFileSearch02 as EmpiraIconInterface,
  empiraFileSearch03 as EmpiraIconInterface,
  empiraFileShield01 as EmpiraIconInterface,
  empiraFileShield02 as EmpiraIconInterface,
  empiraFileShield03 as EmpiraIconInterface,
  empiraFileTypeAudio as EmpiraIconInterface,
  empiraFileTypeAvi as EmpiraIconInterface,
  empiraFileTypeCode as EmpiraIconInterface,
  empiraFileTypeCsv as EmpiraIconInterface,
  empiraFileTypeDoc as EmpiraIconInterface,
  empiraFileTypeDocument as EmpiraIconInterface,
  empiraFileTypeDocx as EmpiraIconInterface,
  empiraFileTypeEmpty as EmpiraIconInterface,
  empiraFileTypeEps as EmpiraIconInterface,
  empiraFileTypeGif as EmpiraIconInterface,
  empiraFileTypeImage as EmpiraIconInterface,
  empiraFileTypeImg as EmpiraIconInterface,
  empiraFileTypeJpeg as EmpiraIconInterface,
  empiraFileTypeJpg as EmpiraIconInterface,
  empiraFileTypeMkv as EmpiraIconInterface,
  empiraFileTypeMp3 as EmpiraIconInterface,
  empiraFileTypeMp4 as EmpiraIconInterface,
  empiraFileTypeMpeg as EmpiraIconInterface,
  empiraFileTypePdf as EmpiraIconInterface,
  empiraFileTypePng as EmpiraIconInterface,
  empiraFileTypePpt as EmpiraIconInterface,
  empiraFileTypePptx as EmpiraIconInterface,
  empiraFileTypeRar as EmpiraIconInterface,
  empiraFileTypeSpreadsheet as EmpiraIconInterface,
  empiraFileTypeSvg as EmpiraIconInterface,
  empiraFileTypeTiff as EmpiraIconInterface,
  empiraFileTypeTxt as EmpiraIconInterface,
  empiraFileTypeVideo as EmpiraIconInterface,
  empiraFileTypeWav as EmpiraIconInterface,
  empiraFileTypeWebp as EmpiraIconInterface,
  empiraFileTypeXls as EmpiraIconInterface,
  empiraFileTypeXlsx as EmpiraIconInterface,
  empiraFileTypeZip as EmpiraIconInterface,
  empiraFileX01 as EmpiraIconInterface,
  empiraFileX02 as EmpiraIconInterface,
  empiraFileX03 as EmpiraIconInterface,
  empiraFilm01 as EmpiraIconInterface,
  empiraFilm02 as EmpiraIconInterface,
  empiraFilm03 as EmpiraIconInterface,
  empiraFilterFunnel01 as EmpiraIconInterface,
  empiraFilterFunnel02 as EmpiraIconInterface,
  empiraFilterLines as EmpiraIconInterface,
  empiraFingerprint01 as EmpiraIconInterface,
  empiraFingerprint02 as EmpiraIconInterface,
  empiraFingerprint03 as EmpiraIconInterface,
  empiraFingerprint04 as EmpiraIconInterface,
  empiraFlag01 as EmpiraIconInterface,
  empiraFlag02 as EmpiraIconInterface,
  empiraFlag03 as EmpiraIconInterface,
  empiraFlag04 as EmpiraIconInterface,
  empiraFlag05 as EmpiraIconInterface,
  empiraFlag06 as EmpiraIconInterface,
  empiraFlashOff as EmpiraIconInterface,
  empiraFlash as EmpiraIconInterface,
  empiraFlexAlignBottom as EmpiraIconInterface,
  empiraFlexAlignLeft as EmpiraIconInterface,
  empiraFlexAlignRight as EmpiraIconInterface,
  empiraFlexAlignTop as EmpiraIconInterface,
  empiraFlipBackward as EmpiraIconInterface,
  empiraFlipForward as EmpiraIconInterface,
  empiraFolderCheck as EmpiraIconInterface,
  empiraFolderClosed as EmpiraIconInterface,
  empiraFolderCode as EmpiraIconInterface,
  empiraFolderDownload as EmpiraIconInterface,
  empiraFolderLock as EmpiraIconInterface,
  empiraFolderMinus as EmpiraIconInterface,
  empiraFolderPlus as EmpiraIconInterface,
  empiraFolderQuestion as EmpiraIconInterface,
  empiraFolderSearch as EmpiraIconInterface,
  empiraFolderShield as EmpiraIconInterface,
  empiraFolderX as EmpiraIconInterface,
  empiraFolder as EmpiraIconInterface,
  empiraFrame as EmpiraIconInterface,
  empiraFramer as EmpiraIconInterface,
  empiraGamingPad01 as EmpiraIconInterface,
  empiraGamingPad02 as EmpiraIconInterface,
  empiraGift01 as EmpiraIconInterface,
  empiraGift02 as EmpiraIconInterface,
  empiraGitBranch01 as EmpiraIconInterface,
  empiraGitBranch02 as EmpiraIconInterface,
  empiraGitCommit as EmpiraIconInterface,
  empiraGitMerge as EmpiraIconInterface,
  empiraGitPullRequest as EmpiraIconInterface,
  empiraGlasses01 as EmpiraIconInterface,
  empiraGlasses02 as EmpiraIconInterface,
  empiraGlobe01 as EmpiraIconInterface,
  empiraGlobe02 as EmpiraIconInterface,
  empiraGlobe03 as EmpiraIconInterface,
  empiraGlobe04 as EmpiraIconInterface,
  empiraGlobe05 as EmpiraIconInterface,
  empiraGlobe06 as EmpiraIconInterface,
  empiraGlobeSlated01 as EmpiraIconInterface,
  empiraGlobeSlated02 as EmpiraIconInterface,
  empiraGoogleChrome as EmpiraIconInterface,
  empiraGraduationHat01 as EmpiraIconInterface,
  empiraGraduationHat02 as EmpiraIconInterface,
  empiraGrid01 as EmpiraIconInterface,
  empiraGrid02 as EmpiraIconInterface,
  empiraGrid03 as EmpiraIconInterface,
  empiraGridDotsBlank as EmpiraIconInterface,
  empiraGridDotsBottom as EmpiraIconInterface,
  empiraGridDotsHorizontalCenter as EmpiraIconInterface,
  empiraGridDotsLeft as EmpiraIconInterface,
  empiraGridDotsOuter as EmpiraIconInterface,
  empiraGridDotsRight as EmpiraIconInterface,
  empiraGridDotsTop as EmpiraIconInterface,
  empiraGridDotsVerticalCenter as EmpiraIconInterface,
  empiraHand as EmpiraIconInterface,
  empiraHardDrive as EmpiraIconInterface,
  empiraHash01 as EmpiraIconInterface,
  empiraHash02 as EmpiraIconInterface,
  empiraHeading01 as EmpiraIconInterface,
  empiraHeading02 as EmpiraIconInterface,
  empiraHeadingSquare as EmpiraIconInterface,
  empiraHeadphones01 as EmpiraIconInterface,
  empiraHeadphones02 as EmpiraIconInterface,
  empiraHeartCircle as EmpiraIconInterface,
  empiraHeartHand as EmpiraIconInterface,
  empiraHeartHexagon as EmpiraIconInterface,
  empiraHeartOctagon as EmpiraIconInterface,
  empiraHeartRounded as EmpiraIconInterface,
  empiraHeartSquare as EmpiraIconInterface,
  empiraHeart as EmpiraIconInterface,
  empiraHearts as EmpiraIconInterface,
  empiraHelpCircle as EmpiraIconInterface,
  empiraHelpHexagon as EmpiraIconInterface,
  empiraHelpOctagon as EmpiraIconInterface,
  empiraHelpSquare as EmpiraIconInterface,
  empiraHexagon01 as EmpiraIconInterface,
  empiraHexagon02 as EmpiraIconInterface,
  empiraHome01 as EmpiraIconInterface,
  empiraHome02 as EmpiraIconInterface,
  empiraHome03 as EmpiraIconInterface,
  empiraHome04 as EmpiraIconInterface,
  empiraHome05 as EmpiraIconInterface,
  empiraHomeLine as EmpiraIconInterface,
  empiraHomeSmile as EmpiraIconInterface,
  empiraHorizontalBarChart01 as EmpiraIconInterface,
  empiraHorizontalBarChart02 as EmpiraIconInterface,
  empiraHorizontalBarChart03 as EmpiraIconInterface,
  empiraHourglass01 as EmpiraIconInterface,
  empiraHourglass02 as EmpiraIconInterface,
  empiraHourglass03 as EmpiraIconInterface,
  empiraHurricane01 as EmpiraIconInterface,
  empiraHurricane02 as EmpiraIconInterface,
  empiraHurricane03 as EmpiraIconInterface,
  empiraImage01 as EmpiraIconInterface,
  empiraImage02 as EmpiraIconInterface,
  empiraImage03 as EmpiraIconInterface,
  empiraImage04 as EmpiraIconInterface,
  empiraImage05 as EmpiraIconInterface,
  empiraImageCheck as EmpiraIconInterface,
  empiraImageDown as EmpiraIconInterface,
  empiraImageIndentLeft as EmpiraIconInterface,
  empiraImageIndentRight as EmpiraIconInterface,
  empiraImageLeft as EmpiraIconInterface,
  empiraImagePlus as EmpiraIconInterface,
  empiraImageRight as EmpiraIconInterface,
  empiraImageUp as EmpiraIconInterface,
  empiraImageUserCheck as EmpiraIconInterface,
  empiraImageUserDown as EmpiraIconInterface,
  empiraImageUserLeft as EmpiraIconInterface,
  empiraImageUserPlus as EmpiraIconInterface,
  empiraImageUserRight as EmpiraIconInterface,
  empiraImageUserUp as EmpiraIconInterface,
  empiraImageUserX as EmpiraIconInterface,
  empiraImageUser as EmpiraIconInterface,
  empiraImageX as EmpiraIconInterface,
  empiraInbox01 as EmpiraIconInterface,
  empiraInbox02 as EmpiraIconInterface,
  empiraInfinity as EmpiraIconInterface,
  empiraInfoCircle as EmpiraIconInterface,
  empiraInfoHexagon as EmpiraIconInterface,
  empiraInfoOctagon as EmpiraIconInterface,
  empiraInfoSquare as EmpiraIconInterface,
  empiraIntersectCircle as EmpiraIconInterface,
  empiraIntersectSquare as EmpiraIconInterface,
  empiraItalic01 as EmpiraIconInterface,
  empiraItalic02 as EmpiraIconInterface,
  empiraItalicSquare as EmpiraIconInterface,
  empiraKey01 as EmpiraIconInterface,
  empiraKey02 as EmpiraIconInterface,
  empiraKeyboard01 as EmpiraIconInterface,
  empiraKeyboard02 as EmpiraIconInterface,
  empiraLaptop01 as EmpiraIconInterface,
  empiraLaptop02 as EmpiraIconInterface,
  empiraLayerSingle as EmpiraIconInterface,
  empiraLayersThree01 as EmpiraIconInterface,
  empiraLayersThree02 as EmpiraIconInterface,
  empiraLayersTwo01 as EmpiraIconInterface,
  empiraLayersTwo02 as EmpiraIconInterface,
  empiraLayoutAlt01 as EmpiraIconInterface,
  empiraLayoutAlt02 as EmpiraIconInterface,
  empiraLayoutAlt03 as EmpiraIconInterface,
  empiraLayoutAlt04 as EmpiraIconInterface,
  empiraLayoutBottom as EmpiraIconInterface,
  empiraLayoutGrid01 as EmpiraIconInterface,
  empiraLayoutGrid02 as EmpiraIconInterface,
  empiraLayoutLeft as EmpiraIconInterface,
  empiraLayoutRight as EmpiraIconInterface,
  empiraLayoutTop as EmpiraIconInterface,
  empiraLeftIndent01 as EmpiraIconInterface,
  empiraLeftIndent02 as EmpiraIconInterface,
  empiraLetterSpacing01 as EmpiraIconInterface,
  empiraLetterSpacing02 as EmpiraIconInterface,
  empiraLifeBuoy01 as EmpiraIconInterface,
  empiraLifeBuoy02 as EmpiraIconInterface,
  empiraLightbulb01 as EmpiraIconInterface,
  empiraLightbulb02 as EmpiraIconInterface,
  empiraLightbulb03 as EmpiraIconInterface,
  empiraLightbulb04 as EmpiraIconInterface,
  empiraLightbulb05 as EmpiraIconInterface,
  empiraLightning01 as EmpiraIconInterface,
  empiraLightning02 as EmpiraIconInterface,
  empiraLineChartDown01 as EmpiraIconInterface,
  empiraLineChartDown02 as EmpiraIconInterface,
  empiraLineChartDown03 as EmpiraIconInterface,
  empiraLineChartDown04 as EmpiraIconInterface,
  empiraLineChartDown05 as EmpiraIconInterface,
  empiraLineChartUp01 as EmpiraIconInterface,
  empiraLineChartUp02 as EmpiraIconInterface,
  empiraLineChartUp03 as EmpiraIconInterface,
  empiraLineChartUp04 as EmpiraIconInterface,
  empiraLineChartUp05 as EmpiraIconInterface,
  empiraLineHeight as EmpiraIconInterface,
  empiraLink01 as EmpiraIconInterface,
  empiraLink02 as EmpiraIconInterface,
  empiraLink03 as EmpiraIconInterface,
  empiraLink04 as EmpiraIconInterface,
  empiraLink05 as EmpiraIconInterface,
  empiraLinkBroken01 as EmpiraIconInterface,
  empiraLinkBroken02 as EmpiraIconInterface,
  empiraLinkExternal01 as EmpiraIconInterface,
  empiraLinkExternal02 as EmpiraIconInterface,
  empiraList as EmpiraIconInterface,
  empiraLoading01 as EmpiraIconInterface,
  empiraLoading02 as EmpiraIconInterface,
  empiraLoading03 as EmpiraIconInterface,
  empiraLock01 as EmpiraIconInterface,
  empiraLock02 as EmpiraIconInterface,
  empiraLock03 as EmpiraIconInterface,
  empiraLock04 as EmpiraIconInterface,
  empiraLockKeyholeCircle as EmpiraIconInterface,
  empiraLockKeyholeSquare as EmpiraIconInterface,
  empiraLockUnlocked01 as EmpiraIconInterface,
  empiraLockUnlocked02 as EmpiraIconInterface,
  empiraLockUnlocked03 as EmpiraIconInterface,
  empiraLockUnlocked04 as EmpiraIconInterface,
  empiraLogIn01 as EmpiraIconInterface,
  empiraLogIn02 as EmpiraIconInterface,
  empiraLogIn03 as EmpiraIconInterface,
  empiraLogIn04 as EmpiraIconInterface,
  empiraLogOut01 as EmpiraIconInterface,
  empiraLogOut02 as EmpiraIconInterface,
  empiraLogOut03 as EmpiraIconInterface,
  empiraLogOut04 as EmpiraIconInterface,
  empiraLuggage01 as EmpiraIconInterface,
  empiraLuggage02 as EmpiraIconInterface,
  empiraLuggage03 as EmpiraIconInterface,
  empiraMagicWand01 as EmpiraIconInterface,
  empiraMagicWand02 as EmpiraIconInterface,
  empiraMail01 as EmpiraIconInterface,
  empiraMail02 as EmpiraIconInterface,
  empiraMail03 as EmpiraIconInterface,
  empiraMail04 as EmpiraIconInterface,
  empiraMail05 as EmpiraIconInterface,
  empiraMap01 as EmpiraIconInterface,
  empiraMap02 as EmpiraIconInterface,
  empiraMark as EmpiraIconInterface,
  empiraMarkerPin01 as EmpiraIconInterface,
  empiraMarkerPin02 as EmpiraIconInterface,
  empiraMarkerPin03 as EmpiraIconInterface,
  empiraMarkerPin04 as EmpiraIconInterface,
  empiraMarkerPin05 as EmpiraIconInterface,
  empiraMarkerPin06 as EmpiraIconInterface,
  empiraMaximize01 as EmpiraIconInterface,
  empiraMaximize02 as EmpiraIconInterface,
  empiraMedicalCircle as EmpiraIconInterface,
  empiraMedicalCross as EmpiraIconInterface,
  empiraMedicalSquare as EmpiraIconInterface,
  empiraMenu01 as EmpiraIconInterface,
  empiraMenu02 as EmpiraIconInterface,
  empiraMenu03 as EmpiraIconInterface,
  empiraMenu04 as EmpiraIconInterface,
  empiraMenu05 as EmpiraIconInterface,
  empiraMessageAlertCircle as EmpiraIconInterface,
  empiraMessageAlertSquare as EmpiraIconInterface,
  empiraMessageChatCircle as EmpiraIconInterface,
  empiraMessageChatSquare as EmpiraIconInterface,
  empiraMessageCheckCircle as EmpiraIconInterface,
  empiraMessageCheckSquare as EmpiraIconInterface,
  empiraMessageCircle01 as EmpiraIconInterface,
  empiraMessageCircle02 as EmpiraIconInterface,
  empiraMessageDotsCircle as EmpiraIconInterface,
  empiraMessageDotsSquare as EmpiraIconInterface,
  empiraMessageHeartCircle as EmpiraIconInterface,
  empiraMessageHeartSquare as EmpiraIconInterface,
  empiraMessageNotificationCircle as EmpiraIconInterface,
  empiraMessageNotificationSquare as EmpiraIconInterface,
  empiraMessagePlusCircle as EmpiraIconInterface,
  empiraMessagePlusSquare as EmpiraIconInterface,
  empiraMessageQuestionCircle as EmpiraIconInterface,
  empiraMessageQuestionSquare as EmpiraIconInterface,
  empiraMessageSmileCircle as EmpiraIconInterface,
  empiraMessageSmileSquare as EmpiraIconInterface,
  empiraMessageSquare01 as EmpiraIconInterface,
  empiraMessageSquare02 as EmpiraIconInterface,
  empiraMessageTextCircle01 as EmpiraIconInterface,
  empiraMessageTextCircle02 as EmpiraIconInterface,
  empiraMessageTextSquare01 as EmpiraIconInterface,
  empiraMessageTextSquare02 as EmpiraIconInterface,
  empiraMessageXCircle as EmpiraIconInterface,
  empiraMessageXSquare as EmpiraIconInterface,
  empiraMicrophone01 as EmpiraIconInterface,
  empiraMicrophone02 as EmpiraIconInterface,
  empiraMicrophoneOff01 as EmpiraIconInterface,
  empiraMicrophoneOff02 as EmpiraIconInterface,
  empiraMicroscope as EmpiraIconInterface,
  empiraMinimize01 as EmpiraIconInterface,
  empiraMinimize02 as EmpiraIconInterface,
  empiraMinusCircle as EmpiraIconInterface,
  empiraMinusSquare as EmpiraIconInterface,
  empiraMinus as EmpiraIconInterface,
  empiraModem01 as EmpiraIconInterface,
  empiraModem02 as EmpiraIconInterface,
  empiraMonitor01 as EmpiraIconInterface,
  empiraMonitor02 as EmpiraIconInterface,
  empiraMonitor03 as EmpiraIconInterface,
  empiraMonitor04 as EmpiraIconInterface,
  empiraMonitor05 as EmpiraIconInterface,
  empiraMoon01 as EmpiraIconInterface,
  empiraMoon02 as EmpiraIconInterface,
  empiraMoonEclipse as EmpiraIconInterface,
  empiraMoonStar as EmpiraIconInterface,
  empiraMouse as EmpiraIconInterface,
  empiraMove as EmpiraIconInterface,
  empiraMusicNote01 as EmpiraIconInterface,
  empiraMusicNote02 as EmpiraIconInterface,
  empiraMusicNotePlus as EmpiraIconInterface,
  empiraNavigationPointer01 as EmpiraIconInterface,
  empiraNavigationPointer02 as EmpiraIconInterface,
  empiraNavigationPointerOff01 as EmpiraIconInterface,
  empiraNavigationPointerOff02 as EmpiraIconInterface,
  empiraNotificationBox as EmpiraIconInterface,
  empiraNotificationMessage as EmpiraIconInterface,
  empiraNotificationText as EmpiraIconInterface,
  empiraOctagon as EmpiraIconInterface,
  empiraPackageCheck as EmpiraIconInterface,
  empiraPackageMinus as EmpiraIconInterface,
  empiraPackagePlus as EmpiraIconInterface,
  empiraPackageSearch as EmpiraIconInterface,
  empiraPackageX as EmpiraIconInterface,
  empiraPackage as EmpiraIconInterface,
  empiraPaintPour as EmpiraIconInterface,
  empiraPaint as EmpiraIconInterface,
  empiraPalette as EmpiraIconInterface,
  empiraPaperclip as EmpiraIconInterface,
  empiraParagraphSpacing as EmpiraIconInterface,
  empiraParagraphWrap as EmpiraIconInterface,
  empiraPasscodeLock as EmpiraIconInterface,
  empiraPasscode as EmpiraIconInterface,
  empiraPassport as EmpiraIconInterface,
  empiraPauseCircle as EmpiraIconInterface,
  empiraPauseSquare as EmpiraIconInterface,
  empiraPenTool01 as EmpiraIconInterface,
  empiraPenTool02 as EmpiraIconInterface,
  empiraPenToolMinus as EmpiraIconInterface,
  empiraPenToolPlus as EmpiraIconInterface,
  empiraPencil01 as EmpiraIconInterface,
  empiraPencil02 as EmpiraIconInterface,
  empiraPencilLine as EmpiraIconInterface,
  empiraPentagon as EmpiraIconInterface,
  empiraPercent01 as EmpiraIconInterface,
  empiraPercent02 as EmpiraIconInterface,
  empiraPercent03 as EmpiraIconInterface,
  empiraPerspective01 as EmpiraIconInterface,
  empiraPerspective02 as EmpiraIconInterface,
  empiraPhone01 as EmpiraIconInterface,
  empiraPhone02 as EmpiraIconInterface,
  empiraPhoneCall01 as EmpiraIconInterface,
  empiraPhoneCall02 as EmpiraIconInterface,
  empiraPhoneHangUp as EmpiraIconInterface,
  empiraPhoneIncoming01 as EmpiraIconInterface,
  empiraPhoneIncoming02 as EmpiraIconInterface,
  empiraPhoneOutgoing01 as EmpiraIconInterface,
  empiraPhoneOutgoing02 as EmpiraIconInterface,
  empiraPhonePause as EmpiraIconInterface,
  empiraPhonePlus as EmpiraIconInterface,
  empiraPhoneX as EmpiraIconInterface,
  empiraPhone as EmpiraIconInterface,
  empiraPieChart01 as EmpiraIconInterface,
  empiraPieChart02 as EmpiraIconInterface,
  empiraPieChart03 as EmpiraIconInterface,
  empiraPieChart04 as EmpiraIconInterface,
  empiraPiggyBank01 as EmpiraIconInterface,
  empiraPiggyBank02 as EmpiraIconInterface,
  empiraPilcrow01 as EmpiraIconInterface,
  empiraPilcrow02 as EmpiraIconInterface,
  empiraPilcrowSquare as EmpiraIconInterface,
  empiraPin01 as EmpiraIconInterface,
  empiraPin02 as EmpiraIconInterface,
  empiraPlaceholder as EmpiraIconInterface,
  empiraPlane as EmpiraIconInterface,
  empiraPlayCircle as EmpiraIconInterface,
  empiraPlaySquare as EmpiraIconInterface,
  empiraPlay as EmpiraIconInterface,
  empiraPlusCircle as EmpiraIconInterface,
  empiraPlusSquare as EmpiraIconInterface,
  empiraPlus as EmpiraIconInterface,
  empiraPodcast as EmpiraIconInterface,
  empiraPower01 as EmpiraIconInterface,
  empiraPower02 as EmpiraIconInterface,
  empiraPower03 as EmpiraIconInterface,
  empiraPresentationChart01 as EmpiraIconInterface,
  empiraPresentationChart02 as EmpiraIconInterface,
  empiraPresentationChart03 as EmpiraIconInterface,
  empiraPrinter as EmpiraIconInterface,
  empiraPuzzlePiece01 as EmpiraIconInterface,
  empiraPuzzlePiece02 as EmpiraIconInterface,
  empiraQrCode01 as EmpiraIconInterface,
  empiraQrCode02 as EmpiraIconInterface,
  empiraReceiptCheck as EmpiraIconInterface,
  empiraReceipt as EmpiraIconInterface,
  empiraRecording01 as EmpiraIconInterface,
  empiraRecording02 as EmpiraIconInterface,
  empiraRecording03 as EmpiraIconInterface,
  empiraReflect01 as EmpiraIconInterface,
  empiraReflect02 as EmpiraIconInterface,
  empiraRefreshCcw01 as EmpiraIconInterface,
  empiraRefreshCcw02 as EmpiraIconInterface,
  empiraRefreshCcw03 as EmpiraIconInterface,
  empiraRefreshCcw04 as EmpiraIconInterface,
  empiraRefreshCcw05 as EmpiraIconInterface,
  empiraRefreshCw01 as EmpiraIconInterface,
  empiraRefreshCw02 as EmpiraIconInterface,
  empiraRefreshCw03 as EmpiraIconInterface,
  empiraRefreshCw04 as EmpiraIconInterface,
  empiraRefreshCw05 as EmpiraIconInterface,
  empiraRepeat01 as EmpiraIconInterface,
  empiraRepeat02 as EmpiraIconInterface,
  empiraRepeat03 as EmpiraIconInterface,
  empiraRepeat04 as EmpiraIconInterface,
  empiraReverseLeft as EmpiraIconInterface,
  empiraReverseRight as EmpiraIconInterface,
  empiraRightIndent01 as EmpiraIconInterface,
  empiraRightIndent02 as EmpiraIconInterface,
  empiraRocket01 as EmpiraIconInterface,
  empiraRocket02 as EmpiraIconInterface,
  empiraRollerBrush as EmpiraIconInterface,
  empiraRoute as EmpiraIconInterface,
  empiraRows01 as EmpiraIconInterface,
  empiraRows02 as EmpiraIconInterface,
  empiraRows03 as EmpiraIconInterface,
  empiraRss01 as EmpiraIconInterface,
  empiraRss02 as EmpiraIconInterface,
  empiraRuler as EmpiraIconInterface,
  empiraSafe as EmpiraIconInterface,
  empiraSale01 as EmpiraIconInterface,
  empiraSale02 as EmpiraIconInterface,
  empiraSale03 as EmpiraIconInterface,
  empiraSale04 as EmpiraIconInterface,
  empiraSave01 as EmpiraIconInterface,
  empiraSave02 as EmpiraIconInterface,
  empiraSave03 as EmpiraIconInterface,
  empiraScale01 as EmpiraIconInterface,
  empiraScale02 as EmpiraIconInterface,
  empiraScale03 as EmpiraIconInterface,
  empiraScales01 as EmpiraIconInterface,
  empiraScales02 as EmpiraIconInterface,
  empiraScan as EmpiraIconInterface,
  empiraScissors01 as EmpiraIconInterface,
  empiraScissors02 as EmpiraIconInterface,
  empiraScissorsCut01 as EmpiraIconInterface,
  empiraScissorsCut02 as EmpiraIconInterface,
  empiraSearchLg as EmpiraIconInterface,
  empiraSearchMd as EmpiraIconInterface,
  empiraSearchRefraction as EmpiraIconInterface,
  empiraSearchSm as EmpiraIconInterface,
  empiraSend01 as EmpiraIconInterface,
  empiraSend02 as EmpiraIconInterface,
  empiraSend03 as EmpiraIconInterface,
  empiraServer01 as EmpiraIconInterface,
  empiraServer02 as EmpiraIconInterface,
  empiraServer03 as EmpiraIconInterface,
  empiraServer04 as EmpiraIconInterface,
  empiraServer05 as EmpiraIconInterface,
  empiraServer06 as EmpiraIconInterface,
  empiraSettings01 as EmpiraIconInterface,
  empiraSettings02 as EmpiraIconInterface,
  empiraSettings03 as EmpiraIconInterface,
  empiraSettings04 as EmpiraIconInterface,
  empiraShare01 as EmpiraIconInterface,
  empiraShare02 as EmpiraIconInterface,
  empiraShare03 as EmpiraIconInterface,
  empiraShare04 as EmpiraIconInterface,
  empiraShare05 as EmpiraIconInterface,
  empiraShare06 as EmpiraIconInterface,
  empiraShare07 as EmpiraIconInterface,
  empiraShield01 as EmpiraIconInterface,
  empiraShield02 as EmpiraIconInterface,
  empiraShield03 as EmpiraIconInterface,
  empiraShieldDollar as EmpiraIconInterface,
  empiraShieldOff as EmpiraIconInterface,
  empiraShieldPlus as EmpiraIconInterface,
  empiraShieldTick as EmpiraIconInterface,
  empiraShieldZap as EmpiraIconInterface,
  empiraShoppingBag01 as EmpiraIconInterface,
  empiraShoppingBag02 as EmpiraIconInterface,
  empiraShoppingBag03 as EmpiraIconInterface,
  empiraShoppingCart01 as EmpiraIconInterface,
  empiraShoppingCart02 as EmpiraIconInterface,
  empiraShoppingCart03 as EmpiraIconInterface,
  empiraShuffle01 as EmpiraIconInterface,
  empiraShuffle02 as EmpiraIconInterface,
  empiraSignal01 as EmpiraIconInterface,
  empiraSignal02 as EmpiraIconInterface,
  empiraSignal03 as EmpiraIconInterface,
  empiraSimcard as EmpiraIconInterface,
  empiraSkew as EmpiraIconInterface,
  empiraSkipBack as EmpiraIconInterface,
  empiraSkipForward as EmpiraIconInterface,
  empiraSlashCircle01 as EmpiraIconInterface,
  empiraSlashCircle02 as EmpiraIconInterface,
  empiraSlashDivider as EmpiraIconInterface,
  empiraSlashOctagon as EmpiraIconInterface,
  empiraSliders01 as EmpiraIconInterface,
  empiraSliders02 as EmpiraIconInterface,
  empiraSliders03 as EmpiraIconInterface,
  empiraSliders04 as EmpiraIconInterface,
  empiraSnowflake01 as EmpiraIconInterface,
  empiraSnowflake02 as EmpiraIconInterface,
  empiraSpacingHeight01 as EmpiraIconInterface,
  empiraSpacingHeight02 as EmpiraIconInterface,
  empiraSpacingWidth01 as EmpiraIconInterface,
  empiraSpacingWidth02 as EmpiraIconInterface,
  empiraSpeaker01 as EmpiraIconInterface,
  empiraSpeaker02 as EmpiraIconInterface,
  empiraSpeaker03 as EmpiraIconInterface,
  empiraSpeedometer01 as EmpiraIconInterface,
  empiraSpeedometer02 as EmpiraIconInterface,
  empiraSpeedometer03 as EmpiraIconInterface,
  empiraSpeedometer04 as EmpiraIconInterface,
  empiraSquare as EmpiraIconInterface,
  empiraStand as EmpiraIconInterface,
  empiraStar01 as EmpiraIconInterface,
  empiraStar02 as EmpiraIconInterface,
  empiraStar03 as EmpiraIconInterface,
  empiraStar04 as EmpiraIconInterface,
  empiraStar05 as EmpiraIconInterface,
  empiraStar06 as EmpiraIconInterface,
  empiraStar07 as EmpiraIconInterface,
  empiraStars01 as EmpiraIconInterface,
  empiraStars02 as EmpiraIconInterface,
  empiraStars03 as EmpiraIconInterface,
  empiraStickerCircle as EmpiraIconInterface,
  empiraStickerSquare as EmpiraIconInterface,
  empiraStopCircle as EmpiraIconInterface,
  empiraStopSquare as EmpiraIconInterface,
  empiraStop as EmpiraIconInterface,
  empiraStrikethrough01 as EmpiraIconInterface,
  empiraStrikethrough02 as EmpiraIconInterface,
  empiraStrikethroughSquare as EmpiraIconInterface,
  empiraSubscript as EmpiraIconInterface,
  empiraSunSetting01 as EmpiraIconInterface,
  empiraSunSetting02 as EmpiraIconInterface,
  empiraSunSetting03 as EmpiraIconInterface,
  empiraSun as EmpiraIconInterface,
  empiraSunrise as EmpiraIconInterface,
  empiraSunset as EmpiraIconInterface,
  empiraSwitchHorizontal01 as EmpiraIconInterface,
  empiraSwitchHorizontal02 as EmpiraIconInterface,
  empiraSwitchVertical01 as EmpiraIconInterface,
  empiraSwitchVertical02 as EmpiraIconInterface,
  empiraTabPlaceholder as EmpiraIconInterface,
  empiraTable as EmpiraIconInterface,
  empiraTablet01 as EmpiraIconInterface,
  empiraTablet02 as EmpiraIconInterface,
  empiraTag01 as EmpiraIconInterface,
  empiraTag02 as EmpiraIconInterface,
  empiraTag03 as EmpiraIconInterface,
  empiraTarget01 as EmpiraIconInterface,
  empiraTarget02 as EmpiraIconInterface,
  empiraTarget03 as EmpiraIconInterface,
  empiraTarget04 as EmpiraIconInterface,
  empiraTarget05 as EmpiraIconInterface,
  empiraTelescope as EmpiraIconInterface,
  empiraTerminalBrowser as EmpiraIconInterface,
  empiraTerminalCircle as EmpiraIconInterface,
  empiraTerminalSquare as EmpiraIconInterface,
  empiraTerminal as EmpiraIconInterface,
  empiraTextInput as EmpiraIconInterface,
  empiraThermometer01 as EmpiraIconInterface,
  empiraThermometer02 as EmpiraIconInterface,
  empiraThermometer03 as EmpiraIconInterface,
  empiraThermometerCold as EmpiraIconInterface,
  empiraThermometerWarm as EmpiraIconInterface,
  empiraThumbsDown as EmpiraIconInterface,
  empiraThumbsUp as EmpiraIconInterface,
  empiraTicket01 as EmpiraIconInterface,
  empiraTicket02 as EmpiraIconInterface,
  empiraToggle01Left as EmpiraIconInterface,
  empiraToggle01Right as EmpiraIconInterface,
  empiraToggle02Left as EmpiraIconInterface,
  empiraToggle02Right as EmpiraIconInterface,
  empiraToggle03Left as EmpiraIconInterface,
  empiraToggle03Right as EmpiraIconInterface,
  empiraTool01 as EmpiraIconInterface,
  empiraTool02 as EmpiraIconInterface,
  empiraTrain as EmpiraIconInterface,
  empiraTram as EmpiraIconInterface,
  empiraTransform as EmpiraIconInterface,
  empiraTranslate01 as EmpiraIconInterface,
  empiraTranslate02 as EmpiraIconInterface,
  empiraTrash01 as EmpiraIconInterface,
  empiraTrash02 as EmpiraIconInterface,
  empiraTrash03 as EmpiraIconInterface,
  empiraTrash04 as EmpiraIconInterface,
  empiraTrendDown01 as EmpiraIconInterface,
  empiraTrendDown02 as EmpiraIconInterface,
  empiraTrendUp01 as EmpiraIconInterface,
  empiraTrendUp02 as EmpiraIconInterface,
  empiraTriangle as EmpiraIconInterface,
  empiraTrophy01 as EmpiraIconInterface,
  empiraTrophy02 as EmpiraIconInterface,
  empiraTruck01 as EmpiraIconInterface,
  empiraTruck02 as EmpiraIconInterface,
  empiraTv01 as EmpiraIconInterface,
  empiraTv02 as EmpiraIconInterface,
  empiraTv03 as EmpiraIconInterface,
  empiraType01 as EmpiraIconInterface,
  empiraType02 as EmpiraIconInterface,
  empiraTypeSquare as EmpiraIconInterface,
  empiraTypeStrikethrough01 as EmpiraIconInterface,
  empiraTypeStrikethrough02 as EmpiraIconInterface,
  empiraUmbrella01 as EmpiraIconInterface,
  empiraUmbrella02 as EmpiraIconInterface,
  empiraUmbrella03 as EmpiraIconInterface,
  empiraUnderline01 as EmpiraIconInterface,
  empiraUnderline02 as EmpiraIconInterface,
  empiraUnderlineSquare as EmpiraIconInterface,
  empiraUpload01 as EmpiraIconInterface,
  empiraUpload02 as EmpiraIconInterface,
  empiraUpload03 as EmpiraIconInterface,
  empiraUpload04 as EmpiraIconInterface,
  empiraUploadCloud01 as EmpiraIconInterface,
  empiraUploadCloud02 as EmpiraIconInterface,
  empiraUsbFlashDrive as EmpiraIconInterface,
  empiraUser01 as EmpiraIconInterface,
  empiraUser02 as EmpiraIconInterface,
  empiraUser03 as EmpiraIconInterface,
  empiraUserCheck01 as EmpiraIconInterface,
  empiraUserCheck02 as EmpiraIconInterface,
  empiraUserCircle as EmpiraIconInterface,
  empiraUserDown01 as EmpiraIconInterface,
  empiraUserDown02 as EmpiraIconInterface,
  empiraUserEdit as EmpiraIconInterface,
  empiraUserLeft01 as EmpiraIconInterface,
  empiraUserLeft02 as EmpiraIconInterface,
  empiraUserMinus01 as EmpiraIconInterface,
  empiraUserMinus02 as EmpiraIconInterface,
  empiraUserPlus01 as EmpiraIconInterface,
  empiraUserPlus02 as EmpiraIconInterface,
  empiraUserRight01 as EmpiraIconInterface,
  empiraUserRight02 as EmpiraIconInterface,
  empiraUserSquare as EmpiraIconInterface,
  empiraUserUp01 as EmpiraIconInterface,
  empiraUserUp02 as EmpiraIconInterface,
  empiraUserX01 as EmpiraIconInterface,
  empiraUserX02 as EmpiraIconInterface,
  empiraUsers01 as EmpiraIconInterface,
  empiraUsers02 as EmpiraIconInterface,
  empiraUsers03 as EmpiraIconInterface,
  empiraUsersCheck as EmpiraIconInterface,
  empiraUsersDown as EmpiraIconInterface,
  empiraUsersEdit as EmpiraIconInterface,
  empiraUsersLeft as EmpiraIconInterface,
  empiraUsersMinus as EmpiraIconInterface,
  empiraUsersPlus as EmpiraIconInterface,
  empiraUsersRight as EmpiraIconInterface,
  empiraUsersUp as EmpiraIconInterface,
  empiraUsersX as EmpiraIconInterface,
  empiraVariable as EmpiraIconInterface,
  empiraVideoRecorderOff as EmpiraIconInterface,
  empiraVideoRecorder as EmpiraIconInterface,
  empiraVirus as EmpiraIconInterface,
  empiraVoicemail as EmpiraIconInterface,
  empiraVolumeMax as EmpiraIconInterface,
  empiraVolumeMin as EmpiraIconInterface,
  empiraVolumeMinus as EmpiraIconInterface,
  empiraVolumePlus as EmpiraIconInterface,
  empiraVolumeX as EmpiraIconInterface,
  empiraWallet01 as EmpiraIconInterface,
  empiraWallet02 as EmpiraIconInterface,
  empiraWallet03 as EmpiraIconInterface,
  empiraWallet04 as EmpiraIconInterface,
  empiraWallet05 as EmpiraIconInterface,
  empiraWatchCircle as EmpiraIconInterface,
  empiraWatchSquare as EmpiraIconInterface,
  empiraWaves as EmpiraIconInterface,
  empiraWebcam01 as EmpiraIconInterface,
  empiraWebcam02 as EmpiraIconInterface,
  empiraWifiOff as EmpiraIconInterface,
  empiraWifi as EmpiraIconInterface,
  empiraWind01 as EmpiraIconInterface,
  empiraWind02 as EmpiraIconInterface,
  empiraWind03 as EmpiraIconInterface,
  empiraXCircle as EmpiraIconInterface,
  empiraXClose as EmpiraIconInterface,
  empiraXSquare as EmpiraIconInterface,
  empiraX as EmpiraIconInterface,
  empiraYoutube as EmpiraIconInterface,
  empiraZapCircle as EmpiraIconInterface,
  empiraZapFast as EmpiraIconInterface,
  empiraZapOff as EmpiraIconInterface,
  empiraZapSquare as EmpiraIconInterface,
  empiraZap as EmpiraIconInterface,
  empiraZoomIn as EmpiraIconInterface,
  empiraZoomOut as EmpiraIconInterface,
];
