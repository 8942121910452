import { CreateDocumentDto } from '../dtos/create-document.dto';
import { CreateFile } from '../interfaces/create-file';
import { getFileTypeFromFilename } from './helpers/get-file-type-from-filename';
import { blobToBase64File } from '../../shared/helpers/blob-to-base64-file';

export async function mapCreateFileToCreateDocumentDto(
  baseFile: CreateFile,
  folderId: number | null,
): Promise<CreateDocumentDto> {
  return {
    fileName: baseFile.name,
    fileType: getFileTypeFromFilename(baseFile.file.name),
    mimeType: baseFile.file.type,
    fileSize: baseFile.file.size,
    description: baseFile.description || '',
    documentPath: '',
    doc: await blobToBase64File(baseFile.file),
    isPublic: baseFile.isPublic,
    allowComment: baseFile.allowComment,
    effectiveFrom: baseFile.effectiveFrom ?? new Date(),
    effectiveTo: baseFile.effectiveTo,
    folderId,
  };
}
