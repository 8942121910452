import { Component, Input, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { DIALOG_DATA } from '../../tokens/DIALOG_DATA';
import { DialogData } from '../../interfaces/dialog-data';
import { ConfirmData } from '../../interfaces/confirm-data';
import { MatButtonModule } from '@angular/material/button';
import { FormUrl } from 'src/app/shared/constants/form-url';
import { Observable, of } from 'rxjs';
import { TranslateNewPipe } from '../../../translation/pipes/translate-new.pipe';

@Component({
  selector: 'app-confirm',
  standalone: true,
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  imports: [AsyncPipe, NgIf, MatButtonModule, TranslateNewPipe],
})
export class ConfirmComponent {
  @Input()
  public width?: string;

  protected asObservable(value: string | Observable<string>) {
    if (typeof value === 'string') {
      return of(value);
    }

    return value;
  }

  protected formUrl = FormUrl.DialogConfirm;

  protected dialogData = inject(DIALOG_DATA) as DialogData<ConfirmData>;

  protected cancelClick() {
    this.dialogData.dialogRef.close(false);
  }

  protected confirmClick() {
    this.dialogData.dialogRef.close(true);
  }
}
