<div class="user-profile-permission">
  <app-user-profile-permissions
    (dataRoomExists)="onDataRoomExist($event)"
    [userId]="idUser"
    [isUser]="isUser"
  />
</div>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab>
    <ng-template
      mat-tab-label
      data-translation-value="Details"
      data-translation-key="user-permissions-form-label-details"
    >
      {{ "user-permissions-form-label-details" | translateNew: formUrl }}
    </ng-template>
    <ng-template matTabContent>
      <app-user-details
        [otherUser]="true"
        [userId]="idUser"
        [isUser]="isUser"
      />
    </ng-template>
  </mat-tab>
  <mat-tab [disabled]="!isUser">
    <ng-template
      mat-tab-label
      data-translation-value="Settings"
      data-translation-key="user-permissions-form-label-settings"
    >
      {{ "user-permissions-form-label-settings" | translateNew: formUrl }}
    </ng-template>
    <ng-template matTabContent>
      <app-list [backlink]="backlink" [userId]="idUser"></app-list>
    </ng-template>
  </mat-tab>
  <mat-tab [disabled]="!isUser">
    <ng-template
      mat-tab-label
      data-translation-value="Profile picture"
      data-translation-key="user-permissions-form-label-profile-picture"
    >
      {{
        "user-permissions-form-label-profile-picture" | translateNew: formUrl
      }}
    </ng-template>
    <ng-template matTabContent>
      <app-user-picture
        [backlink]="backlink"
        [isUser]="true"
        [userId]="idUser"
      />
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template
      mat-tab-label
      data-translation-value="Permissions"
      data-translation-key="user-permissions-form-label-permissions"
    >
      {{ "user-permissions-form-label-permissions" | translateNew: formUrl }}
    </ng-template>
    <ng-template matTabContent>
      <app-user-permission
        [isUser]="isUser"
        [backlink]="backlink"
        [userId]="idUser"
      />
    </ng-template>
  </mat-tab>
  <mat-tab [disabled]="!isUser || !dataRoomExists">
    <ng-template
      mat-tab-label
      data-translation-value="Data Room"
      data-translation-key="user-permissions-form-data-room"
    >
      {{ "user-permissions-form-data-room" | translateNew: formUrl }}
    </ng-template>
    <ng-template matTabContent>
      <app-user-data-room [userId]="idUser" />
    </ng-template>
  </mat-tab>
</mat-tab-group>
