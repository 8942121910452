<ng-container *appSpinner="isSubmitting$ | async" />
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <app-form-header
    [containTab]="true"
    [headline]="'user-permissions-form-settings' | translateNew: formUrl"
    [description]="'user-permissions-form-description' | translateNew: formUrl"
  >
    <a
      [routerLink]="backlink"
      mat-flat-button
      data-translation-key="cancel-btn"
      data-translation-value="Cancel"
      >{{ "cancel-btn" | translateNew: formUrl }}</a
    >
    <button
      mat-flat-button
      color="primary"
      type="submit"
      data-translation-key="save-btn"
      data-translation-value="Save"
    >
      {{ "save-btn" | translateNew: formUrl }}
    </button>
  </app-form-header>
  <app-shared-form-field>
    <mat-label
      data-translation-key="user-permissions-is-active"
      data-translation-value="Active"
      >{{ "user-permissions-is-active" | translateNew: formUrl }}</mat-label
    >
    <mat-slide-toggle formControlName="isActive" />
  </app-shared-form-field>
  <ng-container *ngIf="isInternal">
    <app-shared-form-field>
      <mat-label
        data-translation-key="user-permissions-is-admin"
        data-translation-value="Admin"
        >{{ "user-permissions-is-admin" | translateNew: formUrl }}</mat-label
      >
      <mat-slide-toggle formControlName="isAdmin" />
    </app-shared-form-field>
    <app-shared-form-field>
      <mat-label
        data-translation-key="user-permissions-is-super-admin"
        data-translation-value="Super admin"
        >{{
          "user-permissions-is-super-admin" | translateNew: formUrl
        }}</mat-label
      >
      <mat-slide-toggle formControlName="isSuperAdmin" />
    </app-shared-form-field>
    <app-shared-form-field>
      <mat-label
        data-translation-key="user-permissions-can-switch-platform"
        data-translation-value="Switching platform"
        >{{ "user-permissions-can-switch-platform" | translateNew: formUrl }}
      </mat-label>
      <mat-slide-toggle formControlName="allowSwitchingPlatform" />
    </app-shared-form-field>
  </ng-container>
  <ng-container *ngIf="!isInternal">
    <app-shared-form-field>
      <mat-label
        data-translation-key="user-permissions-bussiness-entity"
        data-translation-value="Business entity"
        >{{ "user-permissions-bussiness-entity" | translateNew: formUrl }}
      </mat-label>
      <mat-form-field *ngIf="businessEntities$ | async as businessEntities">
        <mat-select
          formControlName="businessEntityId"
          placeholder="Select an option"
        >
          <mat-option
            *ngFor="let entity of businessEntities"
            [value]="entity.id"
          >
            {{ entity.organization }} - {{ entity.firstname }}
            {{ entity.surname }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </app-shared-form-field>
  </ng-container>
  <app-shared-form-field>
    <mat-label
      data-translation-key="user-permissions-permission"
      data-translation-value="Permission"
      >{{ "user-permissions-permission" | translateNew: formUrl }}
    </mat-label>
    <mat-form-field>
      <mat-select
        formControlName="userGroupId"
        placeholder="Choose a User Group"
      >
        <mat-option *ngFor="let opt of data.userGroups" [value]="opt.id">
          {{ opt.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <dx-tree-list
      class="user-form-permission"
      [expandedRowKeys]="parentsSelectedValues"
      [selectedRowKeys]="selectedValues"
      (onSelectionChanged)="selectRow($event)"
      [dataSource]="treeDataSource"
      keyExpr="id"
      parentIdExpr="formId"
      (onContentReady)="toggleLoading()"
    >
      <dxo-selection mode="multiple" [recursive]="true" />
      <dxi-column dataField="name" [width]="300" />
      <dxo-pager [visible]="false" />
    </dx-tree-list>
  </app-shared-form-field>
  <ng-container
    data-translation-key="user-permissions-form-settings"
    data-translation-value="Permissions"
  />
  <ng-container
    data-translation-key="user-permissions-form-description"
    data-translation-value="Manage user access and permissions."
  />
</form>
