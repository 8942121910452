import { BusinessEntity } from '../interfaces/business-entity.interface';

export function mapNamingToDisplayName(item: BusinessEntity): BusinessEntity {
  return {
    id: item.id,
    organization: item.organization,
    firstname: item.firstname,
    surname: item.surname,
    displayName: `${item.organization} - ${item.firstname} - ${item.surname}`,
  };
}
