<img *ngIf="preview" class="preview" [src]="preview" alt="" />
<app-shared-drag-and-drop-field
  [formControl]="uploadField"
  [multiple]="false"
  [allowedFileTypes]="allowedFileTypes"
  [mimeTypeError]="mimeTypeError"
  data-translation-key="file-types"
  data-translation-value="SVG, PNG, JPG or GIF"
  [infotext]="'file-types' | translateNew: formUrl"
  [allowedFileSize]="allowedFileSize"
/>
