import { Environment } from '../app/shared/interfaces/environment';

export const environment: Environment = {
  production: false,
  withCredentials: false,
  apiUrl: 'https://empira-development-wa.azurewebsites.net/api/v1',
  version: '1.0.0',
  tenantId: '86fedbbd-f934-40e1-844d-6fe0fea5e46c',
  clientId: '063131eb-dd2b-42c5-b468-0e71dcfd440d',
  redirectUri:
    'https://empiravision-dev.wiso-tech-services.de/authentication/azure-auth',
  scopes: '.default',
  azureLoginUrl:
    'https://login.microsoftonline.com/{tenantId}/oauth2/v2.0/authorize?client_id={clientId}&response_type=token&redirect_uri={redirectUri}&scope={scopes}&prompt=select_account',
  mapbox: {
    apiToken:
      'pk.eyJ1IjoiZW1waXJhLXZpc2lvbiIsImEiOiJjbHJvcWQwNTMwY2I3MmtuamU0cHc3MXhvIn0.4JWOCdExdPR2enmJwY2oJQ',
    styles: [
      {
        label: 'Standard',
        value: 'mapbox://styles/empira-vision/cls1fyqml00as01ql5lg47imo',
      },
      {
        label: 'Satellite',
        value: 'mapbox://styles/mapbox/satellite-v9',
      },
    ],
  },
  supportItemId: 14,
  devExtremeLicenseKey:
    'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogImNjZmYxZWZlLWI4OTYtNDkzOC1hODJlLWYzNGM5NDVkNmJhNSIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.l2RdL+1bojdWt1RcGGbHF9o+CqjBT5R2SVwzyzoz25bOQgThHMru6X0OQRxcDvTf0m/5PhdEjSZNnzOsdGamSuyMR4bVp8gCAa/cdOdWZVJR7kJSaz61vwlecZiImPboKBb0Xw==',
};
