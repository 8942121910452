<div
  class="profile-picture-content"
  [class.has-border]="hasBorder"
  [class.has-shadow]="hasShadow"
>
  <svg
    *ngIf="!profilePicture && name"
    height="100"
    width="100"
    viewBox="0 0 100 100"
  >
    <text x="50" y="50">{{ name | initials }}</text>
  </svg>
  <img *ngIf="profilePicture" [src]="profilePicture" alt="" />
</div>
