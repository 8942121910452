import { DataRoomDto } from '../dtos/data-room.dto';
import { DataRoom } from '../interfaces/data-room';

export function mapDataRoomDtoToNewDataRoom(
  dataRoomDto: DataRoomDto,
): DataRoom {
  return {
    id: dataRoomDto.id,
    name: dataRoomDto.name,
    rootFolderId: dataRoomDto.folderList[0].id,
  };
}
