import { FolderContentDto } from '../dtos/folder-content.dto';
import { FolderContent } from '../interfaces/folder-content.interface';
import { mapDocumentDtoToListItem } from './map-document-dto-to-list-item';
import { mapFolderDtoToListItem } from './map-folder-dto-to-list-item';

export function mapFolderContentDtoToFolderContent(
  folderContentDto: FolderContentDto | null,
): FolderContent {
  if (folderContentDto === null) {
    return { breadcrumb: [], content: [] };
  }

  return {
    breadcrumb: folderContentDto.breadcrumbFolderList.map(
      mapFolderDtoToListItem,
    ),
    content: [
      ...folderContentDto.folderList.map((folderDto) =>
        mapFolderDtoToListItem(folderDto),
      ),
      ...folderContentDto.documentList.map(mapDocumentDtoToListItem),
    ],
  };
}
