<p>{{ asObservable(dialogData.data.content) | async }}</p>
<div class="buttons">
  <button
    *ngIf="dialogData.data.cancelButton"
    mat-flat-button
    (click)="cancelClick()"
    data-translation-key="btn-cancel"
    data-translation-value="Cancel"
  >
    {{ "btn-cancel" | translateNew: formUrl }}
  </button>

  <button
    mat-flat-button
    color="primary"
    class="button-color-{{ dialogData.data.color }}"
    (click)="confirmClick()"
  >
    {{ dialogData.data.successTitle }}
  </button>
</div>
