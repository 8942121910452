import { Component, inject, Input, OnChanges, ViewChild } from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxToolbarModule,
} from 'devextreme-angular';
import { AsyncPipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { DataRoomService } from '../../services/data-room.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ListItem } from '../../interfaces/list-item';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  BehaviorSubject,
  combineLatest,
  lastValueFrom,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { MatMenuModule } from '@angular/material/menu';
import { DialogService } from '../../../dialog/services/dialog.service';
import { FileCreateComponent } from '../file-create/file-create.component';
import { FileEditComponent } from '../file-edit/file-edit.component';
import { DownloadType } from '../../enum/download-type.interface';
import { PermissionComponent } from '../permission/permission/permission.component';
import { FolderEditComponent } from '../folder-edit/folder-edit.component';
import { FolderCreateComponent } from '../folder-create/folder-create.component';
import { FilesizePipe } from '../../pipes/filesize.pipe';
import { ApprovalComponent } from '../approval/approval.component';
import { TranslateNewPipe } from '../../../translation/pipes/translate-new.pipe';
import { FormUrl } from '../../../shared/constants/form-url';
import { FolderUploadComponent } from '../folder-upload/folder-upload.component';
import { DragAndDropFieldComponent } from '../../../shared/components/drag-and-drop-field/drag-and-drop-field.component';
import { DragAndDropDirective } from '../../../shared/directives/drag-and-drop.directive';
import { DropFiles } from '../../../shared/interfaces/drop-files';
import { FormsModule } from '@angular/forms';
import { IconService } from '../../../icons/services/icon.service';
import { FileIconPipe } from '../../../shared/pipes/file-icon.pipe';
import { SingleMultipleOrNone } from 'devextreme/common';
import { base64ToFile } from '../../../shared/helpers/base64-to-file';
import { TranslatePipe } from '../../../translation/pipes/translate.pipe';
import { TranslationService } from '../../../translation/services/translation.service';
import { SelectionToolbarComponent } from '../../../shared/components/selection-toolbar/selection-toolbar.component';
import { SelectionToolbarAction } from '../../../shared/interfaces/selection-toolbar-action.interface';
import { FilePreview } from '../../../dialog/interfaces/file-preview';
import { FilePreviewDirective } from '../../../dialog/directives/file-preview.directive';
import { DataRoomPermissionSharing } from '../../interfaces/data-room-permission-sharing.interface';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-data-room-list',
  standalone: true,
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  imports: [
    AsyncPipe,
    NgIf,
    RouterLink,
    DxDataGridModule,
    MatButtonModule,
    MatIconModule,
    NgForOf,
    BreadcrumbComponent,
    MatMenuModule,
    FilesizePipe,
    TranslateNewPipe,
    DragAndDropFieldComponent,
    DragAndDropDirective,
    FormsModule,
    FileIconPipe,
    NgTemplateOutlet,
    TranslatePipe,
    DxToolbarModule,
    SelectionToolbarComponent,
    FilePreviewDirective,
  ],
  providers: [DataRoomService],
})
export class ListComponent implements OnChanges {
  private dataRoomService = inject(DataRoomService);

  private router = inject(Router);

  private route = inject(ActivatedRoute);

  protected formUrl = FormUrl.DataRoomsList;

  @ViewChild(DxDataGridComponent) private grid!: DxDataGridComponent;

  @Input({ required: true })
  public set dataRoomId(dataRoomId: number) {
    this.dataRoomId$.next(dataRoomId);
  }
  public get dataRoomId() {
    return this.dataRoomId$.value;
  }

  @Input()
  canCreateFolder = false;

  @Input()
  canUpdateFolder = false;

  @Input()
  canDeleteFolder = false;

  @Input()
  canUploadFolder = true;

  @Input()
  canUploadFile = false;

  @Input()
  canUpdateFile = false;

  @Input()
  canDeleteFile = false;

  @Input()
  canApprove = true;

  @Input()
  canSetPermissions = false;

  @Input()
  canSetPermissionsGlobally = false;

  protected canShowAdditionalFolderOptions$ = new BehaviorSubject(false);

  protected canShowAdditionalFileOptions$ = new BehaviorSubject(false);

  protected parentFolderId: number | null = null;

  protected itemsSelected: ListItem[] = [];

  private translationService = inject(TranslationService);

  private dialogService = inject(DialogService);

  protected selectionActions: SelectionToolbarAction[] = [
    { icon: 'trash_01', action: () => this.deleteSelected() },
  ];

  @ViewChild(DxDataGridComponent, { static: true })
  private datagrid!: DxDataGridComponent;

  private dataRoomId$ = new BehaviorSubject<number>(0);

  private dataRoomReload$ = new ReplaySubject<void>(1);

  private currentFolder$ = this.selectFolderContent();

  protected currentFolderContent$ = this.selectCurrentLevel();

  protected breadcrumb$ = this.selectBreadcrumb();

  private rootFolderId: number | null = null;

  constructor() {
    inject(IconService).registerIconList([
      'check',
      'dots_vertical',
      'download_02',
      'edit_05',
      'eye',
      'file_03',
      'folder',
      'plus',
      'printer',
      'trash_01',
      'upload_02',
      'users_01',
    ]);
  }

  ngOnChanges() {
    this.canShowAdditionalFolderOptions$.next(
      this.canSetPermissions || this.canUpdateFolder || this.canDeleteFolder,
    );
    this.canShowAdditionalFileOptions$.next(
      this.canSetPermissions || this.canUpdateFile || this.canDeleteFile,
    );
  }

  public repaint() {
    this.grid.instance.repaint();
  }

  protected get selectionMode(): SingleMultipleOrNone {
    return this.canDeleteAnything() ? 'multiple' : 'none';
  }

  protected deselectAllItems() {
    this.datagrid.instance.deselectAll();
  }

  protected dropFiles(dropFiles: DropFiles) {
    if (!dropFiles.files) {
      return;
    }

    dropFiles.isDirectory
      ? this.openFolderUploadDialog(dropFiles.files)
      : this.openFileCreateDialog(dropFiles.files);
  }

  protected deleteSelected() {
    const deleteFileIds = this.itemsSelected
      .filter((item) => !item.isDirectory)
      .map((item) => item.id);
    const deleteFolderIds = this.itemsSelected
      .filter((item) => item.isDirectory)
      .map((item) => item.id);

    this.deleteMultipleItems(deleteFileIds, deleteFolderIds);
  }

  private canDeleteAnything() {
    return this.canDeleteFile || this.canDeleteFolder;
  }

  protected getThumbnail(element: ListItem): string {
    if (!element.thumbnail) {
      console.warn('No thumbnail for current element');
      return '';
    }
    return `data:${element.mimeType};base64,${element.thumbnail}`;
  }

  protected async deleteFile(item: ListItem) {
    const title = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-delete-file-title',
      this.formUrl,
    );
    const description = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-delete-file-description',
      this.formUrl,
    );

    return this.deleteItem(item, title, description);
  }

  protected async deleteFolder(item: ListItem) {
    const title = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-delete-folder-title',
      this.formUrl,
    );
    const description = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-delete-folder-description',
      this.formUrl,
    );

    return this.deleteItem(item, title, description);
  }

  private selectRootFolderContents() {
    return this.dataRoomId$.pipe(
      shareReplay(),
      switchMap((dataRoomId) =>
        this.dataRoomService.selectFolderContentByDataRoomId(dataRoomId),
      ),
      tap(
        (rootFolderContent) =>
          (this.rootFolderId = this.parentFolderId =
            rootFolderContent.breadcrumb[0].id),
      ),
    );
  }

  private selectFolderContent() {
    return combineLatest([
      this.selectParentFolderId(),
      this.dataRoomReload$.pipe(startWith(null)),
    ]).pipe(
      tap(([currentFolderId]) => (this.parentFolderId = currentFolderId)),
      switchMap(([currentFolderId]) =>
        currentFolderId && currentFolderId !== this.rootFolderId
          ? this.dataRoomService.selectFolderContentByFolderId(currentFolderId)
          : this.selectRootFolderContents(),
      ),
      shareReplay(),
    );
  }

  private selectCurrentLevel() {
    return this.currentFolder$.pipe(
      map((folderContent) => folderContent.content),
    );
  }

  private selectBreadcrumb() {
    return this.currentFolder$.pipe(
      map((folderContent) => folderContent.breadcrumb),
    );
  }

  private selectParentFolderId() {
    return this.route.queryParams.pipe(
      map((params) =>
        isNaN(params['parentFolderId'])
          ? this.rootFolderId
          : Number(params['parentFolderId']),
      ),
    );
  }

  private async deleteItem(
    item: ListItem,
    title: string | Observable<string>,
    description: string | Observable<string>,
  ) {
    this.dialogService
      .deleteConfirm(title, description)
      .afterClosed.subscribe(async (shallDelete) => {
        if (!shallDelete) {
          return;
        }

        try {
          if (!item.isDirectory) {
            await this.dataRoomService.deleteFile(item.id);
            this.dataRoomReload$.next();

            return;
          }

          await this.dataRoomService.deleteFolder(item.id);
          this.dataRoomReload$.next();

          if (this.parentFolderId === item.id) {
            this.router.navigate(['.'], {
              queryParams: { parentFolderId: item.parentId },
              relativeTo: this.route,
            });
          }
        } catch (error) {
          console.error(error);
        }
      });
  }

  private async deleteMultipleItems(fileIds: number[], folderIds: number[]) {
    const title = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-delete-multiple-title',
      this.formUrl,
    );
    const description = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-delete-multiple-description',
      this.formUrl,
    );

    this.dialogService
      .deleteConfirm(title, description)
      .afterClosed.subscribe(async (shallDelete) => {
        if (!shallDelete) {
          return;
        }

        try {
          await this.dataRoomService.deleteMultiple(fileIds, folderIds);
          this.dataRoomReload$.next();
        } catch (error) {
          console.error(error);
        }
      });
  }

  protected async openFolderCreateDialog() {
    const headline = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-create-folder',
      this.formUrl,
    );

    this.dialogService
      .open(FolderCreateComponent, {
        headline,
        icon: 'folder',
        data: {
          dataRoomId: this.dataRoomId,
          parentFolderId: this.parentFolderId,
        },
      })
      .afterClosed.subscribe((folderUpdated) => {
        if (folderUpdated) {
          this.dataRoomReload$.next();
        }
      });
  }

  protected async openFolderEditDialog(item: ListItem) {
    const headline = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-edit-folder',
      this.formUrl,
    );

    this.dialogService
      .open(FolderEditComponent, {
        headline,
        icon: 'folder',
        data: {
          folder: item.id,
          dataRoomId: this.dataRoomId,
          parentFolderId: item.parentId,
        },
      })
      .afterClosed.subscribe((folderUpdated) => {
        if (folderUpdated) {
          this.dataRoomReload$.next();
        }
      });
  }

  protected async shareToOthers(item: ListItem) {
    const headline = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-headline-permissions',
      this.formUrl,
    );

    item.dataRoomId = this.dataRoomId;
    this.dialogService
      .open(PermissionComponent, {
        headline,
        data: item,
      })
      .afterClosed.pipe(
        map((response) => response as DataRoomPermissionSharing),
      )
      .subscribe(async (response) => {
        if (response.status) {
          try {
            if (await this.dataRoomService.share(response)) {
              this.dataRoomReload$.next();
            }
          } catch (error) {
            console.error('', error);
          }
        }
      });
  }

  protected async approval(item: ListItem) {
    const headline = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-headline-approvals',
      this.formUrl,
    );

    item.dataRoomId = this.dataRoomId;
    this.dialogService
      .open(ApprovalComponent, {
        headline,
        data: item,
      })
      .afterClosed.subscribe((fileAdded) => {
        if (fileAdded) {
          this.dataRoomReload$.next();
        }
      });
  }

  protected openFileCreateDialog(files?: File[]) {
    const headline = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-headline-upload-files',
      this.formUrl,
    );

    this.dialogService
      .open(FileCreateComponent, {
        headline,
        icon: 'upload_02',
        data: {
          parentFolderId: this.parentFolderId,
          files,
        },
      })
      .afterClosed.subscribe((fileAdded) => {
        if (fileAdded) {
          this.dataRoomReload$.next();
        }
      });
  }

  protected openFolderUploadDialog(fileList?: File[]) {
    const headline = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-headline-upload-folder',
      this.formUrl,
    );

    this.dialogService
      .open(FolderUploadComponent, {
        headline,
        icon: 'upload_02',
        data: {
          parentFolderId: this.parentFolderId,
          dataRoomId: this.dataRoomId,
          fileList,
        },
      })
      .afterClosed.subscribe((fileAdded) => {
        if (fileAdded) {
          this.dataRoomReload$.next();
        }
      });
  }

  protected openFileEditDialog(fileId: number, parentFolderId: number) {
    const headline = this.translationService.selectTranslationByKey(
      'gallery-list-dialog-headline-edit-file',
      this.formUrl,
    );

    this.dialogService
      .open(FileEditComponent, {
        headline,
        icon: 'upload_02',
        data: {
          file: fileId,
          parentFolderId,
        },
      })
      .afterClosed.subscribe((fileUpdated) => {
        if (fileUpdated) {
          this.dataRoomReload$.next();
        }
      });
  }

  protected downloadContent(): void {
    if (this.parentFolderId) {
      const listItem = {
        id: this.parentFolderId,
        isDirectory: true,
      } as ListItem;
      this.downloadFileOrFolder(listItem);
    } else {
      this.downloadDataRoom();
    }
  }

  protected approvalContent(): void {
    const listItem = {
      dataRoomId: this.dataRoomId,
    } as ListItem;
    this.approval(listItem);
  }

  protected permissionContent(): void {
    const parentFolderId =
      +this.route.snapshot.queryParamMap.get('parentFolderId')!;
    const listItem = {
      dataRoomId: this.dataRoomId,
      id: parentFolderId,
      isDirectory: parentFolderId != 0,
    } as ListItem;
    this.shareToOthers(listItem);
  }

  protected async downloadDataRoom() {
    const blob: Blob = await lastValueFrom(
      this.dataRoomService.download(this.dataRoomId, DownloadType.DataRoom),
    );
    this.triggerFileDownload(blob, 'DataRoom.zip');
  }

  protected async downloadFileOrFolder(file: ListItem) {
    const blob: Blob = await lastValueFrom(
      this.dataRoomService.download(
        file.id,
        file.isDirectory ? DownloadType.Folder : DownloadType.Document,
      ),
    );
    this.triggerFileDownload(
      blob,
      file.isDirectory ? 'Folder.zip' : 'Document',
    );
  }

  private triggerFileDownload(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getImagePreview(file: ListItem): FilePreview {
    return {
      imagePath: this.dataRoomService.getFilePreview(file.id),
      headline: { DE: file.name || '', EN: file.name || '' },
      subheadline: {
        DE: file.description || '',
        EN: file.description || '',
      },
    };
  }

  protected async openFilePreview(file: ListItem) {
    const base64File = await lastValueFrom(
      this.dataRoomService.getFilePreview(file.id),
    );

    const newTab = window.open('about:blank');

    if (!newTab) {
      return;
    }

    setTimeout(() => {
      const objectElement = newTab.document.createElement('object');

      objectElement.type = file.mimeType as string;
      objectElement.className = 'internal';
      objectElement.data = base64File;

      if (file.mimeType === 'application/pdf') {
        objectElement.height = '100%';
        objectElement.width = '100%';

        newTab.document.body.replaceWith(objectElement);

        return;
      }

      //FireFox seems to require a setTimeout for this to work.
      newTab.document.body.appendChild(objectElement);
    });
  }

  protected async print(file: ListItem) {
    const fileString = await lastValueFrom(
      this.dataRoomService.getFilePreview(file.id),
    );
    const base64String = fileString.split(',')[1];
    const blob = base64ToFile(base64String, file.mimeType || '');

    this.printBlob(blob);
  }

  private printBlob(blob: Blob) {
    const iframeId = 'printframe';
    const printframe = document.getElementById(iframeId);

    if (printframe) {
      printframe.remove();
    }

    const iframe = document.createElement('iframe');
    iframe.id = iframeId;
    iframe.style.display = 'none';

    iframe.src = URL.createObjectURL(blob);
    document.body.appendChild(iframe);

    iframe.onload = () => {
      if (!iframe.contentWindow) {
        console.error("The iframe doesn't exist. Printing not possible");
        return;
      }

      iframe.contentWindow.print();
    };
  }
}
