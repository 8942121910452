import { Observable, Subject } from 'rxjs';

export class DialogRef {
  close(result?: unknown) {
    this._afterClosed.next(result);
  }

  element!: HTMLDialogElement;

  private readonly _afterClosed = new Subject<unknown>();
  afterClosed: Observable<unknown> = this._afterClosed.asObservable();
}
